import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Icon from "components/Icon";
import Badge from "components/Badge";
import Button from "components/Button";
import { CompanyCardProps } from "../interfaces";
import { Highlight } from "components/Highlight";
import { GlobalActions } from "store/reducers/global";
import CompanyPicture from "components/CompanyPicture";
import HoverComponent from "components/HoverComponent";
import { useUnarchiveCompanyMutation } from "store/api/investor";
import ActionPopover, { ActionPopoverListItem } from "components/ActionPopover";

const ArchivedCompanyCard: FC<CompanyCardProps> = ({ company, query }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [unarchiveCompany] = useUnarchiveCompanyMutation();

    const [isHovered, setIsHovered] = useState(false);

    const handleClick = () => navigate(`/company/${company.id}`);

    const actions: Array<ActionPopoverListItem> = [
        {
            label: "Unarchive company",
            onClick: async () => {
                try {
                    await unarchiveCompany({ id: company.id });
                    dispatch(
                        GlobalActions.showPopover({
                            type: "dark",
                            label: `Unarchived ${company.name}`,
                        })
                    );
                } catch (err: any) {
                    console.error(err.message);
                    dispatch(
                        GlobalActions.showPopover({
                            type: "error",
                            label: `Something went wrong, please refresh the page`,
                        })
                    );
                }
            },
        },
    ];

    return (
        <article
            onClick={handleClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="flex w-[364px] flex-col rounded-xl border border-default-75 bg-white p-6 hover:cursor-pointer md:!w-[unset]"
        >
            <div className="mb-4 flex items-start justify-between">
                <CompanyPicture
                    size="default"
                    companyId={company.id}
                    name={company.name}
                    image={company?.logo?.url}
                />
                <ActionPopover
                    actions={actions}
                    actionButtonContent={
                        <div className="p-[10px]">
                            <Icon type="Ellipsis" />
                        </div>
                    }
                />
            </div>

            <h2 className="mb-4 truncate text-xl font-bold">
                <Highlight search={query} value={company.name} />
            </h2>

            <p className="mb-4 line-clamp-3 text-sm text-default-500">
                {company.descriptionShort}
            </p>

            <div>
                <HoverComponent
                    isHovered={!isHovered}
                    secondaryContent={
                        <div className="flex w-[100%] flex-row items-start">
                            <Badge
                                type="silver"
                                icon="Package"
                                label="Archived"
                                className="self-start"
                            />
                        </div>
                    }
                >
                    <Button
                        className="mt-3 w-[100%]"
                        iconPosition="right"
                        icon="ArrowRight"
                        customType="secondary-solid"
                    >
                        View details
                    </Button>
                </HoverComponent>
            </div>
        </article>
    );
};

export default ArchivedCompanyCard;
