import Icon, { IconType } from "components/Icon";
import { cn } from "lib/utils";

export type StatLabelType = "founding" | "inventing" | "developing";

type Props = {
    type: StatLabelType;
    className?: string;
};

const TYPE_TO_ICON: Record<StatLabelType, IconType> = {
    founding: "Sprout",
    inventing: "Lightbulb",
    developing: "TrendingUp",
};

const TYPE_TO_TEXT: Record<StatLabelType, string> = {
    founding: "Founding",
    inventing: "Inventing",
    developing: "Developing",
};

const TYPE_TO_BACKGROUND: Record<StatLabelType, string> = {
    founding:
        "linear-gradient(220deg, rgba(221, 251, 232, 0.30) 10.75%, rgba(78, 218, 133, 0.30) 139.49%)",
    inventing:
        "linear-gradient(220deg, rgba(253, 230, 247, 0.30) 10.75%, rgba(248, 110, 208, 0.30) 139.49%)",
    developing:
        "linear-gradient(220deg, rgba(217, 228, 255, 0.30) 10.75%, rgba(41, 91, 255, 0.30) 139.49%)",
};

const TYPE_TO_BOX_SHADOW: Record<StatLabelType, string> = {
    founding: "0px 6.123px 20px 0px rgba(83, 252, 159, 0.24) inset",
    inventing: "0px 6.123px 20px 0px rgba(252, 206, 241, 0.24) inset",
    developing: "0px 6px 20px 0px rgba(137, 170, 255, 0.24) inset",
};

const TYPE_TO_ICON_COLOR: Record<StatLabelType, string> = {
    founding: "text-success-500",
    inventing: "text-tertiary-500",
    developing: "text-quaternary-500",
};

const StatLabel: React.FC<Props> = ({ type, className }) => {
    return (
        <div
            style={{
                boxShadow: TYPE_TO_BOX_SHADOW[type],
                background: TYPE_TO_BACKGROUND[type],
            }}
            className={cn(
                "flex flex-row items-center gap-[6px] rounded-sm px-3 py-[6px]",
                className
            )}
        >
            <Icon
                type={TYPE_TO_ICON[type]}
                className={TYPE_TO_ICON_COLOR[type]}
            />
            {TYPE_TO_TEXT[type]}
        </div>
    );
};

export default StatLabel;
