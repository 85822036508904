import { FC } from "react";
import { useGetMeQuery } from "store/api/auth";
import InvestorEditContent from "./InvestorEdit";
import { useInvestorProfileQuery } from "store/api/member";

const InvestorEdit: FC = () => {
    const { data: meData } = useGetMeQuery();
    const { data: profileData } = useInvestorProfileQuery();

    const profile = profileData?.data;
    const me = meData?.data;

    if (!profile || !me) {
        return null;
    }

    return (
        <div className="bg-default-100 h-[100vh] p-4">
            <div
                className={`
                relative h-[100%] bg-white overflow-scroll rounded-lg
                px-[108px] md:px-[127px] lg:px-[177px] xl:px-[305px] 2xl:px-[433px] 3xl:px-[625px]
            `}
            >
                <InvestorEditContent investor={profile} me={me} />
            </div>
        </div>
    );
};

export default InvestorEdit;
