import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
    MemberOnboardingActions,
    MemberOnboardingSelectors,
} from "store/reducers/memberOnboarding";
import {
    useGetOnboardingQuery,
    useSaveOnboardingMutation,
} from "store/api/onboarding";
import Input from "components/Input";
import BottomButtons from "routes/onboarding/components/BottomButtons";
import { MemberOnboarding } from "store/reducers/memberOnboarding/types";
import GridSelect from "components/GridSelect";
import Collapsed from "components/Collapsed";
import { useEffect } from "react";

type Props = {
    title: string;
    isLastStep?: boolean;
    handleNextClick: () => void;
    handleBackClick: () => void;
};

const schema = yup.object().shape({
    isFounder: yup.string().required("Required"),
    motivationForBeingFounder: yup.string().when("isFounder", {
        is: "true",
        then: () => yup.string().required("Required").max(3_000, "Too long"),
        otherwise: () => yup.string(),
    }),
    whyDidYouBuildThisCompany: yup.string().when("isFounder", {
        is: "true",
        then: () => yup.string().required("Required").max(3_000, "Too long"),
        otherwise: () => yup.string(),
    }),
});

const parseDefaultValues = (
    {
        isFounder,
        motivationForBeingFounder,
        whyDidYouBuildThisCompany,
    }: MemberOnboarding.Motivation,
    data?: Partial<MemberOnboarding.Motivation>
): MemberOnboarding.Motivation => ({
    isFounder: isFounder || data?.isFounder,
    motivationForBeingFounder:
        motivationForBeingFounder || data?.motivationForBeingFounder || "",
    whyDidYouBuildThisCompany:
        whyDidYouBuildThisCompany || data?.whyDidYouBuildThisCompany || "",
});

const MotivationStep = ({
    title,
    isLastStep,
    handleBackClick,
    handleNextClick,
}: Props) => {
    const dispatch = useDispatch();
    const defaultValues = useSelector(
        MemberOnboardingSelectors.state("motivation")
    );
    const { data: savedOnboarding } = useGetOnboardingQuery();
    const [saveOnboarding, saveOnboardingPayload] = useSaveOnboardingMutation();

    const {
        control,
        handleSubmit,
        formState: { isValid },
        watch,
        setValue,
        clearErrors,
    } = useForm({
        defaultValues: parseDefaultValues(
            defaultValues,
            savedOnboarding?.data["member.motivation"]
        ),
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema) as any,
    });

    const { isFounder } = watch();

    useEffect(() => {
        if (isFounder === "false") {
            setValue("motivationForBeingFounder", "");
            setValue("whyDidYouBuildThisCompany", "");
            clearErrors([
                "motivationForBeingFounder",
                "whyDidYouBuildThisCompany",
            ]);
        }
    }, [isFounder]);

    const onSubmit = (motivation: MemberOnboarding.Motivation) => {
        dispatch(MemberOnboardingActions.setMotivation(motivation));
        saveOnboarding({ "member.motivation": motivation });
        handleNextClick();
    };

    return (
        <div>
            <div className="mb-10 text-2xl font-semibold">{title}</div>

            <div className="flex flex-col gap-5">
                <Controller
                    name="isFounder"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <GridSelect<string>
                            label="Are you a (co-)founder?"
                            selected={value ? [value] : []}
                            onSelect={(e) => onChange(e[0])}
                            options={[
                                { label: "Yes", value: "true", icon: "Check" },
                                { label: "No", value: "false", icon: "X" },
                            ]}
                        />
                    )}
                />

                <Collapsed
                    isExpanded={isFounder === "true"}
                    className="space-y-5"
                >
                    <Controller
                        name="motivationForBeingFounder"
                        control={control}
                        render={({
                            fieldState: { error },
                            field: { value, onChange, onBlur },
                        }) => (
                            <Input
                                as="textarea"
                                value={value}
                                onBlur={onBlur}
                                maxLength={3_000}
                                onChange={onChange}
                                errorMessage={error?.message}
                                placeholder="Describe using no more than 3000 characters"
                                label="What is your motivation for being a founder in general?"
                            />
                        )}
                    />

                    <Controller
                        name="whyDidYouBuildThisCompany"
                        control={control}
                        render={({
                            fieldState: { error },
                            field: { value, onChange, onBlur },
                        }) => (
                            <Input
                                as="textarea"
                                value={value}
                                aria-multiline
                                onBlur={onBlur}
                                maxLength={3_000}
                                onChange={onChange}
                                errorMessage={error?.message}
                                placeholder="Describe using no more than 3000 characters"
                                label="Why did you build this company specifically?"
                            />
                        )}
                    />
                </Collapsed>

                <BottomButtons
                    isLastStep={isLastStep}
                    onBack={handleBackClick}
                    onNext={handleSubmit(onSubmit)}
                    disableNext={!isValid || saveOnboardingPayload.isLoading}
                />
            </div>
        </div>
    );
};

export default MotivationStep;
