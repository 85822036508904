import { Card, CardHeader, CardBody, CardFooter } from "components/Card";
import Icon from "components/Icon";
import Input from "components/Input";
import Button from "components/Button";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useUnlockSharedViewReportMutation } from "store/api/shared-view";
import { FC } from "react";
import Collapsed from "components/Collapsed";
import Alert from "components/Alert";

const schema = z.object({
    password: z.string().min(1, "Required"),
});

const defaultValues = {
    password: "",
};

type Props = {
    token: string;
    onAuth: () => void;
};

const ShareReportsAuthForm: FC<Props> = ({ token, onAuth }) => {
    const [unlockSharedViewReport, { isError }] =
        useUnlockSharedViewReportMutation();

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues,
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: zodResolver(schema),
    });

    const onSubmit = handleSubmit(async (data) => {
        try {
            const response = await unlockSharedViewReport({
                token,
                ...data,
            });

            if (!("data" in response)) {
                throw new Error("Something went wrong");
            }

            onAuth();
        } catch (e) {
            console.error(e);
        }
    });

    return (
        <form onSubmit={onSubmit}>
            <Card>
                <CardHeader
                    className={"flex flex-col gap-y-8 px-10 py-10 pb-7"}
                >
                    <div className="flex h-12 w-12 items-center justify-center rounded-md bg-secondary-50 shadow-[inset_0px_6.123px_18.368px_0px_rgba(151,83,252,0.24)]">
                        <Icon
                            className="text-primary-400"
                            size={16}
                            type="Lock"
                        />
                    </div>
                    <div className="flex flex-col gap-y-3 text-center">
                        <h2 className="text-lg font-bold text-foreground">
                            Password required
                        </h2>
                        <p className="text-sm font-semibold text-default-500">
                            This link is password protected. Please enter the
                            password to view it.
                        </p>
                    </div>
                    <Collapsed isExpanded={isError}>
                        <Alert
                            type="error"
                            label="Please enter a valid password. Passwords are case-sensitive."
                        />
                    </Collapsed>
                </CardHeader>
                <CardBody className={"px-10 pb-8 pt-1"}>
                    <Controller
                        name="password"
                        control={control}
                        render={({
                            fieldState: { error },
                            field: { value, onChange, onBlur },
                        }) => (
                            <Input
                                isPasswordInput={true}
                                onBlur={onBlur}
                                placeholder="Enter password here"
                                onChange={onChange}
                                value={value}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                </CardBody>
                <CardFooter className={"px-10 pb-10"}>
                    <Button
                        disabled={!isValid}
                        type="submit"
                        className="w-full"
                        customSize="xl"
                    >
                        Unlock link
                    </Button>
                </CardFooter>
            </Card>
        </form>
    );
};

export default ShareReportsAuthForm;
