import { cn } from "lib/utils";
import { FC, ReactNode } from "react";
import InfoTooltip from "../InfoTooltip";

type Props = {
    tooltip?: string;
    className?: string;
    children?: ReactNode;
};

const Heading: FC<Props> = ({ tooltip, className, children }: Props) => {
    return (
        <div className="mb-8 flex items-center justify-start gap-x-1 py-[6px]">
            <h2
                className={cn(
                    "text-lg font-semibold text-foreground",
                    className
                )}
            >
                {children}
            </h2>
            <InfoTooltip text={tooltip ?? ""} />
        </div>
    );
};
export default Heading;
