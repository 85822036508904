import { Navigate, RouteObject, createBrowserRouter } from "react-router-dom";

// PAGES
//      Onboarding
import OnboardingIndex from "./onboarding";

import OnboardingWelcome from "routes/onboarding/Welcome";
// import OnboardingPassword from "routes/onboarding/password";

import OnboardingMember from "routes/onboarding/member";
import OnboardingCompany from "routes/onboarding/company";
import OnboardingInvestor from "routes/onboarding/investor";
// import OnboardingMember from "routes/onboardingLegacy/member";
// import OnboardingCompany from "routes/onboardingLegacy/company";
// import OnboardingInvestor from "routes/onboardingLegacy/investor";

//      Auth
import AuthSignIn from "routes/auth/signIn";
import AuthNewPassword from "routes/auth/newPassword";
import AuthForgotPassword from "routes/auth/forgotPassword";

//      INVESTOR
import DashboardIndex from "./dashboard";

//      SURVEYS
import SurveysIndex from "./surveys";
import SurveysQuestion from "./surveys/Question";

import AuthGuard from "./AuthGuard";
import CompanyEdit from "./company-edit";
import ProfileEdit from "./profile-edit";
import InvestorEdit from "./investor-edit";

import Company from "./company";

import ShareReportsPage from "./share/reports/[token]";
import ShareReportsAuthPage from "./share/reports/[token]/auth";
import SettingsPage from "./settings";
import ErrorPage from "./components/error-page";

const routes: Array<RouteObject> = [
    {
        path: "onboarding",
        Component: OnboardingIndex,
        children: [
            {
                path: "welcome",
                Component: OnboardingWelcome,
            },
            {
                path: "welcome/:token",
                Component: OnboardingWelcome,
            },
            // {
            //     path: "password/:token",
            //     Component: OnboardingPassword,
            // },
            {
                path: "company",
                Component: OnboardingCompany,
            },
            {
                path: "investor",
                Component: OnboardingInvestor,
            },
            {
                path: "member",
                Component: OnboardingMember,
            },
        ],
    },
    {
        path: "dashboard",
        Component: AuthGuard,
        children: [{ index: true, Component: DashboardIndex }],
    },
    {
        path: "company-edit",
        Component: AuthGuard,
        children: [{ index: true, Component: CompanyEdit }],
    },
    {
        path: "profile-edit",
        Component: AuthGuard,
        children: [{ index: true, Component: ProfileEdit }],
    },
    {
        path: "investor-edit",
        Component: AuthGuard,
        children: [{ index: true, Component: InvestorEdit }],
    },
    {
        path: "settings",
        Component: AuthGuard,
        children: [{ index: true, Component: SettingsPage }],
    },
    {
        path: "surveys/:surveyKey",
        Component: AuthGuard,
        children: [
            { index: true, Component: SurveysIndex },
            { path: ":questionKey", Component: SurveysQuestion },
        ],
    },
    {
        path: "company/:id",
        Component: AuthGuard,
        children: [{ index: true, Component: Company }],
    },
    {
        path: "share/reports/:token/auth",
        Component: ShareReportsAuthPage,
    },
    {
        path: "share/reports/:token",
        Component: ShareReportsPage,
    },
    {
        path: "auth",
        children: [
            {
                path: "sign-in",
                Component: AuthSignIn,
            },
            {
                path: "reset-password",
                Component: AuthForgotPassword,
            },
            {
                path: "forgot-password/:token",
                Component: AuthNewPassword,
            },
        ],
    },
    {
        path: "*",
        element: <Navigate replace to={"/dashboard"} />,
    },
].map((route) => ({ ...route, ErrorBoundary: ErrorPage }));

const router = createBrowserRouter(routes);

export default router;
