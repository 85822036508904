import { FC, useMemo } from "react";
import { LineChartProps } from "./interfaces";
import { ChartContainer } from "../chart";
import {
    CartesianGrid,
    LabelList,
    Line,
    LineChart as RLineChart,
    XAxis,
    YAxis,
} from "recharts";
import { format } from "date-fns";
import Utils from "utils";
import { LineChartData, lineChartDataSchema } from "./schemas/data-schema";

const LineChart: FC<LineChartProps> = ({ data, className }) => {
    const parsedData = useMemo((): LineChartData => {
        const result = lineChartDataSchema.safeParse(data);
        if (!result.success) {
            return [];
        }
        return result.data;
    }, [data]);

    const finalData = parsedData.map((i) => ({
        x: new Date(i.x),
        y: i.y,
    }));

    return (
        <ChartContainer config={{}} className={className}>
            <RLineChart
                data={finalData}
                accessibilityLayer
                margin={{ top: 32, left: 20, right: 20 }}
            >
                <CartesianGrid vertical={false} stroke="#E1E1E8" />
                <XAxis
                    dataKey="x"
                    tickLine={false}
                    tickMargin={8}
                    axisLine={false}
                    tickFormatter={(v) =>
                        v instanceof Date ? format(v, "MMM yyyy") : "?"
                    }
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickCount={3}
                    width={30}
                    tickFormatter={(v: number) =>
                        Utils.Parsers.abbreviateLargeNumbers(v)
                    }
                />
                <Line
                    dataKey="y"
                    type="linear"
                    stroke="#AC74FF"
                    strokeWidth="2"
                    fill="#AC74FF"
                >
                    <LabelList
                        position="top"
                        content={(p) => (
                            <text
                                x={p.x}
                                y={Number(p.y) - 12}
                                fill={p.fill}
                                fontSize={12}
                                textAnchor={p.index === 0 ? "start" : "middle"}
                                className="fill-black"
                            >
                                {Utils.Parsers.abbreviateLargeNumbers(
                                    p.value as number
                                )}
                            </text>
                        )}
                    />
                </Line>
            </RLineChart>
        </ChartContainer>
    );
};

export default LineChart;
