import { z } from "zod";

export const getNumberQuestionSchema = (
    isRequired: boolean,
    hasThousandthsSeparator: boolean
) => {
    const base = z.string().min(isRequired ? 1 : 0);

    if (hasThousandthsSeparator) {
        return base
            .transform((arg) => arg.split(","))
            .pipe(z.array(z.coerce.number()));
    }

    return base.pipe(z.coerce.number());
};
