import { FC } from "react";
import EmptyInsightImg from "../../../../images/dashboard/EmptyInsight.png";
import { cn } from "lib/utils";

type Props = {
    className?: string;
};

const EmptyInsight: FC<Props> = ({ className }) => {
    return (
        <div
            className={cn(
                "flex h-full w-full items-center justify-center py-4",
                className
            )}
        >
            <div className="flex w-full flex-col items-center justify-center gap-y-6 px-2 pb-6">
                <img
                    src={EmptyInsightImg}
                    alt="Empty State"
                    className="h-auto w-full max-w-[160px]"
                />
                <div className="flex flex-col items-center justify-center gap-y-2 text-center">
                    <h1 className="text-sm font-semibold text-foreground">
                        Survey not submitted
                    </h1>
                    <p className="text-sm font-semibold text-default-400">
                        Missing data to display this insight.
                        <br />
                        We'll notify you once new data is available.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EmptyInsight;
