import { FieldValidation } from "types/validation.types";

enum ValidationIds {
    min_characters = "min_characters",
    special_character = "special_character",
    at_least_one_number = "at_least_one_number",
}

type PassValidations = { [key in ValidationIds]: FieldValidation };

const getDefaultPasswordValidations = (): PassValidations => {
    return {
        [ValidationIds.min_characters]: {
            label: "8 characters",
            type: "error",
        },
        [ValidationIds.special_character]: {
            label: "1 special character !@#$%",
            type: "error",
        },
        [ValidationIds.at_least_one_number]: {
            label: "1 number",
            type: "error",
        },
    };
};

export const getPasswordValidations = (
    value: string,
    state: PassValidations = getDefaultPasswordValidations()
): PassValidations => {
    const validations = [
        value.length >= 8,
        /[@$!%*#?&]/.test(value),
        /[0-9]/.test(value),
    ];

    return {
        [ValidationIds.min_characters]: {
            ...state[ValidationIds.min_characters],
            type: validations[0] ? "success" : "error",
        },
        [ValidationIds.special_character]: {
            ...state[ValidationIds.special_character],
            type: validations[1] ? "success" : "error",
        },
        [ValidationIds.at_least_one_number]: {
            ...state[ValidationIds.at_least_one_number],
            type: validations[2] ? "success" : "error",
        },
    };
};
