import { ChartContainer } from "components/ui/chart";
import { FC } from "react";
import { PolarGrid, PolarRadiusAxis, Radar, RadarChart } from "recharts";

type Props = {
    alignmentData: Array<{
        key: string;
        value: number;
        fill: string;
    }>;
    metricValue: number;
};

const PassionAlignment: FC<Props> = ({ alignmentData, metricValue }) => {
    const sortedData = alignmentData
        .slice()
        .sort((a, b) => a.key.localeCompare(b.key));

    return (
        <div className="flex items-center gap-x-5">
            <ChartContainer className="h-[260px] w-[260px]" config={{}}>
                <RadarChart data={sortedData}>
                    <PolarGrid gridType="circle" />
                    <PolarRadiusAxis
                        domain={[0, 5]}
                        tickCount={6}
                        tick={false}
                        axisLine={false}
                    />

                    <Radar
                        dataKey="value"
                        fill="none"
                        stroke="none"
                        dot={(p) => (
                            <circle
                                cx={p.cx}
                                cy={p.cy}
                                r={4}
                                fill={sortedData[p.index].fill}
                            />
                        )}
                    />
                </RadarChart>
            </ChartContainer>
            <div>
                <span className="text-2xl">{metricValue?.toFixed(1)}</span>
                <span className="text-sm">/5</span>
            </div>
        </div>
    );
};

export default PassionAlignment;
