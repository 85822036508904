import {
    InsightsMemberData,
    GeneratedInsightReportTeam,
} from "store/api/investorCompany/interfaces";
import { IndividualScore } from "components/IndividualScore";

type Props = {
    name: string;
    individual: InsightsMemberData;
    team: GeneratedInsightReportTeam;
};

const InsightCardRow: React.FC<Props> = ({
    name,
    individual: { value },
    team: { value: rightValue },
}) => (
    <div className="flex flex-row items-center justify-between text-sm">
        <div>{name}</div>

        <div className="flex flex-row items-center gap-2">
            <IndividualScore size="sm" value={value as number} />

            <div className="w-6 text-end text-default-400">
                {(rightValue as number).toFixed(1)}
            </div>
        </div>
    </div>
);

export default InsightCardRow;
