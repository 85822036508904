import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Input from "components/Input";
import Button from "components/Button";
import CheckBox from "components-legacy/CheckBox";
import { useSignUpMutation } from "store/api/auth";
import { FieldValidation } from "types/validation.types";
import Layout from "components/OnboardingAndAuth/Layout";
import { InvitationType } from "store/api/invitation/interfaces";
import { useParseInvitationTokenQuery } from "store/api/invitation";
import { PageLoader } from "routes/components/PageLoader";
import { skipToken } from "@reduxjs/toolkit/query";

enum ValidationIds {
    min_characters = "min_characters",
    special_character = "special_character",
    at_least_one_number = "at_least_one_number",
}

type PassValidations = { [key in ValidationIds]: FieldValidation };

const passValidations: PassValidations = {
    [ValidationIds.min_characters]: {
        label: "8 characters",
        type: "error",
    },
    [ValidationIds.special_character]: {
        label: "1 special character !@#$%",
        type: "error",
    },
    [ValidationIds.at_least_one_number]: {
        label: "1 number",
        type: "error",
    },
};

const Welcome = () => {
    const navigate = useNavigate();
    const { token = "" } = useParams();

    const { data, isError } = useParseInvitationTokenQuery(
        token
            ? {
                  token,
              }
            : skipToken
    );
    const [signUp, { data: signUpData, isSuccess, isLoading }] =
        useSignUpMutation();

    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [repeatPasswordValidationError, setRepeatPasswordValidationError] =
        useState("");
    const [passwordValidations, setPasswordValidations] =
        useState<PassValidations>(passValidations);

    const onPasswordChange = (value: string) => {
        setPassword(value);

        const validations = [
            value.length >= 8,
            /[@$!%*#?&]/.test(value),
            /[0-9]/.test(value),
        ];

        setPasswordValidations((prev) => ({
            [ValidationIds.min_characters]: {
                ...prev[ValidationIds.min_characters],
                type: validations[0] ? "success" : "error",
            },
            [ValidationIds.special_character]: {
                ...prev[ValidationIds.special_character],
                type: validations[1] ? "success" : "error",
            },
            [ValidationIds.at_least_one_number]: {
                ...prev[ValidationIds.at_least_one_number],
                type: validations[2] ? "success" : "error",
            },
        }));

        const areAllValid = validations.every((valid) => valid);
        setIsPasswordValid(areAllValid);

        return areAllValid;
    };

    const validateRepeatPassword = () => {
        setRepeatPassword(repeatPassword);

        const errorMsg =
            password !== repeatPassword ? "Passwords don't match" : "";
        setRepeatPasswordValidationError(errorMsg);
        return !errorMsg;
    };

    const handleSubmit = () => {
        const passwordValid = onPasswordChange(password);
        const repeatPasswordValid = validateRepeatPassword();

        const formValid = [
            passwordValid,
            repeatPasswordValid,
            acceptedTerms,
        ].every((valid) => valid);

        if (!formValid) return;

        signUp({ password, token });
    };

    useEffect(() => {
        if (isSuccess && signUpData?.ok && data?.ok) {
            const userType = data.data.type;

            if (userType === InvitationType.Investor) {
                navigate("/onboarding/investor");
            } else if (
                [InvitationType.Company, InvitationType.Member].includes(
                    userType
                )
            ) {
                navigate("/onboarding/member");
            }
        }
    }, [isSuccess, data?.ok]);

    useEffect(() => {
        if (!token || (token && isError)) {
            navigate("/auth/sign-in");
        }
    }, [isError, token]);

    const formValid =
        isPasswordValid && !repeatPasswordValidationError && acceptedTerms;

    if (isLoading) {
        return <PageLoader />;
    }

    return (
        <Layout
            type="member"
            title="A window into a team’s potential"
            leftContent={`VC Volt is a unique model based on decades of research on entrepreneurial teams and high performing companies.`}
            leftBottomSecondaryContent="The team is a company’s most important asset. Having a good analysis can contribute to bringing your company to the next level."
        >
            <div className="mb-4 text-3xl font-semibold">
                Welcome to VC Volt👋
            </div>

            <div className="mb-10">
                {data?.data.email} has been invited to join VC Volt. Please set
                the password for your account.
            </div>

            <Input
                isPasswordInput
                value={password}
                type="password"
                label="Password"
                placeholder="Enter your password"
                validations={Object.values(passwordValidations)}
                onChange={(e) => onPasswordChange(e.target.value)}
            />

            <Input
                isPasswordInput
                type="password"
                value={repeatPassword}
                containerClassName="my-6"
                label="Password, once again"
                onBlur={validateRepeatPassword}
                placeholder="Confirm your password"
                errorMessage={repeatPasswordValidationError}
                onChange={(e) => setRepeatPassword(e.target.value)}
            />

            <CheckBox onToggle={setAcceptedTerms} className="mt-10 text-base">
                I agree with{" "}
                <a
                    href="http://google.com"
                    target="_blank"
                    className="underline"
                >
                    Privacy policy
                </a>{" "}
                and{" "}
                <a
                    href="http://google.com"
                    target="_blank"
                    className="underline"
                >
                    Terms & Conditions
                </a>
            </CheckBox>

            <Button
                customSize="xl"
                className="mt-10"
                disabled={!formValid}
                onClick={handleSubmit}
            >
                Create account
            </Button>
        </Layout>
    );
};

export default Welcome;
