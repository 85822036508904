import Avatar from "components/Avatar";
import CounterBadge from "../../../components/CounterBadge";
import AvatarGroup from "components/AvatarGroup";
import { useBreakpoint } from "hooks/useBreakpoint";

type Type = "high" | "medium" | "low";

const TYPE_TO_STYLE: Record<Type, string> = {
    high: "linear-gradient(90deg, #F2CDFF 0%, #E9A6FF 100%)",
    low: "linear-gradient(90deg, rgba(242, 205, 255, 0.40) 0%, rgba(233, 166, 255, 0.40) 100%)",
    medium: "linear-gradient(90deg, rgba(242, 205, 255, 0.70) 0%, rgba(233, 166, 255, 0.70) 100%)",
};

export interface TopExpertiseCardProps {
    type: Type;
    title: string;
    members: Array<{
        memberId: string;
        firstName: string;
        lastName: string;
        picture: string;
    }>;
}

const Card: React.FC<TopExpertiseCardProps> = ({ title, members, type }) => {
    const isLessLg = useBreakpoint("lg");

    const amount = members.length;

    return (
        <div
            style={{ background: amount ? TYPE_TO_STYLE[type] : undefined }}
            className={`h-[88px] rounded-md border border-solid px-4 py-3 ${amount ? "" : "bg-default-25"}`}
        >
            <div className="mb-3 flex flex-row items-center justify-between">
                <div
                    className={`text-sm ${amount ? "text-black" : "text-default-400"}`}
                >
                    {title}
                </div>

                {amount ? (
                    <CounterBadge number={amount} type="white" />
                ) : undefined}
            </div>

            <div className="flex flex-row">
                <AvatarGroup size="sm" max={isLessLg ? 7 : 6}>
                    {members.map(
                        ({ memberId, firstName, lastName, picture }, i) => (
                            <Avatar
                                key={i}
                                memberId={memberId}
                                src={picture}
                                lastName={lastName}
                                firstName={firstName}
                            />
                        )
                    )}
                </AvatarGroup>
            </div>
        </div>
    );
};

export default Card;
