import React, { useMemo, useState } from "react";
import * as ImagePrimitive from "@nextui-org/react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "lib/utils";
import Utils from "utils";

const companyPictureVariants = cva(
    "relative flex aspect-square items-center justify-center border border-default-75 bg-background",
    {
        variants: {
            size: {
                small: "h-[48px] w-[48px] rounded-sm",
                default: "h-[80px] w-[80px] rounded-lg",
                medium: "h-[96px] w-[96px] rounded-lg",
                large: "h-[120px] w-[120px] rounded-lg",
            },
        },
        defaultVariants: {
            size: "default",
        },
    }
);

const fallbackVariants = cva("font-bold text-foreground", {
    variants: {
        size: {
            small: "text-md",
            default: "text-xl",
            medium: "text-2xl",
            large: "text-3xl",
        },
    },
    defaultVariants: {
        size: "default",
    },
});

type Props = React.ComponentPropsWithoutRef<typeof ImagePrimitive.Image> &
    VariantProps<typeof companyPictureVariants> & {
        companyId?: string;
        name?: string;
        image?: string | null;
    };

const CompanyPicture = React.forwardRef<
    React.ElementRef<typeof ImagePrimitive.Image>,
    Props
>(({ image, name, companyId, size, ...props }, ref) => {
    const [showFallback, setShowFallback] = useState<boolean>(false);

    const fallbackGradient = useMemo(
        () => (companyId ? Utils.Avatar.getAvatarColorByUuid(companyId) : null),
        [companyId]
    );

    return (
        <div
            className={cn(companyPictureVariants({ size }))}
            style={{
                ...(showFallback &&
                    fallbackGradient && {
                        background: `linear-gradient(90deg, ${fallbackGradient.from}, ${fallbackGradient.to})`,
                    }),
            }}
        >
            <ImagePrimitive.Image
                ref={ref}
                alt="Company Picture"
                src={image ?? "/"}
                radius="none"
                removeWrapper={true}
                onLoad={() => setShowFallback(false)}
                onError={() => setShowFallback(true)}
                {...props}
            />
            {showFallback && (
                <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
                    <span className={cn(fallbackVariants({ size }))}>
                        {name && name.trim().slice(0, 1).toUpperCase()}
                    </span>
                </div>
            )}
        </div>
    );
});
CompanyPicture.displayName = "CompanyPicture";

export default CompanyPicture;
