import { FC, ReactNode } from "react";
import LogoImage from "images/logo.png";
import { cn } from "lib/utils";

type Props = {
    classNames?: {
        base?: string;
        logo?: string;
    };
    children?: ReactNode;
};

const Header: FC<Props> = ({ classNames, children }) => {
    return (
        <header
            className={cn(
                "flex items-center justify-between p-8",
                classNames?.base
            )}
        >
            <img
                src={LogoImage}
                alt="Vc Volt"
                className={cn("h-12 w-12", classNames?.logo)}
            />
            {children}
        </header>
    );
};

export default Header;
