import LoadingSpinner from "components-legacy/LoadingSpinner";
import { FC } from "react";

const PageLoader: FC = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-default-50">
            <LoadingSpinner className="scale-[4]" />
        </div>
    );
};

export default PageLoader;
