import { FC } from "react";
import type { HighlightProps } from "./interfaces";

const Highlight: FC<HighlightProps> = ({
    value,
    search,
    className = "bg-amber-300",
    emptyValue = "—",
    emptyClassName = "text-gray-400",
}) => {
    if (!search || !value) {
        if (!value) {
            return <span className={emptyClassName}>{emptyValue}</span>;
        }

        return <>{value}</>;
    }

    return (
        <>
            {value.split(new RegExp(`(${search})`, "gi")).map((part, idx) => (
                <span
                    className={
                        part.toLowerCase() === search.toLowerCase()
                            ? className
                            : undefined
                    }
                    key={idx}
                >
                    {part}
                </span>
            ))}
        </>
    );
};

export default Highlight;
