import { FC, Fragment } from "react";
import { Link } from "react-router-dom";

import { GuidelineProps } from "./interfaces";

const Guideline: FC<GuidelineProps> = ({
    steps,
    isLoading,
    emptyMessage = "All surveys completed. Thank you",
}) => (
    <div className="min-h-[24px] max-w-[552px]">
        {steps.length > 0 && (
            <>
                <span className="text-default-400">
                    You have {steps.length} survey(s) to complete:{" "}
                </span>
                {steps.map((step, idx, arr) => (
                    <Fragment key={step.label}>
                        {step.link ? (
                            <Link to={step.link} className="underline">
                                {step.label}
                            </Link>
                        ) : step.action ? (
                            <button
                                type="button"
                                onClick={step.action}
                                className="underline"
                            >
                                {step.label}
                            </button>
                        ) : (
                            <span>{step.label}</span>
                        )}
                        {idx !== arr.length - 1 && <span>, </span>}
                    </Fragment>
                ))}
            </>
        )}
        {!isLoading && steps.length === 0 && (
            <span className="text-default-400">{emptyMessage}</span>
        )}
    </div>
);

export default Guideline;
