import { useDispatch } from "react-redux";

import Button from "components/Button";
import ButtonIcon from "components/ButtonIcon";
import { GlobalActions } from "store/reducers/global";
import CompanyPicture from "components/CompanyPicture";
import { useArchiveCompanyMutation } from "store/api/investor";

type Props = {
    id: string;
    name: string;
    image?: string;
    onClose?: () => void;
};

const ArchiveCompanyModal: React.FC<Props> = ({ onClose, name, image, id }) => {
    const dispatch = useDispatch();

    const [archiveCompany, { isLoading }] = useArchiveCompanyMutation();

    const handleArchive = async () => {
        try {
            await archiveCompany({ id });

            dispatch(
                GlobalActions.showPopover({
                    type: "dark",
                    label: `${name} moved to archive`,
                })
            );
        } catch (err: any) {
            console.error(err.message);
            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: `Something went wrong, please refresh the page`,
                })
            );
        }
    };

    return (
        <div className="flex w-[592px] max-w-full flex-col gap-4 rounded-2xl bg-white p-10">
            <ButtonIcon
                icon="X"
                onClick={onClose}
                customType="light"
                className="absolute right-4 top-4"
            />

            <h2 className="mb-3 text-xl">Archive company?</h2>

            <div className="flex flex-row items-center gap-3">
                <CompanyPicture
                    size="small"
                    companyId={id}
                    name={name}
                    image={image}
                />
                <span className="truncate text-sm">{name}</span>
            </div>

            <div className="text-sm text-default-500">
                Company members will loose access to surveys. This company card
                will be hidden in Archive tab. Archived company can be restored
                at any time.
            </div>

            <div className="mt-8 flex justify-end gap-x-3">
                <Button onClick={onClose} customType="secondary-solid">
                    Cancel
                </Button>
                <Button disabled={isLoading} onClick={handleArchive}>
                    Yes, archive
                </Button>
            </div>
        </div>
    );
};

export default ArchiveCompanyModal;
