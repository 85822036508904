import { CircularProgress } from "@nextui-org/react";

import Icon from "components/Icon";

type Props = {
    value: number;
};

const ProgressStatusCompany: React.FC<Props> = ({ value }) => {
    const isNotStarted = !value || value === 0;
    const isCompleted = value === 100;

    return (
        <div
            className={
                "flex flex-row gap-1 rounded-lg bg-secondary-100 p-[6px] pr-[10px] text-sm"
            }
        >
            <div className="relative flex items-center justify-center">
                <CircularProgress
                    strokeWidth={4}
                    value={100 - value}
                    classNames={{
                        svg: "w-[20px] h-[20px]",
                        track: "stroke-secondary-500",
                        indicator: "stroke-secondary-200",
                    }}
                />
                {isCompleted && (
                    <div className={`absolute rounded-full bg-secondary-500`}>
                        <Icon size={14} type="CCheckXS" fill="#fff" />
                    </div>
                )}
            </div>

            {isNotStarted ? "Onboarding" : `${value}% completed`}
        </div>
    );
};

export default ProgressStatusCompany;
