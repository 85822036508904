import * as yup from "yup";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import {
    CompanyOnboardingActions,
    CompanyOnboardingSelectors,
} from "store/reducers/companyOnboarding";
import {
    useGetOnboardingQuery,
    useSaveOnboardingMutation,
} from "store/api/onboarding";
import Input from "components/Input";
import BottomButtons from "routes/onboarding/components/BottomButtons";
import { CompanyOnboarding } from "store/reducers/companyOnboarding/types";

type Props = {
    title: string;
    handleNextClick: () => void;
    handleBackClick: () => void;
};

const schema = yup.object().shape({
    shortDescription: yup.string().required("Required").max(300, "Too long"),
    longDescription: yup.string().required("Required").max(1_000, "Too long"),
});

const parseDefaultValues = (
    { longDescription, shortDescription }: CompanyOnboarding.Description,
    data?: Partial<CompanyOnboarding.Description>
): CompanyOnboarding.Description => ({
    shortDescription: shortDescription || data?.shortDescription || "",
    longDescription: longDescription || data?.longDescription || "",
});

const DescriptionStep = ({
    title,
    handleBackClick,
    handleNextClick,
}: Props) => {
    const dispatch = useDispatch();
    const defaultValues = useSelector(
        CompanyOnboardingSelectors.state("description")
    );
    const { data: savedOnboarding, isSuccess } = useGetOnboardingQuery();
    const [saveOnboarding, saveOnboardingPayload] = useSaveOnboardingMutation();

    const {
        control,
        setValue,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues,
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema) as any,
    });

    const onSubmit = (description: CompanyOnboarding.Description) => {
        dispatch(CompanyOnboardingActions.setDescription(description));
        saveOnboarding({ "company.description": description });
        handleNextClick();
    };

    useEffect(() => {
        if (isSuccess) {
            const { longDescription, shortDescription } = parseDefaultValues(
                defaultValues,
                savedOnboarding?.data["company.description"]
            );

            setValue("longDescription", longDescription, {
                shouldValidate: !!longDescription,
            });
            setValue("shortDescription", shortDescription, {
                shouldValidate: !!shortDescription,
            });
        }
    }, [isSuccess]);

    return (
        <div>
            <div className="mb-10 text-2xl font-semibold">{title}</div>

            <div className="flex flex-col gap-5">
                <Controller
                    name="shortDescription"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { value, onChange },
                    }) => (
                        <Input
                            as="textarea"
                            value={value}
                            maxLength={300}
                            onChange={onChange}
                            errorMessage={error?.message}
                            label="Explain what your company does in 1 sentence"
                            placeholder="Describe using no more than 300 characters"
                        />
                    )}
                />

                <Controller
                    name="longDescription"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { value, onChange },
                    }) => (
                        <Input
                            as="textarea"
                            value={value}
                            maxLength={1_000}
                            onChange={onChange}
                            errorMessage={error?.message}
                            label="Elaborate what your company does in details"
                            placeholder="Describe using no more than 1000 characters"
                        />
                    )}
                />

                <BottomButtons
                    isLastStep
                    onBack={handleBackClick}
                    onNext={handleSubmit(onSubmit)}
                    disableNext={!isValid || saveOnboardingPayload.isLoading}
                />
            </div>
        </div>
    );
};

export default DescriptionStep;
