import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";
import { InvestorOnboarding } from "./types";

const initialState: InvestorOnboarding.InitState = {
    details: {
        companyName: "",
        vat: "",
        contactRole: "",
    },
    billing: {
        state: "",
        street: "",
        zipCode: "",
        city: "",
        country: undefined,
    },
    profile: {
        firstName: "",
        lastName: "",
        image: undefined,
    },
};

export const investorOnboarding = createSlice({
    initialState,
    name: "investor_onboarding",
    reducers: {
        setDetails: (
            state,
            { payload }: PayloadAction<InvestorOnboarding.Details>
        ) => {
            state.details = payload;
        },
        setBilling: (
            state,
            { payload }: PayloadAction<InvestorOnboarding.Billing>
        ) => {
            state.billing = payload;
        },
        setProfile: (
            state,
            { payload }: PayloadAction<InvestorOnboarding.Profile>
        ) => {
            state.profile = payload;
        },
    },
});

export const InvestorOnboardingActions = investorOnboarding.actions;
export const InvestorOnboardingSelectors = {
    all: (state: RootState) => state.investorOnboarding,
    state:
        <T extends keyof InvestorOnboarding.InitState>(key: T) =>
        (state: RootState) =>
            state.investorOnboarding[key],
};

export default investorOnboarding.reducer;
