import { z } from "zod";

export const keyInsightsSchema = z.object({
    entrepreneurshipPassion: z.number(),
    inventingScore: z.number(),
    foundingScore: z.number(),
    developingScore: z.number(),
    teamDynamics: z.number(),
    softSkills: z.number(),
});

export type KeyInsights = z.infer<typeof keyInsightsSchema>;
