export default () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5 8.57903C17.5 10.1979 16.9977 11.6983 16.142 12.9296C15.1855 14.3061 12.5245 17.059 11.0379 18.5633C10.4624 19.1456 9.5368 19.1456 8.96133 18.5632C7.47497 17.0589 4.81449 14.3061 3.85797 12.9296C3.00229 11.6983 2.5 10.1979 2.5 8.57903C2.5 4.39325 5.85786 1 10 1C14.1421 1 17.5 4.39325 17.5 8.57903ZM10.0003 6.21094C8.69223 6.21094 7.63184 7.27133 7.63184 8.57938C7.63184 9.88744 8.69223 10.9478 10.0003 10.9478C11.3083 10.9478 12.3687 9.88744 12.3687 8.57938C12.3687 7.27133 11.3083 6.21094 10.0003 6.21094Z"
            fill="#0F1117"
        />
    </svg>
);
