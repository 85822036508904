import React from "react";
import * as ModalPrimitive from "@nextui-org/modal";
import { cn } from "lib/utils";
import ButtonIcon from "components/ButtonIcon";

type ModalProps = React.ComponentPropsWithoutRef<
    typeof ModalPrimitive.Modal
> & {
    align?: "left" | "center";
    onClose?: () => void;
};

const Modal = React.forwardRef<
    React.ElementRef<typeof ModalPrimitive.Modal>,
    ModalProps
>(({ className, children, align = "left", hideCloseButton, ...props }, ref) => {
    return (
        <ModalPrimitive.Modal
            ref={ref}
            className={cn(align === "center" && "center group", className)}
            hideCloseButton={true}
            {...props}
        >
            {children}
        </ModalPrimitive.Modal>
    );
});
Modal.displayName = ModalPrimitive.Modal.displayName;

const ModalContent = React.forwardRef<
    React.ElementRef<typeof ModalPrimitive.ModalContent>,
    React.ComponentPropsWithoutRef<typeof ModalPrimitive.ModalContent>
>(({ className, children, ...props }, ref) => {
    return (
        <ModalPrimitive.ModalContent
            ref={ref}
            className={cn("", className)}
            {...props}
        >
            {children}
        </ModalPrimitive.ModalContent>
    );
});
ModalContent.displayName = ModalPrimitive.ModalContent.displayName;

const ModalHeader = React.forwardRef<
    React.ElementRef<typeof ModalPrimitive.ModalHeader>,
    React.ComponentPropsWithoutRef<typeof ModalPrimitive.ModalHeader>
>(({ className, children, ...props }, ref) => {
    return (
        <ModalPrimitive.ModalHeader
            ref={ref}
            className={cn(
                "w-full flex-col gap-y-3 px-10 pb-8 pt-10 group-[.center]:items-center",
                className
            )}
            {...props}
        >
            {children}
        </ModalPrimitive.ModalHeader>
    );
});
ModalHeader.displayName = ModalPrimitive.ModalHeader.displayName;

const ModalBody = React.forwardRef<
    React.ElementRef<typeof ModalPrimitive.ModalBody>,
    React.ComponentPropsWithoutRef<typeof ModalPrimitive.ModalBody>
>(({ className, children, ...props }, ref) => {
    return (
        <ModalPrimitive.ModalBody
            ref={ref}
            className={cn(
                "w-full px-10 py-0 group-[.center]:items-center",
                className
            )}
            {...props}
        >
            {children}
        </ModalPrimitive.ModalBody>
    );
});
ModalBody.displayName = ModalPrimitive.ModalBody.displayName;

const ModalFooter = React.forwardRef<
    React.ElementRef<typeof ModalPrimitive.ModalFooter>,
    React.ComponentPropsWithoutRef<typeof ModalPrimitive.ModalFooter>
>(({ className, children, ...props }, ref) => {
    return (
        <ModalPrimitive.ModalFooter
            ref={ref}
            className={cn(
                "w-full gap-3 px-10 pb-10 pt-8 group-[.center]:justify-center",
                className
            )}
            {...props}
        >
            {children}
        </ModalPrimitive.ModalFooter>
    );
});
ModalFooter.displayName = ModalPrimitive.ModalFooter.displayName;

const ModalTitle = React.forwardRef<
    HTMLHeadingElement,
    React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => {
    return (
        <h2
            ref={ref}
            className={cn("text-xl font-bold text-foreground", className)}
            {...props}
        />
    );
});
ModalTitle.displayName = "ModalTitle";

const ModalDescription = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
    return (
        <p
            ref={ref}
            className={cn("text-sm font-semibold text-default-500", className)}
            {...props}
        />
    );
});
ModalDescription.displayName = "ModalDescription";

const ModalClose = React.forwardRef<
    React.ElementRef<typeof ButtonIcon>,
    React.ComponentPropsWithoutRef<typeof ButtonIcon>
>(({ className, ...props }, ref) => {
    return (
        <ButtonIcon
            ref={ref}
            icon="X"
            customType="light"
            className={cn("absolute right-4 top-4", className)}
            {...props}
        />
    );
});
ModalClose.displayName = "ModalClose";

export {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalTitle,
    ModalDescription,
    ModalClose,
};
