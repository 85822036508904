export default (props: any) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.33301 0.0419922C7.78072 0.0419922 7.33301 0.489707 7.33301 1.04199C7.33301 1.59428 7.78072 2.04199 8.33301 2.04199H11.6663C12.2186 2.04199 12.6663 1.59428 12.6663 1.04199C12.6663 0.489707 12.2186 0.0419922 11.6663 0.0419922H8.33301Z"
            fill="#0F1117"
            {...props}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99967 3C5.76549 3 2.33301 6.43248 2.33301 10.6667C2.33301 14.9008 5.76549 18.3333 9.99967 18.3333C14.2339 18.3333 17.6663 14.9008 17.6663 10.6667C17.6663 6.43248 14.2339 3 9.99967 3ZM13.2071 8.8741C13.5976 8.48357 13.5976 7.85041 13.2071 7.45989C12.8166 7.06936 12.1834 7.06936 11.7929 7.45989L9.29289 9.95989C8.90237 10.3504 8.90237 10.9836 9.29289 11.3741C9.68342 11.7646 10.3166 11.7646 10.7071 11.3741L13.2071 8.8741Z"
            fill="#0F1117"
            {...props}
        />
    </svg>
);
