import { useEffect, useState } from "react";

import {
    useGetSurveyQuestionQuery,
    usePostSurveyAnswerMutation,
} from "store/api/survey";
import Utils from "utils";
import Input from "components/Input";
import Title from "questions/components/Title";
import { QuestionProps } from "questions/types.questions";
import SurveyFooter from "routes/surveys/components/SurveyFooter";
import { NumberAnswerPattern } from "types/surveys/answer-pattern";
import { NumberQuestionPattern } from "types/surveys/question-pattern";

const NumberQuestion = ({
    value,
    isChild,
    surveyKey,
    allDisabled,
    isLastQuestion,
    data: { isRequired, key, answer, text, pattern },
    onError,
    handleNext,
    handleBack,
    onValueChange,
}: QuestionProps<NumberQuestionPattern, NumberAnswerPattern>) => {
    const separateThousands = pattern?.unit?.type === "currency";

    const [postSurveyAnswer, { isError, isLoading, isSuccess }] =
        usePostSurveyAnswerMutation();

    const [isValid, setIsValid] = useState(!isRequired);
    const [selectedValue, setSelectedValue] = useState(
        `${answer?.value ? (separateThousands ? Utils.Parsers.convertInputToNumberOfThousands(answer.value) : answer.value) : ""}`
    );
    const { data } = useGetSurveyQuestionQuery(
        {
            surveyKey,
            questionKey: pattern.unit?.dependsOn!,
        },
        { skip: !pattern?.unit?.dependsOn }
    );

    const handleSubmit = () => {
        postSurveyAnswer({
            surveyKey,
            questionKey: key,
            data: Utils.Parsers.convertInputOfThousandsIntoNumber(
                selectedValue
            ),
        });
    };

    useEffect(() => {
        if (isSuccess) handleNext();
    }, [isSuccess]);

    useEffect(() => {
        onError(isError);
    }, [isError]);

    useEffect(() => {
        setIsValid(
            Utils.Surveys.validateNumberQuestion(
                selectedValue,
                isRequired,
                separateThousands
            )
        );
    }, [isRequired, separateThousands, selectedValue]);

    useEffect(() => {
        if (isChild) setSelectedValue(value ?? "");
    }, [value]);

    const endContent =
        Utils.Parsers.retrieveCurrencyFromAnswer(
            data?.data?.answer?.value || ""
        ) || pattern?.unit?.value;

    return (
        <div className="flex flex-col items-center justify-center gap-12">
            {!isChild && <Title>{text}</Title>}

            <Input
                placeholder="0"
                value={selectedValue}
                disabled={allDisabled}
                endContent={endContent}
                className="!self-center"
                label={isChild ? text : ""}
                containerClassName="flex flex-col items-center"
                inputWrapperClassName="!w-[168px]"
                separateThousands={separateThousands}
                labelClassName={
                    isChild ? "text-center !text-black" : "!text-black"
                }
                onChange={(e: any) => {
                    setSelectedValue(e.target.value);
                    onValueChange?.(e.target.value);
                }}
            />

            {!isChild && (
                <SurveyFooter
                    isLoading={isLoading}
                    loadingText="Saving..."
                    handleBack={handleBack}
                    nextDisabled={!isValid}
                    handleNext={handleSubmit}
                    allDisabled={allDisabled}
                    nextText={isLastQuestion ? "Submit" : undefined}
                />
            )}
        </div>
    );
};

export default NumberQuestion;
