type Props<T> = {
    className?: string;
    data: Array<Array<T>>;
    cellClassName?: string;
    rowLabelClassName?: string;
    columnColors?: Array<string>;
    cellWrapperClassName?: string;
    columnRender: (item: T) => any;
    rows: Array<string | number | undefined>;
    columns: Array<string | number | undefined>;
};

const CustomTable = <T,>({
    rows,
    data,
    columns,
    className,
    columnRender,
    cellClassName,
    columnColors = [],
    rowLabelClassName,
    cellWrapperClassName,
}: Props<T>) => (
    <div className="flex items-center justify-center">
        <div className={`flex flex-col ${className}`}>
            {rows.map((row, rowIndex) => (
                <div key={`row-${rowIndex}`} className="flex">
                    <div
                        className={`mr-[21px] flex flex-shrink-0 items-center bg-white p-2 text-sm ${rowIndex === rows.length - 1 && "mb-[10px]"} ${rowLabelClassName} `}
                    >
                        {row}
                    </div>

                    {columns.map((col, colIndex) => (
                        <div
                            key={`cell-${rowIndex}-${colIndex}`}
                            className={`border-b px-[3px] ${rowIndex === rows.length - 1 && "pb-[10px]"} ${colIndex === 0 && "border-l pl-[10px]"} ${colIndex === 0 && rowIndex === rows.length - 1 && "rounded-bl-lg"} ${cellWrapperClassName} `}
                        >
                            <div
                                className={` ${rowIndex === 0 && "rounded-t-lg"} ${rowIndex === rows.length - 1 && "rounded-b-lg"} ${cellClassName} ${columnColors[colIndex]} `}
                            >
                                {columnRender(data[rowIndex][colIndex])}
                            </div>
                        </div>
                    ))}
                </div>
            ))}

            <div className="mt-4 flex flex-row text-sm">
                <div
                    className={`ml-[5px] flex items-center text-sm ${cellClassName} !h-[unset]`}
                />

                <div className="flex w-[100%] flex-row items-center justify-between">
                    <div>{columns[0]}</div>
                    <div>{columns[columns.length - 1]}</div>
                </div>
            </div>

            {/* <div className="flex ml-[-25px]">
                <div
                    className={`flex items-center text-sm ${cellWrapperClassName} ${cellClassName}`}
                />

                <div className="flex flex-row justify-between flex-1">
                    {columns.map((col, colIndex) => (
                        <div
                            key={`col-${colIndex}`}
                            className={`
                                text-sm text-center 
                                ${colIndex === 0 && "text-start"} 
                                ${colIndex === columns.length - 1 && "text-end"} 
                                ${cellWrapperClassName}
                                ${cellClassName}
                            `}
                        >
                            {col}
                        </div>
                    ))}
                </div>
            </div> */}
        </div>
    </div>
);

export default CustomTable;
