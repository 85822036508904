import { FC } from "react";

import {
    InsightsTeam,
    InsightsIndividual,
} from "store/api/investorCompany/interfaces";
import InsightStatus from "../InsightStatus";
import { GaugeChart } from "components/ui/GaugeChart";
import Title from "routes/company/components/Modal/Title";
import IndividualScores from "routes/company/components/IndividualScores";
import InsightDescription from "routes/company/components/Modal/InsightDescription";

type Props = {
    tooltip: string;
    teamData: InsightsTeam;
    individualData: InsightsIndividual;
};

const GritModal: FC<Props> = ({
    teamData: { ep_grit },
    individualData,
    tooltip,
}) => {
    const epGritValue = (ep_grit?.value as number) ?? 0;

    return (
        <div className="flex flex-col gap-4">
            <Title tooltip={tooltip}>Grit</Title>

            <div className="flex justify-center">
                <GaugeChart value={epGritValue} />
            </div>

            <div>
                <InsightStatus value={epGritValue} />

                <InsightDescription>
                    {ep_grit?.conclusion?.text ?? ""}
                </InsightDescription>
            </div>

            <IndividualScores
                individualData={individualData}
                teamMetricValue={epGritValue}
                metric="ep_grit"
            />
        </div>
    );
};

export default GritModal;
