import { z } from "zod";

export const tractionChartDataSchema = z.array(
    z.object({
        x: z.string(),
        y: z.number(),
    })
);

export type TractionChartData = z.infer<typeof tractionChartDataSchema>;
