import Button from "components/Button";
import Progress from "components/Progress";
import HumanCapitalImg from "images/surveys/human-capital.xs.png";
import PassionImg from "images/surveys/passion.xs.png";
import SoftImg from "images/surveys/soft.xs.png";
import StarImg from "images/surveys/star.png";
import TeamImg from "images/surveys/team.xs.png";

type Props = {
    surveyKey: string;
    step: number;
    title: string;
    total: number;
    onFinishLaterClick: () => void;
};

const getSurveyImage = (key: string) => {
    if (key === "human-capital-skills") {
        return HumanCapitalImg;
    }

    if (key === "entrepreneurial-passion") {
        return PassionImg;
    }

    if (key === "team-dynamics") {
        return TeamImg;
    }

    if (key === "soft-skills") {
        return SoftImg;
    }

    return StarImg;
};

const Header = ({
    title,
    total,
    step,
    onFinishLaterClick,
    surveyKey,
}: Props) => {
    return (
        <div className="absolute top-[20px] justify-center w-full z-100 flex flex-row">
            <div className="bg-white rounded-lg p-3 gap-3 flex flex-row shadow-survey-header sm:max-w-[480px] md:max-w-[528px] lg:min-w-[680px]">
                <div className="w-10 h-10 overflow-hidden rounded-sm">
                    <img src={getSurveyImage(surveyKey)} />
                </div>

                <div className="flex flex-col justify-between flex-1">
                    <div>
                        {title}
                        <span className="ml-2 text-sm text-default-400">
                            Step {step} of {total}
                        </span>
                    </div>

                    <Progress value={(step / total) * 100} />
                </div>

                <Button
                    customType="secondary-solid"
                    onClick={onFinishLaterClick}
                >
                    Finish later
                </Button>
            </div>
        </div>
    );
};

export default Header;
