import { ReactNode } from "react";

type Props = {
    children: ReactNode;
};

const SecondaryTitle = ({ children }: Props) => {
    return (
        <div className="text-center mb-4 text-md text-black">{children}</div>
    );
};

export default SecondaryTitle;
