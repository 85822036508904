import { FC } from "react";

import {
    InsightsTeam,
    InsightsIndividual,
} from "store/api/investorCompany/interfaces";
import InsightStatus from "../InsightStatus";
import { GaugeChart } from "components/ui/GaugeChart";
import Title from "routes/company/components/Modal/Title";
import IndividualScores from "routes/company/components/IndividualScores";
import InsightDescription from "routes/company/components/Modal/InsightDescription";

type Props = {
    tooltip: string;
    teamData: InsightsTeam;
    individualData: InsightsIndividual;
};

const PsychologicalSafetyModal: FC<Props> = ({
    tooltip,
    individualData,
    teamData: { td_psychological_safety },
}) => {
    const tdPsychologicalSafetyValue =
        (td_psychological_safety?.value as number) ?? 0;

    return (
        <div className="flex flex-col gap-4">
            <Title tooltip={tooltip}>Psychological safety</Title>

            <div className="flex justify-center">
                <GaugeChart value={tdPsychologicalSafetyValue} />
            </div>

            <div>
                <InsightStatus value={tdPsychologicalSafetyValue} />

                <InsightDescription>
                    {td_psychological_safety?.conclusion?.text ?? ""}
                </InsightDescription>
            </div>

            <IndividualScores
                individualData={individualData}
                teamMetricValue={tdPsychologicalSafetyValue}
                metric="td_psychological_safety"
            />
        </div>
    );
};

export default PsychologicalSafetyModal;
