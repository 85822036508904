import {
    InvestorCompanyDTO,
    InvestorCompanyReportDTO,
    InvestorCompanyReportsDTO,
} from "./interfaces";
import { api } from "../api";

const investorCompanyAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        investorCompany: builder.query<InvestorCompanyDTO, { id: string }>({
            query: ({ id }) => `/app/investor/companies/${id}`,
        }),
        investorCompanyReports: builder.query<
            InvestorCompanyReportsDTO,
            { companyId: string }
        >({
            query: ({ companyId }) =>
                `/app/investor/companies/${companyId}/reports`,
        }),
        investorCompanyReport: builder.query<
            InvestorCompanyReportDTO,
            { companyId: string; reportId: string }
        >({
            query: ({ companyId, reportId }) =>
                `/app/investor/companies/${companyId}/reports/${reportId}`,
            providesTags: ["InvestorCompany"],
        }),
        investorCompanyGenerateReport: builder.mutation<
            any,
            { companyId: string; reportId: string }
        >({
            query: ({ companyId, reportId }) => ({
                url: `/app/investor/companies/${companyId}/reports/${reportId}`,
                method: "POST",
            }),
            invalidatesTags: ["InvestorCompany", "CompanyInvitation"],
        }),
    }),
});

export const {
    useInvestorCompanyQuery,
    useInvestorCompanyReportQuery,
    useInvestorCompanyReportsQuery,
    useInvestorCompanyGenerateReportMutation,
} = investorCompanyAPI;
