import Button from "components/Button";
import {
    Modal,
    ModalClose,
    ModalContent,
    ModalDescription,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from "components/Modal";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDeactivateSharedViewMutation } from "store/api/shared-view";
import { GlobalActions } from "store/reducers/global";

type Props = {
    isOpen: boolean;
    onOpenChange: () => void;
    onClose: () => void;
    shareOnOpen: () => void;
    companyId: string;
    reportId: string;
};

export const DeactivateShareInsightsModal: FC<Props> = ({
    isOpen,
    onOpenChange,
    onClose,
    shareOnOpen,
    companyId,
    reportId,
}) => {
    const dispatch = useDispatch();
    const [deactivateSharedView, { isLoading }] =
        useDeactivateSharedViewMutation();

    const cancelHandler = useCallback(() => {
        onClose();
        shareOnOpen();
    }, []);

    const deactivateHandler = useCallback(async () => {
        try {
            const response = await deactivateSharedView({
                companyId,
                reportId,
            });

            if (!("data" in response)) {
                throw new Error("Something went wrong");
            }

            dispatch(
                GlobalActions.showPopover({
                    type: "dark",
                    label: "Share link deactivated",
                })
            );

            onClose();
        } catch (e) {
            const message =
                e instanceof Error ? e.message : "Something went wrong";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    }, [companyId, reportId]);

    return (
        <Modal size="xl" isOpen={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                <ModalClose onClick={onClose} />
                <ModalHeader>
                    <ModalTitle>Share insights</ModalTitle>
                    <ModalDescription>
                        Create link to share company insights
                    </ModalDescription>
                </ModalHeader>
                <ModalFooter>
                    <Button
                        customType="secondary-solid"
                        onClick={cancelHandler}
                    >
                        Cancel
                    </Button>
                    <Button
                        isLoading={isLoading}
                        customType="danger"
                        onClick={deactivateHandler}
                    >
                        Yes, deactivate
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeactivateShareInsightsModal;
