import {
    SurveyResponse,
    SurveysResponse,
    PostSurveyAnswer,
    PostSubmitSurvey,
    SurveysQuestionResponse,
    GetSurveyQuestion,
} from "./interfaces";
import { api } from "../api";

export const surveysApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSurveys: builder.query<SurveysResponse, void>({
            query: () => ({
                url: `app/member/surveys`,
                method: "GET",
            }),
            providesTags: ["Surveys"],
        }),
        getSurveyQuestion: builder.query<
            SurveysQuestionResponse,
            GetSurveyQuestion
        >({
            query: ({ questionKey, surveyKey }) => ({
                url: `app/member/surveys/${surveyKey}/questions/${questionKey}`,
                method: "GET",
            }),
            providesTags: (result, error, { questionKey }) => [
                { type: "Surveys", id: questionKey },
            ],
        }),
        getSurvey: builder.query<SurveyResponse, string>({
            query: (key) => ({
                url: `app/member/surveys/${key}`,
                method: "GET",
            }),
            providesTags: (result, error, key) => [
                { type: "Surveys", id: key },
            ],
        }),
        postSurveyAnswer: builder.mutation<any, PostSurveyAnswer>({
            query: ({ surveyKey, questionKey, data }) => ({
                url: `app/member/surveys/${surveyKey}/questions/${questionKey}/answer`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: (result, error, { questionKey }) => [
                { type: "Surveys", id: questionKey },
                { type: "Surveys" },
            ],
            async onQueryStarted(
                { surveyKey, questionKey },
                { dispatch, queryFulfilled }
            ): Promise<void> {
                try {
                    await queryFulfilled;
                    dispatch(
                        surveysApi.endpoints.getSurveyQuestion.initiate({
                            questionKey,
                            surveyKey,
                        })
                    );
                } catch (error) {
                    console.error("Error in postSurveyAnswer:", error);
                }
            },
        }),
        submitSurvey: builder.mutation<any, PostSubmitSurvey>({
            query: ({ surveyKey }) => ({
                url: `app/member/surveys/${surveyKey}`,
                method: "POST",
            }),
            invalidatesTags: ["Team"],
        }),
    }),
});

export const {
    useGetSurveyQuery,
    useGetSurveysQuery,
    useSubmitSurveyMutation,
    useGetSurveyQuestionQuery,
    usePostSurveyAnswerMutation,
} = surveysApi;
