const CCheck = (props: any) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5303 6.46967C15.8232 6.76256 15.8232 7.23744 15.5303 7.53033L8.65533 14.4053C8.36244 14.6982 7.88756 14.6982 7.59467 14.4053L4.46967 11.2803C4.17678 10.9874 4.17678 10.5126 4.46967 10.2197C4.76256 9.92678 5.23744 9.92678 5.53033 10.2197L8.125 12.8143L14.4697 6.46967C14.7626 6.17678 15.2374 6.17678 15.5303 6.46967Z"
            fill="#0F1117"
            {...props}
        />
    </svg>
);

export default CCheck;
