import { cn } from "lib/utils";
import InsightStatus from "./InsightStatus";
import Icon, { IconType } from "components/Icon";
import EmptyInsight from "routes/company/components/EmptyInsight";
import InsightDescription from "routes/company/components/Modal/InsightDescription";
import { GeneratedInsightReportTeam } from "store/api/investorCompany/interfaces";
import InfoTooltip from "routes/company/components/InfoTooltip";

type Props = {
    name: string;
    tooltip: string;
    iconType: IconType;
    smallText?: boolean;
    data: GeneratedInsightReportTeam;
    children?: React.ReactNode;
    onShowMore?: () => void;
};

const Insight: React.FC<Props> = ({
    name,
    iconType,
    smallText,
    data,
    tooltip,
    children,
    onShowMore,
}) => {
    const isEmptyInsight: boolean = !data;
    return (
        <div
            className={`group relative flex h-[536px] flex-col gap-2 rounded-lg bg-white p-6 md:max-w-[576px]`}
        >
            {!isEmptyInsight && (
                <button
                    type="button"
                    onClick={onShowMore}
                    className="absolute right-6 top-6 rounded p-2 opacity-0 transition duration-100 hover:bg-default-50 group-hover:opacity-100"
                >
                    <Icon type="Maximize2" size="16" />
                </button>
            )}
            <div className="flex flex-row items-center gap-2">
                <div className="rounded-sm border border-solid border-default-75 bg-default-50 p-1">
                    <Icon
                        type={iconType}
                        className="text-default-400"
                        size={20}
                    />
                </div>
                <div className="text-sm">{name}</div>

                <InfoTooltip text={tooltip} />
            </div>

            {isEmptyInsight ? (
                <EmptyInsight />
            ) : (
                <>
                    <div className="flex flex-1 items-center justify-center text-2xl">
                        {children ?? ((data?.value as number) ?? 0).toFixed(1)}
                    </div>
                    <div>
                        <InsightStatus value={(data?.value as number) ?? 0} />
                        <InsightDescription
                            className={cn(
                                "my-3",
                                smallText
                                    ? "line-clamp-6 h-[120px]"
                                    : "line-clamp-[7] h-[140px]"
                            )}
                        >
                            {data?.conclusion?.text ?? ""}
                        </InsightDescription>

                        <button
                            type="button"
                            onClick={onShowMore}
                            className="flex cursor-pointer flex-row items-center text-sm transition duration-100 hover:text-primary-500"
                        >
                            Show more{" "}
                            <Icon type="ChevronRight" className="ml-2" />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Insight;
