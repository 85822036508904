import { FC, useMemo } from "react";
import CompanyHeaderCompanyDetails from "../company-details";
import {
    CompanyDetailsData,
    CompanyDetailsSchema,
} from "routes/company/schemas/company-details-schema";
import { useCompanyReportContext } from "routes/components/CompanyReport/contexts/report-context";

type Props = {};

const SharedViewCompanyHeader: FC<Props> = () => {
    const { company } = useCompanyReportContext();

    const { name, descriptionShort, logo } = useMemo((): CompanyDetailsData => {
        const data = {
            name: company?.["company-name"]?.value,
            descriptionShort: company?.["company-description-short"]?.value,
            logo: company?.["company-logo"]?.value,
        };
        const dataParsed = CompanyDetailsSchema.safeParse(data);
        if (!dataParsed.success) {
            return {
                name: "",
                descriptionShort: "",
                logo: null,
            };
        }
        return dataParsed.data;
    }, [company]);

    return (
        <CompanyHeaderCompanyDetails
            image={logo?.url}
            companyName={name}
            companyDescriptionShort={descriptionShort}
        />
    );
};

export default SharedViewCompanyHeader;
