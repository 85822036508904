import { FC } from "react";

import {
    InsightsTeam,
    InsightsIndividual,
} from "store/api/investorCompany/interfaces";
import InsightStatus from "../InsightStatus";
import { GaugeChart } from "components/ui/GaugeChart";
import Title from "routes/company/components/Modal/Title";
import IndividualScores from "routes/company/components/IndividualScores";
import InsightDescription from "routes/company/components/Modal/InsightDescription";

type Props = {
    tooltip: string;
    teamData: InsightsTeam;
    individualData: InsightsIndividual;
};

const OverallModal: FC<Props> = ({
    tooltip,
    individualData,
    teamData: { ss_overall },
}) => {
    const ssOverallValue = (ss_overall?.value as number) ?? 0;

    return (
        <div className="flex flex-col gap-4">
            <Title tooltip={tooltip}>Overall score</Title>

            <div className="flex justify-center">
                <GaugeChart value={ssOverallValue} />
            </div>

            <div>
                <InsightStatus value={ssOverallValue} />

                <InsightDescription>
                    {ss_overall?.conclusion?.text ?? ""}
                </InsightDescription>
            </div>

            <IndividualScores
                individualData={individualData}
                teamMetricValue={ssOverallValue}
                metric="ss_overall"
            />
        </div>
    );
};

export default OverallModal;
