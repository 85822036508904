import {
    InsightsCompany,
    CityCountryLocation,
} from "store/api/investorCompany/interfaces";
import Utils from "utils";
import Frame from "../shared/Frame";
import StatItem from "../shared/StatItem";
import CompanyAboutLink from "./components/AboutLink";
import ExpandableDescription from "../shared/ExpandableDescription";

type Props = {
    data: InsightsCompany;
};

const AboutCompanyCard: React.FC<Props> = ({ data }) => {
    const {
        website,
        industry,
        officeType,
        headquarter,
        businessType,
        foundingDate,
        officeLocations,
        descriptionLong,
    } = {
        headquarter: data["company-country"]?.value as string,
        foundingDate: Utils.Parsers.formatDateToMonthYear(
            data["company-founding-date"]?.value
        ),
        industry: data["company-industry"]?.value,
        website: data["company-website"]?.value,
        businessType: data["company-business-type"]?.value,
        descriptionLong: data["company-description-long"]?.value,

        officeType: data["company-office-type"]?.value,
        officeLocations: (
            (data["company-office-location"]?.value ||
                []) as Array<CityCountryLocation>
        ).reduce((prev, curr) => {
            const address = Utils.Parsers.getReadableLocation(curr);
            if (prev) prev += `\n${address}`;
            else prev = address;
            return prev;
        }, ""),
    } as Record<string, any>;

    return (
        <Frame title="About">
            <CompanyAboutLink website={website} />

            <ExpandableDescription
                considerLarge={445}
                description={descriptionLong}
            />

            <div className="grid grid-cols-2 gap-6">
                <StatItem title="Founded" label={foundingDate} />
                <StatItem title="Incorporated in" label={headquarter} />
                <StatItem title="Industry" label={industry} />
                <StatItem title="Business type" label={businessType} />
                <StatItem title="Office style" label={officeType} />
                {officeType !== "Remote" && (
                    <StatItem
                        title="Office locations"
                        label={officeLocations}
                    />
                )}
            </div>
        </Frame>
    );
};
export default AboutCompanyCard;
