import Button from "components/Button";
import Collapsed from "components/Collapsed";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalTitle,
    ModalDescription,
    ModalClose,
    ModalFooter,
} from "components/Modal";
import { FC, useCallback, useMemo } from "react";
import { SharedView } from "store/api/shared-view/interfaces";
import ShareInsightsModalForm from "./form";
import { useActivateSharedViewMutation } from "store/api/shared-view";
import { useDispatch } from "react-redux";
import { GlobalActions } from "store/reducers/global";
import { cn } from "lib/utils";

type Props = {
    isOpen: boolean;
    onOpenChange: () => void;
    onClose: () => void;
    deactivateOnOpen: () => void;
    sharedView: SharedView | undefined;
    companyId: string;
    reportId: string;
};

const ShareInsightsModal: FC<Props> = ({
    isOpen,
    onOpenChange,
    onClose,
    deactivateOnOpen,
    sharedView,
    companyId,
    reportId,
}) => {
    const dispatch = useDispatch();
    const [activateSharedView, { isLoading }] = useActivateSharedViewMutation();
    const hasSharedView = useMemo<boolean>(
        () => sharedView !== undefined,
        [sharedView]
    );

    const activateShareInsightsHandler = useCallback(async () => {
        try {
            const response = await activateSharedView({
                companyId,
                reportId,
            });

            if (!("data" in response)) {
                throw new Error("Something went wrong");
            }
        } catch (e) {
            const message =
                e instanceof Error ? e.message : "Something went wrong";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    }, [companyId, reportId]);

    const deactivateShareLinkHandler = useCallback(() => {
        onClose();
        deactivateOnOpen();
    }, []);

    return (
        <Modal
            size="xl"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            className={"bg-default-25"}
        >
            <ModalContent>
                <ModalClose onClick={onClose} />
                <ModalHeader>
                    <ModalTitle>Share insights</ModalTitle>
                    <ModalDescription>
                        Create link to share company insights
                    </ModalDescription>
                </ModalHeader>
                <ModalBody>
                    <Collapsed className="w-full" isExpanded={!hasSharedView}>
                        <Button
                            className="w-full"
                            customSize="xl"
                            icon={isLoading ? "RotateCw" : "Link"}
                            iconClassName={cn(isLoading && "animate-spin")}
                            onClick={activateShareInsightsHandler}
                        >
                            Create link
                        </Button>
                    </Collapsed>
                    <Collapsed isExpanded={hasSharedView}>
                        <ShareInsightsModalForm
                            sharedView={sharedView}
                            onDeactivateShareLink={deactivateShareLinkHandler}
                            companyId={companyId}
                            reportId={reportId}
                        />
                    </Collapsed>
                </ModalBody>
                <ModalFooter className={"pt-0"} />
            </ModalContent>
        </Modal>
    );
};

export default ShareInsightsModal;
