import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";
import { MemberOnboarding } from "./types";

const initialState: MemberOnboarding.InitState = {
    profile: {
        firstName: "",
        lastName: "",
        image: undefined,
    },
    detailsNew: {
        locationCountry: null,
        locationCity: null,
        yearOfBirth: "",
        nationality: [],
        gender: undefined,
        genderDescription: "",
    },
    profileNew: {
        lastName: "",
        firstName: "",
        linkedIn: "",
        image: undefined,
    },
    details: {
        joined: "",
        location: undefined,
        yearOfBirth: "",
        nationality: undefined,
        gender: undefined,
        genderDescription: "",
    },
    roleAndShares: {
        haveSharedValue: "",
        haveShared: undefined,
        isFounder: undefined,
        isFounderValue: undefined,
    },
    roleAndSharesNew: {
        joined: "",
        haveSharedValue: "",
        haveShared: undefined,
        isFounderValue: undefined,
        roleDescription: "",
    },
    motivation: {
        isFounder: undefined,
        motivationForBeingFounder: "",
        whyDidYouBuildThisCompany: "",
    },
};

export const memberOnboarding = createSlice({
    initialState,
    name: "member_onboarding",
    reducers: {
        setProfile: (
            state,
            { payload }: PayloadAction<MemberOnboarding.ProfileNew>
        ) => {
            state.profileNew = payload;
        },
        setDetails: (
            state,
            { payload }: PayloadAction<MemberOnboarding.DetailsNew>
        ) => {
            state.detailsNew = payload;
        },
        setMotivation: (
            state,
            { payload }: PayloadAction<MemberOnboarding.Motivation>
        ) => {
            state.motivation = payload;
        },
        setRoleAndShares: (
            state,
            { payload }: PayloadAction<MemberOnboarding.RoleAndSharesNew>
        ) => {
            state.roleAndSharesNew = payload;
        },
    },
});

export const MemberOnboardingActions = memberOnboarding.actions;
export const MemberOnboardingSelectors = {
    all: (state: RootState) => state.memberOnboarding,
    state:
        <T extends keyof MemberOnboarding.InitState>(key: T) =>
        (state: RootState) =>
            state.memberOnboarding[key],
};

export default memberOnboarding.reducer;
