import { useEffect, useState } from "react";

import Utils from "utils";
import Title from "questions/components/Title";
import InputSelect from "components/InputSelect";
import { QuestionProps } from "questions/types.questions";
import { InputSearchResults } from "types/validation.types";
import { useLocationSearch } from "hooks/useLocationSearch";
import { usePostSurveyAnswerMutation } from "store/api/survey";
import SurveyFooter from "routes/surveys/components/SurveyFooter";
import { LocationSearchMultiSelectAnswerPatter } from "types/surveys/answer-pattern";
import { LocationSearchMultiSelectQuestionPattern } from "types/surveys/question-pattern";

const surveyAnswerLocationToSelectOption = (
    items: LocationSearchMultiSelectAnswerPatter
) =>
    items.map((item) => ({
        value: item.id,
        label: Utils.Parsers.getReadableLocation({
            city: item.city,
            country: item.country,
        }),
    }));

const DropDownMultiSelectQuestion = ({
    value,
    isChild,
    surveyKey,
    allDisabled,
    isLastQuestion,
    data: { key, isRequired, answer, text },
    onError,
    handleBack,
    handleNext,
    onValueChange,
}: QuestionProps<
    LocationSearchMultiSelectQuestionPattern,
    LocationSearchMultiSelectAnswerPatter
>) => {
    const [options, setOptions] = useState<InputSearchResults>([]);

    const { data, setQuery } = useLocationSearch({ type: "city" });

    const [postSurveyAnswer, { isError, isLoading, isSuccess }] =
        usePostSurveyAnswerMutation();

    const [isValid, setIsValid] = useState(!isRequired);
    const [selectedValue, setSelectedValue] = useState<InputSearchResults>(
        answer?.value ? surveyAnswerLocationToSelectOption(answer.value) : []
    );

    const handleSubmit = () => {
        postSurveyAnswer({
            surveyKey,
            questionKey: key,
            data: JSON.stringify(selectedValue.map((i) => i.value)),
        });
    };

    useEffect(() => {
        if (isSuccess) handleNext();
    }, [isSuccess]);

    useEffect(() => {
        onError(isError);
    }, [isError]);

    useEffect(() => {
        setIsValid(!!selectedValue.length);
    }, [selectedValue]);

    useEffect(() => {
        setOptions(data);
    }, [data]);

    useEffect(() => {
        setSelectedValue(
            answer?.value
                ? surveyAnswerLocationToSelectOption(answer.value)
                : []
        );
        onValueChange?.(
            answer?.value
                ? surveyAnswerLocationToSelectOption(answer.value)
                : []
        );
    }, [answer?.value]);

    useEffect(() => {
        if (isChild) setSelectedValue(value || []);
    }, [value]);

    return (
        <div className="flex flex-col items-center justify-center gap-12">
            {!isChild && <Title>{text}</Title>}

            <InputSelect<InputSearchResults[0]>
                label={isChild ? text : ""}
                labelClassName={
                    isChild ? "text-center !text-black" : "!text-black"
                }
                isMulti={true}
                options={options}
                disabled={allDisabled}
                selected={selectedValue}
                defaultValue={selectedValue}
                handleInputChange={setQuery}
                className="!w-[100%] !max-w-[600px]"
                placeholder={"Type to search cities; select one or multiple"}
                getOptionValue={(option) => option?.value}
                renderOptionLabel={(option) => option.label}
                handleSelect={(val) => {
                    setSelectedValue(val);
                    onValueChange?.(val);
                }}
            />

            {!isChild && (
                <SurveyFooter
                    disableSubmitOnEnter
                    isLoading={isLoading}
                    loadingText="Saving..."
                    handleBack={handleBack}
                    handleNext={handleSubmit}
                    allDisabled={allDisabled}
                    nextDisabled={!selectedValue || !isValid}
                    nextText={isLastQuestion ? "Submit" : undefined}
                />
            )}
        </div>
    );
};

export default DropDownMultiSelectQuestion;
