import { FC, useMemo } from "react";
import UserCard from "./components/user-card";
import { InsightsIndividual } from "store/api/investorCompany/interfaces";
import Utils from "utils";
import Heading from "routes/company/components/Heading";
import useModalController from "routes/company/useModalController";
import ModalController, {
    CompanyInsightModalConfig,
} from "routes/company/ModalController";
import UserCardModal from "./components/user-card-modal";

type Props = {
    individualData: InsightsIndividual;
};

const TeamOverviewTab: FC<Props> = ({ individualData }) => {
    const modalController = useModalController();
    const individualEntries = useMemo(
        () =>
            Object.entries(individualData).sort(
                Utils.Insight.membersRoleNameCompareFn
            ),
        [individualData]
    );
    const modals: Array<CompanyInsightModalConfig> = useMemo(
        () =>
            individualEntries.map(([, value]): CompanyInsightModalConfig => {
                const memberInfo =
                    Utils.Insight.getMemberInfoFromInsight(value);
                return {
                    title: `${memberInfo.firstName} ${memberInfo.lastName}`,
                    content: <UserCardModal memberData={value} />,
                };
            }),
        [individualEntries]
    );

    return (
        <>
            <Heading>Team overview</Heading>
            <div className="mx-auto grid max-w-xl grid-cols-1 gap-4 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                {individualEntries.map(([key, value], index) => (
                    <UserCard
                        key={key}
                        memberData={value}
                        onShowMore={() => modalController.open(index)}
                    />
                ))}
            </div>
            <ModalController modals={modals} {...modalController} />
        </>
    );
};

export default TeamOverviewTab;
