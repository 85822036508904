import { zodResolver } from "@hookform/resolvers/zod";
import Button from "components/Button";
import Input from "components/Input";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useChangePasswordMutation } from "store/api/auth";
import { GlobalActions } from "store/reducers/global";
import Utils from "utils";
import { z } from "zod";

const schema = z
    .object({
        currentPassword: z.string().min(1, "Required"),
        newPassword: z.string().min(1, "Required"),
        confirmPassword: z.string().min(1, "Required"),
    })
    .superRefine(({ confirmPassword, newPassword }, ctx) => {
        if (confirmPassword !== newPassword) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Passwords don't match",
                path: ["confirmPassword"],
            });
        }
        if (
            Object.values(
                Utils.Password.getPasswordValidations(newPassword)
            ).some((value) => value.type === "error")
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Password must meet complexity requirements",
                path: ["newPassword"],
            });
        }
    });

const defaultValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
};

type Props = {};

const SettingsPasswordForm: FC<Props> = () => {
    const dispatch = useDispatch();
    const [changePassword, { isLoading }] = useChangePasswordMutation();

    const {
        control,
        handleSubmit,
        formState: { isValid },
        reset,
    } = useForm({
        defaultValues,
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: zodResolver(schema),
    });

    const onSubmit = handleSubmit(async (data) => {
        try {
            const { currentPassword, newPassword } = data;
            const response = await changePassword({
                oldPassword: currentPassword,
                newPassword,
            });

            if (!("data" in response)) {
                const error = response.error;
                if ("status" in error && error.status === 400) {
                    throw new Error(
                        "Please enter a valid password. Passwords are case-sensitive."
                    );
                }
                throw new Error("Something went wrong");
            }

            dispatch(
                GlobalActions.showPopover({
                    type: "success",
                    label: "Your password has been successfully updated",
                })
            );

            reset();
        } catch (e) {
            const message =
                e instanceof Error ? e.message : "Something went wrong";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    });

    return (
        <form onSubmit={onSubmit} className="space-y-6">
            <Controller
                name="currentPassword"
                control={control}
                render={({
                    fieldState: { error },
                    field: { value, onChange, onBlur },
                }) => (
                    <Input
                        isPasswordInput={true}
                        onBlur={onBlur}
                        placeholder="Enter current password here"
                        label="Current password"
                        onChange={onChange}
                        value={value}
                        errorMessage={error?.message}
                    />
                )}
            />
            <Controller
                name="newPassword"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                        isPasswordInput={true}
                        onBlur={onBlur}
                        placeholder="Enter new password here"
                        label="New password"
                        onChange={onChange}
                        value={value}
                        validations={Object.values(
                            Utils.Password.getPasswordValidations(value)
                        )}
                    />
                )}
            />
            <Controller
                name="confirmPassword"
                control={control}
                render={({
                    fieldState: { error },
                    field: { value, onChange, onBlur },
                }) => (
                    <Input
                        isPasswordInput={true}
                        onBlur={onBlur}
                        placeholder="Confirm new password here"
                        label="Confirm new password"
                        onChange={onChange}
                        value={value}
                        errorMessage={error?.message}
                    />
                )}
            />
            <Button type="submit" disabled={!isValid} isLoading={isLoading}>
                Upddate password
            </Button>
        </form>
    );
};

export default SettingsPasswordForm;
