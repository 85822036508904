import { IconType } from "components/Icon";

export const GRID_OPTION_TO_ICON: Record<string, IconType> = {
    Engineering: "Code",
    Product: "Sparkles",
    Sales: "HandCoins",
    "Business development": "ListChecks",
    "Account management": "MessageCircleMore",
    Marketing: "Crosshair",
    Data: "DatabaseZap",
    Finance: "CircleDollarSign",
    Legal: "Scale",
};

export const GRID_LARGE_OPTION_TO_ICON: Record<string, IconType> = {
    Sales: "ShoppingCart",
    "Technical skills": "Code",
    "Ability to wear multiple hats": "Hat",
    "Ability to get things done": "ListChecks",
    Finance: "HandCoins",
    Design: "Eye",
    "Product management": "Network",
    Networking: "Users",
    "Team building": "Heart",
    Hiring: "UserPlus",
    Delegating: "Forward",
    Bootstrap: "Sprout",
    Negotiate: "Handshake",
    "Building a brand": "Sparkles",
    Prioritizing: "Scale",
    Storytelling: "MessageCircle",
    "Customer success": "Smile",
    Growth: "TrendingUp",
    "Data analysis": "DatabaseZap",
};
