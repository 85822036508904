import "swiper/css";
import "swiper/css/pagination";

import ReactDOM from "react-dom/client";
import Gleap from "gleap";

import "./index.css";
import App from "App";

const GLEAP_API_KEY = "Q19weXRUh4yxYJv29v6PeTdrgO5ZADfU";
export const SHOW_TRACKER = ["prod", "stage", "dev"].includes(
    process.env.REACT_APP_MODE ?? ""
);

if (SHOW_TRACKER) {
    Gleap.initialize(GLEAP_API_KEY, true);
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(<App />);
