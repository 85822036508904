import { api } from "../api";
import {
    ActivateSharedViewRequest,
    ActivateSharedViewResponse,
    DeactivateSharedViewRequest,
    DeactivateSharedViewResponse,
    GetSharedViewReportRequest,
    GetSharedViewReportResponse,
    UnlockSharedViewReportRequest,
    UnlockSharedViewReportResponse,
    UpdateSharedViewRequest,
    UpdateSharedViewResponse,
} from "./interfaces";

const sharedViewApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSharedViewReport: builder.query<
            GetSharedViewReportResponse,
            GetSharedViewReportRequest
        >({
            query: ({ token }) => ({
                url: `/app/public/reports/${token}`,
                method: "GET",
                providesTags: ["SharedViewReport"],
            }),
        }),
        unlockSharedViewReport: builder.mutation<
            UnlockSharedViewReportResponse,
            UnlockSharedViewReportRequest
        >({
            query: ({ token, ...body }) => ({
                url: `/app/public/reports/${token}`,
                method: "POST",
                body: JSON.stringify(body),
            }),
            invalidatesTags: ["SharedViewReport"],
        }),
        activateSharedView: builder.mutation<
            ActivateSharedViewResponse,
            ActivateSharedViewRequest
        >({
            query: ({ companyId, reportId }) => ({
                url: `/app/investor/companies/${companyId}/reports/${reportId}/shared-view`,
                method: "POST",
            }),
            invalidatesTags: ["InvestorCompany", "SharedViewReport"],
        }),
        updateSharedView: builder.mutation<
            UpdateSharedViewResponse,
            UpdateSharedViewRequest
        >({
            query: ({ companyId, reportId, ...body }) => ({
                url: `/app/investor/companies/${companyId}/reports/${reportId}/shared-view`,
                method: "PATCH",
                body: JSON.stringify(body),
            }),
            invalidatesTags: ["InvestorCompany", "SharedViewReport"],
        }),
        deactivateSharedView: builder.mutation<
            DeactivateSharedViewResponse,
            DeactivateSharedViewRequest
        >({
            query: ({ companyId, reportId }) => ({
                url: `/app/investor/companies/${companyId}/reports/${reportId}/shared-view`,
                method: "DELETE",
            }),
            invalidatesTags: ["InvestorCompany", "SharedViewReport"],
        }),
    }),
});

export const {
    useGetSharedViewReportQuery,
    useUnlockSharedViewReportMutation,
    useActivateSharedViewMutation,
    useUpdateSharedViewMutation,
    useDeactivateSharedViewMutation,
} = sharedViewApi;
