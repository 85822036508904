import Card, {
    SkillsDistributionCardProps,
} from "../../components/HCGridSelectCard";
import {
    InsightsTeam,
    InsightsIndividual,
    HumanCapitalSkillsTop3,
} from "store/api/investorCompany/interfaces";
import ContainerWrapper from "../../components/ContainerWrapper";
import { useMemo } from "react";

type Props = {
    team: InsightsTeam;
    individual: InsightsIndividual;
    tooltip?: string;
};

const SkillsDistributionCard: React.FC<Props> = ({
    team,
    individual,
    tooltip,
}) => {
    const totalMembers = Object.keys(individual).length;

    const data = useMemo(() => team?.["hc-top-3-skills"], [team]);
    const dataValue = useMemo(
        () => (data?.value ?? {}) as HumanCapitalSkillsTop3,
        [data]
    );
    const isEmptyInsight = useMemo(() => !data, [data]);

    const skills: Array<SkillsDistributionCardProps> = [
        {
            iconType: "Code",
            number: dataValue["Technical skills"]?.length ?? 0,
            title: "Technical skills",
        },
        {
            iconType: "Hat",
            number: dataValue["Ability to wear multiple hats"]?.length ?? 0,
            title: "Ability to wear multiple hats",
        },
        {
            iconType: "ListChecks",
            number: dataValue["Ability to get things done"]?.length ?? 0,
            title: "Ability to get things done",
        },
        {
            iconType: "Eye",
            number: dataValue.Design?.length ?? 0,
            title: "Design",
        },
        {
            iconType: "Heart",
            number: dataValue["Team building"]?.length ?? 0,
            title: "Team building",
        },
        {
            iconType: "Sprout",
            number: dataValue.Bootstrap?.length ?? 0,
            title: "Bootstrap",
        },
        {
            iconType: "Smile",
            number: dataValue["Customer success"]?.length ?? 0,
            title: "Customer success",
        },
        {
            iconType: "UserPlus",
            number: dataValue.Hiring?.length ?? 0,
            title: "Hiring",
        },
        {
            iconType: "HandCoins",
            number: dataValue.Sales?.length ?? 0,
            title: "Sales",
        },
        {
            iconType: "CircleDollarSign",
            number: dataValue.Finance?.length ?? 0,
            title: "Finance",
        },
        {
            iconType: "DatabaseZap",
            number: dataValue["Data analysis"]?.length ?? 0,
            title: "Data analysis",
        },
        {
            iconType: "Network",
            number: dataValue["Product management"]?.length ?? 0,
            title: "Product management",
        },
        {
            iconType: "Users",
            number: dataValue.Networking?.length ?? 0,
            title: "Networking",
        },
        {
            iconType: "Forward",
            number: dataValue.Delegating?.length ?? 0,
            title: "Delegating",
        },
        {
            iconType: "Handshake",
            number: dataValue.Negotiate?.length ?? 0,
            title: "Negotiate",
        },
        {
            iconType: "Sparkles",
            number: dataValue["Building a brand"]?.length ?? 0,
            title: "Building a brand",
        },
        {
            iconType: "ListOrdered",
            number: dataValue.Prioritizing?.length ?? 0,
            title: "Prioritizing",
        },
        {
            iconType: "MessageCircleMore",
            number: dataValue.Storytelling?.length ?? 0,
            title: "Storytelling",
        },
        {
            iconType: "TrendingUp",
            number: dataValue.Growth?.length ?? 0,
            title: "Growth",
        },
    ].sort((a, b) => b.number - a.number) as any;

    return (
        <ContainerWrapper
            iconType="Check"
            name="Skills distribution"
            tooltip={tooltip}
            className="my-0 h-auto"
            isEmptyInsight={isEmptyInsight}
        >
            <div className="flex flex-wrap items-start gap-3">
                {skills.map((i) => (
                    <Card key={i.title} {...i} total={totalMembers} />
                ))}
            </div>
        </ContainerWrapper>
    );
};

export default SkillsDistributionCard;
