import { FC } from "react";
import {
    SettingsLayout,
    SettingsLayoutBlock,
    SettingsLayoutBlockTitle,
    SettingsLayoutContent,
    SettingsLayoutHeader,
    SettingsLayoutTitle,
} from "routes/layouts/settings-layout";
import SettingsPasswordForm from "./components/password-form";

type Props = {};

const SettingsPage: FC<Props> = () => {
    return (
        <SettingsLayout>
            <SettingsLayoutHeader />
            <SettingsLayoutContent>
                <SettingsLayoutTitle>Settings</SettingsLayoutTitle>
                <SettingsLayoutBlock>
                    <SettingsLayoutBlockTitle>
                        Password
                    </SettingsLayoutBlockTitle>
                    <div className="rounded-2xl bg-default-25 p-10">
                        <SettingsPasswordForm />
                    </div>
                </SettingsLayoutBlock>
            </SettingsLayoutContent>
        </SettingsLayout>
    );
};

export default SettingsPage;
