import { FC, useMemo, useState } from "react";
import {
    useSetDeadlineMutation,
    useEditInvitationDeadlineMutation,
} from "store/api/investor";
import Utils from "utils";
import Button from "components/Button";
import DateInput from "components/DateInput";
import TimeInput from "components/TimeInput";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Collapsed from "components/Collapsed";
import { useDispatch } from "react-redux";
import { GlobalActions } from "store/reducers/global";
import useDeadlineValidation from "../hooks/useDeadlineValidation";
import GridSelect from "components/GridSelect";
import { DEADLINE_OPTIONS } from "../constants/deadline";
import { z } from "zod";

const schema = z
    .object({
        date: z.string(),
        time: z.string(),
    })
    .transform(({ date, time }) => ({
        date,
        time,
        deadline: `${date}T${time}${Utils.Date.getUserTimezoneOffset()}`,
    }))
    .pipe(
        z.object({
            date: z.string().date(),
            time: z.string().time(),
            deadline: z.string().datetime({ offset: true }),
        })
    )
    .superRefine(({ deadline }, ctx) => {
        try {
            if (new Date(deadline) < new Date()) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Date can't be in the past",
                    path: ["deadline"],
                });
            }
        } catch (e) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid date",
                path: ["deadline"],
            });
        }
    });

type Props = {
    onClose: () => void;
    type: "company" | "invitation";
    companyName: string;
    deadline: string | null | undefined;
    entityId: string;
};

const DeadlineModal: FC<Props> = ({
    onClose,
    companyName,
    deadline,
    entityId,
    type,
}) => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState<Array<number>>([]);
    const [setCompanyDeadline, { isLoading: setCompanyDeadlineIsLoading }] =
        useSetDeadlineMutation();
    const [
        setInvitationDeadline,
        { isLoading: setInvitationDeadlineIsLoading },
    ] = useEditInvitationDeadlineMutation();
    const hasDeadline = useMemo(() => !!deadline, []);
    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isDirty },
        watch,
        trigger,
        setValue,
    } = useForm({
        defaultValues: {
            date: deadline ? Utils.Date.formatDateToISO(deadline) : "",
            time: deadline
                ? Utils.Date.getHoursMinutesSecondsFromDate(deadline)
                : "",
            deadline: "",
        },
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: zodResolver(schema),
    });
    const { showDeadlineError, handleDeadlineOption } = useDeadlineValidation(
        errors,
        setValue,
        watch,
        trigger
    );

    const onSubmit = handleSubmit(async (data) => {
        try {
            const { deadline: theDeadline } = data;

            let response;
            if (type === "company")
                response = await setCompanyDeadline({
                    deadline: theDeadline,
                    companyId: entityId,
                });
            else {
                response = await setInvitationDeadline({
                    deadline: theDeadline,
                    invitationId: entityId,
                });
            }

            if (!("data" in response)) {
                throw new Error("Something went wrong");
            }

            dispatch(
                GlobalActions.showPopover({
                    type: "success",
                    label: `${companyName} deadline updated`,
                })
            );

            onClose();
        } catch (e: unknown) {
            const message =
                e instanceof Error ? e.message : "Something went wrong";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    });

    return (
        <div className="w-[592px] max-w-full rounded-2xl bg-white p-10">
            <h2 className="mb-3 text-xl">
                {hasDeadline ? "Edit" : "Set"} deadline for {companyName}
            </h2>
            <p className="mb-8 text-sm text-default-500">
                {hasDeadline
                    ? "Update deadline for the team to complete all surveys. Email notification will be sent to all team members."
                    : "Set deadline for the team to complete all surveys. Email notification will be sent to all team members."}
            </p>

            <div className="flex rounded-lg bg-default-75 p-4 text-sm">
                <span className="mr-3 text-default-400">Note</span>
                <span className="font-semibold">
                    One team member spends ~40 mins to complete all surveys.
                </span>
            </div>

            {!hasDeadline && (
                <div className="mt-4 flex gap-x-2 text-sm">
                    <GridSelect
                        optionClassName="py-[6px] px-3"
                        selected={selected}
                        isMultiSelect={false}
                        onSelect={(value) =>
                            handleDeadlineOption(value, setSelected)
                        }
                        options={DEADLINE_OPTIONS}
                    />
                </div>
            )}

            <form onSubmit={onSubmit} className="mt-4">
                <div className="grid grid-cols-2 gap-x-4">
                    <Controller
                        name="date"
                        control={control}
                        render={({
                            fieldState: { error },
                            field: { value, onChange, onBlur },
                        }) => (
                            <DateInput
                                value={value}
                                errorMessage={error?.message}
                                label="Deadline date"
                                onChange={(v) => onChange(v ?? "")}
                                onBlur={onBlur}
                            />
                        )}
                    />
                    <Controller
                        name="time"
                        control={control}
                        render={({
                            fieldState: { error },
                            field: { value, onChange, onBlur },
                        }) => (
                            <TimeInput
                                value={value}
                                errorMessage={error?.message}
                                label="Deadline time"
                                onChange={(v) => onChange(v ?? "")}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </div>
                <Collapsed isExpanded={showDeadlineError}>
                    <div className="mt-2 text-sm text-danger-500">
                        {errors?.deadline?.message}
                    </div>
                </Collapsed>

                <div className="mt-8 flex justify-end gap-x-3">
                    <Button
                        onClick={onClose}
                        customType="secondary-solid"
                        customSize="xl"
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={!isValid || !isDirty}
                        isLoading={
                            setCompanyDeadlineIsLoading ||
                            setInvitationDeadlineIsLoading
                        }
                        customSize="xl"
                    >
                        Set deadline
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default DeadlineModal;
