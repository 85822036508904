import { FC } from "react";

import { InsightsIndividual } from "store/api/investorCompany/interfaces";
import Title from "routes/company/components/Modal/Title";
import InsightDescription from "routes/company/components/Modal/InsightDescription";
import { TypeOfPassion } from "utils/insight";
import MainPassionType from "../MainPassionType";
import MemberInfo from "routes/company/components/MemberInfo";
import StatLabel from "../../Individual/StatLabel";
import Utils from "utils";

type Props = {
    individualData: InsightsIndividual;
    passionType: TypeOfPassion;
    tooltip: string;
};

const MainPassionTypeModal: FC<Props> = ({
    tooltip,
    passionType,
    individualData,
}) => {
    return (
        <div className="flex flex-col gap-4">
            <Title tooltip={tooltip}>Main type of passion</Title>

            <div className="flex justify-center">
                <MainPassionType
                    className="py-[34px]"
                    passionType={passionType}
                />
            </div>

            <div>
                <p className="text-md font-semibold text-foreground">
                    {passionType.label}
                </p>

                <InsightDescription>
                    {passionType.conclusion.high}
                </InsightDescription>
            </div>

            <div className={"flex flex-col items-center gap-4 p-10"}>
                {Object.entries(individualData)
                    .sort(Utils.Insight.membersRoleNameCompareFn)
                    .map(([key, value]) => {
                        const memberInfo =
                            Utils.Insight.getMemberInfoFromInsight(value);
                        const {
                            developmentScore,
                            foundingScore,
                            inventingScore,
                        } = {
                            developmentScore:
                                (value.ep_developing_score?.value as number) ??
                                0,
                            foundingScore:
                                (value.ep_founding_score?.value as number) ?? 0,
                            inventingScore:
                                (value.ep_inventing_score?.value as number) ??
                                0,
                        };

                        const individualPassionType =
                            Utils.Insight.getTypeOfPassion(
                                developmentScore,
                                foundingScore,
                                inventingScore
                            );

                        return (
                            <div
                                key={key}
                                className="grid w-2/3 grid-cols-5 items-center"
                            >
                                <MemberInfo
                                    className="col-span-3"
                                    {...memberInfo}
                                />

                                <StatLabel
                                    type={individualPassionType.labelType}
                                    className="col-span-2 justify-center"
                                />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default MainPassionTypeModal;
