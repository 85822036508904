import {
    InsightsTeam,
    InsightsIndividual,
} from "store/api/investorCompany/interfaces";
import Insight from "./Insight";
import Utils from "utils";

type Props = {
    teamData: InsightsTeam;
    individualData: InsightsIndividual;
};

const SoftSkillsTeamTab: React.FC<Props> = ({ individualData, teamData }) => {
    return (
        <div className="flex justify-center md:block">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                {Object.entries(individualData)
                    .sort(Utils.Insight.membersRoleNameCompareFn)
                    .map(([key, value]) => (
                        <Insight
                            key={key}
                            teamData={teamData}
                            individualData={value}
                        />
                    ))}
            </div>
        </div>
    );
};

export default SoftSkillsTeamTab;
