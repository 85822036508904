import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Input from "components/Input";
import Button from "components/Button";
import Layout from "components/OnboardingAndAuth/Layout";
import { FieldValidation } from "types/validation.types";
import { InvitationType } from "store/api/invitation/interfaces";
import { useGetMeQuery, useSendResetPasswordMutation } from "store/api/auth";

enum ValidationIds {
    min_characters = "min_characters",
    special_character = "special_character",
    at_least_one_number = "at_least_one_number",
}

type PassValidations = { [key in ValidationIds]: FieldValidation };

const passValidations: PassValidations = {
    [ValidationIds.min_characters]: {
        label: "8 characters",
        type: "error",
    },
    [ValidationIds.special_character]: {
        label: "1 special character !@#$%",
        type: "error",
    },
    [ValidationIds.at_least_one_number]: {
        label: "1 number",
        type: "error",
    },
};

const Index = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { token = "" } = useParams();
    const getMePayload = useGetMeQuery();

    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [sendRestPassword, resetPasswordPayload] =
        useSendResetPasswordMutation();
    const [repeatPasswordValidationError, setRepeatPasswordValidationError] =
        useState("");
    const [passwordValidations, setPasswordValidations] =
        useState<PassValidations>(passValidations);

    const handleSubmit = () => {
        sendRestPassword({ password, token });
    };

    const onPasswordChange = (value: string) => {
        setPassword(value);

        const validations = [
            value.length >= 8,
            /[@$!%*#?&]/.test(value),
            /[0-9]/.test(value),
        ];

        setPasswordValidations((prev) => ({
            [ValidationIds.min_characters]: {
                ...prev[ValidationIds.min_characters],
                type: validations[0] ? "success" : "error",
            },
            [ValidationIds.special_character]: {
                ...prev[ValidationIds.special_character],
                type: validations[1] ? "success" : "error",
            },
            [ValidationIds.at_least_one_number]: {
                ...prev[ValidationIds.at_least_one_number],
                type: validations[2] ? "success" : "error",
            },
        }));

        const areAllValid = validations.every((valid) => valid);
        setIsPasswordValid(areAllValid);

        return areAllValid;
    };

    const onRepeatPasswordChange = (value: string) => {
        setRepeatPassword(value);

        const errorMsg = password !== value ? "Passwords don't match" : "";
        setRepeatPasswordValidationError(errorMsg);
        return !errorMsg;
    };

    useEffect(() => {
        if (resetPasswordPayload.isSuccess) {
            navigate("/auth/sign-in");
        }
    }, [resetPasswordPayload.isSuccess]);

    useEffect(() => {
        if (!getMePayload.isSuccess) return;

        const { data } = getMePayload.data;

        const user = data.user;
        const isAuth = data.auth;
        const onboarding = data.onboarding;

        if (isAuth) {
            if (data.isOnboarding) {
                switch (onboarding?.type) {
                    case InvitationType.Investor:
                        return navigate("/onboarding/investor");
                    case InvitationType.Company:
                    case InvitationType.Member:
                        return navigate("/onboarding/member");
                }
            } else if (user) {
                const params = new URLSearchParams(location.search);
                const redirectURL = params.get("redirect") || "/dashboard";
                return navigate(redirectURL);
            }
        }
    }, [getMePayload]);

    const formValid =
        isPasswordValid && repeatPassword && !repeatPasswordValidationError;

    return (
        <Layout
            type="auth"
            title="A window into a team’s potential"
            leftContent="VC Volt is a unique model based on decades of research on entrepreneurial teams and high performing companies."
            leftBottomSecondaryContent="The team is a company’s most important asset. Having a good analysis can contribute to bringing your company to the next level."
        >
            <div className="flex flex-col gap-10">
                <div className="text-center text-3xl text-black">
                    Set new password
                </div>

                <div className="flex flex-col gap-6">
                    <Input
                        isPasswordInput
                        type="password"
                        value={password}
                        label="New password"
                        placeholder="Enter a new password"
                        validations={Object.values(passwordValidations)}
                        onChange={(e) => onPasswordChange(e.target.value)}
                    />

                    <Input
                        isPasswordInput
                        type="password"
                        value={repeatPassword}
                        label="Confirm password"
                        placeholder="Confirm your new password"
                        errorMessage={repeatPasswordValidationError}
                        onChange={(e) => onRepeatPasswordChange(e.target.value)}
                    />
                </div>

                <Button disabled={!formValid} onClick={handleSubmit}>
                    Update password
                </Button>
            </div>
        </Layout>
    );
};

export default Index;
