import { configureStore } from "@reduxjs/toolkit";

// REDUCERS
import { api } from "./api/api";
import reducers from "./reducers";

export const store = configureStore({
    reducer: {
        ...reducers,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
