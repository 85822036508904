import ActionPopover, { ActionPopoverListItem } from "components/ActionPopover";
import { buttonVariants } from "components/Button";
import Icon from "components/Icon";
import { cn } from "lib/utils";
import { FC, useState } from "react";

type Props = {
    sort: number;
    actions: Array<ActionPopoverListItem>;
};

const SortMenu: FC<Props> = ({ sort, actions }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openHandler = (state: boolean) => {
        setIsOpen(state);
    };

    return (
        <ActionPopover
            triggerScaleOnOpen={false}
            actionButtonContent={
                <div
                    className={cn(
                        buttonVariants({
                            variant: "secondary-light",
                            size: "md",
                        }),
                        "px-4",
                        isOpen && "bg-default-100 hover:bg-default-100"
                    )}
                >
                    <span className="text-sm text-default-400">Sort by</span>{" "}
                    <span>{actions[sort]?.label}</span>
                    <Icon type={isOpen ? "ChevronUp" : "ChevronDown"} />
                </div>
            }
            actions={actions}
            onOpenChange={openHandler}
        />
    );
};

export default SortMenu;
