/* eslint-disable @typescript-eslint/no-use-before-define */
// import { QuestionWildCard } from "types/surveys";

import { getNumberQuestionSchema } from "questions/Number/schemas/number-question-schema";
import { getNumberArrayQuestionSchema } from "questions/NumberArray/schemas/number-array-question-schema";
import { Question, QuestionWildCard } from "types/surveys";
import {
    AllQuestionPatterns,
    QuestionType,
} from "types/surveys/question-pattern";

const pushUri = (path: string) => window.history.pushState({}, "", path);

// this function purpose is to pre-create a history of navigation urls before navigating to last uncompleted survey question
// it helps navigating back after being redirected to last un-answered question even though the user didn't go through them
// use case:
//      1) I go through survey
//      2) I copy url to specific question and close the tab
//      3) Open new tab and fill in the copied url to the survey question
//      4) Client is focused on that very question (at this point he didn't go through precedent questions which technically means that navigation history doesn't know about them)
//      5) However, by invoking this function we make sure it creates the history for us so that when user press Back button or back with mouse, he wil be redirected to previous question
const pushSurveyNavHistory = (
    surveyKey: string,
    questions: Array<[boolean, QuestionWildCard]>,
    navigate: (_: string) => void,
    qKey?: string
) => {
    const indexFirstUnansweredQuestion = Math.max(
        questions.findIndex((q) =>
            qKey ? q[0] && q[1].key === qKey : !q[1]?.answer && q[0]
        ),
        0
    );

    const registeredKeys: Array<string> = [];
    if (indexFirstUnansweredQuestion > 0) {
        questions.slice(0, indexFirstUnansweredQuestion).map((q) => {
            if (!q[0]) return; // skip question if it is programmatically hidden

            const key = q[1].key;
            registeredKeys.push(key);
            pushUri(`/surveys/${surveyKey}/${key}`);
        });
    }

    const questionKey = questions[indexFirstUnansweredQuestion][1]?.key;

    if (!registeredKeys.includes(questionKey)) {
        navigate(`/surveys/${surveyKey}/${questionKey}`);
    } else {
        const lastKey = `/surveys/${surveyKey}/${registeredKeys[registeredKeys.length - 1]}`;
        pushUri(lastKey);
    }
};

const validateNumberQuestion = (
    value: string,
    isRequired: boolean,
    hasThousandthsSeparator: boolean
) => {
    return getNumberQuestionSchema(
        isRequired,
        hasThousandthsSeparator
    ).safeParse(value).success;
};

const validateNumberArrayQuestion = (
    value: Array<string>,
    isRequired: boolean,
    hasThousandthsSeparator: boolean
) => {
    return getNumberArrayQuestionSchema(
        isRequired,
        hasThousandthsSeparator
    ).safeParse(value).success;
};

const validateChildren = (
    selectedValue: any,
    childValues: Record<string, any>,
    children: Array<Question<AllQuestionPatterns, unknown>> = []
) => {
    let areChildrenValid = true;

    children.map((item) => {
        const childValue = childValues[item.key];

        if (!areChildrenValid) {
            // skip validation if invalid
            // if first child is invalid and second is valid then areChildrenValid will be valid in the end
            return;
        }

        if (isPrimitive(selectedValue) || isArray(selectedValue)) {
            if (
                item.conditions.includes(selectedValue) ||
                item.conditions.some(
                    (i: any) =>
                        isArray(selectedValue) && selectedValue.includes(i)
                )
            ) {
                switch (item.pattern.type) {
                    case QuestionType.number:
                        areChildrenValid = validateNumberQuestion(
                            childValue,
                            item.isRequired,
                            item.pattern.unit?.type === "currency"
                        );
                        return;
                    case QuestionType.number_array:
                        areChildrenValid = validateNumberArrayQuestion(
                            childValue,
                            item.isRequired,
                            item.pattern.unit?.type === "currency"
                        );
                        return;

                    default:
                        break;
                }

                areChildrenValid = isPrimitive(childValue)
                    ? typeof childValue === "number" ||
                      typeof childValue === "boolean" ||
                      !!childValue
                    : childValue?.length &&
                      childValue?.every(
                          (val: any) => typeof val === "number" || !!val
                      );
            } else {
                areChildrenValid = true;
            }
        }
    });

    return areChildrenValid;
};

const getQuestionsAndVisibility = (
    questions: Array<QuestionWildCard>
): Array<[boolean, QuestionWildCard]> => {
    return questions.map((q) => {
        const parentQuestion = questions.find((qq) => qq.key === q.parent);
        const showQuestion = parentQuestion
            ? typeof parentQuestion?.answer?.value !== "undefined"
                ? q.conditions.includes(parentQuestion.answer.value)
                : true
            : true;

        return [showQuestion, q];
    });
};

const getNextQuestionKey = (
    questions: Array<[boolean, QuestionWildCard]>,
    nextQuestionIndex: number = 0
) =>
    (questions.slice(nextQuestionIndex, questions.length).find((q) => q[0]) ||
        [])[1]?.key;

const getCurrentQuestionIndex = (
    questions: Array<[boolean, QuestionWildCard]>,
    questionKey: string
) => questions.findIndex((q) => q[1].key === questionKey);

type A =
    | "string"
    | "number"
    | "bigint"
    | "boolean"
    | "symbol"
    | "undefined"
    | "object"
    | "function";

function isPrimitive(val: any) {
    return (["boolean", "number", "string"] as Array<A>).includes(typeof val);
}

function isArray(val: any) {
    return typeof val === "object" && Array.isArray(val);
}

const SURVEYS = {
    validateNumberQuestion,
    validateNumberArrayQuestion,
    validateChildren,
    getNextQuestionKey,
    pushSurveyNavHistory,
    getCurrentQuestionIndex,
    getQuestionsAndVisibility,
};

export default SURVEYS;
