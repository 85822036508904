import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// import API from "api";
import { ADMIN_BASE_URL } from "core/env";

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: ADMIN_BASE_URL,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        prepareHeaders: (headers, arg) => {
            // const token = API.TokenManager.getToken();

            // if (token) {
            //     headers.set("Authorization", `Bearer ${token}`);
            // }

            if (arg.endpoint === "createMedia") {
                // Remove 'Content-Type' header to allow browser to add
                // along with the correct 'boundary' for multipart/form-data requests
                headers.delete("Content-Type");
            }

            return headers;
        },
    }),
    tagTypes: [
        "Me",
        "Onboarding",
        "Surveys",
        "Company",
        "InvestorCompany",
        "Team",
        "Profile",
        "CompanyInvitation",
        "ArchivedCompanies",
        "SharedViewReport",
    ],
    endpoints: () => ({}),
});
