/* eslint-disable @typescript-eslint/naming-convention */
import * as yup from "yup";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
    useGetOnboardingQuery,
    useSaveOnboardingMutation,
} from "store/api/onboarding";
import {
    MemberOnboardingActions,
    MemberOnboardingSelectors,
} from "store/reducers/memberOnboarding";

import Utils from "utils";
import Input from "components/Input";
import { MemberRole } from "data/onboarding";
import Collapsed from "components/Collapsed";
import GridSelect from "components/GridSelect";
import InputSelect from "components/InputSelect";
import { InputSearchResults } from "types/validation.types";
import BottomButtons from "routes/onboarding/components/BottomButtons";
import { MemberOnboarding } from "store/reducers/memberOnboarding/types";
import DateInputMonthYear from "components/DateInputMonthYear";

type Props = {
    title: string;
    handleNextClick: () => void;
    handleBackClick: () => void;
};

const memberRoleArray = Utils.Parsers.enumToArray(MemberRole);

const schema = yup.object({
    joined: yup.string().required("Required"),
    isFounderValue: Utils.Validations.searchOption,
    roleDescription: yup.string().when("isFounderValue.value", {
        is: MemberRole.Other,
        then: () => yup.string().required("Required"),
        otherwise: () => yup.string(),
    }),
    haveShared: yup.string().required("Required"),
    haveSharedValue: yup.string().when("haveShared", {
        is: "true",
        then: () => Utils.Validations.sharePercentage,
        otherwise: () => yup.string(),
    } as any),
});

const parseDefaultValues = (
    {
        joined,
        haveShared,
        isFounderValue,
        roleDescription,
        haveSharedValue,
    }: MemberOnboarding.RoleAndSharesNew,
    data?: Partial<MemberOnboarding.RoleAndSharesNew>
): MemberOnboarding.RoleAndSharesNew => ({
    joined: joined || data?.joined || "",
    haveShared: haveShared || data?.haveShared,
    isFounderValue: isFounderValue || data?.isFounderValue,
    roleDescription: (roleDescription || data?.roleDescription) ?? "",
    haveSharedValue: data?.haveSharedValue || haveSharedValue,
});

const PasswordStep = ({ handleBackClick, handleNextClick, title }: Props) => {
    const dispatch = useDispatch();
    const defaultValues = useSelector(
        MemberOnboardingSelectors.state("roleAndSharesNew")
    );
    const { data: savedOnboarding } = useGetOnboardingQuery();
    const [saveOnboarding, saveOnboardingPayload] = useSaveOnboardingMutation();

    const {
        watch,
        control,
        setValue,
        clearErrors,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues: parseDefaultValues(
            defaultValues,
            savedOnboarding?.data["member.roleAndSharesNew"]
        ),
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema) as any,
    });

    const { isFounderValue: wRole, haveShared } = watch();

    const onSubmit = (roleAndShares: MemberOnboarding.RoleAndSharesNew) => {
        dispatch(MemberOnboardingActions.setRoleAndShares(roleAndShares));
        saveOnboarding({ "member.roleAndSharesNew": roleAndShares });
        handleNextClick();
    };

    useEffect(() => {
        if (haveShared === "false") {
            setValue("haveSharedValue", "");
            clearErrors("haveSharedValue");
        }
    }, [haveShared]);

    useEffect(() => {
        if (wRole?.value !== MemberRole.Other) {
            setValue("roleDescription", "");
            clearErrors("roleDescription");
        }
    }, [wRole]);

    return (
        <div>
            <div className="mb-10 text-2xl font-semibold">{title}</div>

            <div className="flex flex-col gap-5">
                <Controller
                    name="joined"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, value, onBlur },
                    }) => (
                        <DateInputMonthYear
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            errorMessage={error?.message}
                            label="When did you join the company"
                        />
                    )}
                />

                <Controller
                    name="isFounderValue"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { value, onChange, onBlur },
                    }) => (
                        <div className="flex-[4]">
                            <InputSelect<InputSearchResults[0]>
                                onBlur={onBlur}
                                selected={value as any}
                                handleSelect={onChange}
                                options={memberRoleArray}
                                label="What is your role?"
                                errorMessage={error?.message}
                                getOptionValue={(i) => i?.value}
                                renderOptionLabel={(i) => i.label}
                                placeholder="Select from drop-down"
                            />
                        </div>
                    )}
                />
                <Collapsed isExpanded={wRole?.value === MemberRole.Other}>
                    <Controller
                        control={control}
                        name="roleDescription"
                        render={({
                            fieldState: { error },
                            field: { onBlur, onChange, value },
                        }) => (
                            <Input
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                label="Role description"
                                errorMessage={error?.message}
                                placeholder="Describe your role"
                            />
                        )}
                    />
                </Collapsed>

                <Controller
                    name="haveShared"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <GridSelect<string>
                            label="Do you have shares?"
                            selected={value ? [value] : []}
                            onSelect={(e) => onChange(e[0])}
                            options={[
                                {
                                    label: "Yes",
                                    value: "true",
                                    icon: "Check",
                                },
                                {
                                    label: "No",
                                    value: "false",
                                    icon: "X",
                                },
                            ]}
                        />
                    )}
                />

                <Collapsed isExpanded={haveShared === "true"}>
                    <Controller
                        name="haveSharedValue"
                        control={control}
                        render={({
                            fieldState: { error },
                            field: { value, onChange, onBlur },
                        }) => (
                            <Input
                                type="number"
                                value={value}
                                endContent="%"
                                onBlur={onBlur}
                                onChange={onChange}
                                label="Share percentage"
                                errorMessage={error?.message}
                                placeholder="number in percentages; ex: 20%"
                            />
                        )}
                    />
                </Collapsed>

                <BottomButtons
                    onBack={handleBackClick}
                    onNext={handleSubmit(onSubmit)}
                    disableNext={!isValid || saveOnboardingPayload.isLoading}
                />
            </div>
        </div>
    );
};

export default PasswordStep;
