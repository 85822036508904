import { ActionPopoverListItem } from "components/ActionPopover";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const useCompaniesSort = (
    actions: Array<Partial<ActionPopoverListItem>>,
    onSort?: (sort: number, searchParams?: URLSearchParams) => void
) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const getSort = (): number => {
        const sortParams = parseInt(searchParams.get("sort") ?? "0");
        const page = Math.min(
            Math.max(0, isFinite(sortParams) ? sortParams : 0),
            actions.length - 1
        );
        return page;
    };

    const [sort, setSort] = useState<number>(getSort());

    const clickHanalder = (index: number) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("sort", index.toString());
        setSearchParams(newSearchParams, { replace: true });

        setSort(index);
        onSort?.(index, newSearchParams);
    };

    const actionsWithHandler: Array<ActionPopoverListItem> = actions.map(
        (action, index) => {
            const { label } = action;
            const newAction: ActionPopoverListItem = {
                ...action,
                label: label ?? "",
                icon: index === sort ? "Check" : undefined,
                onClick: () => clickHanalder(index),
            };
            return newAction;
        }
    );

    return { actions: actionsWithHandler, sort };
};

export default useCompaniesSort;
