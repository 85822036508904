import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
    CompanyOnboardingActions,
    CompanyOnboardingSelectors,
} from "store/reducers/companyOnboarding";
import {
    useGetOnboardingQuery,
    useSaveOnboardingMutation,
} from "store/api/onboarding";
import API from "api";
import Utils from "utils";
import Input from "components/Input";
import Alert from "components/Alert";
import FileInput, { AllowedFileTypes } from "components/FileInput";
import BottomButtons from "routes/onboarding/components/BottomButtons";
import { CompanyOnboarding } from "store/reducers/companyOnboarding/types";

type Props = {
    title: string;
    handleNextClick: () => void;
};

const schema = yup.object({
    image: Utils.Validations.image,
    website: Utils.Validations.websiteUrl,
    companyName: yup.string().required("Required"),
});

const parseDefaultValues = (
    { companyName, image, website }: CompanyOnboarding.Profile,
    data?: Partial<CompanyOnboarding.Profile>
): CompanyOnboarding.Profile => {
    const websiteValue = website || data?.website;
    return {
        companyName: companyName || data?.companyName || "",
        image: (image || data?.image) ?? null,
        website: websiteValue
            ? Utils.Parsers.formatWebsiteUrlToPresent(websiteValue)
            : "",
    };
};

const CompanyStep = ({ title, handleNextClick }: Props) => {
    const dispatch = useDispatch();
    const defaultValues = useSelector(
        CompanyOnboardingSelectors.state("profile")
    );
    const { data: savedOnboarding } = useGetOnboardingQuery();
    const [saveOnboarding, saveOnboardingPayload] = useSaveOnboardingMutation();

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues: parseDefaultValues(
            defaultValues,
            savedOnboarding?.data["company.profile"]
        ),
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema) as any,
    });

    const onSubmit = async (profile: CompanyOnboarding.Profile) => {
        profile.image =
            profile.image instanceof File
                ? await API.FileUpload.image(profile.image)
                : profile.image;
        profile.website = profile.website
            ? Utils.Parsers.formatWebsiteUrl(profile.website)
            : profile.website;

        dispatch(CompanyOnboardingActions.setCompany(profile));
        saveOnboarding({ "company.profile": profile });
        handleNextClick();
    };

    return (
        <div>
            <Alert
                type="success"
                className="absolute top-10"
                label="Your personal profile was successfully created!"
            />

            <div className="mb-10 text-2xl font-semibold">{title}</div>

            <div className="flex flex-col gap-5">
                <Controller
                    name="image"
                    control={control}
                    render={({
                        field: { onChange, value, onBlur },
                        fieldState: { error },
                    }) => (
                        <FileInput
                            onBlur={onBlur}
                            label="Company logo"
                            selectedFile={value}
                            errorMessage={error?.message}
                            fileTypes={AllowedFileTypes.Image}
                            setFiles={(files) => onChange(files)}
                            uploadLabel="Drag or upload a company logo"
                        />
                    )}
                />

                <Controller
                    name="companyName"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            label="Company name"
                            errorMessage={error?.message}
                            placeholder="Name to display in your company profile"
                        />
                    )}
                />

                <Controller
                    name="website"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            label="Website"
                            onBlur={onBlur}
                            onChange={onChange}
                            errorMessage={error?.message}
                            placeholder="https://yoursite.com"
                        />
                    )}
                />

                <BottomButtons
                    onNext={handleSubmit(onSubmit)}
                    disableNext={!isValid || saveOnboardingPayload.isLoading}
                />
            </div>
        </div>
    );
};

export default CompanyStep;
