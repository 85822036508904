import {
    IndividualScore,
    IndividualScoreWrapper,
} from "components/IndividualScore";
import { FC, ReactNode, useCallback, useMemo } from "react";

type BarWrapperProps = {
    children: ReactNode;
};

const BarWrapper: FC<BarWrapperProps> = ({ children }) => {
    return (
        <div className="relative col-span-4 flex h-8 max-h-8 flex-1 items-center justify-center rounded-sm bg-default-50 text-2xl text-default-400">
            {children}
        </div>
    );
};

type Props = {
    metricValue: number | undefined;
    averageValue: number;
};

const OverallIndividualBar: FC<Props> = ({
    metricValue,
    averageValue,
}: Props) => {
    if (metricValue === undefined) {
        return (
            <BarWrapper>
                <p className="text-sm font-semibold text-default-400">
                    Survey not submitted
                </p>
            </BarWrapper>
        );
    }

    const calcPositionLeft = useCallback((value: number) => {
        const min = 0;
        const max = 100;
        const position = ((value - 1) * (max - min)) / (5 - 1) + min;
        return Math.min(Math.max(position, min), max);
    }, []);

    const calcPositionOffset = useCallback((value: number) => {
        return -value / 100;
    }, []);

    const calcScorePosition = useCallback(
        (value: number) => {
            const left = calcPositionLeft(value);
            const offset = calcPositionOffset(left);
            // 44 is the width of the score wrapper element, should be updated together with the block
            // ref can be incorporated to get dynamic width
            const position = `calc(${left}% + (${44 * offset}px))`;
            return position;
        },
        [calcPositionLeft]
    );

    const roundedMetricValue = useMemo(
        () => Number(metricValue.toFixed(1)),
        [metricValue]
    );
    const roundedAverageValue = useMemo(
        () => Number(averageValue.toFixed(1)),
        [averageValue]
    );

    const individualScorePosition = useMemo(
        () => calcScorePosition(roundedMetricValue),
        [roundedMetricValue, calcScorePosition]
    );
    const averageScorePosition = useMemo(
        () => calcScorePosition(roundedAverageValue),
        [roundedAverageValue, calcScorePosition]
    );

    return (
        <BarWrapper>
            <IndividualScore
                size={"md"}
                value={roundedMetricValue}
                className="absolute"
                style={{
                    left: individualScorePosition,
                }}
            />
            <IndividualScoreWrapper
                size={"md"}
                className="absolute"
                style={{
                    left: averageScorePosition,
                }}
            >
                <span className="h-[20px] w-[2px] rounded-full bg-warning-500" />
            </IndividualScoreWrapper>
        </BarWrapper>
    );
};

export default OverallIndividualBar;
