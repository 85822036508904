import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMeQuery } from "store/api/auth";
import { useCompanyQuery } from "store/api/member";
import CompanyEditContent from "./CompanyEdit";
import { UserType } from "store/api/invitation/interfaces";

const CompanyEdit: FC = () => {
    const { data: meData } = useGetMeQuery();
    const { data: companyData } = useCompanyQuery();
    const navigate = useNavigate();

    useEffect(() => {
        // @TODO: have unified approach for guards
        if (
            !(
                meData?.data?.user?.type === UserType.Member &&
                meData?.data?.user?.isSuper
            )
        ) {
            navigate("/dashboard");
        }
    }, [meData, navigate]);

    const company = companyData?.data;

    if (!company) {
        // @TODO: loader
        return null;
    }

    return (
        <div className="h-[100vh] bg-default-100 p-4">
            <div
                className={`relative h-[100%] overflow-scroll rounded-lg bg-white px-[108px] md:px-[127px] lg:px-[177px] xl:px-[305px] 2xl:px-[433px] 3xl:px-[625px]`}
            >
                <CompanyEditContent company={company} />
            </div>
        </div>
    );
};

export default CompanyEdit;
