import { FC, ReactNode } from "react";
import { Header } from "routes/components/header";

type Props = {
    title: string;
    description: ReactNode;
    img: string;
    secondaryContent?: ReactNode;
};

const EmptyPage: FC<Props> = ({
    title,
    description,
    img,
    secondaryContent,
}) => {
    return (
        <div className="flex min-h-full flex-col bg-default-50">
            <Header />
            <div className="flex items-center justify-center px-14 pb-12 lg:px-[158px]">
                <div className="flex w-full max-w-[1124px] items-center justify-center rounded-2xl bg-white py-[146px]">
                    <div className="flex w-full max-w-md flex-col gap-y-7">
                        <img
                            src={img}
                            alt="Empty State"
                            className="h-auto w-full"
                        />
                        <div className="flex flex-col gap-y-2 text-center">
                            <h1 className="text-md font-semibold text-foreground">
                                {title}
                            </h1>
                            <p className="text-sm font-semibold text-default-400">
                                {description}
                            </p>
                        </div>
                        {secondaryContent}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmptyPage;
