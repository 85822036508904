import { FC } from "react";

import { useCompanyInvitationsQuery } from "store/api/investor";
import EmptyInvites from "../../../images/dashboard/investor/EmptyInvites.png";

// Components
import Empty from "./Empty";
import Button from "components/Button";
import PendingInviteCard from "./cards/PendingInviteCard";

type Props = {
    onInviteClick: () => void;
};

const PendingInvites: FC<Props> = ({ onInviteClick }) => {
    const { data, isLoading } = useCompanyInvitationsQuery();
    const invitations = data?.data ?? [];
    const noInvites = !isLoading && invitations.length === 0;

    if (noInvites)
        return (
            <Empty
                image={EmptyInvites}
                title="No pending invites"
                buttonLabel="Invite company"
                onInviteClick={onInviteClick}
                subText="Invite company to VC.Volt to get insights on the team"
            />
        );

    return (
        <>
            <div className="my-6 flex flex-row items-center justify-between">
                <div className="text-xl text-black">Pending invites</div>

                <Button onClick={onInviteClick} icon="UserPlus">
                    Invite company
                </Button>
            </div>

            <div className="flex items-center justify-center md:block">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                    {invitations.map((invite) => (
                        <PendingInviteCard key={invite.id} data={invite} />
                    ))}
                </div>
            </div>
        </>
    );
};

export default PendingInvites;
