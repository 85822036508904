import { useEffect, useState } from "react";

const sizes = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
};

export const useBreakpoint = (bp: keyof typeof sizes) => {
    const [matches, setMatches] = useState(window.innerWidth < sizes[bp]);

    useEffect(() => {
        const query = window.matchMedia(`(max-width: ${sizes[bp]}px)`);

        const handleChange = (e: MediaQueryListEvent) => {
            setMatches(e.matches);
        };

        query.addEventListener("change", handleChange);

        return () => {
            query.removeEventListener("change", handleChange);
        };
    }, [bp]);

    return matches;
};
