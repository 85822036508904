import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import "./styles.css";
import Utils from "utils";

import StarImg from "images/surveys/star.success.png";
import SoftImg from "images/surveys/soft.success.png";
import TeamImg from "images/surveys/team.success.png";
import PassionImg from "images/surveys/passion.success.png";
import HumanCapitalImg from "images/surveys/human-capital.success.png";

// STORE
import { useGetSurveyQuery, useSubmitSurveyMutation } from "store/api/survey";

// COMPONENTS
import Alert from "components/Alert";
import Button from "components/Button";
import Header from "./components/Header";
import { GlobalActions } from "store/reducers/global";
import { PageLoader } from "routes/components/PageLoader";
import QuestionRenderer from "./components/QuestionRenderer";
import ErrorPage from "routes/components/error-page";

const getSurveyImage = (key: string) => {
    if (key === "human-capital-skills") {
        return HumanCapitalImg;
    }

    if (key === "entrepreneurial-passion") {
        return PassionImg;
    }

    if (key === "team-dynamics") {
        return TeamImg;
    }

    if (key === "soft-skills") {
        return SoftImg;
    }

    if (key === "company-survey") {
        return StarImg;
    }

    return PassionImg;
};

export default () => {
    const dispatch = useDispatch();
    const swiperRef = useRef<any>(null);

    const navigate = useNavigate();
    const { surveyKey = "", questionKey = "" } = useParams();
    const smoothSlide = useRef(false);

    const {
        requestId,
        data: surveyData,
        isLoading: surveyLoading,
        isSuccess: surveySuccess,
        isFetching: surveyIsFetching,
        refetch,
    } = useGetSurveyQuery(surveyKey);
    const [shouldWait, setShouldWait] = useState(false);
    const [
        submitSurvey,
        { isSuccess: submitSurveyIsSuccess, isLoading: submitSurveyIsLoading },
    ] = useSubmitSurveyMutation();

    const initialRequestId = useRef(requestId);

    const [currStep, setCurrStep] = useState(0);
    const [errorState, setErrorState] = useState<boolean>();
    const [warningState, setWarningState] = useState<string>();

    const { questions: qs = [] } = surveyData?.data || {};
    const questions = Utils.Surveys.getQuestionsAndVisibility(qs);

    useEffect(() => {
        if (!surveyIsFetching && shouldWait) {
            const nextQuestionIndex =
                Utils.Surveys.getCurrentQuestionIndex(questions, questionKey) +
                1;

            if (nextQuestionIndex < questions.length) {
                const nextQuestionKey = Utils.Surveys.getNextQuestionKey(
                    questions,
                    nextQuestionIndex
                );

                navigate(`/surveys/${surveyKey}/${nextQuestionKey}`);
                setCurrStep((prev) => ++prev);
                swiperRef.current.swiper.slideNext(300);
                smoothSlide.current = true;
            } else if (nextQuestionIndex === questions.length) {
                submitSurvey({ surveyKey });
            }
            setShouldWait(false);
        }
    }, [surveyIsFetching, shouldWait, questions]);

    const handleNext = (changed?: boolean) => {
        if (!questions?.length) return;

        const isCurrentQuestionParent = qs.some(
            (question) => question.parent === questionKey
        );

        void changed;

        smoothSlide.current = true;

        if (isCurrentQuestionParent) {
            setShouldWait(true);
            refetch();
            return;
        }

        const nextQuestionIndex =
            Utils.Surveys.getCurrentQuestionIndex(questions, questionKey) + 1;

        if (nextQuestionIndex < questions.length) {
            const nextQuestionKey = Utils.Surveys.getNextQuestionKey(
                questions,
                nextQuestionIndex
            );

            navigate(`/surveys/${surveyKey}/${nextQuestionKey}`);
            setCurrStep((prev) => ++prev);
            swiperRef.current.swiper.slideNext(300);
        } else if (nextQuestionIndex === questions.length) {
            submitSurvey({ surveyKey });
        }
    };

    const handleBack = () => {
        if (!questions?.length || !swiperRef.current.swiper) return;

        const prevQuestionIndex =
            Utils.Surveys.getCurrentQuestionIndex(questions, questionKey) - 1;

        if (prevQuestionIndex >= 0) {
            navigate(-1);
            setCurrStep((prev) => --prev);
            swiperRef.current.swiper.slidePrev(300);
            smoothSlide.current = true;
        }
    };

    const handleCloseSurvey = () => navigate("/dashboard");

    const handleGoToSurveyIntro = () => navigate(`/surveys/${surveyKey}`);

    useEffect(() => {
        if (!questions?.length || !swiperRef.current.swiper) return;

        const currQuestionIndex = Utils.Surveys.getCurrentQuestionIndex(
            questions,
            questionKey
        );

        if (currStep === currQuestionIndex) return;

        swiperRef.current.swiper.slideTo(
            currQuestionIndex,
            smoothSlide.current ? 300 : 0
        );
        smoothSlide.current = false;
        setCurrStep(currQuestionIndex);
    }, [surveyData?.data, questionKey]);

    useEffect(() => {
        if (surveySuccess && !initialRequestId.current) {
            Utils.Surveys.pushSurveyNavHistory(
                surveyKey,
                questions,
                navigate,
                questionKey
            );
        }
    }, [surveySuccess]);

    if (submitSurveyIsSuccess && !submitSurveyIsLoading) {
        dispatch(
            GlobalActions.showModal({
                image: getSurveyImage(surveyKey),
                title: "Answers successfully submitted",
                subTitle: "Thank you for taking the time to participate!",
                bottomContent: (
                    <Button
                        icon="ArrowRight"
                        iconPosition="right"
                        onClick={() => dispatch(GlobalActions.hideModal())}
                    >
                        Go to dashboard
                    </Button>
                ),
            })
        );

        return <Navigate to="/dashboard" replace />;
    }

    if (surveyLoading) {
        return <PageLoader />;
    }

    if (!surveySuccess) {
        return <ErrorPage />;
    }

    const { name } = surveyData.data;

    return (
        <div className="relative flex h-screen flex-1 bg-default-100">
            <Header
                surveyKey={surveyKey}
                title={name}
                step={currStep + 1}
                total={questions.length}
                onFinishLaterClick={handleCloseSurvey}
            />

            <Swiper
                simulateTouch
                ref={swiperRef}
                spaceBetween={8}
                className="my-12"
                centeredSlides={true}
                allowTouchMove={false}
                slidesPerView={"auto"}
            >
                {questions.map((question, index) => (
                    <SwiperSlide
                        key={index}
                        className={
                            "relative flex h-full w-[90%] shrink-0 flex-col items-center justify-center self-stretch rounded-3xl bg-default-25"
                        }
                    >
                        <div
                            className={`slide-content flex !w-[100%] max-w-[480px] flex-col md:max-w-[596px] lg:max-w-[800px]`}
                        >
                            <QuestionRenderer
                                data={question[1]}
                                surveyKey={surveyKey}
                                handleNext={handleNext}
                                onError={setErrorState}
                                onWarning={setWarningState}
                                type={question[1].pattern.type}
                                allDisabled={currStep !== index} // make buttons/inputs disabled so that on pressing TAB it doesn't focus on next/previews slides
                                isLastQuestion={index === questions.length - 1}
                                handleBack={
                                    index === 0
                                        ? handleGoToSurveyIntro
                                        : handleBack
                                }
                            />
                        </div>

                        {errorState ? (
                            <Alert
                                type="error"
                                className="absolute bottom-6 max-w-[440px]"
                                label="We're sorry, we are having trouble with saving your data. Please reload this page."
                            />
                        ) : undefined}

                        {warningState && (
                            <Alert
                                type="tip"
                                label={warningState}
                                className="absolute bottom-6 max-w-[440px]"
                            />
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
