import { format, Track } from "@react-input/mask";
import InputMask from "components/InputMask";
import { cn } from "lib/utils";
import {
    ChangeEvent,
    FC,
    FocusEventHandler,
    useCallback,
    useRef,
    useState,
} from "react";

const INPUT_MASK = "mm / yyyy";
const INPUT_MASK_REPLACEMENT = { m: /\d/, y: /\d/ };

type Props = {
    label?: string;
    errorMessage?: string;
    value?: string;
    onChange: (v?: string) => void;
    onBlur: FocusEventHandler<HTMLInputElement>;
};

const DateInputMonthYear: FC<Props> = ({
    label,
    errorMessage,
    value: controllerValue,
    onChange,
    onBlur,
}) => {
    const [inputValue, setInputValue] = useState<string>(
        controllerValue
            ? format(
                  `${controllerValue.substring(5, 7)}${controllerValue.substring(0, 5)}`,
                  { mask: INPUT_MASK, replacement: INPUT_MASK_REPLACEMENT }
              )
            : ""
    );
    const [activeBlock, setActiveBlock] = useState<"month" | "year" | null>(
        null
    );
    const inputRef = useRef<HTMLInputElement | null>(null);

    const refHandler = useCallback(
        (ref: React.MutableRefObject<HTMLInputElement | null>) => {
            if (!ref.current) {
                return;
            }
            inputRef.current = ref.current;
        },
        []
    );

    const setSelectionRange = (
        selectionStart: number,
        selectionEnd?: number
    ) => {
        const ref = inputRef.current;
        if (!ref) {
            return;
        }
        ref.setSelectionRange(selectionStart, selectionEnd ?? selectionStart);
    };

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        if (newValue === INPUT_MASK) {
            setInputValue("");
            return;
        }

        const month = parseInt(`${newValue[0]}${newValue[1]}`);

        if (month > 12) {
            setInputValue(`${12}${newValue.substring(2)}`);
            return;
        }

        const ref = inputRef.current;
        if (ref && ref.selectionStart === 2) {
            setSelectionRange(5);
        }

        setInputValue(newValue);
    };

    const blurHandler = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        setActiveBlock(null);
        const month = parseInt(`${inputValue[0]}${inputValue[1]}`);
        const year = parseInt(
            `${inputValue[5]}${inputValue[6]}${inputValue[7]}${inputValue[8]}`
        );
        if (!isFinite(month) || !isFinite(year)) {
            onChange(undefined);
            onBlur(e);
            return;
        }
        const formatedMonth = month.toString().padStart(2, "0");
        const formatedYear = year.toString().padEnd(4, "0");
        setInputValue(
            format(`${formatedMonth}${formatedYear}`, {
                mask: INPUT_MASK,
                replacement: INPUT_MASK_REPLACEMENT,
            })
        );
        onChange(`${formatedYear}-${formatedMonth}-01`);
        onBlur(e);
    };

    const selectionHandler = (
        selectionStart: number | null,
        selectionEnd: number | null
    ) => {
        if (selectionStart === null || selectionEnd === null) {
            return;
        }
        if (selectionStart <= 3) {
            setActiveBlock("month");
        } else {
            setActiveBlock("year");
        }
    };

    const trackHandler: Track = ({
        inputType,
        value,
        data,
        selectionStart,
        selectionEnd,
    }) => {
        const parsedValueMonth = {
            first: parseInt(value[0]),
            second: parseInt(value[1]),
        };
        const isMonthTen = parsedValueMonth.first === 1;
        const parsedValueYear = [
            parseInt(value[5]),
            parseInt(value[6]),
            parseInt(value[7]),
            parseInt(value[8]),
        ];

        if (data === null) {
            if (selectionStart === 0 && selectionEnd === INPUT_MASK.length) {
                return;
            }
            if (selectionStart === 0) {
                // Do not allow to delete number if next one is more then 1 (month can't start with 2)
                if (
                    (inputType === "deleteBackward" ||
                        inputType === "deleteForward") &&
                    parsedValueMonth.second > 1
                ) {
                    return false;
                }
                // Do not allow to remove first month digit and make month 00 if there is 0 year
                if (inputType === "deleteBackward" && parsedValueYear[0] <= 0) {
                    return false;
                }
            }
            if (selectionStart === 1) {
                if (isMonthTen) {
                    // Do not allow to set second month number to more then 2 when deleting and shifting year
                    if (
                        parsedValueYear[
                            selectionEnd - selectionStart !== 1
                                ? selectionEnd % 5
                                : 0
                        ] > 2
                    ) {
                        return false;
                    }
                } else {
                    // Do not allow to remove second month digit and make month 00 if there is 0 year
                    if (
                        parsedValueYear[
                            selectionEnd - selectionStart !== 1
                                ? selectionEnd % 5
                                : 0
                        ] <= 0
                    ) {
                        return false;
                    }
                }
            }

            return;
        }

        const parsedData = parseInt(data);

        if (selectionStart === 0) {
            // Add zero in front if first month number is not 0 or 1
            if (parsedData > 1) {
                return "0" + data;
            }
        }
        if (selectionStart === 1) {
            if (isMonthTen) {
                // Do not allow month more then 12
                if (parsedData > 2) {
                    return false;
                }
            } else {
                // Do not allow 00 month
                if (parsedData <= 0) {
                    return false;
                }
            }
        }
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (inputValue === "") {
            return;
        }
        if (e.key !== "Tab") {
            return;
        }
        const ref = inputRef.current;
        if (!ref) {
            return;
        }
        if (ref.selectionStart === null || ref.selectionStart >= 5) {
            return;
        }
        e.preventDefault();
        setSelectionRange(5);
    };

    return (
        <InputMask
            mask={INPUT_MASK}
            replacement={INPUT_MASK_REPLACEMENT}
            showMask={true}
            track={trackHandler}
            value={inputValue}
            onRef={refHandler}
            onChange={changeHandler}
            onBlur={blurHandler}
            onKeyDown={keyDownHandler}
            selectionHandler={selectionHandler}
            inputWrapperClassName="!w-full"
            label={label}
            errorMessage={errorMessage}
        >
            <div
                role="none"
                className="pointer-events-none absolute left-0 top-1/2 -translate-y-1/2 border-1 border-transparent p-[14px] text-sm text-transparent"
            >
                <span
                    className={cn(
                        "relative",
                        activeBlock === "month" &&
                            "before:absolute before:-left-1/4 before:h-full before:w-[150%] before:rounded-full before:bg-default-500/10"
                    )}
                >
                    {inputValue.substring(0, 2)}
                </span>{" "}
                <span className={cn(inputValue && "text-default-400")}>/</span>{" "}
                <span
                    className={cn(
                        "relative",
                        activeBlock === "year" &&
                            "before:absolute before:-left-[12.5%] before:h-full before:w-[125%] before:rounded-full before:bg-default-500/10"
                    )}
                >
                    {inputValue.substring(5)}
                </span>
            </div>
        </InputMask>
    );
};

export default DateInputMonthYear;
