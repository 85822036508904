import { useState } from "react";
import {
    GeneratedInsightReportTeam,
    InsightsIndividualMember,
} from "store/api/investorCompany/interfaces";

export type MatrixControllerData = {
    key: keyof InsightsIndividualMember;
    data: GeneratedInsightReportTeam;
};

const useMatrixController = (matrixData: Array<MatrixControllerData>) => {
    const [currIdx, setCurrIdx] = useState<number>(0);

    const controlHandler = (shift: -1 | 1) => {
        let nextIdx = currIdx + shift;

        if (nextIdx < 0) {
            nextIdx = matrixData.length - 1;
        } else if (nextIdx >= matrixData.length) {
            nextIdx = 0;
        }

        setCurrIdx(nextIdx);
    };

    return {
        matrixData,
        currIdx,
        setCurrIdx,
        controlHandler,
    };
};

export default useMatrixController;
