// QUESTION TYPES
import ScaleQuestion from "questions/Scale";
import GridSelectQuestion from "questions/GridSelect";
import GridMultiSelectQuestion from "questions/GridMultiSelect";

import {
    QuestionType,
    AllQuestionPatterns,
    ScaleQuestionPattern,
    NumberQuestionPattern,
    BooleanQuestionPattern,
    LongTextQuestionPattern,
    ShortTextQuestionPattern,
    GridSelectQuestionPattern,
    NumberArrayQuestionPattern,
    GridMultiSelectQuestionPattern,
    DropDownMultiSelectQuestionPattern,
    LocationSearchMultiSelectQuestionPattern,
} from "types/surveys/question-pattern";
import {
    AllAnswerPatterns,
    ScaleAnswerPattern,
    NumberAnswerPattern,
    BooleanAnswerPattern,
    ShortTextAnswerPattern,
    GridSelectAnswerPattern,
    GridMultiSelectAnswerPattern,
    DropDownMultiSelectAnswerPattern,
    LocationSearchMultiSelectAnswerPatter,
} from "types/surveys/answer-pattern";
import SurveyFooter from "./SurveyFooter";
import NumberQuestion from "questions/Number";
import BooleanQuestion from "questions/Boolean";
import ShortTextQuestion from "questions/ShortText";
import NumberArrayQuestion from "questions/NumberArray";
import { QuestionProps } from "questions/types.questions";
import DropDownMultiSelectQuestion from "questions/DropdownMultiSelect";
import LocationSearchMultiSelectQuestion from "questions/LocationSearchMultiSelect";

type Props = {
    type: QuestionType;
} & QuestionProps<AllQuestionPatterns, AllAnswerPatterns>;

const QuestionRenderer = ({ type, ...rest }: Props) => {
    switch (type) {
        case QuestionType.scale:
            return (
                <ScaleQuestion
                    {...(rest as QuestionProps<
                        ScaleQuestionPattern,
                        ScaleAnswerPattern
                    >)}
                />
            );
        case QuestionType.grid_select:
            return (
                <GridSelectQuestion
                    {...(rest as QuestionProps<
                        GridSelectQuestionPattern,
                        GridSelectAnswerPattern
                    >)}
                />
            );
        case QuestionType.grid_multi_select:
            return (
                <GridMultiSelectQuestion
                    {...(rest as QuestionProps<
                        GridMultiSelectQuestionPattern,
                        GridMultiSelectAnswerPattern
                    >)}
                />
            );
        case QuestionType.boolean:
            return (
                <BooleanQuestion
                    {...(rest as QuestionProps<
                        BooleanQuestionPattern,
                        BooleanAnswerPattern
                    >)}
                />
            );
        case QuestionType.number:
            return (
                <NumberQuestion
                    {...(rest as QuestionProps<
                        NumberQuestionPattern,
                        NumberAnswerPattern
                    >)}
                />
            );
        case QuestionType.drop_down_multi_select:
            return (
                <DropDownMultiSelectQuestion
                    {...(rest as QuestionProps<
                        DropDownMultiSelectQuestionPattern,
                        DropDownMultiSelectAnswerPattern
                    >)}
                />
            );
        case QuestionType.location_search_multi_select:
            return (
                <LocationSearchMultiSelectQuestion
                    {...(rest as QuestionProps<
                        LocationSearchMultiSelectQuestionPattern,
                        LocationSearchMultiSelectAnswerPatter
                    >)}
                />
            );
        case QuestionType.short_text:
        case QuestionType.long_text:
            return (
                <ShortTextQuestion
                    {...(rest as QuestionProps<
                        ShortTextQuestionPattern | LongTextQuestionPattern,
                        ShortTextAnswerPattern | LongTextQuestionPattern
                    >)}
                />
            );
        case QuestionType.number_array:
            return (
                <NumberArrayQuestion
                    {...(rest as QuestionProps<
                        NumberArrayQuestionPattern,
                        any
                    >)}
                />
            );
        default:
            return (
                <>
                    <div className="text-sm text-center text-default-500">
                        Question type <span className="text-black">{type}</span>{" "}
                        in development
                    </div>

                    <SurveyFooter
                        loadingText="Saving data..."
                        handleBack={rest.handleBack}
                        handleNext={rest.handleNext}
                        allDisabled={rest.allDisabled}
                        nextText={rest.isLastQuestion ? "Submit" : undefined}
                    />
                </>
            );
    }
};

export default QuestionRenderer;
