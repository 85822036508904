import { Dispatch, FC } from "react";
import { InsightsIndividual } from "store/api/investorCompany/interfaces";
import Title from "routes/company/components/Modal/Title";
import Matrix from "../Matrix";
import IndividualScores from "routes/company/components/IndividualScores";
import { MatrixControllerData } from "../useMatrixController";
import { TOOLTIPS } from "routes/company/tooltips";

type Props = {
    individualData: InsightsIndividual;
    matrixData: Array<MatrixControllerData>;
    currIdx: number;
    tooltip: string;
    setCurrIdx: Dispatch<React.SetStateAction<number>>;
    controlHandler: (shift: -1 | 1) => void;
};

const SoftSkillsMatrixModal: FC<Props> = ({
    individualData,
    matrixData,
    currIdx,
    tooltip,
    ...matrixController
}) => {
    const currData = matrixData[currIdx];
    return (
        <div className="flex flex-col gap-4">
            <Title tooltip={tooltip}>Soft skills matrix</Title>

            <div>
                <Matrix
                    iconType="Hexagon"
                    tooltip={TOOLTIPS.softSkills.spider}
                    name="Soft skills matrix"
                    matrixData={matrixData}
                    currIdx={currIdx}
                    {...matrixController}
                    showTitle={false}
                    showMore={false}
                    className="max-w-none !p-0"
                    wrapperClassName="gap-y-4"
                    statusWrapperClassName="!m-0"
                />
            </div>

            <IndividualScores
                individualData={individualData}
                teamMetricValue={(currData?.data?.value as number) ?? 0}
                metric={currData?.key}
            />
        </div>
    );
};

export default SoftSkillsMatrixModal;
