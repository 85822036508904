import { FC } from "react";
import { SpiderChartProps } from "./interfaces";
import {
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart,
} from "recharts";
import { ChartContainer } from "../chart";

const SpiderChart: FC<SpiderChartProps> = ({
    className,
    data,
    highlightedIdx,
    stroke = "#BD97FF",
    fill = "#BD97FF",
    onClick,
}) => {
    void highlightedIdx;
    return (
        <ChartContainer config={{}} className={className}>
            <RadarChart data={data}>
                <PolarGrid />
                <PolarRadiusAxis domain={[0, 5]} />
                <PolarAngleAxis
                    dataKey="name"
                    tick={({ x, y, payload, textAnchor, index }) => {
                        const value = `${payload.value} (${data[index].value.toFixed(1)})`;
                        const r = 8;
                        const w = r * 2 + value.length * 6;
                        const h = r * 2 + 10;
                        const ry = -h + 12;
                        const rx =
                            textAnchor === "end"
                                ? -w + r
                                : textAnchor === "middle"
                                  ? -w / 2
                                  : -r;

                        return (
                            <g
                                className="cursor-pointer"
                                transform={`translate(${x},${y})`}
                                onClick={() => onClick?.(index)}
                            >
                                {highlightedIdx === index && (
                                    <rect
                                        x={rx}
                                        y={ry}
                                        width={w}
                                        height={h}
                                        fill="#F6F6F8"
                                        rx={4}
                                    />
                                )}
                                <text
                                    fill="#0F1117"
                                    textAnchor={textAnchor}
                                    dy={4}
                                >
                                    {value}
                                </text>
                            </g>
                        );
                    }}
                    tickFormatter={(v, i) =>
                        `${v} (${data[i].value.toFixed(1)})`
                    }
                />
                <Radar
                    dataKey="value"
                    stroke={stroke}
                    fill={fill}
                    fillOpacity={0.6}
                />
            </RadarChart>
        </ChartContainer>
    );
};

export default SpiderChart;
