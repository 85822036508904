import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useEffect } from "react";
import { InvestorEditProps } from "./interfaces";
import { useUpdateInvestorProfileMutation } from "store/api/member";
import { Controller, useForm } from "react-hook-form";
import { InferFieldValues } from "types/utils";
import Button from "components/Button";
import Input from "components/Input";
import FileInput, { AllowedFileTypes } from "components/FileInput";
import API from "api";
import { useDispatch } from "react-redux";
import { GlobalActions } from "store/reducers/global";

import Logo from "../../images/logo.png";
import Utils from "utils";
import { Link } from "react-router-dom";

const schema = yup.object({
    image: Utils.Validations.image,
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),

    state: yup.string(),
    street: yup.string().required("Required"),
    zipCode: yup.string().required("Required"),
    city: yup.string().required("Required"),
    country: yup.string().required("Required"),

    companyName: yup.string().required("Required"),
    contactRole: yup.string().required("Required"),
    vat: yup.string().required("Required"),
});

const InvestorEdit: FC<InvestorEditProps> = ({ investor, me }) => {
    const dispatch = useDispatch();
    const [updateInvestorProfile, updateInvestorProfileData] =
        useUpdateInvestorProfileMutation();

    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema) as any,
        values: {
            // Personal details
            image: investor.contact.picture ?? null,
            firstName: investor.contact.firstName,
            lastName: investor.contact.lastName,

            // Your Company
            companyName: investor.companyName,
            contactRole: investor.contact.role,
            vat: investor.vat,

            // Billing Address
            state: investor.address.state || "",
            street: investor.address.street,
            zipCode: investor.address.postcode,
            city: investor.address.city,
            country: investor.address.country,
        },
    });

    const onSubmit = async (data: InferFieldValues<typeof handleSubmit>) => {
        const picture =
            data.image instanceof File
                ? await API.FileUpload.image(data.image)
                : data.image;

        updateInvestorProfile({
            vat: data.vat,
            companyName: data.companyName,
            address: {
                state: data.state,
                street: data.street,
                postcode: data.zipCode,
                city: data.city,
                country: data.country,
            },
            contact: {
                picture: picture?.id ?? null,
                role: data.contactRole,
                firstName: data.firstName,
                lastName: data.lastName,
            },
        });
    };

    useEffect(() => {
        if (
            updateInvestorProfileData.isUninitialized ||
            updateInvestorProfileData.isLoading
        ) {
            return;
        }

        dispatch(
            GlobalActions.showPopover({
                type: updateInvestorProfileData.isSuccess ? "success" : "error",
                label: updateInvestorProfileData.isSuccess
                    ? `Profile updated`
                    : "An error occurred updating investor profile",
            })
        );
    }, [updateInvestorProfileData]);

    void onSubmit;

    return (
        <>
            <div className="mx-auto max-w-[638px] py-10 pb-[100px]">
                <div className="fixed left-0 right-0 top-0 flex flex-row items-center justify-between p-12">
                    <img src={Logo} className="h-[48px] w-[48px]" />

                    <Button
                        as={Link}
                        icon="X"
                        to="/dashboard"
                        iconPosition="left"
                        customType="secondary-light"
                        className="bg-[transparent]"
                    >
                        Close
                    </Button>
                </div>

                <div className="mb-10 flex items-center justify-between">
                    <h1 className="text-xl font-bold">Edit personal profile</h1>
                </div>

                <div className="flex flex-col gap-10">
                    <section>
                        <h2 className="mb-6 font-semibold">Basic info</h2>
                        <div className="flex flex-col gap-8 rounded-2xl bg-default-25 p-10">
                            <Controller
                                name="image"
                                control={control}
                                render={({
                                    field: { onChange, value, onBlur },
                                    fieldState: { error },
                                }) => (
                                    <FileInput
                                        label="Photo"
                                        onBlur={onBlur}
                                        selectedFile={value}
                                        errorMessage={error?.message}
                                        fileTypes={AllowedFileTypes.Image}
                                        setFiles={(files) => onChange(files)}
                                        uploadLabel="Drag or upload a picture"
                                    />
                                )}
                            />

                            <Controller
                                name="firstName"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <Input
                                        {...field}
                                        label="First name"
                                        errorMessage={error?.message}
                                        placeholder="Add your first name"
                                    />
                                )}
                            />

                            <Controller
                                name="lastName"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <Input
                                        {...field}
                                        label="Last name"
                                        errorMessage={error?.message}
                                        placeholder="Add your last name"
                                    />
                                )}
                            />

                            <Input disabled value={me.email} label="Email" />
                        </div>
                    </section>

                    <section>
                        <h2 className="mb-6 font-semibold">Your company</h2>
                        <div className="flex flex-col gap-8 rounded-2xl bg-default-25 p-10">
                            <Controller
                                name="companyName"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <Input
                                        ref={ref}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        label="Company name"
                                        errorMessage={error?.message}
                                        placeholder="Full legal name"
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="contactRole"
                                render={({
                                    fieldState: { error },
                                    field: { onChange, value, onBlur },
                                }) => (
                                    <Input
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        label="What is your role?"
                                        errorMessage={error?.message}
                                        placeholder="Add your answer here"
                                    />
                                )}
                            />

                            <Controller
                                name="vat"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { value, onChange, onBlur },
                                }) => (
                                    <Input
                                        value={value}
                                        onBlur={onBlur}
                                        label="VAT number"
                                        onChange={onChange}
                                        errorMessage={error?.message}
                                        placeholder="Company tax number"
                                    />
                                )}
                            />
                        </div>
                    </section>

                    <section>
                        <h2 className="mb-6 font-semibold">Billing address</h2>
                        <div className="flex flex-col gap-8 rounded-2xl bg-default-25 p-10">
                            <Controller
                                name="street"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <Input
                                        ref={ref}
                                        value={value}
                                        label="Street"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errorMessage={error?.message}
                                        placeholder="Street name & number"
                                    />
                                )}
                            />

                            <Controller
                                name="city"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <Input
                                        ref={ref}
                                        value={value}
                                        label="City"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errorMessage={error?.message}
                                        placeholder="City name"
                                    />
                                )}
                            />

                            <Controller
                                name="zipCode"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <Input
                                        ref={ref}
                                        value={value}
                                        label="Postcode"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errorMessage={error?.message}
                                        placeholder="Postcode or zip code"
                                    />
                                )}
                            />

                            <Controller
                                name="state"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <Input
                                        ref={ref}
                                        value={value}
                                        label="State"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errorMessage={error?.message}
                                        placeholder="State or province"
                                    />
                                )}
                            />

                            <Controller
                                name="country"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <Input
                                        ref={ref}
                                        value={value}
                                        label="Country"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errorMessage={error?.message}
                                        placeholder="Country name"
                                    />
                                )}
                            />
                        </div>
                    </section>
                </div>
            </div>

            <div className="fixed bottom-4 left-12 right-12 rounded-b-lg border-t border-solid bg-white">
                <div className="mx-auto my-4 max-w-[638px]">
                    <Button
                        disabled={!isDirty || !isValid}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Save changes
                    </Button>
                </div>
            </div>
        </>
    );
};

export default InvestorEdit;
