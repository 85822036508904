import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function useSearchParamsPagination() {
    const [searchParams, setSearchParams] = useSearchParams();

    const getPage = (): number => {
        const pageParams = parseInt(searchParams.get("page") ?? "1");
        const page = Math.max(1, isFinite(pageParams) ? pageParams : 1);
        return page;
    };

    const [currPage, setCurrPage] = useState<number>(getPage());

    const setPage = (page: number, oldSearchParams?: URLSearchParams): void => {
        const newSearchParams = new URLSearchParams(
            oldSearchParams ?? searchParams
        );
        newSearchParams.set("page", page.toString());
        setSearchParams(newSearchParams, { replace: true });
        setCurrPage(page);
    };

    return { page: currPage, setPage };
}
