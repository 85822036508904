import { FC } from "react";
import { RadialChartProps } from "./interfaces";
import { ChartContainer } from "../chart";
import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";

const RadialChart: FC<RadialChartProps> = ({ className, data }) => {
    return (
        <ChartContainer config={{}} className={className}>
            <RadialBarChart data={data} innerRadius="40%" outerRadius="100%">
                <PolarAngleAxis type="number" domain={[0, 5]} tick={false} />
                <RadialBar dataKey="value" background cornerRadius={8} />
            </RadialBarChart>
        </ChartContainer>
    );
};

export default RadialChart;
