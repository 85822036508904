import { cva, VariantProps } from "class-variance-authority";
import Icon, { IconType } from "components/Icon";
import { cn } from "lib/utils";

const statusCompanyVariants = cva(
    "flex flex-row items-center gap-1 rounded-lg p-[6px] pr-[10px] text-sm",
    {
        variants: {
            variant: {
                "secondary-light": cn("bg-white"),
                "secondary-solid": cn("bg-default-50"),
            },
        },
        defaultVariants: {
            variant: "secondary-light",
        },
    }
);

type Props = {
    iconType: IconType;
    label: string;
} & VariantProps<typeof statusCompanyVariants>;

const StatusCompany: React.FC<Props> = ({ iconType, variant, label }) => {
    return (
        <div className={cn(statusCompanyVariants({ variant }))}>
            <Icon size={14} type={iconType} />
            {label}
        </div>
    );
};

export default StatusCompany;
