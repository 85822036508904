import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "components/Alert";
import { GlobalActions, GlobalSelectors } from "store/reducers/global";
import { cn } from "lib/utils";

let timeout: NodeJS.Timeout;

const GlobalPopover = () => {
    const dispatch = useDispatch();
    const { label, type, show } = useSelector(GlobalSelectors.popover);

    useEffect(() => {
        if (!show) return;

        clearTimeout(timeout);
        timeout = setTimeout(() => {
            dispatch(GlobalActions.hidePopover());
        }, 3_000);
    }, [show, label]);

    return (
        <div
            className={cn(
                `fixed left-1/2 -translate-x-1/2 transform`,
                "p-auto flex justify-center",
                "z-9999 transition-all duration-500 ease-in-out",
                show ? "top-[32px] opacity-100" : "top-[-50px] opacity-0",
                "max-w-[90%] md:max-w-[400px]"
            )}
        >
            {label && type && <Alert label={label} type={type} />}
        </div>
    );
};

export default GlobalPopover;
