import Icon, { IconType } from "components/Icon";
import { cn } from "lib/utils";
import EmptyInsight from "routes/company/components/EmptyInsight";
import InfoTooltip from "routes/company/components/InfoTooltip";

type Props = {
    iconType: IconType;
    name: string;
    isEmptyInsight?: boolean;
    tooltip?: string;
    children: React.ReactNode;
    className?: string;
};

const ContainerWrapper: React.FC<Props> = ({
    iconType,
    name,
    isEmptyInsight,
    tooltip,
    children,
    className,
}) => {
    return (
        <div
            className={cn(
                "m-auto flex h-[100%] w-[100%] max-w-[576px] flex-col gap-2 rounded-lg bg-white p-6 lg:max-w-[unset]",
                className
            )}
        >
            <div className="mb-7 flex flex-row items-center gap-2">
                <div className="rounded-sm border border-solid border-default-75 bg-default-50 p-1">
                    <Icon
                        size={20}
                        type={iconType}
                        className="text-default-400"
                    />
                </div>

                <div className="text-sm">{name}</div>

                <InfoTooltip text={tooltip ?? ""} />
            </div>

            {isEmptyInsight ? <EmptyInsight /> : children}
        </div>
    );
};

export default ContainerWrapper;
