import { FC, useEffect, useMemo, useState } from "react";
import EmptyPage from "../empty-page";
import EmptyPageImg from "../../../images/dashboard/empty-page.png";
import { Link } from "react-router-dom";
import Button from "components/Button";
import Gleap from "gleap";
import { SHOW_TRACKER } from "index";

type Props = {};

const ErrorPage: FC<Props> = () => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const url = useMemo(() => window.location.origin, []);

    useEffect(() => {
        Gleap.on("open", () => {
            setDisabled(true);
        });
        Gleap.on("close", () => {
            setDisabled(false);
        });
    }, []);

    return (
        <EmptyPage
            title="Looks like something went wrong :("
            description={
                <>
                    Try reloading the page or open{" "}
                    <Link
                        className="text-foreground underline transition-colors hover:text-primary-500"
                        to={url}
                    >
                        {url}
                    </Link>
                </>
            }
            img={EmptyPageImg}
            {...(SHOW_TRACKER === true && {
                secondaryContent: (
                    <Button
                        customType="secondary-solid"
                        icon="TriangleAlert"
                        onClick={() =>
                            Gleap.startClassicForm("bugreporting", true)
                        }
                        disabled={disabled}
                        className="mx-auto"
                    >
                        Report an issue
                    </Button>
                ),
            })}
        />
    );
};

export default ErrorPage;
