import { FC } from "react";
import { useGetMeQuery } from "store/api/auth";
import { CompanyCard } from "../CompanyCard";
import { TeamCard } from "../TeamCard";
import { SurveysCard } from "../SurveysCard";
import Guideline from "./Guideline";
import { cn } from "lib/utils";
import { UserType } from "store/api/invitation/interfaces";

const Member: FC = () => {
    const { data: meData } = useGetMeQuery();

    if (!meData) {
        return;
    }

    return (
        <div
            className={cn(
                "min-h-screen",
                "mx-auto w-full px-8 pb-8 sm:w-[582px] sm:px-0 xl:w-[1124px]"
            )}
        >
            <section className="mb-4 flex max-w-[552px] flex-col">
                <h1 className="mb-2 text-3xl">
                    Hello,{" "}
                    {meData?.data?.user?.type === UserType.Member
                        ? meData.data.user.data.firstName
                        : ""}{" "}
                    👋
                </h1>
                <Guideline />
            </section>
            <section className="m-auto grid max-w-[582px] grid-cols-1 gap-4 lg:max-w-[unset] xl:grid-cols-2">
                <CompanyCard />
                <TeamCard />
            </section>
            <section className="m-auto mt-4 grid max-w-[582px] grid-cols-1 gap-4 lg:max-w-[unset]">
                <SurveysCard />
            </section>
        </div>
    );
};

export default Member;
