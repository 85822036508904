import Icon, { IconType } from "components/Icon";
import { cn } from "lib/utils";
import { ReactNode } from "react";

export type AlertType = "tip" | "error" | "success" | "info" | "dark";
type Props = {
    label: string | React.ReactNode;
    type: AlertType;
    headline?: string;
    className?: string;
    children?: ReactNode;
};

const TYPE_TO_ICON: Record<AlertType, IconType> = {
    error: "CircleAlert",
    info: "Info",
    success: "CircleCheck",
    tip: "Sparkles",
    dark: "Check",
};

const TYPE_TO_HEADLINE_COLOR: Record<AlertType, string> = {
    error: "text-danger-500",
    info: "text-default-500",
    success: "text-success-500",
    tip: "text-secondary-400",
    dark: "text-white",
};

const TYPE_TO_LABEL_COLOR: Record<AlertType, string> = {
    error: "text-danger-500",
    info: "text-foreground",
    success: "text-success-500",
    tip: "text-black",
    dark: "text-white",
};

const TYPE_TO_BACKGROUND: Record<AlertType, string> = {
    error: "bg-danger-100",
    info: "bg-background",
    success: "bg-success-50",
    tip: "bg-alert-secondary",
    dark: "bg-black",
};

const TYPE_TO_BORDER: Record<AlertType, string> = {
    info: "",
    error: "",
    success: "",
    tip: "border border-secondary-200",
    dark: "",
};

const TYPE_TO_SHADOW: Record<AlertType, string> = {
    error: "",
    info: "shadow-sm",
    success: "",
    tip: "",
    dark: "",
};

const Alert = ({
    type = "tip",
    label,
    headline,
    className,
    children,
}: Props) => {
    return (
        <div
            className={cn(
                `flex flex-row items-center rounded-lg p-4 ${TYPE_TO_BORDER[type]} ${TYPE_TO_BACKGROUND[type]} ${TYPE_TO_HEADLINE_COLOR[type]}`,
                TYPE_TO_SHADOW[type],
                className
            )}
        >
            <div className="flex flex-1">
                <div className="flex flex-row">
                    <Icon
                        type={TYPE_TO_ICON[type]}
                        className={`${!headline && "mr-3"} ${TYPE_TO_HEADLINE_COLOR[type]}`}
                    />
                    {headline && (
                        <div className="ml-2 mr-3 text-sm">{headline}</div>
                    )}
                </div>

                <div
                    className={`flex flex-1 text-sm text-black ${TYPE_TO_LABEL_COLOR[type]}`}
                >
                    {label}
                </div>
            </div>

            {children}
        </div>
    );
};

export default Alert;
