import { useMemo } from "react";
import Utils from "utils";

export type StatusType = "low" | "medium" | "high";

type Props = {
    value: number;
    statusKey?: StatusType;
};

const BG_TO_TYPE: Record<StatusType, string> = {
    high: "bg-primary-500",
    low: "bg-danger-500",
    medium: "bg-tertiary-500",
};

const TYPE_TO_TEXT: Record<StatusType, string> = {
    high: "High",
    low: "Low",
    medium: "Medium",
};

const InsightStatus: React.FC<Props> = ({ value, statusKey }) => {
    // @TODO: get rid of value and use statusKey only
    //
    const type = useMemo((): StatusType => {
        if (statusKey) {
            return statusKey;
        }

        return Utils.Status.getStatusType(value);
    }, [value, statusKey]);

    return (
        <div className="flex flex-row items-center gap-2">
            <div
                className={`${BG_TO_TYPE[type]} h-[8px] w-[8px] rounded-full`}
            />
            {TYPE_TO_TEXT[type]}
        </div>
    );
};

export default InsightStatus;
