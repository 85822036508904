import { FC } from "react";

const SvgDefs: FC = () => {
    return (
        <svg className="absolute h-0 w-0">
            <defs>
                <radialGradient id="soft-skills-matrix">
                    <stop offset="10%" stopColor="#EEE5FF" />
                    <stop offset="95%" stopColor="#BD97FF" />
                </radialGradient>
                <linearGradient id="insight-score-low">
                    <stop offset="0%" stopColor="#FC8E3F" />
                    <stop offset="100%" stopColor="#FA6F87" />
                </linearGradient>
                <linearGradient id="insight-score-medium">
                    <stop offset="0%" stopColor="#FA6F87" />
                    <stop offset="100%" stopColor="#F256C0" />
                </linearGradient>
                <linearGradient id="insight-score-high">
                    <stop offset="0%" stopColor="#D357FA" />
                    <stop offset="100%" stopColor="#9753FC" />
                </linearGradient>
                <linearGradient id="area-gradient">
                    <stop offset="0%" stopColor="#DBC7FF" />
                    <stop offset="100%" stopColor="#BD97FF" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default SvgDefs;
