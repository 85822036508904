import { DTO } from "../shared.types";

export type InvitationQueryParams = {
    token: string;
};

export type GetInvitation = DTO<{
    email: string;
    name: string;
    type: InvitationType;
}>;

export enum InvitationType {
    Member = "Member",
    Company = "Company",
    Investor = "Investor",
    Admin = "Admin",
}

export enum UserType {
    Member = "member",
    Company = "company",
    Investor = "investor",
    Admin = "admin",
}

export interface CreateInvitationRequest {
    email: string;
}

export type CreateInvitationResponse = DTO<{
    id: string;
    email: string;
    status: string;
}>;

export interface ResendInvitationRequest {
    id: string;
}

export type ResendInvitationResponse = DTO<null>;

export interface RevokeInvitationRequest {
    id: string;
}

export type RevokeInvitationResponse = DTO<null>;
