import Icon, { IconType } from "components/Icon";
import cn from "classnames";
import InsightDescription from "routes/company/components/Modal/InsightDescription";
import EmptyInsight from "routes/company/components/EmptyInsight";
import InfoTooltip from "routes/company/components/InfoTooltip";
import { InsightsTeam } from "store/api/investorCompany/interfaces";
import InsightStatus, { StatusType } from "./InsightStatus";
import { useBreakpoint } from "hooks/useBreakpoint";

type Props = {
    teamData: InsightsTeam;
    name: string;
    iconType: IconType;
    tooltip: string;
    showTitle?: boolean;
    showMore?: boolean;
    onShowMore?: () => void;
    className?: string;
    insightClassName?: string;
};

const GRADIENTS = {
    fixed: "radial-gradient(circle, #FA6F87 0%, #FF9CA7 100%)",
    growth: "radial-gradient(circle, #AC74FF 0%, #DBC7FF 100%)",
};

const FixedGrowth: React.FC<Props> = ({
    teamData: { ss_fixed_mindset, ss_growth_mindset, ss_mindset },
    name,
    iconType,
    tooltip,
    showTitle = true,
    showMore = true,
    onShowMore,
    className,
    insightClassName,
}) => {
    const isLessXl = useBreakpoint("xl");
    const fixed = ss_fixed_mindset;
    const growth = ss_growth_mindset;
    const mindset = ss_mindset;
    const fixedConclusionLevel: StatusType =
        (fixed?.conclusion?.level?.toLowerCase?.() as StatusType | undefined) ??
        "low";
    const growthConclusionLevel: StatusType =
        (growth?.conclusion?.level?.toLowerCase?.() as
            | StatusType
            | undefined) ?? "low";
    const description = mindset?.conclusion?.text;
    const maxHeight = isLessXl ? 160 : 200;
    const maxValue = 5;
    const fixedSize = (maxHeight * ((fixed?.value as number) ?? 0)) / maxValue;
    const growthSize =
        (maxHeight * ((growth?.value as number) ?? 0)) / maxValue;
    const isEmptyInsight: boolean = !fixed || !growth || !mindset;

    return (
        <div
            className={cn(
                "group relative m-auto flex w-[100%] max-w-[576px] flex-col gap-2 rounded-lg bg-white p-6 lg:max-w-[unset]",
                showMore && "h-[536px]",
                className
            )}
        >
            {showMore && !isEmptyInsight && (
                <button
                    type="button"
                    onClick={onShowMore}
                    className="absolute right-6 top-6 rounded p-2 opacity-0 transition duration-100 hover:bg-default-50 group-hover:opacity-100"
                >
                    <Icon type="Maximize2" size="16" />
                </button>
            )}
            {showTitle && (
                <div className="flex flex-row items-center gap-2">
                    <div className="rounded-sm border border-solid border-default-75 bg-default-50 p-1">
                        <Icon
                            size={20}
                            type={iconType}
                            className="text-default-400"
                        />
                    </div>

                    <div className="text-sm">{name}</div>

                    <InfoTooltip text={tooltip} />
                </div>
            )}

            {isEmptyInsight ? (
                <EmptyInsight />
            ) : (
                <>
                    <div
                        className={cn(
                            "flex flex-1 items-center justify-center gap-x-4 text-sm",
                            insightClassName
                        )}
                    >
                        <span
                            className={
                                "inline-block rounded-sm p-2 transition duration-200"
                            }
                        >
                            Fixed mindset
                        </span>
                        <div
                            style={{
                                backgroundImage: GRADIENTS.fixed,
                                width: fixedSize,
                                height: fixedSize,
                            }}
                            className="flex aspect-square animate-scale-in items-center justify-center rounded-full text-white"
                        >
                            {((fixed?.value as number) ?? 0).toFixed(1)}
                        </div>
                        <div
                            style={{
                                backgroundImage: GRADIENTS.growth,
                                width: growthSize,
                                height: growthSize,
                            }}
                            className="flex aspect-square animate-scale-in items-center justify-center rounded-full text-white"
                        >
                            {((growth?.value as number) ?? 0).toFixed(1)}
                        </div>
                        <span
                            className={
                                "inline-block rounded-sm p-2 transition duration-200"
                            }
                        >
                            Growth mindset
                        </span>
                    </div>

                    <div>
                        <div className="flex items-center gap-x-6">
                            <div className="flex flex-row items-center gap-1">
                                <InsightStatus
                                    value={0}
                                    statusKey={fixedConclusionLevel}
                                />
                                <span>fixed mindset</span>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <InsightStatus
                                    value={0}
                                    statusKey={growthConclusionLevel}
                                />
                                <span>growth mindset</span>
                            </div>
                        </div>

                        <InsightDescription
                            className={cn(
                                showMore && "my-3 line-clamp-6 h-[120px]"
                            )}
                        >
                            {description ?? ""}
                        </InsightDescription>

                        {showMore && (
                            <button
                                type="button"
                                onClick={onShowMore}
                                className="flex cursor-pointer flex-row items-center text-sm transition duration-100 hover:text-primary-500"
                            >
                                Show more{" "}
                                <Icon type="ChevronRight" className="ml-2" />
                            </button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default FixedGrowth;
