import { useMemo } from "react";

import {
    InsightsTeam,
    InsightsMemberData,
    InsightsIndividualMember,
    GeneratedInsightReportTeam,
    GeneratedReportIndividualTDKeys,
} from "store/api/investorCompany/interfaces";
import Utils from "utils";
import Icon from "components/Icon";
import Delimiter from "./Delimiter";
import InsightCardRow from "./InsightCardRow";
import MemberInfo from "routes/company/components/MemberInfo";
import OverallIndividualBar from "components/OverallIndividualBar";
import EmptyInsight from "routes/company/components/EmptyInsight";

type Props = {
    teamData: InsightsTeam;
    individualData: InsightsIndividualMember;
};

type Rows = Array<
    | {
          title: string;
          team?: GeneratedInsightReportTeam;
          individual?: InsightsMemberData;
      }
    | "delimiter"
>;

const Insight: React.FC<Props> = ({ individualData, teamData }) => {
    const nonMemberValues = useMemo((): Record<
        GeneratedReportIndividualTDKeys,
        InsightsMemberData
    > => {
        const values: Partial<
            Record<GeneratedReportIndividualTDKeys, InsightsMemberData>
        > = {};

        for (const key in individualData) {
            if (individualData.hasOwnProperty(key) && key.startsWith("td_")) {
                (values as any)[key as any] = (individualData as any)[key];
            }
        }

        return values as any;
    }, [individualData]);

    const memberInfo = Utils.Insight.getMemberInfoFromInsight(individualData);

    const rows: Rows = [
        {
            title: "Result",
            individual: nonMemberValues.td_result_orientation,
            team: teamData.td_result_orientation,
        },
        {
            title: "Accountability",
            individual: nonMemberValues.td_accountability,
            team: teamData.td_accountability,
        },
        {
            title: "Commitment",
            team: teamData.td_commitment,
            individual: nonMemberValues.td_commitment,
        },
        {
            title: "Conflict",
            team: teamData.td_conflict,
            individual: nonMemberValues.td_conflict,
        },
        {
            title: "Trust",
            team: teamData.td_trust,
            individual: nonMemberValues.td_trust,
        },
        "delimiter",
        {
            title: "Strategic consensus",
            team: teamData.td_strategic_consensus,
            individual: nonMemberValues.td_strategic_consensus,
        },
        "delimiter",
        {
            title: "Psychological safety",
            team: teamData.td_psychological_safety,
            individual: nonMemberValues.td_psychological_safety,
        },
        "delimiter",
        {
            title: "Team composition",
            team: teamData.td_team_composition,
            individual: nonMemberValues.td_team_composition,
        },
    ];

    return (
        <div className="flex h-[556px] w-[400px] flex-col gap-4 rounded-lg bg-white p-6 md:w-[unset] lg:w-[unset]">
            <MemberInfo disableTooltip {...memberInfo} />

            {Utils.Parsers.isObjectEmpty(nonMemberValues) ? (
                <EmptyInsight />
            ) : (
                <>
                    <OverallIndividualBar
                        metricValue={
                            (individualData?.td_overall?.value as number) ?? 0
                        }
                        averageValue={
                            (teamData?.td_overall?.value as number) ?? 0
                        }
                    />

                    <div className="my-2 flex flex-row items-center justify-between">
                        <div className="text-sm text-default-400">
                            Lencioni pyramid
                        </div>

                        <div className="flex flex-row items-center">
                            <Icon type="User" size={16} className="mr-[21px]" />

                            <Icon
                                type="Users"
                                size={16}
                                className="text-default-400"
                            />
                        </div>
                    </div>

                    <div className="flex flex-col gap-[16px]">
                        {rows.map((item, i) =>
                            item === "delimiter" ? (
                                <Delimiter key={i} />
                            ) : (
                                item.individual &&
                                item.team && (
                                    <InsightCardRow
                                        key={i}
                                        name={item.title}
                                        team={item.team}
                                        individual={item.individual}
                                    />
                                )
                            )
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Insight;
