import Utils from "utils";
import Icon from "components/Icon";

type Props = {
    isRaisingCapital: boolean;

    date: Date;
    roundLabel: string | number;
    raisingLabel: string | number;
    preMoneyValuation: string | number;
};
type ItemProps = {
    label: string;
    title: string | number;
};
const Item: React.FC<ItemProps> = ({ label, title }) => (
    <div className="flex flex-1 flex-col">
        <div>{title}</div>
        <div className="text-sm text-default-400">{label}</div>
    </div>
);

const Raising: React.FC<Props> = ({
    date,
    roundLabel,
    raisingLabel,
    isRaisingCapital,
    preMoneyValuation,
}) => {
    return (
        <div className="flex flex-col gap-6 rounded-lg bg-default-50 px-6 py-5">
            <div className="flex flex-row items-center gap-2 text-sm">
                <Icon
                    type={isRaisingCapital ? "CircleCheckBig" : "CircleX"}
                    size={20}
                />
                <div>
                    {isRaisingCapital
                        ? "Currently raising"
                        : "Not raising capital now"}
                </div>
                <div className="text-default-400">
                    ({Utils.Parsers.formatDateToMonthYear(date)})
                </div>
            </div>

            {isRaisingCapital && (
                <div className="grid grid-cols-3">
                    <Item label="Round" title={roundLabel} />
                    <Item label="Raising" title={raisingLabel} />
                    <Item
                        title={preMoneyValuation}
                        label="Pre-money valuation"
                    />
                </div>
            )}
        </div>
    );
};

export default Raising;
