import { useMemo } from "react";
import Avatar from "components/Avatar";
import { InsightsIndividual } from "store/api/investorCompany/interfaces";
import AvatarGroup from "components/AvatarGroup";
import Utils from "utils";

type Props = {
    individual: InsightsIndividual;
};

const MAX_SURVEY_TEAM = 3;

const CompanyTeam: React.FC<Props> = ({ individual }) => {
    const members = useMemo(
        () =>
            Object.entries(individual)
                .sort(Utils.Insight.membersRoleNameCompareFn)
                .map(([, individualData]) =>
                    Utils.Insight.getMemberInfoFromInsight(individualData)
                ),
        [individual]
    );

    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center">
                <AvatarGroup max={MAX_SURVEY_TEAM} size="sm">
                    {members.map(
                        ({ memberId, firstName, lastName, picture }, i) => (
                            <Avatar
                                key={i}
                                memberId={memberId}
                                firstName={firstName}
                                lastName={lastName}
                                src={picture}
                            />
                        )
                    )}
                </AvatarGroup>
            </div>

            <div className="text-sm">{members.length} members on VC Volt</div>
        </div>
    );
};

export default CompanyTeam;
