import { InsightsCompany } from "store/api/investorCompany/interfaces";

const DEFAULT_SIGN = "$";

export const getCurrencySign = (data: InsightsCompany["company-currency"]) => {
    if (!data || !data.value) {
        return DEFAULT_SIGN;
    }

    const value = data.value;

    if (typeof value !== "string") {
        return DEFAULT_SIGN;
    }

    const result = value.match(/\((.)\)/);

    if (result) {
        return result[1];
    }

    return DEFAULT_SIGN;
};
