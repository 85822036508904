import Icon from "components/Icon";
import { Link } from "react-router-dom";
import EmptyInsight from "routes/company/components/EmptyInsight";

type Props = {
    title: string;
    children: React.ReactNode;
    link?: {
        label: string;
        href: string;
    };
    isEmptyInsight?: boolean;
};

const Frame: React.FC<Props> = ({ title, children, link, isEmptyInsight }) => {
    return (
        <div className="flex flex-col gap-4 rounded-lg bg-white p-6 sm:m-[unset] sm:w-[unset] md:m-auto md:w-[555px] lg:m-[unset] lg:w-[unset]">
            <div className="flex flex-row items-center justify-between">
                <div>{title}</div>
                {link && (
                    <Link
                        to={link.href}
                        className="flex cursor-pointer flex-row items-center gap-2 text-sm"
                    >
                        {link.label} <Icon type="ChevronRight" size={20} />
                    </Link>
                )}
            </div>

            <div className="flex flex-col gap-6">
                {isEmptyInsight ? <EmptyInsight /> : children}
            </div>
        </div>
    );
};

export default Frame;
