const STORAGE_KEY = "token";

const getToken = () => localStorage.getItem(STORAGE_KEY);

const setToken = (newToken: string) => {
    localStorage.setItem(STORAGE_KEY, newToken);
};

const TokenManager = {
    getToken,
    setToken,
};

export default TokenManager;
