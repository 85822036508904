export const TOOLTIPS = {
    MAIN: {
        entrepreneurialPassion:
            "Entrepreneurial passion refers to the intense, positive emotional drive that entrepreneurs experience toward their business ventures and activities. This passion is a key motivator that compels entrepreneurs to pursue and persist in their entrepreneurial endeavours, often in the face of significant challenges and risks. It encompasses a deep commitment and enthusiasm for creating, developing, and sustaining a business, and it often manifests in a strong desire to innovate, solve problems, and achieve business success.",
        softSkills:
            "Soft skills are essential for founders because they facilitate effective communication, leadership, and relationship-building, which are critical for the success of any entrepreneurial venture. With VC Volt we measure the soft skills that have predictive power in the entrepreneurial context. Decades of research have demonstrated that the variables displayed here drive entrepreneurial success.",
        teamDynamics:
            "Team dynamics refer to the behavioral relationships and interactions among members of a team. These dynamics can significantly influence how a team functions, communicates, collaborates, and ultimately, how successful it is in achieving its goals. In the context of a startup or entrepreneurial venture, team dynamics encompass the way team members work together, the roles they assume, how they handle conflict, and how they support and motivate each other.",
    },
    entrepreneurialPassion: {
        main: "By understanding your main type of passion, you can better channel your energy and resources toward building a business that not only succeeds financially but also fulfills you personally. Knowing your main type of passion will help you guide your efforts, sustain motivation, foster innovation, build authentic relationships, improve well-being, and ultimately drive the success of your entrepreneurial venture.",
        passion:
            "Entrepreneurial passion is defined as the intense positive emotions entrepreneurs have for their venture. Passion is believed to be extremely important for entrepreneurs to thrive through the challenges they need to overcome when starting a new venture.",
        types: `We identify 3 types of passion:
Passion for inventing involves a passion for creating new products, services, or processes. Entrepreneurs with this type of passion are often highly innovative and focused on bringing novel ideas to life.

Passion for founding reflects a strong desire to establish a new organization. Entrepreneurs with this passion are motivated by the idea of starting and growing a business from the ground up.

Passion for developing is about the commitment to growing and nurturing a business once it has been established. Entrepreneurs with this passion are driven by the process of scaling their ventures, improving operations, and ensuring long-term success.
`,
        alignment:
            "Team passion diversity is defined as with-in group variance between individual group members and their individual passions.",
        grit: "Grit is passion and perseverance for the long-term. Grit is a goal you care so much about that it organizes and gives meaning to almost everything you do. Grit is holding steadfast to that goal, even when you fall down.",
    },
    softSkills: {
        overall:
            "Since soft skills influence team dynamics and eventual venture success, your overall score on soft skills gives us an insight on personal focus areas. The overall score on soft skills gives an overview of a founders’ ability to navigate the complexities of both the professional context of being a founder as well as the more personal characteristics.",
        mindset:
            "Having a growth mindset means you believe that your talents can be developed through hard work, good strategies, and input from others. In contrast, people with a fixed mindset are characterized by a belief that their talents are innate gifts - that you're born with a particular set of skills and that you can't change them. Still, it’s important to understand that these are not simply two different mindsets of which you either have one or the other. Everyone is actually a mixture of fixed and growth mindsets. The balance between the two gives you more insights in how your team operates and why. ",
        spider: "The soft skill matrix shows your score on those soft skills that truly contribute to your team and venture success. All skills displayed in this matrix have been scientifically proven to contribute to entrepreneurial success.",
    },
    humanCapitalSkills: {
        topExpertise:
            "Teams with complementary human capital outperform homogeneous teams it is important to understand how complimentary skills and knowledge in a team are.",
        skillsDistribution:
            "There are several skills necessary to be successful as a startup. It is not necessary to have all skills in the team from the start but it is important to understand what skills are available in the team and how much they are distributed or concentrated. This can give you insight in what skills to look for in certain hires. The phase of the company plays an important role in deciding what is most crucial.",
        skillsMatrix:
            "There is a strong empirical relationship between prior experience and new venture performance. This matrix gives you insight what experience is present in the team, to what extend and how concentrated or distributed that experience is. It is important to be aware of this and take it into consideration for future hires.",
        industryExperience:
            "What industries does this team have experience in and is it relevant for your current company?",
        startupExperience:
            "There is a strong relationship between prior startup experience and future venture success. Experienced entrepreneurs have a better understanding of what to expect in terms of workload, stress, and the unpredictability of the startup environment. This realism helps them stay focused and motivated even when things get tough.",
        entrepreneurialExperience:
            "Entrepreneurs with prior entrepreneurial experience are well-versed in the typical challenges that come with starting and growing a business. This familiarity allows them to anticipate and prepare for obstacles, making them more resilient and adaptable.",
        managementExperience:
            "Management experience is a valuable asset for startup founders because it equips them with a range of skills and insights that are crucial for the successful operation and growth of a startup.",
    },
    teamDynamics: {
        overall:
            "Team dynamics refer to the behavioral relationships and interactions among members of a team. These dynamics can significantly influence how a team functions, communicates, collaborates, and ultimately, how successful it is in achieving its goals. In the context of a startup or entrepreneurial venture, team dynamics encompass the way team members work together, the roles they assume, how they handle conflict, and how they support and motivate each other.",
        pyramid:
            "The Lencioni model, or the Five Dysfunctions of a Team Pyramid, is particularly important for entrepreneurial teams because it provides a framework to identify and address common issues that can hinder team effectiveness, especially in the fast-paced and high-pressure environment of a startup. Here’s why the Lencioni model is crucial for entrepreneurial teams:",
        consensus:
            "Strategic consensus refers to the level of agreement and alignment among key stakeholders within an organization, particularly among its leadership team, regarding the strategic goals, priorities, and direction of the organization. It involves a shared understanding and commitment to the strategies that have been chosen to achieve the organization’s objectives.",
        safety: "Psychological safety is a critical factor for founding teams, as it directly influences their ability to collaborate effectively, innovate, and navigate the challenges inherent in building a new venture.",
        composition:
            "The way founders reflect upon and evaluate their own team composition is critically important for several reasons, particularly because it directly impacts the effectiveness, cohesion, and success of the startup. Your own reflection of your team composition matters! ",
    },
};
