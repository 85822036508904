import Button from "components/Button";

type Props = {
    title: string;
    image: any;
    subText: string;
    buttonLabel?: string;
    onInviteClick?: () => void;
};

const Empty = ({
    image,
    subText,
    title,
    buttonLabel,
    onInviteClick,
}: Props) => (
    <div className="bg-default-25 rounded-2xl flex justify-center items-center gap-6 flex-col py-[120px]">
        <img src={image} className="w-[320px] h-[180px]" />

        <div className="text-center text-black">{title}</div>

        <div className="text-sm text-center text-default-400">{subText}</div>

        {buttonLabel && onInviteClick && (
            <Button onClick={onInviteClick} icon="Plus">
                {buttonLabel}
            </Button>
        )}
    </div>
);

export default Empty;
