import { FC, useMemo } from "react";
import { BarChartProps } from "./interfaces";
import { ChartContainer } from "../chart";
import {
    XAxis,
    BarChart as RBarChart,
    CartesianGrid,
    YAxis,
    LabelList,
    Bar,
} from "recharts";
import Utils from "utils";
import { BarChartData, barChartDataSchema } from "./schemas/data-schema";

const BarChart: FC<BarChartProps> = ({ data, currencySign }) => {
    const parsedData = useMemo((): BarChartData => {
        const result = barChartDataSchema.safeParse(data);
        if (!result.success) {
            return [];
        }
        return result.data;
    }, [data]);

    const finalData = parsedData.map((i) => ({
        x: new Date(i.x),
        y: i.y,
    }));

    return (
        <ChartContainer config={{}}>
            <RBarChart accessibilityLayer data={finalData} margin={{ top: 32 }}>
                <XAxis
                    dataKey="x"
                    tickLine={false}
                    tickMargin={10}
                    axisLine
                    tickFormatter={(v) =>
                        v instanceof Date ? v.getFullYear().toString() : "?"
                    }
                />
                <CartesianGrid vertical={false} stroke="#E1E1E8" />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickCount={3}
                    tickFormatter={(v: number) =>
                        Utils.Parsers.abbreviateLargeNumbers(v)
                    }
                />
                <Bar dataKey="y" fill="#9753FC" radius={8}>
                    <LabelList
                        position="top"
                        offset={12}
                        fontSize={12}
                        className="fill-black"
                        formatter={(v: number) =>
                            currencySign
                                ? `${currencySign}${Utils.Parsers.abbreviateLargeNumbers(v)}`
                                : Utils.Parsers.abbreviateLargeNumbers(v)
                        }
                    />
                </Bar>
            </RBarChart>
        </ChartContainer>
    );
};

export default BarChart;
