import { FC } from "react";
import { useGetMeQuery } from "store/api/auth";
import { CompanyCard } from "../CompanyCard";
import { TeamCard } from "../TeamCard";
import { SurveysCard } from "../SurveysCard";
import { CompanySurveyCard } from "../CompanySurveyCard";
import cn from "classnames";
import Guideline from "./Guideline";
import { UserType } from "store/api/invitation/interfaces";

const SuperMember: FC = () => {
    const { data: meData } = useGetMeQuery();

    if (!meData) {
        return null;
    }

    return (
        <div className="mx-auto w-full px-8 pb-8 sm:w-[582px] sm:px-0 xl:w-[1124px]">
            <div className={cn("mx-auto flex flex-col", "gap-4")}>
                <section className="mb-4 flex flex-col">
                    <h1 className="mb-2 text-3xl">
                        Hello,{" "}
                        {meData?.data?.user?.type === UserType.Member
                            ? meData.data.user.data.firstName
                            : ""}{" "}
                        👋
                    </h1>
                    <Guideline />
                </section>
                <section className="flex flex-col gap-4 xl:flex-row">
                    <div className="flex w-full flex-1 flex-col gap-y-4 xl:w-1/2">
                        <CompanyCard />
                        <CompanySurveyCard />
                    </div>

                    <TeamCard perPage={6} />
                </section>

                <SurveysCard />
            </div>
        </div>
    );
};

export default SuperMember;
