import { Link, useNavigate, useSearchParams } from "react-router-dom";
import cn from "classnames";

import { useInvestorCompanyGenerateReportMutation } from "store/api/investorCompany";
import Empty from "./Empty";
import Button from "components/Button";
import { DashboardHeader } from "routes/components/header";
import CompanyHeader from "./CompanyHeader";

// Tabs
import AboutTab from "../../Tabs/About";
import SoftSkillsTab from "../../Tabs/SoftSkills";
import TeamDynamicsTab from "../../Tabs/TeamDynamics";
import HumanCapitalSkillsTab from "../../Tabs/HumanCapitalSkills";
import EntrepreneurialPassionTab from "../../Tabs/EntrepreneurialPassion";
import SvgDefs from "./SvgDefs";
import Alert from "components/Alert";
import { useAppDispatch } from "core/hooks";
import { GlobalActions } from "store/reducers/global";
import TeamOverviewTab from "../../Tabs/TeamOverview";
import { useCompanyReportContext } from "routes/components/CompanyReport/contexts/report-context";
import { useMemo } from "react";
import SharedViewCompanyHeader from "../shared-view-company-header";

type TabKey =
    | "about"
    | "team-overview"
    | "soft-skills"
    | "team-dynamics"
    | "entrepreneurial-passion"
    | "human-capital-skills";

type Tab = {
    id: TabKey;
    label: string;
};

const CompanyReport = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [generateReport, { isLoading }] =
        useInvestorCompanyGenerateReportMutation();

    const {
        companyId,
        reportId,
        team,
        individual,
        company,
        isSharedView,
        generatedAt,
        isOutdated,
        progress,
    } = useCompanyReportContext();

    const isEmpty = useMemo(() => !generatedAt, [generatedAt]);
    const isOnboardingNotStarted = useMemo(() => progress === 0, [progress]);

    const tabs: Array<Tab> = [
        {
            id: "about",
            label: "About",
        },
        {
            id: "team-overview",
            label: "Team Overview",
        },
        {
            id: "entrepreneurial-passion",
            label: "Entrepreneurial passion",
        },
        {
            id: "soft-skills",
            label: "Soft skills",
        },
        {
            id: "human-capital-skills",
            label: "Human capital skills",
        },
        {
            id: "team-dynamics",
            label: "Team dynamics",
        },
    ];

    const activeTab =
        tabs.find((tab) => tab.id === searchParams.get("tab")) ?? tabs[0];

    const handleGenerateReport = async () => {
        try {
            if (companyId === undefined || reportId === undefined) {
                throw new Error("Something went wrong");
            }

            const res = await generateReport({
                companyId,
                reportId,
            });

            if (!("data" in res)) {
                throw new Error("Failed to regenerate report");
            }

            dispatch(
                GlobalActions.showPopover({
                    type: "success",
                    label: "Report regenerated",
                })
            );
        } catch (err: unknown) {
            const message =
                err instanceof Error
                    ? err.message
                    : "Failed to regenerate report";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    };

    return (
        <div className="bg-default-50">
            {!isSharedView && (
                <DashboardHeader
                    classNames={{ logo: "max-xl:hidden" }}
                    goBack={() => navigate("/dashboard")}
                    goBackText="Back to dashboard"
                />
            )}
            <SvgDefs />

            <div className="flex justify-center">
                <div
                    className={`mx-auto min-h-screen w-[100%] max-w-[1124px] p-8 pb-8 md:mx-8 xl:mx-[78px] 2xl:mx-[206px] 3xl:mx-[398px]`}
                >
                    {isSharedView ? (
                        <SharedViewCompanyHeader />
                    ) : (
                        <CompanyHeader />
                    )}

                    {isOutdated && (
                        <Alert
                            headline="New data"
                            type="tip"
                            label="We’ve received new survey results. Please regenerate report for more accurate insights."
                            className="mt-8"
                        >
                            <Button
                                disabled={isLoading}
                                iconClassName={cn(isLoading && "animate-spin")}
                                icon="RotateCw"
                                onClick={handleGenerateReport}
                                className="ml-3"
                            >
                                Regenerate report
                            </Button>
                        </Alert>
                    )}

                    <section className="my-9 flex items-center justify-between">
                        <div className="flex items-center gap-x-5">
                            {tabs.map((tab) => (
                                <Link
                                    to={`?tab=${tab.id}`}
                                    key={tab.id}
                                    className={cn(
                                        "border-b-2 pb-2 text-sm text-default-400",
                                        tab.id === activeTab.id
                                            ? "border-black !text-black"
                                            : "border-transparent hover:border-default-500"
                                    )}
                                >
                                    {tab.label}
                                </Link>
                            ))}
                        </div>
                    </section>

                    {isEmpty ? (
                        <Empty
                            allowGenerateReport={!isOnboardingNotStarted}
                            handleGenerate={handleGenerateReport}
                        />
                    ) : (
                        <>
                            {activeTab.id === "about" && (
                                <AboutTab
                                    companyInsight={company}
                                    individual={individual}
                                />
                            )}
                            {activeTab.id === "team-overview" && (
                                <TeamOverviewTab individualData={individual} />
                            )}
                            {activeTab.id === "entrepreneurial-passion" && (
                                <EntrepreneurialPassionTab
                                    team={team}
                                    individual={individual}
                                />
                            )}
                            {activeTab.id === "human-capital-skills" && (
                                <HumanCapitalSkillsTab
                                    team={team}
                                    individual={individual}
                                    companyInsight={company}
                                />
                            )}
                            {activeTab.id === "soft-skills" && (
                                <SoftSkillsTab
                                    team={team}
                                    individual={individual}
                                />
                            )}
                            {activeTab.id === "team-dynamics" && (
                                <TeamDynamicsTab
                                    team={team}
                                    individual={individual}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CompanyReport;
