import { FC, useCallback, useEffect, useMemo } from "react";
import { Header } from "routes/components/header";
import ShareReportsAuthForm from "./components/form";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSharedViewReportQuery } from "store/api/shared-view";

type Props = {};

const ShareReportsAuthPage: FC<Props> = () => {
    const navigate = useNavigate();
    const params = useParams();
    const token = useMemo(() => params?.token ?? "", [params]);
    const { error, isFetching, isSuccess } = useGetSharedViewReportQuery({
        token,
    });

    const onAuth = useCallback(() => {
        navigate("..", { relative: "path" });
    }, []);

    useEffect(() => {
        if (isFetching) {
            return;
        }
        if (error && "data" in error && error.status === 404) {
            onAuth();
            return;
        }
        if (!isSuccess) {
            return;
        }
        onAuth();
    }, [isFetching, isSuccess, onAuth]);

    return (
        <div className="min-h-full bg-default-50">
            <Header />
            <div className="mx-auto w-full max-w-[592px] py-24">
                <ShareReportsAuthForm onAuth={onAuth} token={token} />
            </div>
        </div>
    );
};

export default ShareReportsAuthPage;
