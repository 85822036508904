import { IconType } from "components/Icon";
import { MemberRole } from "data/onboarding";
import { StatLabelType } from "routes/company/Tabs/EntrepreneurialPassion/Individual/StatLabel";
import { InsightsIndividualMember } from "store/api/investorCompany/interfaces";
import { TeamMember } from "store/api/shared.types";

export interface TypeOfPassion {
    icon: IconType;
    label: string;
    labelType: StatLabelType;
    iconColor: string;
    gradient: string;
    conclusion: { high: string; medium: string; low: string };
}

// @TODO: there are no medium/low/high conclusions
// there are should be only one conclusion
const TYPES_OF_PASSION: Array<TypeOfPassion> = [
    {
        icon: "Sprout",
        label: "Founding",
        labelType: "founding",
        iconColor: "text-success-500",
        gradient: "radial-gradient(circle, #DDFBE84D 30%, #4EDA854D 100%)",
        conclusion: {
            low: "Your team doesn't enjoy activities that are involved with setting up and nurturing a new startup. Fundraising, hiring, assembling a board - these all probably sound somewhat daunting to your team. They are part of the process, but definitely not the fun part! If you had the chance, your team would let someone else take care of these activities so that you could focus on the more interesting stuff.",
            medium: "Though your team definitely doesn't hate it, founding is not exactly a passion of yours. Setting up and nurturing a new business is not energizing to your team. It's more a means to an end: Of course you need funding, you need to hire, you need to establish a board. But to your team these activities - though they can be fun at times - are steps that need to be taken in order to achieve a higher goal.",
            high: `Passion for Founding is about the excitement and fulfillment derived from establishing a new business or venture. Entrepreneurs with this passion enjoy the challenges of starting a company from scratch, building a team, and creating an organization that reflects their vision. 
A good example of a founder with strong passion for founding is Sara Blakely, the founder of Spanx, Sara Blakely invented a line of undergarments that revolutionized the fashion industry. She was driven by a passion for solving a personal problem—finding a comfortable and effective slimming undergarment—and turned her invention into a billion-dollar business. Next to her product she continues to found new streams of business within her existing line of work and with that keeps expanding her empire and founding her business. These types of founders are often serial entrepreneurs. Another good example is Ali Niknam. Ali is best known as the founder and CEO of Bunq, a digital bank based in the Netherlands. Before founding bunq in 2012, Niknam had already successfully launched and sold other companies, which solidifies his reputation as a serial entrepreneur. Some of his key ventures include TransIP, a web hosting and domain registration company, and The Datacenter Group (TDCG), which operates data centers in the Netherlands. The company became one of the leading data center providers in the country. Ali Niknam’s track record of founding and developing multiple successful companies across different industries makes him a quintessential serial entrepreneur. His ventures span across technology, finance, and infrastructure, showcasing his versatility and entrepreneurial spirit.`,
        },
    },
    {
        icon: "Lightbulb",
        label: "Inventing",
        labelType: "inventing",
        iconColor: "text-tertiary-500",
        gradient: "radial-gradient(circle, #FDE6F74D 30%, #F86ED04D 100%)",
        conclusion: {
            low: "Your team is not passionate about inventing. This means that activities involved in identifying, inventing, and exploring new opportunities are likely to feel draining or exhaustingn to team members. Building product is more of a necessary evil than something you would simply do for fun. Your team probably wouldn't identify as a builder.",
            medium: "Your team is moderately passionate about inventing. Identifying, inventing, and exploring new opportunities are activities that can sometimes be interesting, but they fail to really energize your team. Creating new products or services or coming up with market-disruption ideas are not likely to be things your team prefers to spend a lot of time on, though it can definitely be fun. Your team does care about product a lot, but building is not necessarily on the top of your priorities list.",
            high: `Founders that are passionate for inventing are often technology founders or scientisist that have a strong desire to create new tools, platforms, or software that solve problems or improve lives. They are motivated by the potential of technology to disrupt industries or create entirely new markets. 
A good example of a founder with very strong passion for inventing is Daniel Ek, the founder of Spotify. Daniel co-founded Spotify in 2006, aiming to combat music piracy by offering a legal streaming alternative. His passion for inventing and drive for innovating the platform led to continuous improvements in user experience, content offerings, and global reach. Under his leadership, Spotify has grown into the world’s largest music streaming service, with over 500 million users. Another example is Leah Busque, the founder of TaskRabbit. Leah was extremely passionate about creating technology-driven solutions to everyday problems and so she invented the TaskRabbit platform to connect people with local service providers, transforming the way people get help with tasks and services. Her focus on inventing led her to truly understand how to get the product to a stage of product market fit.`,
        },
    },
    {
        icon: "TrendingUp",
        label: "Developing",
        labelType: "developing",
        iconColor: "text-quaternary-500",
        gradient: "radial-gradient(circle, #D9E4FF4D 30%, #295BFF4D 100%)",
        conclusion: {
            low: "Your teams passion for developing is low. This means that the prospect of growing and expanding a business is really not exciting for your team. Processes like building an organizational culture, driving financial growth, and attracting new customers are really don't seem very interesting. If it were up to your team, you would leave that part to someone else.",
            medium: "Your teams passion for developing is average: The idea of growing and expanding a business might sound nice, but it doesn't exactly excite team members very much. Your team is most probably less focused on processes like building an organizational culture, driving financial growth, and attracting new customers. For your team, the scaling phase of a growing business is not the most interesting one.",
            high: "Passion for Developing: Entrepreneurs who have a passion for developing are motivated by the process of growing and scaling a business. They find satisfaction in nurturing their venture, improving processes, and expanding the company to reach its full potential. Mette Lykke, the CEO of Too Good To Go is a good example of a founder that is very passionate for developing. Too Good Too Go is an app designed to reduce food waste by connecting consumers with restaurants and stores that have surplus food. Her passion for developing the business has driven its expansion across Europe and beyond, turning Too Good To Go into a widely recognized brand in the sustainability space. Another good example is Niklas Zennström, the founder of Skype and Atomico). Niklas co-founded Skype, which revolutionized communication through VoIP technology. After Skype’s success, Zennström turned his passion for developing into founding Atomico, a venture capital firm that supports and scales tech startups in Europe and beyond. With the founding of his investment firm he continues to fuel other ventures in the European ecosystem with his vision.",
        },
    },
];

export const getTypeOfPassion = (
    developmentScore: number,
    foundingScore: number,
    inventingScore: number
) => {
    const idx = [foundingScore, inventingScore, developmentScore].indexOf(
        Math.max(foundingScore, inventingScore, developmentScore)
    );
    return TYPES_OF_PASSION[idx];
};

const getSortIndexFromMemberRole = (role: MemberRole): number | null => {
    switch (role) {
        case MemberRole.CEO:
            return 1;
        case MemberRole.CTO:
            return 2;
        case MemberRole.CPOProduct:
            return 3;
        case MemberRole.CPOPeople:
            return 4;
        case MemberRole.CMO:
            return 5;
        case MemberRole.CFO:
            return 6;
        case MemberRole.CCO:
            return 7;
        case MemberRole.COO:
            return 8;
        case MemberRole.Other:
            return 9;
        default:
            return null;
    }
};

export const membersRoleNameCompareFn = (
    a: [string, InsightsIndividualMember],
    b: [string, InsightsIndividualMember]
) => {
    const getMemberData = (
        data: [string, InsightsIndividualMember]
    ): [string, number | null] => {
        const role =
            (data[1]?.["member-role"]?.value as MemberRole) ?? MemberRole.Other;
        const name = (data[1]?.["member-first-name"]?.value as string) ?? "";
        const sortIndex = getSortIndexFromMemberRole(role);
        return [name, sortIndex];
    };

    const [aName, aSortIndex] = getMemberData(a);
    const [bName, bSortIndex] = getMemberData(b);

    if (!aSortIndex || !bSortIndex || aSortIndex === bSortIndex) {
        return aName.localeCompare(bName);
    }
    return aSortIndex - bSortIndex;
};

export const teamMembersRoleNameCompareFn = (a: TeamMember, b: TeamMember) => {
    const aName = a.firstName;
    const aSortIndex = getSortIndexFromMemberRole(a.role as MemberRole);
    const bName = b.firstName;
    const bSortIndex = getSortIndexFromMemberRole(b.role as MemberRole);

    if (!aSortIndex || !bSortIndex || aSortIndex === bSortIndex) {
        return aName.localeCompare(bName);
    }
    return aSortIndex - bSortIndex;
};

export const getMemberInfoFromInsight = (
    insight: InsightsIndividualMember
): {
    memberId: string;
    firstName: string;
    lastName: string;
    picture: string;
    role: string;
    roleDescription: string;
} => {
    const memberId = insight?.["member-first-name"]?.memberId;
    const firstName = insight?.["member-first-name"]?.value;
    const lastName = insight?.["member-last-name"]?.value;
    const picture = (
        insight?.["member-picture"]?.value as Record<string, unknown> | null
    )?.url;
    const role = insight?.["member-role"]?.value;
    const roleDescription = insight?.["member-role-description"]?.value;

    return {
        memberId: typeof memberId === "string" ? memberId : "",
        firstName: typeof firstName === "string" ? firstName : "",
        lastName: typeof lastName === "string" ? lastName : "",
        picture: typeof picture === "string" ? picture : "",
        role: typeof role === "string" ? role : "",
        roleDescription:
            typeof roleDescription === "string" ? roleDescription : "",
    };
};
