import { createContext, useContext } from "react";
import {
    InsightsCompany,
    InsightsIndividual,
    InsightsTeam,
} from "store/api/investorCompany/interfaces";
import { SharedView } from "store/api/shared-view/interfaces";

type CompanyReportContextProps = {
    team: InsightsTeam;
    company: InsightsCompany;
    individual: InsightsIndividual;
    isSharedView: boolean;
    companyId: string;
    reportId?: string;
    deadline?: string | null;
    generatedAt?: string | null;
    isOutdated?: boolean;
    sharedView?: SharedView;
    sharedBy?: {
        companyName: string;
    };
    progress?: number;
};

const CompanyReportContext = createContext<CompanyReportContextProps | null>(
    null
);

export const CompanyReportContextProvider = CompanyReportContext.Provider;

export const useCompanyReportContext = () => {
    const context = useContext(CompanyReportContext);

    if (!context) {
        throw new Error("Component must be used within a CompanyReportContext");
    }

    return context;
};
