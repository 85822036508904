import { Modal, ModalBody, ModalContent, ModalHeader } from "@nextui-org/react";
import Button from "components/Button";
import ButtonIcon from "components/ButtonIcon";
import { cn } from "lib/utils";
import { FC, useMemo } from "react";

export type CompanyInsightModalConfig = {
    title: string;
    content: React.ReactNode;
};

type Props = {
    modals: CompanyInsightModalConfig[];
    current: number;
    onChangeCurrent: (idx: number) => void;
    isOpen: boolean;
    onOpenChange: () => void;
    onClose: () => void;
};

const ModalController: FC<Props> = ({
    modals,
    current,
    onChangeCurrent,
    isOpen,
    onOpenChange,
    onClose,
}) => {
    const currentModal = modals[current];
    const prev = modals[current === 0 ? modals.length - 1 : current - 1];
    const next = modals[current === modals.length - 1 ? 0 : current + 1];
    const showNavigation = useMemo(() => modals.length > 1, [modals]);

    const move = (shift: -1 | 1) => {
        const newIdx = current + shift;
        const finalIdx =
            newIdx < 0 ? modals.length - 1 : newIdx % modals.length;

        onChangeCurrent(finalIdx);
    };

    return (
        <Modal
            size="3xl"
            isOpen={isOpen}
            hideCloseButton
            onOpenChange={onOpenChange}
            scrollBehavior="inside"
            className="bg-default-25 p-4"
            classNames={{
                wrapper: "overflow-hidden",
                base: "h-full !max-h-[calc(100%-64px)] lg:!max-h-[calc(100%-40px)] 2xl:h-[calc(100%-80px)] 2xl:!max-h-[952px]",
            }}
        >
            <ModalContent className="flex gap-4">
                <ModalHeader
                    className={cn(
                        "flex flex-row items-center justify-between p-0",
                        !showNavigation && "justify-end"
                    )}
                >
                    {showNavigation && (
                        <div className="flex flex-row gap-2">
                            <Button
                                icon="ChevronLeft"
                                onClick={() => move(-1)}
                                customType="secondary-solid"
                            >
                                {prev?.title}
                            </Button>
                            <Button
                                customType="secondary-solid"
                                icon="ChevronRight"
                                iconPosition="right"
                                onClick={() => move(1)}
                            >
                                {next?.title}
                            </Button>
                        </div>
                    )}

                    <ButtonIcon icon="X" customType="light" onClick={onClose} />
                </ModalHeader>

                <ModalBody
                    className="rounded-lg bg-white px-5 py-6 shadow-sm"
                    style={{ border: "1px solid var(--default-75, #F1F1F5)" }}
                >
                    {currentModal.content}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ModalController;
