import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalContent, useDisclosure } from "@nextui-org/react";

// Modals
import DeadlineModal from "../modals/DeadlineModal";
import ArchiveCompanyModal from "../modals/ArchiveCompanyModal";

import Utils from "utils";
import Icon from "components/Icon";
import Button from "components/Button";
import { CompanyCardProps } from "../interfaces";
import { Highlight } from "components/Highlight";
import CompanyPicture from "components/CompanyPicture";
import HoverComponent from "components/HoverComponent";
import CompanyDeadlineBadge from "components/CompanyDeadlineBadge";
import ActionPopover, { ActionPopoverListItem } from "components/ActionPopover";
import ProgressStatusCompany from "components/ProgressStatusCompany";
import StatusCompany from "components/StatusCompany";
import AvatarGroup from "components/AvatarGroup";
import Avatar from "components/Avatar";

const MAX_MEMBERS = 4;

const CompanyCard: FC<CompanyCardProps> = ({ company, query }) => {
    const navigate = useNavigate();
    const {
        isOpen: setDeadlineIsOpen,
        onOpen: setDeadlineOnOpen,
        onOpenChange: setDeadlineOnOpenChange,
    } = useDisclosure();
    const {
        isOpen: archiveCompanyIsOpen,
        onOpen: setArchiveCompanyOnOpen,
        onOpenChange: setArchiveCompanyOnOpenChange,
    } = useDisclosure();

    const [isHovered, setIsHovered] = useState(false);
    const handleClick = () => navigate(`/company/${company.id}`);

    const deadline = useMemo(
        () => Utils.Parsers.companyDeadline(company.report.deadline as any),
        [company.report.deadline]
    );
    const isOnboardingCompleted = useMemo(
        () => company.progress === 1,
        [company.progress]
    );
    const isReportGenerated = useMemo(
        () => !!company.report.generatedAt,
        [company.report.generatedAt]
    );
    const isReportOutdated = useMemo(
        () => company.report.isOutdated,
        [company.report.isOutdated]
    );
    const hasDeadline = !!company.report.deadline;

    const actions: Array<ActionPopoverListItem> = [
        {
            label: hasDeadline
                ? "Edit surveys deadline"
                : "Set surveys deadline",
            onClick: setDeadlineOnOpen,
        },
        {
            type: "danger",
            label: "Archive company",
            onClick: setArchiveCompanyOnOpen,
        },
    ];

    return (
        <>
            <Modal
                size="xl"
                isOpen={setDeadlineIsOpen}
                onOpenChange={setDeadlineOnOpenChange}
            >
                <ModalContent>
                    {(onClose) => (
                        <DeadlineModal
                            type="company"
                            onClose={onClose}
                            entityId={company.id}
                            companyName={company.name}
                            deadline={company.report.deadline}
                        />
                    )}
                </ModalContent>
            </Modal>

            <Modal
                size="xl"
                hideCloseButton
                isOpen={archiveCompanyIsOpen}
                onOpenChange={setArchiveCompanyOnOpenChange}
            >
                <ModalContent>
                    {(onClose) => (
                        <ArchiveCompanyModal
                            id={company.id}
                            onClose={onClose}
                            name={company.name}
                            image={company.logo?.url}
                        />
                    )}
                </ModalContent>
            </Modal>

            <article
                onClick={handleClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="flex w-[364px] flex-col rounded-xl border border-default-75 bg-white p-6 hover:cursor-pointer md:!w-[unset]"
            >
                <div className="mb-4 flex items-start justify-between">
                    <CompanyPicture
                        size="default"
                        companyId={company.id}
                        name={company.name}
                        image={company?.logo?.url}
                    />
                    <div className="flex items-center gap-x-2">
                        {deadline.type !== "passed" && (
                            <CompanyDeadlineBadge
                                date={company.report.deadline}
                                time={deadline.time}
                                state={deadline.type}
                            />
                        )}
                        <ActionPopover
                            actionButtonContent={
                                <div className="p-[10px]">
                                    <Icon type="Ellipsis" />
                                </div>
                            }
                            actions={actions}
                        />
                    </div>
                </div>

                <h2 className="mb-4 truncate text-xl font-bold">
                    <Highlight search={query} value={company.name} />
                </h2>

                <p className="mb-4 line-clamp-3 text-ellipsis text-sm text-default-500">
                    {company.descriptionShort}
                </p>

                <AvatarGroup max={MAX_MEMBERS} size="sm">
                    {company.members
                        .slice()
                        .sort(Utils.Insight.teamMembersRoleNameCompareFn)
                        .map(({ id, firstName, lastName, picture }, i) => (
                            <Avatar
                                key={i}
                                memberId={id}
                                firstName={firstName}
                                lastName={lastName}
                                src={picture?.url}
                            />
                        ))}
                </AvatarGroup>

                <div>
                    <HoverComponent
                        isHovered={!isHovered}
                        secondaryContent={
                            <div className="flex w-[100%] flex-row items-start gap-x-2">
                                {!isOnboardingCompleted && (
                                    <ProgressStatusCompany
                                        value={Number(
                                            Math.floor(
                                                company.progress * 100
                                            ).toFixed(0)
                                        )}
                                    />
                                )}
                                {isReportGenerated && (
                                    <>
                                        {isReportOutdated ? (
                                            <StatusCompany
                                                iconType="Zap"
                                                label="New report data"
                                                variant={"secondary-solid"}
                                            />
                                        ) : (
                                            <StatusCompany
                                                iconType="CCheckSimple"
                                                label="Report generated"
                                                variant={"secondary-solid"}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        }
                    >
                        <Button
                            icon="ArrowRight"
                            iconPosition="right"
                            className="mt-3 w-[100%]"
                            customType="secondary-solid"
                        >
                            View details
                        </Button>
                    </HoverComponent>
                </div>
            </article>
        </>
    );
};

export default CompanyCard;
