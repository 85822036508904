const CCheck = (props: any) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0408 3.29289C14.4313 3.68342 14.4313 4.31658 14.0408 4.70711L6.70743 12.0404C6.31691 12.431 5.68374 12.431 5.29322 12.0404L1.95989 8.70711C1.56936 8.31658 1.56936 7.68342 1.95989 7.29289C2.35041 6.90237 2.98357 6.90237 3.3741 7.29289L6.00033 9.91912L12.6266 3.29289C13.0171 2.90237 13.6502 2.90237 14.0408 3.29289Z"
            fill="#0F1117"
            {...props}
        />
    </svg>
);

export default CCheck;
