export default () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <ellipse
            cx="9.50098"
            cy="5.34375"
            rx="4.625"
            ry="1.53125"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <path
            d="M14.126 5.5625V11.5C14.126 12.3457 12.0553 13.0312 9.50098 13.0312C6.94666 13.0312 4.87598 12.3457 4.87598 11.5V5.5625"
            stroke="currentColor"
            strokeWidth="1.5"
        />
        <path
            d="M14.7934 11.416C16.5284 11.933 17.6274 12.6997 17.6274 13.5553C17.6274 15.1123 13.9883 16.3745 9.49924 16.3745C5.01019 16.3745 1.37109 15.1123 1.37109 13.5553C1.37109 12.6997 2.47006 11.933 4.20512 11.416"
            stroke="currentColor"
            strokeWidth="1.5"
        />
    </svg>
);
