/* eslint-disable @typescript-eslint/naming-convention */
import Insight from "./Insight";
import FixedGrowth from "./FixedGrowth";
import Matrix from "./Matrix";
import { GaugeChart } from "components/ui/GaugeChart";
import {
    InsightsIndividual,
    InsightsTeam,
} from "store/api/investorCompany/interfaces";
import useModalController from "routes/company/useModalController";
import ModalController from "routes/company/ModalController";
import OverallModal from "./Modals/OverallModal";
import FixedVsGrowthModal from "./Modals/FixedVsGrowthModal";
import SoftSkillsMatrixModal from "./Modals/SoftSkillsMatrixModal";
import useMatrixController, {
    MatrixControllerData,
} from "./useMatrixController";
import { TOOLTIPS } from "routes/company/tooltips";

type Props = {
    teamData: InsightsTeam;
    individualData: InsightsIndividual;
};

const SoftSkillsTeamTab: React.FC<Props> = ({ teamData, individualData }) => {
    const {
        ss_overall,
        ss_motivation,
        ss_resilience,
        ss_task_clarity,
        ss_risk_propensity,
        ss_self_reflection,
        ss_stress_resistance,
    } = teamData;
    const modalController = useModalController();
    const matrixController = useMatrixController(
        [
            { key: "ss_motivation", data: ss_motivation },
            { key: "ss_resilience", data: ss_resilience },
            { key: "ss_task_clarity", data: ss_task_clarity },
            { key: "ss_risk_propensity", data: ss_risk_propensity },
            { key: "ss_self_reflection", data: ss_self_reflection },
            { key: "ss_stress_resistance", data: ss_stress_resistance },
        ].filter((i): i is MatrixControllerData => Boolean(i.data))
    );
    const modals = [
        {
            title: "Overall score",
            content: (
                <OverallModal
                    teamData={teamData}
                    individualData={individualData}
                    tooltip={TOOLTIPS.softSkills.overall}
                />
            ),
        },
        {
            title: "Fixed vs Growth mindset",
            content: (
                <FixedVsGrowthModal
                    tooltip={TOOLTIPS.softSkills.mindset}
                    teamData={teamData}
                />
            ),
        },
        {
            title: "Soft skills matrix",
            content: (
                <SoftSkillsMatrixModal
                    individualData={individualData}
                    tooltip={TOOLTIPS.softSkills.spider}
                    {...matrixController}
                />
            ),
        },
    ];

    return (
        <div className="grid grid-cols-1 gap-4">
            <ModalController modals={modals} {...modalController} />
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                <Insight
                    smallText
                    data={ss_overall}
                    name="Overall score"
                    iconType="MessagesSquare"
                    tooltip={TOOLTIPS.softSkills.overall}
                    onShowMore={() => modalController.open(0)}
                >
                    <GaugeChart value={(ss_overall?.value as number) ?? 0} />
                </Insight>

                <div className="col-span-1 lg:col-span-2">
                    <FixedGrowth
                        teamData={teamData}
                        iconType="ArrowRightLeft"
                        name="Fixed vs Growth mindset"
                        tooltip={TOOLTIPS.softSkills.mindset}
                        onShowMore={() => modalController.open(1)}
                    />
                </div>
            </div>

            <Matrix
                iconType="Hexagon"
                name="Soft skills matrix"
                onShowMore={() => modalController.open(2)}
                tooltip={TOOLTIPS.softSkills.spider}
                {...matrixController}
            />
        </div>
    );
};

export default SoftSkillsTeamTab;
