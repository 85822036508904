import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import {
    CompanyOnboardingActions,
    CompanyOnboardingSelectors,
} from "store/reducers/companyOnboarding";
import {
    useGetOnboardingQuery,
    useSaveOnboardingMutation,
} from "store/api/onboarding";
import Utils from "utils";
import DateInputMonthYear from "components/DateInputMonthYear";
import GridSelect from "components/GridSelect";
import InputSelect from "components/InputSelect";
import { BusinessType, Industry } from "data/onboarding";
import { InputSearchResults } from "types/validation.types";
import { useLocationSearch } from "hooks/useLocationSearch";
import BottomButtons from "routes/onboarding/components/BottomButtons";
import { CompanyOnboarding } from "store/reducers/companyOnboarding/types";

type Props = {
    title: string;
    handleNextClick: () => void;
    handleBackClick: () => void;
};

const industryArray = Utils.Parsers.enumToArray(Industry);
const businessTypeArray = Utils.Parsers.enumToArray(BusinessType);

const schema = yup.object({
    industry: Utils.Validations.searchOption,
    country: Utils.Validations.searchOption,
    foundingDate: Utils.Validations.fullDateInput,
    businessType: yup.string().required("Business type is required"),
});
const parseDefaultValues = (
    {
        industry,
        foundingDate,
        country,
        businessType,
    }: CompanyOnboarding.Details,
    data?: Partial<CompanyOnboarding.Details>
): CompanyOnboarding.Details => ({
    foundingDate: foundingDate || data?.foundingDate || "",
    country: country || data?.country,
    businessType: businessType || data?.businessType,
    industry: industry || data?.industry,
});
const DetailsStep = ({ title, handleBackClick, handleNextClick }: Props) => {
    const dispatch = useDispatch();
    const defaultValues = useSelector(
        CompanyOnboardingSelectors.state("details")
    );
    const { data: savedOnboarding } = useGetOnboardingQuery();
    const [saveOnboarding, saveOnboardingPayload] = useSaveOnboardingMutation();
    const { data: countryData, setQuery: setNationalityQuery } =
        useLocationSearch({ type: "country" });

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues: parseDefaultValues(
            defaultValues,
            savedOnboarding?.data["company.details"]
        ),
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema) as any,
    });

    const onSubmit = (details: CompanyOnboarding.Details) => {
        dispatch(CompanyOnboardingActions.setDetails(details));
        saveOnboarding({ "company.details": details });
        handleNextClick();
    };

    return (
        <div>
            <div className="mb-10 text-2xl font-semibold">{title}</div>

            <div className="flex flex-col gap-5">
                <Controller
                    name="industry"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputSelect<InputSearchResults[0]>
                            label="Industry"
                            selected={value as any}
                            handleSelect={onChange}
                            options={industryArray}
                            getOptionValue={(i) => i?.value}
                            placeholder="Select from dropdown"
                            renderOptionLabel={(i) => i.label}
                        />
                    )}
                />

                <Controller
                    name="country"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, value },
                    }) => (
                        <InputSelect<InputSearchResults[0]>
                            isMulti={false}
                            options={countryData}
                            selected={value as any}
                            handleSelect={onChange}
                            defaultValue={value as any}
                            errorMessage={error?.message}
                            label="Country of incorporation"
                            handleInputChange={setNationalityQuery}
                            getOptionValue={(option) => option?.value}
                            renderOptionLabel={(option) => option.label}
                            placeholder="Type country name to search and select"
                        />
                    )}
                />

                <Controller
                    name="businessType"
                    control={control}
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                    }) => (
                        <div>
                            <GridSelect
                                label="Business type"
                                options={businessTypeArray}
                                selected={value ? [value] : []}
                                onSelect={(e) => onChange(e[0])}
                            />
                            {error?.message}
                        </div>
                    )}
                />

                <Controller
                    name="foundingDate"
                    control={control}
                    render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                    }) => (
                        <DateInputMonthYear
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            label="Founding date"
                            errorMessage={error?.message}
                        />
                    )}
                />

                <BottomButtons
                    onBack={handleBackClick}
                    onNext={handleSubmit(onSubmit)}
                    disableNext={!isValid || saveOnboardingPayload.isLoading}
                />
            </div>
        </div>
    );
};

export default DetailsStep;
