import Badge from "components/Badge";
import { format } from "date-fns";

type Props = {
    time: number;
    date: string | null | undefined;
    state: "passed" | "days" | "hours" | "minutes";
};

const CompanyDeadlineBadge: React.FC<Props> = ({ time, state, date }) => {
    // @TODO: now this logic is divided between badge, company card/company header & utils
    // make it coherent
    const label =
        state === "days" && time >= 7 && date
            ? format(date, "MMM dd, yyyy")
            : `${time} ${state} left`;

    return (
        <Badge
            icon="CTimerOutlined"
            type={state === "days" ? "silver" : "danger"}
            label={label}
            className={"!bg-background"}
            iconProps={{ fill: state === "days" ? "#737785" : "#FC3F55" }}
        />
    );
};

export default CompanyDeadlineBadge;
