import { useEffect, useState } from "react";

import {
    QuestionType,
    GridSelectQuestionPattern,
} from "types/surveys/question-pattern";
import Utils from "utils";
import GridSelect from "components/GridSelect";
import Title from "questions/components/Title";
import { QuestionProps } from "questions/types.questions";
import { InputSearchResults } from "types/validation.types";
import { usePostSurveyAnswerMutation } from "store/api/survey";
import { GRID_OPTION_TO_ICON } from "questions/components/data";
import SurveyFooter from "routes/surveys/components/SurveyFooter";
import { GridSelectAnswerPattern } from "types/surveys/answer-pattern";
import QuestionRenderer from "routes/surveys/components/QuestionRenderer";
import ChildQuestionRenderer from "questions/components/ChildQuestionRenderer";

const GridSelectQuestion = ({
    isChild,
    surveyKey,
    allDisabled,
    isLastQuestion,
    data: { isRequired, key, pattern, answer, children = [], text },
    onError,
    onWarning,
    handleBack,
    handleNext,
}: QuestionProps<GridSelectQuestionPattern, GridSelectAnswerPattern>) => {
    const [postSurveyAnswer, { isError, isLoading, isSuccess }] =
        usePostSurveyAnswerMutation();

    const [isValid, setIsValid] = useState(!isRequired);
    const [selectedValue, setSelectedValue] = useState<Array<string>>(
        answer?.value ? [answer.value] : []
    );
    const [childValues, setChildValues] = useState<Record<any, any>>(
        children.reduce((prev, curr) => {
            let value = curr.answer?.value as any;

            if (value)
                if (curr.pattern.type === "number") {
                    value =
                        Utils.Parsers.convertInputToNumberOfThousands(value);
                } else if (curr.pattern.type === "number-array") {
                    value = value.map(
                        Utils.Parsers.convertInputToNumberOfThousands
                    );
                }

            return { ...prev, [curr.key]: value ?? "" };
        }, {})
    );

    const handleSubmit = () => {
        postSurveyAnswer({
            surveyKey,
            questionKey: key,
            data: JSON.stringify(selectedValue[0]),
        });

        Object.entries(childValues).map(([k, value]) => {
            if (!value) return;

            const childQuestion = children.find((i) => i.key === k);
            const isQuestionLocationMultiSearch =
                childQuestion?.pattern?.type ===
                QuestionType.location_search_multi_select;
            const isNumber =
                childQuestion?.pattern?.type === QuestionType.number;

            let valueToSend = value;
            if (isQuestionLocationMultiSearch)
                valueToSend = (value as InputSearchResults).map((i) => i.value);
            else if (
                typeof value === "string" &&
                childQuestion?.pattern.type !== "number"
            )
                valueToSend = JSON.stringify(value);
            else if (isNumber) {
                valueToSend =
                    Utils.Parsers.convertInputOfThousandsIntoNumber(
                        valueToSend
                    );
            }

            postSurveyAnswer({
                surveyKey,
                questionKey: k,
                data: valueToSend,
            });
        });
    };

    useEffect(() => {
        if (isSuccess) handleNext();
    }, [isSuccess]);

    useEffect(() => {
        onError(isError);
    }, [isError]);

    useEffect(() => {
        const areChildrenValid = Utils.Surveys.validateChildren(
            selectedValue,
            childValues,
            children
        );

        setIsValid(!!selectedValue.length && areChildrenValid);
    }, [selectedValue, childValues]);

    // make each question optionally receive callback function which will be invoked
    // with updated value of that question so that the parents knows about it

    // map the children questions of the question and pass that function
    // there wil be a useEffect or something that will check the fulfilled value for validity and toggle disabled state of the Next button

    return (
        <div className="flex flex-col items-center justify-center gap-12">
            <div>{!isChild && <Title>{text}</Title>}</div>

            <GridSelect<string>
                centered
                disabled={allDisabled}
                selected={selectedValue}
                onSelect={setSelectedValue}
                options={pattern.options.map((label) => ({
                    label,
                    value: label,
                    icon: pattern.withIcons
                        ? GRID_OPTION_TO_ICON[label]
                        : undefined,
                }))}
            />

            <div className="w-[100%]">
                {children.map((i, index) => (
                    <ChildQuestionRenderer
                        key={index}
                        childConditions={i.conditions}
                        parentValue={selectedValue[0]}
                        onHide={() =>
                            setChildValues((prev) => ({
                                ...prev,
                                [i.key]: undefined,
                            }))
                        }
                    >
                        <QuestionRenderer
                            isChild
                            data={i}
                            key={i.key}
                            surveyKey={surveyKey}
                            type={i.pattern.type}
                            allDisabled={allDisabled}
                            value={childValues[i.key]}
                            onError={onError}
                            onWarning={onWarning}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            onValueChange={(value) =>
                                setChildValues((prev) => ({
                                    ...prev,
                                    [i.key]: value,
                                }))
                            }
                        />
                    </ChildQuestionRenderer>
                ))}

                <SurveyFooter
                    isLoading={isLoading}
                    loadingText="Saving..."
                    handleBack={handleBack}
                    handleNext={handleSubmit}
                    allDisabled={allDisabled}
                    nextDisabled={!selectedValue || !isValid}
                    nextText={isLastQuestion ? "Submit" : undefined}
                />
            </div>
        </div>
    );
};

export default GridSelectQuestion;
