import { FC, useMemo } from "react";
import CardBlockWrapper from "../card-block-wrapper";
import { cva } from "class-variance-authority";
import { cn } from "lib/utils";
import { InsightsIndividualMember } from "store/api/investorCompany/interfaces";
import {
    CompanyMemberInfo as TCompanyMemberInfo,
    companyMemberInfoSchema,
} from "../../schemas/company-member-info-schema";

const titleVariants = cva("text-sm font-semibold text-default-400");
const descriptionVariants = cva("text-sm font-semibold text-foreground");

type Props = {
    memberData: InsightsIndividualMember;
};

const CompanyMemberInfo: FC<Props> = ({ memberData }) => {
    const { joined, workedWithTeam, workedWithTeamYears } =
        useMemo((): TCompanyMemberInfo => {
            const data = {
                joined: memberData?.["member-joined"]?.value,
                workedWithTeam: memberData?.["hc-worked-with-team"]?.value,
                workedWithTeamYears:
                    memberData?.["hc-worked-with-team-years"]?.value,
            };
            const dataParsed = companyMemberInfoSchema.safeParse(data);
            if (!dataParsed.success) {
                return {
                    joined: new Date().toISOString(),
                    workedWithTeam: undefined,
                    workedWithTeamYears: undefined,
                };
            }
            return dataParsed.data;
        }, [memberData]);

    const joinedFormated = useMemo(() => {
        const formater = new Intl.DateTimeFormat("en-US", {
            month: "long",
            year: "numeric",
        });
        return formater.format(new Date(joined));
    }, [joined]);

    return (
        <CardBlockWrapper>
            <div className="flex flex-col gap-y-1">
                <h6 className={cn(titleVariants())}>Joined the company</h6>
                <p className={cn(descriptionVariants())}>{joinedFormated}</p>
            </div>
            <div className="flex flex-col gap-y-1">
                <h6 className={cn(titleVariants())}>
                    Worked with this team before
                </h6>
                <p className={cn(descriptionVariants())}>
                    {workedWithTeam === undefined ? (
                        <>-</>
                    ) : (
                        <>
                            {workedWithTeam
                                ? `Yes, for ${workedWithTeamYears} years`
                                : "No"}
                        </>
                    )}
                </p>
            </div>
        </CardBlockWrapper>
    );
};

export default CompanyMemberInfo;
