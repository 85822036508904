type Props = {
    currentIndex: number;
    labels: Array<string>;
};

const ActiveIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <circle
            cx="10"
            cy="10"
            r="9"
            fill="#0F1117"
            stroke="#0F1117"
            strokeWidth="2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5303 7.46967C13.8232 7.76256 13.8232 8.23744 13.5303 8.53033L9.53033 12.5303C9.23744 12.8232 8.76256 12.8232 8.46967 12.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L9 10.9393L12.4697 7.46967C12.7626 7.17678 13.2374 7.17678 13.5303 7.46967Z"
            fill="white"
        />
    </svg>
);

const Icon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <circle cx="10" cy="10" r="9" stroke="#0F1117" strokeWidth="2" />
    </svg>
);

const Progress = ({ currentIndex, labels }: Props) => {
    return (
        <div key={currentIndex} className="flex flex-col gap-5">
            {labels.map((label, index) => (
                <div
                    key={label}
                    className={`flex flex-row gap-3 items-center text-sm ${index <= currentIndex ? "opacity-100" : "opacity-50"}`}
                >
                    {index < currentIndex ? <ActiveIcon /> : <Icon />}
                    {label}
                </div>
            ))}
        </div>
    );
};

export default Progress;
