import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CompanyReport from "routes/company/components/company-report";
import { CompanyReportContextProvider } from "routes/components/CompanyReport/contexts/report-context";
import { PageLoader } from "routes/components/PageLoader";
import { useGetSharedViewReportQuery } from "store/api/shared-view";
import EmptyShareReport from "./components/empty-share-report";
import { Header } from "routes/components/header";
import ReportInfo from "./components/report-info";

type Props = {};

const ShareReportsPage: FC<Props> = () => {
    const navigate = useNavigate();
    const params = useParams();
    const token = useMemo(() => params?.token ?? "", [params]);
    const {
        data: reportData,
        error,
        isError,
        isFetching,
    } = useGetSharedViewReportQuery({ token });

    if (isFetching) {
        return <PageLoader />;
    }

    if (!reportData || isError) {
        if (error && "data" in error && error.status === 401) {
            navigate("auth");
            return;
        }
        return <EmptyShareReport />;
    }

    const { insights, companyId, generatedAt, investor } = reportData.data;

    return (
        <CompanyReportContextProvider
            value={{
                ...insights,
                isSharedView: true,
                companyId,
                generatedAt,
                sharedBy: investor,
            }}
        >
            <Header classNames={{ base: "bg-default-50" }}>
                <ReportInfo />
            </Header>
            <CompanyReport />
        </CompanyReportContextProvider>
    );
};

export default ShareReportsPage;
