import Avatar from "components/Avatar";

type Props = {
    memberId: string;
    firstName: string;
    lastName: string;
    picture: string;

    time: number;
    teamSize?: number;
};

const Card: React.FC<Props> = ({
    memberId,
    firstName,
    lastName,
    picture,
    time,
    teamSize,
}) => {
    return (
        <div className="flex h-[56px] flex-row items-center gap-3 rounded-md bg-default-50 p-3 text-sm">
            <Avatar
                size="sm"
                src={picture}
                memberId={memberId}
                firstName={firstName}
                lastName={lastName}
                disableTooltip
            />

            <div className="flex flex-1 flex-col">
                <div>{`${firstName} ${lastName}`}</div>
                <div className="text-default-400">
                    {time} years{teamSize && <span>, team of {teamSize}</span>}
                </div>
            </div>
        </div>
    );
};

export default Card;
