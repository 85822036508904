import React, { useState } from "react";

type Type = "default" | "primary";

type Props = {
    type?: Type;
    children: any;
    allowExpand?: boolean;
    considerLarge?: number;
};

const TYPE_TO_STYLE: Record<Type, string> = {
    default: "bg-default-75 text-black",
    primary: "bg-primary-500 text-white",
};

const Chip: React.FC<Props> = ({
    children,
    allowExpand = false,
    type = "default",
    considerLarge = 445,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isLarge = children?.length > considerLarge;

    const toggleExpansion = () => setIsExpanded(!isExpanded);

    const label =
        allowExpand && isLarge && !isExpanded
            ? `${children?.substring(0, considerLarge)}`
            : children;

    return (
        <div className="flex flex-col items-start gap-2">
            <div
                className={` px-2 py-1 text-sm rounded-sm ${TYPE_TO_STYLE[type]}`}
            >
                {label}
            </div>

            {allowExpand && isLarge && (
                <button
                    onClick={toggleExpansion}
                    className={"text-primary-500 text-sm"}
                >
                    {isExpanded ? "Show less" : "Show more"}
                </button>
            )}
        </div>
    );
};

export default Chip;
