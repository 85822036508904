import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useEffect } from "react";
import { ProfileEditProps } from "./interfaces";
import { useUpdateProfileMutation } from "store/api/member";
import { Controller, useForm } from "react-hook-form";
import { InferFieldValues } from "types/utils";
import Button from "components/Button";
import Utils from "utils";
import Input from "components/Input";
import { Gender, MemberRole } from "data/onboarding";
import GridSelect from "components/GridSelect";
import InputSelect from "components/InputSelect";
import { useLocationSearch } from "hooks/useLocationSearch";
import DateInputMonthYear from "components/DateInputMonthYear";
import FileInput, { AllowedFileTypes } from "components/FileInput";
import API from "api";
import { useDispatch } from "react-redux";
import { GlobalActions } from "store/reducers/global";
import Logo from "../../images/logo.png";
import Collapsed from "components/Collapsed";
import { Link } from "react-router-dom";

const schema = yup.object({
    picture: Utils.Validations.image,
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    linkedin: Utils.Validations.websiteUrl,

    joined: yup.string().required("Required"),
    role: Utils.Validations.searchOption,
    roleDescription: yup.string().when("role.value", {
        is: MemberRole.Other,
        then: () => yup.string().required("Required"),
        otherwise: () => yup.string(),
    }),
    hasShares: yup.string().when("isFounder", {
        is: "true",
        then: () => yup.string().required("Required"),
        otherwise: () => yup.string(),
    }),
    sharesAmount: yup.string().when("hasShares", {
        is: "true",
        then: () => Utils.Validations.sharePercentage,
        otherwise: () => yup.string(),
    } as any),

    isFounder: yup.string().required("Required"),
    founderWhy: yup.string().when("isFounder", {
        is: "true",
        then: () => yup.string().required("Required").max(3000, "Too long"),
        otherwise: () => yup.string().notRequired(),
    }),
    startedCompanyWhy: yup.string().when("isFounder", {
        is: "true",
        then: () => yup.string().required("Required").max(3000, "Too long"),
        otherwise: () => yup.string().notRequired(),
    }),

    locationCountry: Utils.Validations.searchOption,
    locationCity: Utils.Validations.searchOption,

    gender: yup.string().required("Required"),
    year: Utils.Validations.yearInput,
    nationality: Utils.Validations.searchOptionArray,
    genderDescription: yup.string().when("gender", {
        is: "Prefer to self-describe",
        then: () => yup.string().required("Required"),
        otherwise: () => yup.string(),
    } as any),
});

const MAX_FOUNDER_WHY = 3000;

const genderArray = Utils.Parsers.enumToArray(Gender);
const memberRoleArray = Utils.Parsers.enumToArray(MemberRole);

const MemberEdit: FC<ProfileEditProps> = ({ profile, me }) => {
    const dispatch = useDispatch();
    const [updateProfile, updateProfileData] = useUpdateProfileMutation();
    const { data: nationalityData, setQuery: setNationalityQuery } =
        useLocationSearch({ type: "country" });
    const { data: countryData, setQuery: setCountryQuery } = useLocationSearch({
        type: "country",
    });

    const isFounderValue =
        typeof profile.isFounder === "boolean"
            ? profile.isFounder
                ? "true"
                : "false"
            : undefined;
    const hasSharesValue =
        Boolean(profile.sharesAmount) === true ? "true" : "false";

    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid },
        watch,
        setValue,
        getFieldState,
        clearErrors,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema) as any,
        values: {
            picture: profile.picture ?? null,
            firstName: profile.firstName,
            lastName: profile.lastName,
            linkedin: profile.linkedin
                ? Utils.Parsers.formatWebsiteUrlToPresent(profile.linkedin)
                : "",
            gender: profile.gender,
            genderDescription: profile.genderDescription || undefined,
            year: String(profile.year),
            nationality: profile.nationality.map((i) => ({
                value: i,
                label: i,
            })),
            locationCountry: profile?.location?.country
                ? {
                      value: profile.location.country,
                      label: profile.location.country,
                  }
                : null,
            locationCity: profile?.location?.city
                ? {
                      value: profile.location.city,
                      label: profile.location.city,
                  }
                : null,
            isFounder: isFounderValue,
            hasShares: hasSharesValue,
            founderWhy: profile.founderWhy ?? "",
            sharesAmount: profile.sharesAmount
                ? String(profile.sharesAmount)
                : "",
            role: {
                value: profile.role,
                label: profile.role,
            },
            roleDescription: profile?.roleDescription ?? "",
            joined: Utils.Date.toISODate(profile.joined) ?? "", // @TODO: can founding date be empty? if yes test how date input would behave
            startedCompanyWhy: profile.startedCompanyWhy ?? "",
        },
    });

    const {
        gender: wGender,
        isFounder: wIsFounder,
        hasShares: wHasShares,
        role: wRole,
        locationCountry: wLocationCountry,
    } = watch();

    const { data: cityData, setQuery: setCityQuery } = useLocationSearch({
        type: "city",
        filterCountry: wLocationCountry?.value,
    });

    useEffect(() => {
        const { isDirty: isLocationCountryDirty } =
            getFieldState("locationCountry");
        if (!isLocationCountryDirty) {
            return;
        }
        setValue("locationCity", null);
        clearErrors("locationCity");
    }, [wLocationCountry, getFieldState, setValue, clearErrors, setCityQuery]);

    const onSubmit = async (data: InferFieldValues<typeof handleSubmit>) => {
        const picture =
            data.picture instanceof File
                ? await API.FileUpload.image(data.picture)
                : data.picture;

        data.picture = picture?.id ?? null;
        const nationality = data.nationality.map((i) => i.value);

        const {
            hasShares,
            sharesAmount,
            isFounder,
            linkedin,
            locationCountry,
            locationCity,
            ...rest
        } = data;

        const parsedSharesAmount = Number(data.sharesAmount);
        const location =
            locationCountry?.value && locationCity?.value
                ? {
                      country: locationCountry.value,
                      city: locationCity.value,
                  }
                : undefined;

        updateProfile({
            ...rest,
            linkedin: Utils.Parsers.formatWebsiteUrl(linkedin),
            role: data.role.value,
            isFounder: isFounder === "true",
            nationality,
            year: Number(data.year),
            sharesAmount:
                hasShares === "true"
                    ? isFinite(parsedSharesAmount)
                        ? parsedSharesAmount
                        : 0
                    : 0,
            location,
        });
    };

    useEffect(() => {
        if (updateProfileData.isUninitialized || updateProfileData.isLoading) {
            return;
        }

        dispatch(
            GlobalActions.showPopover({
                type: updateProfileData.isSuccess ? "success" : "error",
                label: updateProfileData.isSuccess
                    ? `Profile updated`
                    : "An error occurred updating profile",
            })
        );
    }, [updateProfileData, dispatch]);

    return (
        <>
            <div className="mx-auto max-w-[638px] py-10 pb-[100px]">
                <div className="fixed left-0 right-0 top-0 flex flex-row items-center justify-between p-12">
                    <img src={Logo} className="h-[48px] w-[48px]" />

                    <Button
                        as={Link}
                        icon="X"
                        to="/dashboard"
                        iconPosition="left"
                        customType="secondary-light"
                        className="bg-[transparent]"
                    >
                        Close
                    </Button>
                </div>

                <div className="mb-10 flex items-center justify-between">
                    <h1 className="text-xl font-bold">Edit personal details</h1>
                </div>

                <div className="flex flex-col gap-10">
                    <section>
                        <h2 className="mb-6 font-semibold">Profile</h2>
                        <div className="flex flex-col gap-8 rounded-2xl bg-default-25 p-10">
                            <Controller
                                name="picture"
                                control={control}
                                render={({
                                    field: { onChange, value, onBlur },
                                    fieldState: { error },
                                }) => (
                                    <FileInput
                                        label="Profile picture"
                                        onBlur={onBlur}
                                        selectedFile={value}
                                        errorMessage={error?.message}
                                        fileTypes={AllowedFileTypes.Image}
                                        setFiles={(files) => onChange(files)}
                                        uploadLabel="Drag or upload a picture"
                                    />
                                )}
                            />

                            <Controller
                                name="firstName"
                                control={control}
                                render={({
                                    field: { onBlur, onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        label="First name"
                                        errorMessage={error?.message}
                                        placeholder="Your first name"
                                    />
                                )}
                            />

                            <Controller
                                name="lastName"
                                control={control}
                                render={({
                                    field: { onBlur, onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        label="Last name"
                                        errorMessage={error?.message}
                                        placeholder="Your last name"
                                    />
                                )}
                            />

                            <Controller
                                name="linkedin"
                                control={control}
                                render={({
                                    field: { onBlur, onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        label="LinkedIn"
                                        placeholder="Your LinkedIn profile link"
                                        errorMessage={error?.message}
                                    />
                                )}
                            />

                            <Input disabled value={me.email} label="Email" />
                        </div>
                    </section>

                    <section>
                        <h2 className="mb-6 font-semibold">Details</h2>
                        <div className="flex flex-col gap-8 rounded-2xl bg-default-25 p-10">
                            <div>
                                <Controller
                                    name="gender"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <GridSelect
                                            label="Gender"
                                            options={genderArray}
                                            selected={value ? [value] : []}
                                            onSelect={(e) => onChange(e[0])}
                                        />
                                    )}
                                />

                                <Collapsed
                                    isExpanded={
                                        wGender === "Prefer to self-describe"
                                    }
                                >
                                    <div className="mt-[20px]">
                                        <Controller
                                            control={control}
                                            name="genderDescription"
                                            render={({
                                                fieldState: { error },
                                                field: {
                                                    onBlur,
                                                    onChange,
                                                    value,
                                                },
                                            }) => (
                                                <Input
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    label="Gender description"
                                                    errorMessage={
                                                        error?.message
                                                    }
                                                    placeholder="Describe your gender"
                                                />
                                            )}
                                        />
                                    </div>
                                </Collapsed>
                            </div>

                            <Controller
                                name="year"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { value, onChange, onBlur },
                                }) => (
                                    <Input
                                        type="number"
                                        value={value}
                                        onBlur={onBlur}
                                        placeholder="yyyy"
                                        label="Year of birth"
                                        errorMessage={error?.message}
                                        onChange={(e) =>
                                            onChange(e.target.value)
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="nationality"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { value, onChange, onBlur },
                                }) => (
                                    <InputSelect
                                        isMulti
                                        onBlur={onBlur}
                                        label="Nationality"
                                        selected={value as any}
                                        handleSelect={onChange}
                                        options={nationalityData}
                                        defaultValue={value as any}
                                        errorMessage={error?.message}
                                        placeholder="Type to search countries; select one or multiple"
                                        handleInputChange={setNationalityQuery}
                                        getOptionValue={(option) =>
                                            option?.value
                                        }
                                        renderOptionLabel={(option) =>
                                            option.label
                                        }
                                    />
                                )}
                            />

                            <Controller
                                name="locationCountry"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { value, onChange, onBlur },
                                }) => (
                                    <InputSelect
                                        isMulti={false}
                                        onBlur={onBlur}
                                        options={countryData}
                                        handleSelect={onChange}
                                        selected={value as any}
                                        defaultValue={value as any}
                                        errorMessage={error?.message}
                                        label="Country"
                                        handleInputChange={setCountryQuery}
                                        placeholder="Type to search countries; select from drop-down"
                                        renderOptionLabel={(option) =>
                                            option.label
                                        }
                                        getOptionValue={(option) =>
                                            option?.value
                                        }
                                    />
                                )}
                            />

                            <Controller
                                name="locationCity"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { value, onChange, onBlur },
                                }) => (
                                    <InputSelect
                                        disabled={!wLocationCountry}
                                        isMulti={false}
                                        onBlur={onBlur}
                                        options={cityData}
                                        handleSelect={onChange}
                                        selected={value as any}
                                        defaultValue={value as any}
                                        errorMessage={error?.message}
                                        label="City"
                                        handleInputChange={setCityQuery}
                                        placeholder="Type to search cities; select from drop-down"
                                        renderOptionLabel={(option) =>
                                            option.label
                                        }
                                        getOptionValue={(option) =>
                                            option?.value
                                        }
                                    />
                                )}
                            />
                        </div>
                    </section>

                    <section>
                        <h2 className="mb-6 font-semibold">Roles & shares</h2>
                        <div className="flex flex-col gap-8 rounded-2xl bg-default-25 p-10">
                            <Controller
                                name="joined"
                                control={control}
                                render={({
                                    field: { onChange, value, onBlur },
                                    fieldState: { error },
                                }) => (
                                    <DateInputMonthYear
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errorMessage={error?.message}
                                        label="When did you join the company"
                                    />
                                )}
                            />

                            <Controller
                                name="role"
                                control={control}
                                render={({
                                    field: { value, onChange, onBlur },
                                    fieldState: { error },
                                }) => (
                                    <InputSelect
                                        onBlur={onBlur}
                                        selected={value as any}
                                        handleSelect={onChange}
                                        options={memberRoleArray}
                                        label="What is your role?"
                                        placeholder="Select from drop-down"
                                        errorMessage={error?.message}
                                        renderOptionLabel={(i) => i.label}
                                        getOptionValue={(i) => i?.value}
                                    />
                                )}
                            />
                            <Collapsed
                                isExpanded={wRole?.value === MemberRole.Other}
                            >
                                <Controller
                                    control={control}
                                    name="roleDescription"
                                    render={({
                                        fieldState: { error },
                                        field: { onBlur, onChange, value },
                                    }) => (
                                        <Input
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                            label="Role description"
                                            errorMessage={error?.message}
                                            placeholder="Describe your role"
                                        />
                                    )}
                                />
                            </Collapsed>

                            <Controller
                                name="hasShares"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <GridSelect<string>
                                        label="Do you have shares?"
                                        selected={value ? [value] : []}
                                        onSelect={(e) => onChange(e[0])}
                                        options={[
                                            {
                                                label: "Yes",
                                                value: "true",
                                                icon: "Check",
                                            },
                                            {
                                                label: "No",
                                                value: "false",
                                                icon: "X",
                                            },
                                        ]}
                                    />
                                )}
                            />
                            {wHasShares === "true" && (
                                <Controller
                                    name="sharesAmount"
                                    control={control}
                                    render={({
                                        fieldState: { error },
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <Input
                                            type="number"
                                            value={value}
                                            endContent="%"
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            label="Share percentage"
                                            errorMessage={error?.message}
                                            placeholder="number in percentages; ex: 20%"
                                        />
                                    )}
                                />
                            )}
                        </div>
                    </section>

                    <section>
                        <h2 className="mb-6 font-semibold">Co-founder info</h2>
                        <div className="flex flex-col gap-8 rounded-2xl bg-default-25 p-10">
                            <Controller
                                name="isFounder"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <div>
                                        <GridSelect<string>
                                            label="Are you a (co-)founder?"
                                            selected={value ? [value] : []}
                                            onSelect={(e) => onChange(e[0])}
                                            options={[
                                                {
                                                    label: "Yes",
                                                    value: "true",
                                                    icon: "Check",
                                                },
                                                {
                                                    label: "No",
                                                    value: "false",
                                                    icon: "X",
                                                },
                                            ]}
                                        />
                                        {error?.message}
                                    </div>
                                )}
                            />
                            {wIsFounder === "true" && (
                                <Controller
                                    name="founderWhy"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Input
                                            as="textarea"
                                            value={value}
                                            maxLength={MAX_FOUNDER_WHY}
                                            onChange={onChange}
                                            errorMessage={error?.message}
                                            placeholder="Describe using no more than 3000 characters"
                                            label="What is your motivation for being a founder in general?"
                                        />
                                    )}
                                />
                            )}
                            {wIsFounder === "true" && (
                                <Controller
                                    name="startedCompanyWhy"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <Input
                                            as="textarea"
                                            value={value}
                                            aria-multiline
                                            maxLength={3_000}
                                            onChange={onChange}
                                            errorMessage={error?.message}
                                            placeholder="Describe using no more than 3000 characters"
                                            label="Why did you build this company specifically?"
                                        />
                                    )}
                                />
                            )}
                        </div>
                    </section>
                </div>
            </div>

            <div className="fixed bottom-4 left-12 right-12 rounded-b-lg border-t border-solid bg-white">
                <div className="mx-auto my-4 max-w-[638px]">
                    <Button
                        disabled={!isDirty || !isValid}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Save changes
                    </Button>
                </div>
            </div>
        </>
    );
};

export default MemberEdit;
