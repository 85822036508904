import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalContent } from "@nextui-org/react";

import { GlobalActions, GlobalSelectors } from "store/reducers/global";

const GlobalModal = () => {
    const dispatch = useDispatch();

    const { show, title, subTitle, image, bottomContent } = useSelector(
        GlobalSelectors.modal
    );

    const handleCloseModal = () => dispatch(GlobalActions.hideModal());

    return (
        <Modal isOpen={show} onClose={handleCloseModal}>
            <ModalContent className="items-center gap-8 p-10">
                {image && <img src={image} />}

                <div className="flex flex-col gap-4">
                    <div className="text-xl text-center text-black">
                        {title}
                    </div>

                    <div className="text-sm text-center text-default-500">
                        {subTitle}
                    </div>
                </div>

                {bottomContent}
            </ModalContent>
        </Modal>
    );
};

export default GlobalModal;
