import { FC, useState } from "react";

import Utils from "utils";
import Icon from "components/Icon";
import Input from "components/Input";
import Button from "components/Button";
import { useDispatch } from "react-redux";
import { InvitationModalProps } from "./interfaces";
import { GlobalActions } from "store/reducers/global";
import { useCreateInvitationMutation } from "store/api/invitation";

const InvitationModal: FC<InvitationModalProps> = ({ onClose }) => {
    const dispatch = useDispatch();
    const [createInvitation] = useCreateInvitationMutation();
    const [value, setValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [emails, setEmails] = useState<string[]>([]);

    const handleEmailAdding = async () => {
        if (!value) return;
        try {
            const parsedData = Utils.Validations.email.safeParse(value);

            if (!parsedData.success) {
                throw new Error(parsedData.error.format()._errors[0]);
            }

            const parsedValue = parsedData.data;

            const existing = emails.find((email) => email === parsedValue);

            if (existing) {
                throw new Error("There's already such email");
            }

            setEmails([...emails, parsedValue]);
            setValue("");
        } catch (error) {
            const message =
                error instanceof Error ? error.message : "Invalid email";

            setErrorMessage(message);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") handleEmailAdding();
    };

    const handleSend = async () => {
        setIsLoading(true);

        let successSends = 0;
        const errors: Array<string> = [];

        for (const email of emails) {
            try {
                const res = await createInvitation({ email });

                if (!("data" in res)) {
                    errors.push(email);
                    continue;
                }

                successSends++;
            } catch (err: unknown) {
                errors.push(email);
            }
        }

        setEmails([]);
        setIsLoading(false);
        onClose();

        if (successSends === 0 && errors.length > 0) {
            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: `failed to deliver invites to: ${errors.join(", ")}`,
                })
            );
            return;
        }

        if (successSends > 0 && errors.length > 0) {
            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: `${successSends} invites successfully sent; failed to deliver invites to: ${errors.join(", ")}`,
                })
            );
            return;
        }

        dispatch(
            GlobalActions.showPopover({
                type: "success",
                label: `${successSends} invites successfully sent`,
            })
        );
    };

    return (
        <div className="w-[592px] max-w-full rounded-2xl bg-white p-10">
            <h2 className="mb-3 text-xl">Invite teammates</h2>
            <p className="mb-8 text-sm text-default-500">
                Enter email addresses of your teammates to send invites.
            </p>
            <div className="flex">
                <Input
                    endContent={
                        <Button
                            className="m-[2px]"
                            disabled={!value.length}
                            onClick={handleEmailAdding}
                        >
                            Add
                        </Button>
                    }
                    placeholder="Enter email address and press Enter."
                    value={value}
                    onChange={({ target }) => {
                        setErrorMessage("");
                        setValue(target.value);
                    }}
                    containerClassName="w-full"
                    inputWrapperClassName="p-0 pl-[10px]"
                    onKeyDown={handleKeyDown}
                    errorMessage={errorMessage}
                />
            </div>
            <div className="mt-4 flex flex-wrap gap-3 text-sm">
                {emails.map((email, idx) => (
                    <div
                        key={email}
                        className="flex items-center rounded-lg bg-default-100 p-2"
                    >
                        <span className="mr-2">{email}</span>
                        <button
                            type="button"
                            onClick={() =>
                                setEmails(emails.filter((_, i) => i !== idx))
                            }
                        >
                            <Icon type="X" width="16" height="16" />
                        </button>
                    </div>
                ))}
            </div>
            <div className="mt-8 flex justify-end gap-x-3 text-sm">
                <Button onClick={onClose} customType="secondary-solid">
                    Cancel
                </Button>
                <Button
                    disabled={!emails.length || isLoading}
                    onClick={handleSend}
                    icon="ArrowRight" // @TODO: match icon with design
                    iconPosition="left"
                >
                    Send invites
                </Button>
            </div>
        </div>
    );
};

export default InvitationModal;
