import { FC } from "react";

import Icon from "components/Icon";
import Button from "components/Button";
import { useGetSurveysQuery } from "store/api/survey";
import ProgressStatus from "components/ProgressStatus";
import { Link } from "react-router-dom";
import StarImg from "images/surveys/star.png";

const CompanySurveyCard: FC = () => {
    const { data: surveysData } = useGetSurveysQuery();
    const surveys = surveysData?.data ?? [];
    const companySurvey = surveys.find((s) => s.isCompanyWide);

    const progress = companySurvey?.totalSteps
        ? companySurvey?.completedSteps / companySurvey?.totalSteps
        : 0;

    const calcProgress = (progress * 100) | 0;

    return (
        <div className="flex items-start justify-between p-6 bg-white rounded-2xl gap-x-6">
            <div className="flex">
                <img
                    src={StarImg}
                    alt="Company"
                    width="96"
                    height="96"
                    className="object-cover w-24 h-24 mr-6 border rounded-lg border-default-100"
                />
                <div className="flex flex-col items-start">
                    <h4 className="mb-1 text-lg">
                        {companySurvey?.name ?? ""}
                    </h4>

                    {calcProgress ? (
                        <ProgressStatus progress={progress} type="primary" />
                    ) : (
                        <div className="flex items-center text-sm">
                            <Icon type="CTimerFilled" width="16" height="16" />
                            <span className="ml-1">{companySurvey?.timeToComplete} min</span>
                        </div>
                    )}
                </div>
            </div>
            <div>
                {companySurvey ? (
                    companySurvey.isSubmitted ? undefined : (
                        <Button
                            as={Link}
                            icon="ArrowRight"
                            iconPosition="right"
                            customType="secondary-solid"
                            to={`/surveys/${companySurvey.key}`}
                        >
                            {calcProgress ? "Resume survey" : "Start survey"}
                        </Button>
                    )
                ) : null}
            </div>
        </div>
    );
};

export default CompanySurveyCard;
