import { Tooltip } from "@nextui-org/react";

import Icon from "components/Icon";

type Props = {
    text: string;
};

const InfoTooltip: React.FC<Props> = ({ text }) => {
    return (
        <Tooltip
            showArrow
            radius="sm"
            isDisabled={!text}
            color="foreground"
            className="max-w-[400px]"
            content={<div className="text-sm">{text}</div>}
        >
            <div>
                <Icon type="Info" size={16} />
            </div>
        </Tooltip>
    );
};

export default InfoTooltip;
