import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
    InvestorOnboardingActions,
    InvestorOnboardingSelectors,
} from "store/reducers/investorOnboarding";
import {
    useGetOnboardingQuery,
    useSaveOnboardingMutation,
} from "store/api/onboarding";
import Input from "components/Input";
import BottomButtons from "routes/onboarding/components/BottomButtons";
import { InvestorOnboarding } from "store/reducers/investorOnboarding/types";

type Props = {
    title: string;
    handleNextClick: () => void;
    handleBackClick: () => void;
};

const schema = yup.object({
    companyName: yup.string().required("Required"),
    contactRole: yup.string().required("Required"),
    vat: yup.string().required("Required"),
});

const parseDefaultValues = (
    { vat, companyName, contactRole }: InvestorOnboarding.Details,
    data?: Partial<InvestorOnboarding.Details>
): InvestorOnboarding.Details => ({
    vat: vat || data?.vat || "",
    contactRole: contactRole || data?.contactRole || "",
    companyName: companyName || data?.companyName || "",
});

const DetailsStep = ({ handleNextClick, handleBackClick, title }: Props) => {
    const dispatch = useDispatch();
    const { data: savedOnboarding } = useGetOnboardingQuery();
    const [saveOnboarding, saveOnboardingPayload] = useSaveOnboardingMutation();

    const defaultValues = useSelector(
        InvestorOnboardingSelectors.state("details")
    );

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues: parseDefaultValues(
            defaultValues,
            savedOnboarding?.data["investor.details"]
        ),
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema) as any,
    });

    const onSubmit = (details: InvestorOnboarding.Details) => {
        dispatch(InvestorOnboardingActions.setDetails(details));
        saveOnboarding({ "investor.details": details });
        handleNextClick();
    };

    return (
        <div>
            <div className="mb-10 text-2xl font-semibold">{title}</div>

            <div className="flex flex-col gap-5">
                <Controller
                    name="companyName"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            label="Company name"
                            errorMessage={error?.message}
                            placeholder="Full legal name"
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="contactRole"
                    render={({
                        fieldState: { error },
                        field: { onChange, value, onBlur },
                    }) => (
                        <Input
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            label="Your Role"
                            errorMessage={error?.message}
                            placeholder="Your role in the company"
                        />
                    )}
                />

                <Controller
                    name="vat"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { value, onChange, onBlur },
                    }) => (
                        <Input
                            value={value}
                            onBlur={onBlur}
                            label="VAT number"
                            onChange={onChange}
                            errorMessage={error?.message}
                            placeholder="Company tax number"
                        />
                    )}
                />

                <BottomButtons
                    onBack={handleBackClick}
                    onNext={handleSubmit(onSubmit)}
                    disableNext={!isValid || saveOnboardingPayload.isLoading}
                />
            </div>
        </div>
    );
};

export default DetailsStep;
