export default (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            d="M10.0004 2.9082L12.5754 8.12487L18.3337 8.96654L14.167 13.0249L15.1504 18.7582L10.0004 16.0499L4.85035 18.7582L5.83369 13.0249L1.66702 8.96654L7.42535 8.12487L10.0004 2.9082Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0004 1.9082C10.381 1.9082 10.7286 2.12428 10.8971 2.46558L13.2396 7.21134L18.4783 7.97705C18.8549 8.03209 19.1676 8.2961 19.2849 8.6581C19.4023 9.02011 19.304 9.41736 19.0314 9.68289L15.2415 13.3742L16.136 18.5892C16.2003 18.9643 16.0461 19.3435 15.7381 19.5673C15.4301 19.791 15.0218 19.8205 14.6849 19.6433L10.0004 17.1797L5.31581 19.6433C4.97889 19.8205 4.5706 19.791 4.26262 19.5673C3.95464 19.3435 3.8004 18.9643 3.86475 18.5892L4.75917 13.3742L0.969289 9.68289C0.69667 9.41736 0.598398 9.02011 0.715775 8.6581C0.833153 8.2961 1.14583 8.03209 1.52239 7.97705L6.76109 7.21134L9.10365 2.46558C9.27212 2.12428 9.61974 1.9082 10.0004 1.9082ZM10.0004 5.16746L8.32206 8.56749C8.17652 8.86234 7.89534 9.0668 7.56998 9.11436L3.81543 9.66314L6.53142 12.3085C6.76729 12.5383 6.87496 12.8694 6.8193 13.1939L6.17854 16.9299L9.5349 15.1648C9.82629 15.0116 10.1744 15.0116 10.4658 15.1648L13.8222 16.9299L13.1814 13.1939C13.1258 12.8694 13.2334 12.5383 13.4693 12.3085L16.1853 9.66314L12.4307 9.11436C12.1054 9.0668 11.8242 8.86234 11.6786 8.56749L10.0004 5.16746Z"
            fill="currentColor"
        />
    </svg>
);
