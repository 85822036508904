import {
    DateValue,
    DateInputProps,
    DateInput as NextDateInput,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { parseDate } from "@internationalized/date";
import { I18nProvider } from "@react-aria/i18n";
import Collapsed from "components/Collapsed";

import "./index.module.css";

type Props = {
    value?: string;
    label?: string;
    errorMessage?: string;
    onChange: (v?: string) => void;
} & Omit<DateInputProps, "onChange" | "value">;

const ERROR_VANISH_DURATION = 300;
const DateInput = ({
    onBlur,
    onChange,
    label,
    value: val,
    errorMessage: errMsg,
    ...rest
}: Props) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [value, setValue] = useState<DateValue | undefined>(
        val ? parseDate(val) : undefined
    );

    const handleBlur = (e: any) => {
        if (
            value &&
            value.year !== undefined &&
            value.month !== undefined &&
            value.day !== undefined
        ) {
            const month = value.month.toString().padStart(2, "0");
            const year = value.year.toString().padEnd(4, "0");
            const day = value.day.toString().padStart(2, "0");

            const vvv = `${year}-${month}-${day}`;
            onChange?.(vvv);
            setValue(parseDate(vvv));
        } else {
            onChange?.(undefined);
        }

        onBlur?.(e);
    };

    useEffect(() => {
        if (val) {
            setValue(parseDate(val));
        } else {
            setValue(undefined);
        }
    }, [val]);

    useEffect(() => {
        if (!errMsg) {
            setTimeout(() => {
                setErrorMessage(undefined);
            }, ERROR_VANISH_DURATION);
        } else {
            setErrorMessage(errMsg);
        }
    }, [errMsg]);

    return (
        <div>
            {label && (
                <div className={`mb-2 text-sm text-default-500`}>{label}</div>
            )}

            <I18nProvider locale="sv-SE">
                <NextDateInput
                    classNames={{
                        // texts
                        label: "text-black text-sm mb-2",
                        errorMessage: "text-danger-500 text-sm mt-2",

                        // containers
                        base: "bg-[transparent]",
                        innerWrapper:
                            "bg-[transparent] h-auto focus:border-[#9753FC]",
                        segment: `                
                        data-[editable=true]:data-[placeholder=true]:text-default-400
                        text-black
                        text-default-400
                        focus:bg-default-100
                        group-data-[invalid=true]:text-default-400
                        group-data-[invalid=true]:focus:bg-default-100
                        data-[editable=true]:data-[placeholder=false]:text-danger-500
                    `,
                        input: `
                        text-black bg-white
                        p-[14px] border border-solid border-default-200 rounded-md
                        focus-within:border-[#9753FC]
                        focus-within:shadow-focus-ring
                        group-data-[invalid=true]:border-danger-500
                    `,
                        inputWrapper: `
                        shadow-none p-0 m-0 h-auto bg-[transparent] rounded-md
                        focus-within:hover:bg-transparent 
                        focus-within:hover:group-data-[invalid=true]:bg-transparent
                        hover:bg-[transparent]
                        hover:group-data-[invalid=true]:bg-transparent
                        group-data-[invalid=true]:bg-transparent
                    `,
                    }}
                    placeholderValue={value}
                    isInvalid={!!errorMessage}
                    onChange={setValue}
                    onBlur={handleBlur}
                    value={value}
                    {...rest}
                />
            </I18nProvider>

            <Collapsed isExpanded={!!errMsg} duration={ERROR_VANISH_DURATION}>
                <div className="mt-2 text-sm text-danger-500">
                    {errMsg || errorMessage}
                </div>
            </Collapsed>
        </div>
    );
};

export default DateInput;
