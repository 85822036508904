export type TabsGroupTab<T> = {
    label: string;
    value: T;
};

type Props<T> = {
    activeTab: T;
    tabs: Array<TabsGroupTab<T>>;
    onClick: (value: T) => void;
};

const TabsGroup = <T extends {}>({ onClick, activeTab, tabs }: Props<T>) => {
    return (
        <div className="flex flex-row gap-2 p-1 rounded-md bg-default-100">
            {tabs.map(({ label, value }) => (
                <div
                    key={value as any}
                    onClick={() => onClick(value)}
                    className={`cursor-pointer rounded-sm ${activeTab === value ? "bg-white" : ""} py-2 w-[152px] text-center text-sm`}
                >
                    {label}
                </div>
            ))}
        </div>
    );
};

export default TabsGroup;
