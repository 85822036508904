import { FC } from "react";
import LogoImg from "./images/logo.png";
import EmptyStateImg from "./images/mobile-empty-state.png";

const MobileEmptyState: FC = () => (
    <div className="max-w-screen flex h-screen max-h-screen w-screen flex-col overflow-hidden bg-default-25">
        <header className="p-4">
            <img src={LogoImg} alt="Vc Volt" className="h-12 w-12" />
        </header>
        <div className="-mt-20 flex h-full flex-col items-center justify-center px-4">
            <img
                src={EmptyStateImg}
                alt="Empty State"
                className="h-[180px] w-auto object-cover"
            />
            <h1 className="text-xl font-bold">Mobile coming soon</h1>
            <p className="text-center text-sm font-semibold text-default-500">
                Currently the platform is only available on desktops and
                tablets. Mobile version is in the making.
            </p>
        </div>
    </div>
);

export default MobileEmptyState;
