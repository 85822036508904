import { useDebounce } from "use-debounce";
import { useEffect, useState } from "react";

import Utils from "utils";
import { GeoSearchType } from "store/api/shared.types";
import { InputSearchResults } from "types/validation.types";
import { useSearchLocationsQuery } from "store/api/location";
import { CityEntity, CountryEntity } from "store/api/location/interfaces";

export const useLocationSearch = ({
    type,
    page = 0,
    perPage = 20,
    debounceDelay = 300,
    filterCountry,
}: {
    page?: number;
    perPage?: number;
    type: GeoSearchType;
    filterCountry?: string;
    debounceDelay?: number;
}) => {
    const [query, setQuery] = useState("");
    const [debouncedQuery] = useDebounce(query, debounceDelay);
    const [parsedData, setParsedData] = useState<InputSearchResults>([]);

    const { data, error, isLoading } = useSearchLocationsQuery({
        search: debouncedQuery,
        perPage: type === "city" ? perPage : undefined,
        page: type === "city" ? page : undefined,
        type,
        filterCountry,
    });

    useEffect(() => {
        const locations = data?.data;

        if (!locations?.length) {
            setParsedData([]);
            return;
        }

        if (type === "country") {
            setParsedData(
                (locations as Array<CountryEntity>).map((item) => ({
                    value: item,
                    label: item,
                }))
            );
        } else if (!filterCountry) {
            setParsedData(
                (locations as Array<CityEntity>).map((item) => ({
                    value: item.id,
                    label: Utils.Parsers.getReadableLocation(item),
                }))
            );
        } else {
            setParsedData(
                (locations as Array<CityEntity>).map((item) => ({
                    value: item.city,
                    label: item.city,
                }))
            );
        }
    }, [type, filterCountry, data]);

    return { data: parsedData, error, isLoading, query, setQuery };
};
