import { ReactNode, useEffect, useState } from "react";

type Props = {
    parentValue: any;
    onHide?: () => void;
    children: ReactNode;
    childConditions: Array<any>;
};

const Wrapper = ({ children, onHide, isVisible }: any) => {
    useEffect(() => {
        return () => {
            onHide?.();
        };
    }, []);

    return (
        <div
            onTransitionEnd={(e) => {
                if (e.propertyName === "opacity" && !isVisible) {
                    onHide?.();
                }
            }}
            className={`transition-all duration-500 ease-in-out ${isVisible ? "max-h-screen opacity-100" : "max-h-0 overflow-hidden opacity-0"} w-[100%]`}
        >
            {children}
            <div className="h-[48px]" />
        </div>
    );
};

const ChildQuestionRenderer = ({
    onHide,
    children,
    parentValue,
    childConditions,
}: Props) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (childConditions.includes(parentValue)) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [parentValue, childConditions]);

    return (
        <Wrapper isVisible={isVisible} onHide={onHide}>
            {children}
        </Wrapper>
    );
};

export default ChildQuestionRenderer;
