import { AvatarProps, Avatar as NextAvatar, Tooltip } from "@nextui-org/react";

import Utils from "utils";
import Icon from "components/Icon";
import { cn } from "lib/utils";
import { useMemo } from "react";

type FallbackType = "initials" | "name" | "icon";

type Props = Pick<
    AvatarProps,
    | "src"
    | "size"
    | "style"
    | "fallback"
    | "className"
    | "isDisabled"
    | "classNames"
> & {
    name?: string;
    firstName?: string;
    lastName?: string;
    disableTooltip?: boolean;
    fallbackType?: FallbackType;
    memberId?: string;
};

const Avatar: React.FC<Props> = ({
    name,
    lastName,
    className,
    firstName,
    disableTooltip,
    fallbackType = "initials",
    memberId,
    style,
    isDisabled,
    ...rest
}) => {
    if (name && !firstName && !lastName) {
        const [fName, lName] = name.split(" ");
        firstName = fName;
        lastName = lName;
    }

    const initials = Utils.Parsers.getInitials(firstName + " " + lastName);

    const fallbackGradient = useMemo(
        () => (memberId ? Utils.Avatar.getAvatarColorByUuid(memberId) : null),
        [memberId]
    );

    return (
        <Tooltip
            showArrow
            radius="sm"
            color="foreground"
            isDisabled={disableTooltip || (!firstName && !lastName)}
            content={
                firstName || lastName ? `${firstName} ${lastName}` : undefined
            }
        >
            <NextAvatar
                fallback={
                    fallbackType === "icon" ? (
                        <Icon
                            type="User"
                            size={20}
                            className={cn(
                                "text-default-400",
                                isDisabled && "opacity-40"
                            )}
                        />
                    ) : (
                        <span
                            className={cn(
                                "text-xs",
                                isDisabled && "opacity-40"
                            )}
                        >
                            {fallbackType === "name" ? name : initials}
                        </span>
                    )
                }
                alt={initials}
                {...rest}
                showFallback
                className={cn(
                    "bg-default-50",
                    "ring-white ring-offset-0",
                    className
                )}
                classNames={{
                    img: cn(isDisabled && "[loaded=true]:opacity-40"),
                }}
                style={{
                    ...(fallbackGradient && {
                        background: `linear-gradient(90deg, ${fallbackGradient.from}, ${fallbackGradient.to})`,
                    }),
                    ...style,
                }}
                isBordered
            />
        </Tooltip>
    );
};

export default Avatar;
