import React, { useCallback, useEffect, useRef, useState } from "react";
import ButtonIcon from "components/ButtonIcon";
import Icon from "components/Icon";
import { cn } from "lib/utils";

export enum AllowedFileTypes {
    Video = "video/*",
    PDF = "application/pdf",
    Image = "image/png,image/jpg,image/jpeg,image/webp,image/heic,image/heif",
    All = "image/*,video/*,application/pdf",
}

type Props = {
    errorMessage?: string;
    label?: string;
    uploadLabel?: string;
    selectedFile: any;
    multiple?: boolean;
    className?: string;
    fileTypes: AllowedFileTypes;
    onBlur?: () => void;
    setFiles: (file: File | File[] | null) => void;
    placeholder?: string;
};

const FileInput = ({
    label,
    onBlur,
    setFiles,
    multiple,
    className,
    fileTypes,
    placeholder,
    selectedFile,
    errorMessage,
}: Props) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const [isDragActive, setIsDragActive] = useState<boolean>(false);
    const [previewUrl, setPreviewUrl] = useState<string>("");
    const [previewType, setPreviewType] = useState<string>("");
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleDrag = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setIsDragActive(true);
        } else if (e.type === "dragleave") {
            setIsDragActive(false);
        }
    };

    const handleClear = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        setFiles(multiple ? [] : null);
        onBlur?.();
        setPreviewUrl("");
        setPreviewType("");
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    };

    const handleFileChange = (file: File | Object) => {
        if (file instanceof File) {
            if (file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    if (e.target?.result) {
                        setPreviewUrl(e.target.result as string);
                        setPreviewType("image");
                    }
                };
                reader.readAsDataURL(file);
            } else if (file.type.startsWith("video/")) {
                setPreviewUrl(URL.createObjectURL(file));
                setPreviewType("video");
            }
        } else if (typeof file === "object" && file !== null) {
            setPreviewUrl((file as any).url);
            setPreviewType("image");
        }
    };

    const onDrop = useCallback(
        (e: React.DragEvent<HTMLLabelElement>) => {
            e.preventDefault();
            setIsDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                const files = e.dataTransfer.files;
                setFiles(files[0]);
                onBlur?.();
                handleFileChange(files[0]);
                e.dataTransfer.clearData();
            }
        },
        [setFiles]
    );

    const onChange = useCallback(
        (e: any) => {
            e.preventDefault();
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                setFiles(file);
                onBlur?.();
                handleFileChange(file);
            }
        },
        [setFiles]
    );

    useEffect(() => {
        if (selectedFile) {
            handleFileChange(selectedFile);
        }
    }, []);

    return (
        <div>
            {label && (
                <div className={`mb-2 text-sm text-default-500`}>{label}</div>
            )}

            <div
                className={`flex !h-[140px] w-full flex-col gap-2 ${className}`}
            >
                <label
                    onDrop={onDrop}
                    onChange={onChange}
                    htmlFor="dropzone-file"
                    onDragOver={handleDrag}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    className={cn(
                        "round flex h-max w-full flex-1 cursor-pointer flex-col overflow-hidden rounded-md rounded-tr-md border border-solid border-default-200 bg-white shadow-drag-and-drop-input",
                        !previewUrl &&
                            "hover:border-2 hover:border-primary-400",
                        "focus:border-2 focus:border-primary-400 focus:outline focus:outline-2 focus:outline-primary-300",
                        isDragActive &&
                            "border-2 border-primary-400 bg-primary-50",
                        previewUrl && "hover:bg-default-950/20"
                    )}
                >
                    {previewUrl &&
                    (previewType === "image" || previewType === "video") ? (
                        <div className="relative flex h-full w-auto justify-center">
                            <img
                                src={previewUrl}
                                className="h-full w-auto object-contain"
                            />
                            {isHovering && (
                                <ButtonIcon
                                    className="absolute right-3 top-3"
                                    onClick={handleClear}
                                    icon="Trash2"
                                />
                            )}
                        </div>
                    ) : (
                        <div className="mx-[14px] my-[23.5px] flex flex-1 flex-col items-center justify-center gap-2">
                            <Icon
                                size={16}
                                type="Image"
                                className="text-default-400"
                            />
                            <div>
                                <p className="text-center text-sm text-black">
                                    {placeholder || "Drag or upload an image"}
                                </p>
                                <p className="text-center text-xs text-default-400">
                                    5MB max file size
                                </p>
                            </div>
                        </div>
                    )}
                    <input
                        ref={inputRef}
                        type="file"
                        id="dropzone-file"
                        accept={fileTypes}
                        multiple={multiple}
                        className="hidden h-0"
                    />
                </label>
            </div>
            {errorMessage && (
                <div className="text-sm text-danger-500">{errorMessage}</div>
            )}
        </div>
    );
};

export default FileInput;
