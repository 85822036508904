import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
    MemberOnboardingActions,
    MemberOnboardingSelectors,
} from "store/reducers/memberOnboarding";
import {
    useGetOnboardingQuery,
    useSaveOnboardingMutation,
} from "store/api/onboarding";
import Utils from "utils";
import Input from "components/Input";
import Collapsed from "components/Collapsed";
import GridSelect from "components/GridSelect";
import InputSelect from "components/InputSelect";
import { InputSearchResults } from "types/validation.types";
import { useLocationSearch } from "hooks/useLocationSearch";
import BottomButtons from "routes/onboarding/components/BottomButtons";
import { MemberOnboarding } from "store/reducers/memberOnboarding/types";
import { useEffect } from "react";

type Props = {
    title: string;
    handleNextClick: () => void;
    handleBackClick: () => void;
};

const schema = yup.object({
    locationCountry: Utils.Validations.searchOption,
    locationCity: Utils.Validations.searchOption,
    gender: yup.string().required("Required"),
    yearOfBirth: Utils.Validations.yearInput,
    nationality: Utils.Validations.searchOptionArray,
    genderDescription: yup.string().when("gender", {
        is: "Prefer to self-describe",
        then: () => yup.string().required("Required"),
        otherwise: () => yup.string(),
    } as any),
});

const GENDERS = [
    {
        value: "Male",
        label: "Male",
    },
    {
        value: "Female",
        label: "Female",
    },
    {
        value: "Non-binary",
        label: "Non-binary",
    },
    {
        value: "Wish not to specify",
        label: "Wish not to specify",
    },
    {
        value: "Prefer to self-describe",
        label: "Prefer to self-describe",
    },
];

const parseDefaultValues = (
    {
        gender,
        locationCountry,
        locationCity,
        nationality,
        yearOfBirth,
        genderDescription,
    }: MemberOnboarding.DetailsNew,
    data?: Partial<MemberOnboarding.DetailsNew>
): MemberOnboarding.DetailsNew => ({
    gender: gender || data?.gender,
    locationCountry: (locationCountry || data?.locationCountry) ?? null,
    locationCity: (locationCity || data?.locationCity) ?? null,
    nationality: nationality?.length ? nationality : data?.nationality,
    yearOfBirth: yearOfBirth || data?.yearOfBirth || "",
    genderDescription: genderDescription || data?.genderDescription || "",
});

const DetailsStep = ({ handleBackClick, handleNextClick, title }: Props) => {
    const dispatch = useDispatch();
    const defaultValues = useSelector(
        MemberOnboardingSelectors.state("detailsNew")
    );
    const { data: savedOnboarding } = useGetOnboardingQuery();
    const [saveOnboarding, saveOnboardingPayload] = useSaveOnboardingMutation();
    const { data: nationalityData, setQuery: setNationalityQuery } =
        useLocationSearch({ type: "country" });
    const { data: countryData, setQuery: setCountryQuery } = useLocationSearch({
        type: "country",
    });

    const {
        watch,
        control,
        handleSubmit,
        formState: { isValid },
        setValue,
        getFieldState,
        clearErrors,
    } = useForm({
        defaultValues: parseDefaultValues(
            defaultValues,
            savedOnboarding?.data["member.detailsNew"]
        ),
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema) as any,
    });
    const { gender: watchedGender, locationCountry: wLocationCountry } =
        watch();

    const { data: cityData, setQuery: setCityQuery } = useLocationSearch({
        type: "city",
        filterCountry: wLocationCountry?.value,
    });

    useEffect(() => {
        const { isDirty: isLocationCountryDirty } =
            getFieldState("locationCountry");
        if (!isLocationCountryDirty) {
            return;
        }
        setValue("locationCity", null);
        clearErrors("locationCity");
    }, [wLocationCountry, getFieldState, setValue, clearErrors, setCityQuery]);

    const onSubmit = (details: MemberOnboarding.DetailsNew) => {
        dispatch(MemberOnboardingActions.setDetails(details));
        saveOnboarding({ "member.detailsNew": details });
        handleNextClick();
    };

    return (
        <div>
            <div className="mb-10 text-2xl font-semibold">{title}</div>

            <div className="flex flex-col gap-5">
                <div>
                    <Controller
                        name="gender"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <GridSelect
                                label="Gender"
                                options={GENDERS}
                                selected={value ? [value] : []}
                                onSelect={(e) => onChange(e[0])}
                            />
                        )}
                    />

                    <Collapsed
                        isExpanded={watchedGender === "Prefer to self-describe"}
                    >
                        <div className="mt-[20px]">
                            <Controller
                                control={control}
                                name="genderDescription"
                                render={({
                                    fieldState: { error },
                                    field: { value, onChange, onBlur },
                                }) => (
                                    <Input
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        label="Gender description"
                                        errorMessage={error?.message}
                                        placeholder="Describe your gender"
                                    />
                                )}
                            />
                        </div>
                    </Collapsed>
                </div>

                <Controller
                    name="yearOfBirth"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { value, onChange, onBlur },
                    }) => (
                        <Input
                            type="number"
                            value={value}
                            onBlur={onBlur}
                            placeholder="yyyy"
                            label="Year of birth"
                            errorMessage={error?.message}
                            onChange={(e) => onChange(e.target.value)}
                        />
                    )}
                />

                <Controller
                    name="nationality"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { value, onChange, onBlur },
                    }) => (
                        <InputSelect<InputSearchResults[0]>
                            isMulti
                            onBlur={onBlur}
                            label="Nationality"
                            selected={value as any}
                            handleSelect={onChange}
                            options={nationalityData}
                            defaultValue={value as any}
                            errorMessage={error?.message}
                            handleInputChange={setNationalityQuery}
                            getOptionValue={(option) => option?.value}
                            renderOptionLabel={(option) => option.label}
                            placeholder="Type to search, select one or multiple"
                        />
                    )}
                />

                <Controller
                    name="locationCountry"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { value, onChange, onBlur },
                    }) => {
                        return (
                            <InputSelect<InputSearchResults[0]>
                                isMulti={false}
                                onBlur={onBlur}
                                options={countryData}
                                handleSelect={onChange}
                                selected={value as any}
                                defaultValue={value as any}
                                errorMessage={error?.message}
                                label="Country"
                                handleInputChange={setCountryQuery}
                                getOptionValue={(option) => option?.value}
                                renderOptionLabel={(option) => option.label}
                                placeholder="Type country name to search and select"
                            />
                        );
                    }}
                />

                <Controller
                    name="locationCity"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { value, onChange, onBlur },
                    }) => {
                        return (
                            <InputSelect<InputSearchResults[0]>
                                disabled={!wLocationCountry}
                                isMulti={false}
                                onBlur={onBlur}
                                options={cityData}
                                handleSelect={onChange}
                                selected={value as any}
                                defaultValue={value as any}
                                errorMessage={error?.message}
                                label="City"
                                handleInputChange={setCityQuery}
                                getOptionValue={(option) => option?.value}
                                renderOptionLabel={(option) => option.label}
                                placeholder="Type city name to search and select"
                            />
                        );
                    }}
                />

                <BottomButtons
                    onBack={handleBackClick}
                    onNext={handleSubmit(onSubmit)}
                    disableNext={!isValid || saveOnboardingPayload.isLoading}
                />
            </div>
        </div>
    );
};

export default DetailsStep;
