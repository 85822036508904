import {
    InsightsTeam,
    HumanCapitalSkillsTop3,
    InsightsCompany,
} from "store/api/investorCompany/interfaces";
import ContainerWrapper from "../../components/ContainerWrapper";
import HCGridSelectCard from "../../components/HCGridSelectCard";
import Icon from "components/Icon";

type Props = {
    team: InsightsTeam;
    company: InsightsCompany;
    tooltip?: string;
};

const IndustryExperienceCard: React.FC<Props> = ({
    team,
    company,
    tooltip,
}) => {
    const data = team?.["hc-previous-industry"];
    const dataValue: HumanCapitalSkillsTop3 =
        (data?.value as HumanCapitalSkillsTop3) ?? {};
    const isEmptyInsight: boolean = !data;

    const companyIndustryValue = company?.["company-industry"]?.value;
    const companyIndustry =
        typeof companyIndustryValue === "string" ? companyIndustryValue : "";

    let hasCompanyIndustrySkill: boolean = false;
    const skills: Array<{
        title: string;
        members: number;
        isCompanyIndustry: boolean;
    }> = Object.entries(dataValue)
        .map(([key, value]) => {
            const isCompanyIndustry = key === companyIndustry;
            if (isCompanyIndustry) {
                hasCompanyIndustrySkill = true;
            }
            return {
                title: key,
                members: value.length,
                isCompanyIndustry,
            };
        })
        .sort((a, b) => b.members - a.members) as any;

    return (
        <ContainerWrapper
            name="Industry experience"
            iconType="BriefcaseBusiness"
            tooltip={tooltip}
            isEmptyInsight={isEmptyInsight}
        >
            <div className="flex flex-wrap gap-3">
                {skills.map(({ title, members, isCompanyIndustry }) => (
                    <HCGridSelectCard
                        key={title}
                        type="outlined"
                        title={title}
                        number={members}
                        asterisk={isCompanyIndustry}
                    />
                ))}
            </div>
            {hasCompanyIndustrySkill && (
                // By design wrapper should have gap-5 but for some unknown reason it's gap-2 so adding mt-3 to compensate and not break the rest of the page
                <div className="mt-3 flex items-center justify-start gap-x-1">
                    <Icon className="text-secondary-500" type="Asterisk" />
                    <span className="text-sm font-semibold text-default-400">
                        Company industry
                    </span>
                </div>
            )}
        </ContainerWrapper>
    );
};

export default IndustryExperienceCard;
