import {
    ChangeEventHandler,
    FC,
    FocusEventHandler,
    ReactNode,
    useCallback,
    useEffect,
} from "react";
import { MaskProps, useMask } from "@react-input/mask";
import { cn } from "lib/utils";
import Collapsed from "components/Collapsed";

const ERROR_VANISH_DURATION = 300;

type Props = {
    label?: string;
    value: string;
    onRef: (ref: React.MutableRefObject<HTMLInputElement | null>) => void;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    selectionHandler?: (
        selectionStart: number | null,
        selectionEnd: number | null
    ) => void;
    readOnly?: boolean;
    disabled?: boolean;
    errorMessage?: string;
    inputClassName?: string;
    inputWrapperClassName?: string;
    children?: ReactNode;
} & MaskProps;

const InputMask: FC<Props> = ({
    mask,
    replacement,
    showMask,
    separate,
    track,
    label,
    value,
    onRef,
    onChange,
    onBlur,
    onKeyDown,
    selectionHandler,
    readOnly = false,
    disabled = false,
    errorMessage,
    inputClassName,
    inputWrapperClassName,
    children,
}) => {
    const inputRef = useMask({
        mask,
        replacement,
        showMask,
        separate,
        track,
    });

    useEffect(() => {
        onRef?.(inputRef);
    }, [onRef, inputRef]);

    const getSelection = useCallback((): {
        selectionStart: number | null;
        selectionEnd: number | null;
    } => {
        return {
            selectionStart: inputRef?.current?.selectionStart ?? null,
            selectionEnd: inputRef?.current?.selectionEnd ?? null,
        };
    }, [inputRef]);

    const handleSelect = useCallback(() => {
        if (!selectionHandler) {
            return;
        }
        const selection = getSelection();
        selectionHandler(selection.selectionStart, selection.selectionEnd);
    }, [selectionHandler, getSelection]);

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        handleSelect();
        onKeyDown?.(e);
    };

    const keyUpHandler = () => {
        handleSelect();
    };

    const clickHandler = () => {
        handleSelect();
    };

    return (
        <div
            className={cn(
                "relative flex h-full w-full flex-col",
                inputWrapperClassName
            )}
        >
            {label && (
                <label className="mb-2 text-sm text-default-500">{label}</label>
            )}
            <div className="relative">
                <input
                    ref={inputRef}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={clickHandler}
                    onKeyDown={keyDownHandler}
                    onKeyUp={keyUpHandler}
                    placeholder={mask}
                    className={cn(
                        // base
                        "!self-stretch bg-[transparent]",
                        // input
                        "placeholder:text-default-400",
                        disabled && "!self-stretch !text-default-400",
                        inputClassName,
                        // innerWrapper
                        "h-auto w-[100%] !self-stretch bg-[transparent] text-sm focus:border-[#9753FC]",
                        // inputWrapper
                        "h-[unset] min-h-[unset] rounded-md border border-solid border-default-200 bg-white p-[14px] shadow-sm",
                        "hover:bg-white",
                        "hover:!border-[#9753FC]",
                        `hover:${readOnly ? "border-none" : "border-primary-500"}`,
                        "focus:bg-white",
                        "focus:shadow-focus-ring",
                        "hover:group-data-[invalid=true]:bg-transparent",
                        "hover:group-data-[invalid=true]:border-[#9753FC]",
                        "focus-visible:ring-0",
                        "focus-visible:outline-none",
                        `focus-within:${readOnly ? "border-none" : "border-[#9753FC]"}`,
                        "group-data-[invalid=true]:!bg-white",
                        "!self-stretch",
                        disabled &&
                            "!self-stretch border-none !bg-default-50 !text-default-400",
                        readOnly && "!self-stretch !bg-default-50",
                        // errorMessage
                        errorMessage &&
                            "!border-danger-500 hover:!border-danger-500"
                    )}
                />
                {children}
            </div>
            <Collapsed
                isExpanded={!!errorMessage}
                duration={ERROR_VANISH_DURATION}
            >
                <div className="mt-2 text-sm text-danger-500">
                    {errorMessage}
                </div>
            </Collapsed>
        </div>
    );
};

export default InputMask;
