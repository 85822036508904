import {
    TeamResponse,
    CompanyResponse,
    ProfileResponse,
    DeleteMemberRequest,
    DeleteMemberResponse,
    UpdateCompanyRequest,
    UpdateProfileRequest,
    UpdateInvestorProfile,
    InvestorProfileResponse,
    SendSurveyReminderRequest,
    SendSurveyReminderResponse,
} from "./interfaces";
import { api } from "../api";

const memberApi = api.injectEndpoints({
    endpoints: (builder) => ({
        company: builder.query<CompanyResponse, void>({
            query: () => "/app/member/company",
            providesTags: ["Company"],
        }),
        updateCompany: builder.mutation<CompanyResponse, UpdateCompanyRequest>({
            query: (body) => ({
                url: "/app/member/company",
                method: "PATCH",
                body: JSON.stringify(body),
            }),
            invalidatesTags: (result) => (result?.ok ? ["Company"] : []),
        }),
        deleteMember: builder.mutation<
            DeleteMemberResponse,
            DeleteMemberRequest
        >({
            query: ({ memberId }) => ({
                url: `/app/member/team/members/${memberId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Team"],
        }),
        team: builder.query<TeamResponse, void>({
            query: () => "/app/member/team",
            providesTags: ["Team"],
        }),
        profile: builder.query<ProfileResponse, void>({
            query: () => "/app/member/profile",
            providesTags: ["Profile"],
        }),
        investorProfile: builder.query<InvestorProfileResponse, void>({
            query: () => "/app/investor/profile",
            providesTags: ["Profile"],
        }),
        updateProfile: builder.mutation<ProfileResponse, UpdateProfileRequest>({
            query: (body) => ({
                url: "/app/member/profile",
                method: "PATCH",
                body: JSON.stringify(body),
            }),
            invalidatesTags: (result) =>
                result?.ok ? ["Profile", "Me", "Team"] : [],
        }),
        updateInvestorProfile: builder.mutation<
            InvestorProfileResponse,
            UpdateInvestorProfile
        >({
            query: (body) => ({
                url: "/app/investor/profile",
                method: "PATCH",
                body: JSON.stringify(body),
            }),
            invalidatesTags: (result) => (result?.ok ? ["Profile", "Me"] : []),
        }),
        sendSurveyReminder: builder.mutation<
            SendSurveyReminderResponse,
            SendSurveyReminderRequest
        >({
            query: ({ id }) => ({
                url: `/app/member/team/members/${id}/survey-reminder`,
                method: "POST",
            }),
        }),
    }),
});

export const {
    useTeamQuery,
    useCompanyQuery,
    useProfileQuery,
    useDeleteMemberMutation,
    useInvestorProfileQuery,
    useUpdateCompanyMutation,
    useUpdateProfileMutation,
    useSendSurveyReminderMutation,
    useUpdateInvestorProfileMutation,
} = memberApi;
