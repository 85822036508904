import { Dispatch, FC } from "react";
import {
    InsightsIndividual,
    InsightsTeam,
} from "store/api/investorCompany/interfaces";
import InsightStatus from "../InsightStatus";
import IndividualScores from "routes/company/components/IndividualScores";
import Title from "routes/company/components/Modal/Title";
import InsightDescription from "routes/company/components/Modal/InsightDescription";
import TypesOfPassion from "../TypesOfPassion";
import { TypesOfPassionControllerData } from "../useTypesOfPassionController";
import { cn } from "lib/utils";
import ButtonIcon from "components/ButtonIcon";

type Props = {
    teamData: InsightsTeam;
    individualData: InsightsIndividual;
    typesOfPassionData: Array<TypesOfPassionControllerData>;
    currIdx: number;
    setCurrIdx: Dispatch<React.SetStateAction<number>>;
    controlHandler: (shift: -1 | 1) => void;
    tooltip: string;
};

const TypesOfPassionModal: FC<Props> = ({
    tooltip,
    teamData,
    individualData,
    typesOfPassionData,
    currIdx,
    setCurrIdx,
    controlHandler,
}) => {
    const currData = typesOfPassionData[currIdx];

    const currDataValue = (currData?.value as number) ?? 0;

    return (
        <div className="flex flex-col gap-4">
            <Title tooltip={tooltip}>Types of passion</Title>

            <div className="mx-auto grid w-4/5 grid-cols-8 items-center justify-center gap-x-12 py-8">
                <div className="col-span-5 flex flex-col text-sm md:col-span-4">
                    {typesOfPassionData.map((item, idx) => (
                        <button
                            type="button"
                            key={idx}
                            className={cn(
                                "flex items-center justify-between rounded-sm px-2 py-1.5",
                                currIdx === idx && "bg-default-50"
                            )}
                            onClick={() => setCurrIdx(idx)}
                        >
                            <div className="flex items-center justify-center gap-x-2">
                                <div
                                    className="size-2 rounded-[2px] bg-default-400"
                                    style={{
                                        backgroundColor: item.fill,
                                    }}
                                />
                                <span>{item.label}</span>
                            </div>
                            <span>{item.value?.toFixed(1)}</span>
                        </button>
                    ))}
                </div>
                <div className="col-span-3 flex items-center justify-center md:col-span-4">
                    <TypesOfPassion
                        teamData={teamData}
                        className="h-[200px] w-[200px]"
                    />
                </div>
            </div>

            <div>
                <div className="flex items-center justify-between">
                    <div className="flex flex-row items-center gap-2">
                        <span>{currData?.label}</span>
                        <InsightStatus value={currDataValue} />
                    </div>

                    <div className="flex flex-row items-center gap-x-1">
                        <ButtonIcon
                            icon="ChevronLeft"
                            customType="light"
                            className="cursor-pointer"
                            onClick={() => controlHandler(-1)}
                        />
                        <ButtonIcon
                            icon="ChevronRight"
                            customType="light"
                            className="cursor-pointer"
                            onClick={() => controlHandler(1)}
                        />
                    </div>
                </div>

                <InsightDescription>
                    {currData?.data?.conclusion?.text ?? ""}
                </InsightDescription>
            </div>

            <IndividualScores
                individualData={individualData}
                teamMetricValue={currData.value}
                metric={currData.key}
            />
        </div>
    );
};

export default TypesOfPassionModal;
