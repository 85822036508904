import { useMemo } from "react";

import Table from "./components/Table";
import Avatar from "components/Avatar";
import ContainerWrapper from "../../components/ContainerWrapper";
import {
    InsightsIndividual,
    InsightsIndividualMember,
} from "store/api/investorCompany/interfaces";
import Utils from "utils";
import AvatarGroup from "components/AvatarGroup";

type Props = {
    individual: InsightsIndividual;
    tooltip?: string;
};

const SkillsMatrixCard: React.FC<Props> = ({ individual, tooltip }) => {
    const array = useMemo(() => {
        const result: Array<Array<Array<InsightsIndividualMember>>> = [
            [],
            [],
            [],
            [],
            [],
        ];

        Object.values(individual).map((user) => {
            const exitExperienceIndex = (user.hc_exit_exp?.value as number)
                ? (user.hc_exit_exp.value as number) - 1
                : undefined;
            const internationalExperience = (user.hc_international_exp
                ?.value as number)
                ? (user.hc_international_exp.value as number) - 1
                : undefined;
            const newMarketsExperience = (user.hc_new_market_exp
                ?.value as number)
                ? (user.hc_new_market_exp.value as number) - 1
                : undefined;
            const newProductExperience = (user.hc_new_product_exp
                ?.value as number)
                ? (user.hc_new_product_exp.value as number) - 1
                : undefined;
            const dataDrivenMentality = (user.hc_data_mentality
                ?.value as number)
                ? (user.hc_data_mentality.value as number) - 1
                : undefined;

            if (exitExperienceIndex !== undefined)
                if (result[0][exitExperienceIndex]) {
                    result[0][exitExperienceIndex].push(user);
                } else {
                    result[0][exitExperienceIndex] = [user];
                }

            if (internationalExperience !== undefined)
                if (result[1][internationalExperience]) {
                    result[1][internationalExperience].push(user);
                } else {
                    result[1][internationalExperience] = [user];
                }

            if (newMarketsExperience !== undefined)
                if (result[2][newMarketsExperience]) {
                    result[2][newMarketsExperience].push(user);
                } else {
                    result[2][newMarketsExperience] = [user];
                }

            if (newProductExperience !== undefined)
                if (result[3][newProductExperience]) {
                    result[3][newProductExperience].push(user);
                } else {
                    result[3][newProductExperience] = [user];
                }

            if (dataDrivenMentality !== undefined)
                if (result[4][dataDrivenMentality]) {
                    result[4][dataDrivenMentality].push(user);
                } else {
                    result[4][dataDrivenMentality] = [user];
                }
        });

        return result;
    }, [individual]);

    const isEmptyInsight = useMemo(() => array.flat().length === 0, [array]);

    return (
        <ContainerWrapper
            iconType="LandPlot"
            name="Skills matrix"
            tooltip={tooltip}
            isEmptyInsight={isEmptyInsight}
        >
            <Table<Array<InsightsIndividualMember>>
                rowLabelClassName="!w-[110px]"
                cellClassName="p-2 pl-[12px] lg:pl-[20px] !w-[74px] h-[128px] lg:h-[92px] lg:!w-[144px]"
                columnRender={(users) => {
                    if (!users?.length) return undefined;

                    const usersParsed = Object.entries(users)
                        .sort(Utils.Insight.membersRoleNameCompareFn)
                        .map(([, individualData]) =>
                            Utils.Insight.getMemberInfoFromInsight(
                                individualData
                            )
                        );

                    return (
                        <>
                            <AvatarGroup
                                max={5}
                                size="sm"
                                className="lg:hidden"
                                variant="multiline"
                            >
                                {usersParsed.map(
                                    (
                                        {
                                            memberId,
                                            firstName,
                                            lastName,
                                            picture,
                                        },
                                        i
                                    ) => (
                                        <Avatar
                                            key={i}
                                            memberId={memberId}
                                            firstName={firstName}
                                            lastName={lastName}
                                            src={picture}
                                        />
                                    )
                                )}
                            </AvatarGroup>

                            <AvatarGroup
                                max={7}
                                size="sm"
                                className="max-lg:hidden"
                                variant="multiline"
                            >
                                {usersParsed.map(
                                    (
                                        {
                                            memberId,
                                            firstName,
                                            lastName,
                                            picture,
                                        },
                                        i
                                    ) => (
                                        <Avatar
                                            key={i}
                                            memberId={memberId}
                                            firstName={firstName}
                                            lastName={lastName}
                                            src={picture}
                                        />
                                    )
                                )}
                            </AvatarGroup>
                        </>
                    );
                }}
                columnColors={[
                    "bg-[#fdf9ff80]",
                    "bg-[#fcf3ff99]",
                    "bg-secondary-50",
                    "bg-secondary-100",
                    "bg-secondary-200",
                ]}
                data={array}
                rows={[
                    "Exit experience",
                    "International experience",
                    "New markets experience",
                    "New products experience",
                    "Data-driven mentality",
                ]}
                columns={[
                    "No experience",
                    undefined,
                    undefined,
                    undefined,
                    "Extensive experience",
                ]}
            />
        </ContainerWrapper>
    );
};

export default SkillsMatrixCard;
