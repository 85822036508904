import { CircularProgress, CircularProgressSlots } from "@nextui-org/react";

import Icon from "components/Icon";

export type ProgressStatusType = "primary" | "light";

const STYLE_TO_TYPE: Record<
    ProgressStatusType,
    Partial<Record<CircularProgressSlots, string>>
> = {
    primary: {
        track: "stroke-primary-500",
        indicator: "stroke-primary-200",
    },
    light: {
        track: "stroke-[#E3E4E9]",
        indicator: "stroke-[white]",
    },
};

const STYLE_TO_BACKGROUND: Record<ProgressStatusType, string> = {
    primary: "bg-primary-50",
    light: "bg-white/25",
};

const STYLE_TO_ICON_COLOR: Record<ProgressStatusType, string> = {
    primary: "#fff", // text-white
    light: "#0F1117", // text-black
};

const STYLE_TO_ICON_BACKGROUND: Record<ProgressStatusType, string> = {
    primary: "bg-primary-500",
    light: "bg-white",
};

const STYLE_TO_ICON_LABEL: Record<ProgressStatusType, string> = {
    primary: "text-primary-500",
    light: "text-white",
};

type Props = {
    className?: string;
    progress: number;
    type: ProgressStatusType;
};

const ProgressStatus = ({ className, progress, type }: Props) => {
    const isCompleted = progress === 1;
    const isNotStarted = progress === 0;
    const calcProgress = (progress * 100) | 0;

    return (
        <div
            className={`flex flex-row items-center ${STYLE_TO_BACKGROUND[type]} rounded-xl px-3 py-1 gap-1 ${className}`}
        >
            <div className="relative flex items-center justify-center">
                <CircularProgress
                    strokeWidth={4}
                    value={100 - calcProgress}
                    classNames={{
                        svg: "w-[20px] h-[20px]",
                        track: STYLE_TO_TYPE[type].track,
                        indicator: STYLE_TO_TYPE[type].indicator,
                    }}
                />
                {isCompleted && (
                    <div
                        className={`absolute rounded-full ${STYLE_TO_ICON_BACKGROUND[type]}`}
                    >
                        <Icon
                            size={14}
                            type="CCheckXS"
                            fill={STYLE_TO_ICON_COLOR[type]}
                        />
                    </div>
                )}
            </div>

            <div className={`text-sm ${STYLE_TO_ICON_LABEL[type]}`}>
                {isCompleted
                    ? "Completed"
                    : isNotStarted
                      ? "Not started"
                      : calcProgress + "% completed"}
            </div>
        </div>
    );
};

export default ProgressStatus;
