import Icon from "components/Icon";

type Props = {
    optionProps: any;
    isMulti?: boolean;
    isSelected: boolean;
    label: string | number;
};

const SearchOption = ({ optionProps, label, isMulti, isSelected }: Props) => {
    return (
        <li
            {...optionProps}
            className={`
             swiper-no-swiping !rounded-sm !p-[10px] flex items-center
             ${isSelected && "!bg-default-50"} hover:!bg-default-50 active:!bg-default-50 focus:!bg-default-50`}
        >
            {!isMulti ? (
                <>
                    <span>{label}</span>
                    {isSelected ? (
                        <Icon
                            type={"Check"}
                            fontSize="small"
                            className="!text-black"
                        />
                    ) : undefined}
                </>
            ) : (
                <>
                    <div className="relative">
                        <div className="border-solid border-black h-[20px] w-[20px] flex justify-center items-center rounded-[5px] border-[1.5px]">
                            {isSelected ? (
                                <Icon type="Check" className="!text-black" />
                            ) : undefined}
                        </div>
                        {/* <CheckBox
                            disabled
                            readOnly
                            onClick={undefined}
                            isSelected={true}
                            className="!bg-transparent z-0   custom-checkbox"
                            classNames={{
                                icon: isSelected
                                    ? "!text-black  "
                                    : "invisible  ",
                                label: !isSelected && "!bg-transparent  ",
                                wrapper:
                                    !isSelected &&
                                    "!bg-transparent group-data-[hover=true]:before:bg-[transparent]   ",
                                base: "  ",
                            }}
                        /> */}
                        {/* this is component is overlapping the CheckBox because
                        dropdown collapses on pressing the checkbox */}
                        {/* <div className="absolute bg-transparent z-1000 left-[-10px] bottom-[-10px] top-[-10px] right-[-10px]"></div> */}
                    </div>
                    <div className="!text-black ml-2">{label}</div>
                </>
            )}
        </li>
    );
};

export default SearchOption;
