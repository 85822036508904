import { useMemo } from "react";

import {
    InsightsTeam,
    InsightsIndividual,
    HumanCapitalSkillsTop3,
} from "store/api/investorCompany/interfaces";
import Card, { TopExpertiseCardProps } from "./components/Card";
import ContainerWrapper from "../../components/ContainerWrapper";
import Utils from "utils";

type Props = {
    team: InsightsTeam;
    individual: InsightsIndividual;
    tooltip?: string;
};

function getValuesByKey(
    data: HumanCapitalSkillsTop3,
    individual: InsightsIndividual,
    total: number,
    key: string
): TopExpertiseCardProps {
    const currentData = data[key] ?? [];
    const currentLength = currentData.length;

    const percentage = (currentLength / total) * 100;

    let type: "high" | "medium" | "low";
    if (percentage >= 50) {
        type = "high";
    } else if (percentage >= 20) {
        type = "medium";
    } else {
        type = "low";
    }

    return {
        title: key,
        type: type,
        members: Object.entries(currentData.map((i) => individual[i]))
            .sort(Utils.Insight.membersRoleNameCompareFn)
            .map(([, individualData]) =>
                Utils.Insight.getMemberInfoFromInsight(individualData)
            ),
    };
}

const TopExpertiseCard: React.FC<Props> = ({ team, individual, tooltip }) => {
    const data = useMemo(() => team?.["hc-main-expertise"], [team]);
    const dataValue = useMemo(
        () => (data?.value ?? {}) as HumanCapitalSkillsTop3,
        [data]
    );
    const isEmptyInsight = useMemo(() => !data, [data]);

    const total = useMemo(
        () =>
            Object.values(dataValue).reduce(
                (prev, curr) => prev + curr.length,
                0
            ),
        [dataValue]
    );

    const skills: Array<TopExpertiseCardProps> = [
        getValuesByKey(dataValue, individual, total, "Engineering"),
        getValuesByKey(dataValue, individual, total, "Product"),
        getValuesByKey(dataValue, individual, total, "Business development"),
        getValuesByKey(dataValue, individual, total, "Account management"),
        getValuesByKey(dataValue, individual, total, "Marketing"),
        getValuesByKey(dataValue, individual, total, "Sales"),
        getValuesByKey(dataValue, individual, total, "Data"),
        getValuesByKey(dataValue, individual, total, "Finance"),
        getValuesByKey(dataValue, individual, total, "Legal"),
    ].sort((a, b) => b.members.length - a.members.length);

    return (
        <ContainerWrapper
            iconType="Trophy"
            name="Top expertise"
            tooltip={tooltip}
            className="my-0 h-auto"
            isEmptyInsight={isEmptyInsight}
        >
            <div className="grid grid-cols-2 gap-3">
                {skills.map((props) => (
                    <Card key={props.title} {...props} />
                ))}
            </div>
        </ContainerWrapper>
    );
};

export default TopExpertiseCard;
