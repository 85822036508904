export const toISODate = (value: string | null | undefined) => {
    if (!value) {
        return value;
    }

    const date = new Date(value);
    const [d] = date.toISOString().split("T");

    return d;
};

export const timePassedFromGivenDate = (
    date: Date
): { type: "hours" | "days" | "now"; value: number } => {
    const now = new Date();
    const pastDate = new Date(date);

    const differenceInMs = (now as any) - (pastDate as any);

    const millisecondsInAnHour = 1000 * 60 * 60;
    const millisecondsInADay = millisecondsInAnHour * 24;

    const daysPassed = Math.floor(differenceInMs / millisecondsInADay);
    const hoursPassed = Math.floor(differenceInMs / millisecondsInAnHour);

    if (hoursPassed === 0) {
        return { type: "now", value: 0 };
    } else if (daysPassed < 1) {
        return { type: "hours", value: hoursPassed };
    } else {
        return { type: "days", value: daysPassed };
    }
};

export const getHoursMinutesSecondsFromDate = (value: string) => {
    const date = new Date(value);

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
};

export const formatDateToISO = (deadline: Date | string) => {
    const date = new Date(deadline);
    return date.toISOString().split("T")[0];
};

export const invitedSince = (date: Date) => {
    const now = new Date();
    const timeSince = ((now as any) - (new Date(date) as any)) / 1000; // Time since in seconds

    if (timeSince < 2 * 60) {
        return "Invited just now";
    } else if (timeSince < 1.5 * 60 * 60) {
        const minutes = Math.ceil(timeSince / 60);
        return `Invited ${minutes} minutes ago`;
    } else if (timeSince < 2 * 24 * 60 * 60) {
        const hours = Math.ceil(timeSince / (60 * 60));
        return `Invited ${hours} hours ago`;
    } else if (timeSince < 7 * 24 * 60 * 60) {
        const days = Math.ceil(timeSince / (24 * 60 * 60));
        return `Invited ${days} days ago`;
    } else {
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
        } as any;
        return `Invited on ${new Date(date).toLocaleDateString("en-US", options)}`;
    }
};

export const getUserTimezoneOffset = (): string => {
    const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, "0");
    const date = new Date();
    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? "+" : "-";
    return diff + pad(tzOffset / 60) + ":" + pad(tzOffset % 60);
};
