import { useEffect, useState } from "react";

import {
    useGetSurveyQuestionQuery,
    usePostSurveyAnswerMutation,
} from "store/api/survey";
import Utils from "utils";
import Input from "components/Input";
import Title from "questions/components/Title";
import { QuestionProps } from "questions/types.questions";
import SecondaryTitle from "questions/components/SecondaryTitle";
import SurveyFooter from "routes/surveys/components/SurveyFooter";
import { NumberArrayQuestionPattern } from "types/surveys/question-pattern";

const NumberArrayQuestion = ({
    value,
    isChild,
    surveyKey,
    allDisabled,
    isLastQuestion,
    data: {
        key,
        text,
        answer,
        isRequired,
        pattern: { labels, unit },
    },
    onError,
    handleNext,
    handleBack,
    onValueChange,
}: QuestionProps<NumberArrayQuestionPattern, any>) => {
    const separateThousands = unit?.type === "currency";

    const [postSurveyAnswer, { isError, isLoading, isSuccess }] =
        usePostSurveyAnswerMutation();

    const [isValid, setIsValid] = useState(!isRequired);
    const [selectedValue, setSelectedValue] = useState<Array<string>>(
        answer?.value
            ? answer.value.map((i: string) =>
                  Utils.Parsers.convertInputToNumberOfThousands(i || "")
              )
            : labels.map(() => "")
    );

    const { data } = useGetSurveyQuestionQuery(
        {
            surveyKey,
            questionKey: unit?.dependsOn!,
        },
        { skip: !unit?.dependsOn }
    );

    const handleSubmit = () => {
        postSurveyAnswer({
            surveyKey,
            questionKey: key,
            data: JSON.stringify(
                selectedValue.map(
                    Utils.Parsers.convertInputOfThousandsIntoNumber
                )
            ),
        });
    };

    const handleChange = (index: number) => (e: any) => {
        const v = e.target.value;

        const newVal = JSON.parse(
            JSON.stringify(selectedValue || labels.map(() => ""))
        );

        newVal.splice(index, 1, v as number);

        setSelectedValue(newVal);
        onValueChange?.(newVal);
    };

    useEffect(() => {
        if (isSuccess) handleNext();
    }, [isSuccess]);

    useEffect(() => {
        onError(isError);
    }, [isError]);

    useEffect(() => {
        setIsValid(
            Utils.Surveys.validateNumberArrayQuestion(
                selectedValue,
                isRequired,
                separateThousands
            )
        );
    }, [selectedValue, isRequired, separateThousands]);

    useEffect(() => {
        if (isChild) setSelectedValue(value || labels.map(() => ""));
    }, [value]);

    const endContent =
        Utils.Parsers.retrieveCurrencyFromAnswer(
            data?.data?.answer?.value || ""
        ) || unit?.value;

    return (
        <div className="flex flex-col items-center justify-center gap-12">
            {!isChild && <Title>{text}</Title>}

            <div>
                {isChild && <SecondaryTitle>{text}</SecondaryTitle>}
                <div className="flex flex-row gap-3">
                    {labels.map((label, index) => (
                        <Input
                            key={label}
                            label={label}
                            placeholder="0"
                            disabled={allDisabled}
                            endContent={endContent}
                            value={selectedValue[index]}
                            onChange={handleChange(index)}
                            inputWrapperClassName="!w-[168px]"
                            separateThousands={separateThousands}
                            labelClassName="text-default-500 text-sm"
                        />
                    ))}
                </div>
            </div>

            {!isChild && (
                <SurveyFooter
                    isLoading={isLoading}
                    loadingText="Saving..."
                    handleBack={handleBack}
                    nextDisabled={!isValid}
                    handleNext={handleSubmit}
                    allDisabled={allDisabled}
                    nextText={isLastQuestion ? "Submit" : undefined}
                />
            )}
        </div>
    );
};

export default NumberArrayQuestion;
