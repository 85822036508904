import { FC } from "react";
import { GaugeChartProps } from "./interfaces";
import { ChartContainer } from "../chart";
import { Pie, PieChart } from "recharts";
import Utils from "utils";
import cn from "classnames";

const GaugeChart: FC<GaugeChartProps> = ({
    value = 0,
    max = 5,
    width = 250,
    height = 250,
    className,
}) => {
    const gray = max - value;
    const type = Utils.Status.getStatusType(value);
    const data = [
        { value, fill: `url('#insight-score-${type}')` },
        { value: gray, fill: "#F1F1F5" },
    ];

    return (
        <div className={cn("relative", className)}>
            <ChartContainer style={{ width, height }} config={{}}>
                <PieChart>
                    <Pie
                        data={data}
                        dataKey="value"
                        innerRadius="74"
                        cy="65%"
                        cornerRadius={5}
                        startAngle={180}
                        endAngle={0}
                    />
                </PieChart>
            </ChartContainer>
            <div
                style={{
                    top: 0.65 * height,
                }}
                className="absolute left-1/2 -mt-3 -translate-x-1/2 -translate-y-1/2"
            >
                <span className="text-2xl">{value?.toFixed(1)}</span>
                <span className="text-sm">/{max}</span>
            </div>
        </div>
    );
};

export default GaugeChart;
