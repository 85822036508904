export default (props: any) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.33203 1.04297C7.33203 0.490684 7.77975 0.0429688 8.33203 0.0429688H11.6654C12.2176 0.0429688 12.6654 0.490684 12.6654 1.04297C12.6654 1.59525 12.2176 2.04297 11.6654 2.04297H8.33203C7.77975 2.04297 7.33203 1.59525 7.33203 1.04297Z"
            fill="#0F1117"
            {...props}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2061 7.46086C13.5967 7.85139 13.5967 8.48455 13.2061 8.87508L10.7061 11.3751C10.3156 11.7656 9.68244 11.7656 9.29192 11.3751C8.90139 10.9846 8.90139 10.3514 9.29192 9.96086L11.7919 7.46086C12.1824 7.07034 12.8156 7.07034 13.2061 7.46086Z"
            fill="#0F1117"
            {...props}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.9987 5.00098C6.86908 5.00098 4.33203 7.53803 4.33203 10.6676C4.33203 13.7973 6.86908 16.3343 9.9987 16.3343C13.1283 16.3343 15.6654 13.7973 15.6654 10.6676C15.6654 7.53803 13.1283 5.00098 9.9987 5.00098ZM2.33203 10.6676C2.33203 6.43346 5.76451 3.00098 9.9987 3.00098C14.2329 3.00098 17.6654 6.43346 17.6654 10.6676C17.6654 14.9018 14.2329 18.3343 9.9987 18.3343C5.76451 18.3343 2.33203 14.9018 2.33203 10.6676Z"
            fill="#0F1117"
            {...props}
        />
    </svg>
);
