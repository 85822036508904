import { FC, useEffect, useState } from "react";
import { HeartChartProps } from "./interfaces";
import { useSpring } from "framer-motion";
import cn from "classnames";
import Utils from "utils";

const W = 168;
const H = 146;

const lerp = (a: number, b: number, t: number) => (b - a) * t + a;

const getPathD = (value: number, max: number, yOffset = 0) => {
    const x = (value / max) * W;
    const y = H * 0.92 * lerp(1, 0.2, value / max);
    const q = lerp(20, 40, value / max);

    return `M 0 ${y + yOffset} Q ${x / 2} ${y - q + yOffset} ${x} ${y + yOffset} T ${W} ${y + yOffset} L ${W} ${H} L 0 ${H} Z`;
};

const getPathDRight = (value: number, max: number, yOffset = 0) => {
    const x = W - ((value * 0.85) / max) * W;
    const y = H * 0.92 * lerp(1, 0.2, value / max);
    const q = lerp(20, 40, value / max);

    return `M ${W} ${y + yOffset} Q ${W - x / 2} ${y - q + yOffset} ${W - x} ${y + yOffset} T 0 ${y + yOffset} L 0 ${H} L ${W} ${H} Z`;
};

void getPathD;
void getPathDRight;

const HeartChart: FC<HeartChartProps> = ({ value, max }) => {
    const motionValue = useSpring(0);
    const [currentValue, setCurrentValue] = useState(0);
    const type = Utils.Status.getStatusType(value);

    useEffect(() => {
        motionValue.set(value);

        return motionValue.on("change", (v) => setCurrentValue(v));
    }, [value]);

    return (
        <div className="relative">
            <svg
                width={W}
                height={H}
                viewBox={`0 0 ${W} ${H}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_i_4173_64572)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M57.0879 1.9298C38.059 -2.26004 23.5705 5.86452 18.7369 10.4095L18.7179 10.4275L18.6965 10.4427C12.6856 14.7158 0.79252 28.0904 1.49006 47.2728C1.92838 59.3265 3.89935 67.8497 7.39226 75.5173C10.8899 83.1953 15.9209 90.0337 22.5203 98.7221C27.7594 105.619 39.0932 115.044 51.2027 123.773C63.1213 132.365 75.7406 140.246 83.9336 144.335C83.943 144.34 83.9524 144.345 83.9618 144.349C84.1509 144.444 84.2288 144.673 84.1353 144.863C84.0419 145.052 83.8162 145.132 83.627 145.038C75.3834 140.928 62.7082 133.01 50.7551 124.394C38.6464 115.666 27.2263 106.183 21.9109 99.185C15.3173 90.5044 10.234 83.6017 6.69579 75.8346C3.15283 68.0571 1.16636 59.4314 0.72524 47.3006C0.0165046 27.8104 12.0717 14.2248 18.2318 9.83406C23.2357 5.14317 37.9544 -3.0604 57.2364 1.17886C61.1827 1.70314 71.8355 5.22353 83.8414 15.106C84.1386 15.3507 84.1806 15.793 83.9336 16.0884C83.9094 16.1173 83.8659 16.1207 83.837 16.0965C71.7044 5.96571 60.9347 2.43656 57.1203 1.93549L57.104 1.93335L57.0879 1.9298Z"
                        fill="#FCF3FF"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M110.779 1.9298C129.808 -2.26004 144.297 5.86452 149.13 10.4095L149.149 10.4275L149.171 10.4427C155.182 14.7158 167.075 28.0904 166.377 47.2728C165.939 59.3265 163.968 67.8497 160.475 75.5173C156.977 83.1953 151.946 90.0337 145.347 98.7221C140.108 105.619 128.774 115.044 116.665 123.773C104.746 132.365 92.1265 140.246 83.9336 144.335C83.9242 144.34 83.9148 144.345 83.9054 144.349C83.7163 144.444 83.6384 144.673 83.7318 144.863C83.8253 145.052 84.051 145.132 84.2402 145.038C92.4838 140.928 105.159 133.01 117.112 124.394C129.221 115.666 140.641 106.183 145.956 99.185C152.55 90.5044 157.633 83.6017 161.171 75.8346C164.714 68.0571 166.701 59.4314 167.142 47.3006C167.851 27.8104 155.795 14.2248 149.635 9.83406C144.631 5.14317 129.913 -3.0604 110.631 1.17886C106.684 1.70314 96.0317 5.22353 84.0258 15.106C83.7286 15.3507 83.6866 15.793 83.9336 16.0884C83.9577 16.1173 84.0013 16.1207 84.0302 16.0965C96.1628 5.96571 106.933 2.43656 110.747 1.93549L110.763 1.93335L110.779 1.9298Z"
                        fill="#FCF3FF"
                    />
                    <path
                        id="heart-shape"
                        d="M57.0879 1.9298C38.059 -2.26004 23.5705 5.86452 18.7369 10.4095L18.7179 10.4275L18.6965 10.4427C12.6856 14.7158 0.79252 28.0904 1.49006 47.2728C1.92838 59.3265 3.89935 67.8497 7.39226 75.5173C10.8899 83.1953 15.9209 90.0337 22.5203 98.7221C27.7594 105.619 39.0932 115.044 51.2027 123.773C63.1213 132.365 75.7406 140.246 83.9336 144.335C92.1265 140.246 104.746 132.365 116.665 123.773C128.774 115.044 140.108 105.619 145.347 98.7221C151.946 90.0337 156.977 83.1953 160.475 75.5173C163.968 67.8497 165.939 59.3265 166.377 47.2728C167.075 28.0904 155.182 14.7158 149.171 10.4427L149.149 10.4275L149.13 10.4095C144.297 5.86452 129.808 -2.26004 110.779 1.9298L110.763 1.93335L110.747 1.93549C106.933 2.43656 96.1628 5.96571 84.0302 16.0965C84.0013 16.1207 83.9577 16.1173 83.9336 16.0884C83.9094 16.1173 83.8659 16.1207 83.837 16.0965C71.7044 5.96571 60.9347 2.43656 57.1203 1.93549L57.104 1.93335L57.0879 1.9298Z"
                        fill="#FCF3FF"
                    />
                </g>

                <clipPath id="heart-clip">
                    <use href="#heart-shape" xlinkHref="#heart-shape" />
                </clipPath>

                <path
                    d={getPathD(currentValue, max)}
                    fill={`url('#insight-score-${type}')`}
                    clipPath="url('#heart-clip')"
                />
                <path
                    d={getPathD(currentValue, max, -10)}
                    fill={`url('#insight-score-${type}')`}
                    opacity="0.8"
                    clipPath="url('#heart-clip')"
                />
                <path
                    d={getPathDRight(currentValue, max, 0)}
                    fill={`url('#insight-score-${type}')`}
                    opacity="0.4"
                    clipPath="url('#heart-clip')"
                />

                <defs>
                    <filter
                        id="filter0_i_4173_64572"
                        x="0.695312"
                        y="0"
                        width="166.477"
                        height="151.201"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="6.12261" />
                        <feGaussianBlur stdDeviation="9.18392" />
                        <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                        />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.592157 0 0 0 0 0.32549 0 0 0 0 0.988235 0 0 0 0.24 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect1_innerShadow_4173_64572"
                        />
                    </filter>
                </defs>
            </svg>
            <div
                className={cn(
                    "absolute left-1/2 top-1/2 -translate-x-1/2",
                    value < 2 ? "text-black" : "text-white"
                )}
            >
                <span className="text-2xl">{value.toFixed(1)}</span>
                <span className="text-sm">/{max}</span>
            </div>
        </div>
    );
};

export default HeartChart;
