import Icon from "components/Icon";

type Props = {
    roundLabel: string | number;
    preMoneyValuation: string | number;
};
type ItemProps = {
    label: string;
    title: string | number;
};

const Item: React.FC<ItemProps> = ({ label, title }) => (
    <div className="flex flex-1 flex-col">
        <div>{title}</div>
        <div className="text-sm text-default-400">{label}</div>
    </div>
);

const NotRaising: React.FC<Props> = ({ preMoneyValuation, roundLabel }) => {
    return (
        <div className="flex flex-col gap-6 rounded-lg border border-solid border-default-100 bg-transparent px-6 py-5">
            <div className="flex flex-row items-center gap-2 text-sm">
                <Icon type={"History"} size={20} />

                <div>Previous round</div>
            </div>

            <div className="grid grid-cols-2 items-end">
                <Item label="Round" title={roundLabel} />

                <Item title={preMoneyValuation} label="Pre-money valuation" />
            </div>
        </div>
    );
};

export default NotRaising;
