import { ADMIN_BASE_URL } from "core/env";

const parseLocalstackUrl = (value: string) =>
    value.replace("localstack", "localhost");

const image = async (file: File) => {
    const response = await fetch(`${ADMIN_BASE_URL}/app/shared/uploads/`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ contentType: file.type }),
    });
    const {
        data: { fields, url, upload },
    } = await response.json();

    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]: any) => {
        formData.append(key, value);
    });
    formData.append("file", file);

    const uploadUrl = parseLocalstackUrl(url);
    try {
        await fetch(uploadUrl, {
            method: "POST",
            body: formData,
        });
    } catch (err) {
        console.error(err);
    }

    upload.url = parseLocalstackUrl(upload.url);

    return upload;
};

const FileUpload = {
    image,
};

export default FileUpload;
