type Props = {
    checked: boolean;
    checkedLabel: string;
    uncheckedLabel?: string;
    type?: "rose" | "violet";
    hideIfUnchecked?: boolean;
    disabledWhenUnchecked?: boolean;
};

const Checked = ({ color }: { color: string }) => (
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill={color}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 0.908203C2.68629 0.908203 0 3.5945 0 6.9082V14.9082C0 18.2219 2.68629 20.9082 6 20.9082H14C17.3137 20.9082 20 18.2219 20 14.9082V6.9082C20 3.5945 17.3137 0.908203 14 0.908203H6ZM15.5303 8.43853C15.8232 8.14564 15.8232 7.67077 15.5303 7.37787C15.2374 7.08498 14.7626 7.08498 14.4697 7.37787L8.125 13.7225L5.53033 11.1279C5.23744 10.835 4.76256 10.835 4.46967 11.1279C4.17678 11.4208 4.17678 11.8956 4.46967 12.1885L7.59467 15.3135C7.88756 15.6064 8.36244 15.6064 8.65533 15.3135L15.5303 8.43853Z"
        />
    </svg>
);

const Unchecked = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="20" height="20" rx="6" fill="#C9CAD4" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.25 10C5.25 9.58579 5.58579 9.25 6 9.25H14C14.4142 9.25 14.75 9.58579 14.75 10C14.75 10.4142 14.4142 10.75 14 10.75H6C5.58579 10.75 5.25 10.4142 5.25 10Z"
            fill="white"
        />
    </svg>
);

const FakeCheckbox: React.FC<Props> = ({
    checked,
    checkedLabel,
    uncheckedLabel,
    hideIfUnchecked,
    type = "violet",
}) => {
    if (hideIfUnchecked && !checked) return undefined;

    return (
        <div className="flex flex-row items-center gap-2 text-sm">
            <div>
                {checked ? (
                    <Checked
                        color={type === "violet" ? "#9753FC" : "#DF71FF"}
                    />
                ) : (
                    <Unchecked />
                )}
            </div>
            <span>{checked ? checkedLabel : uncheckedLabel}</span>
        </div>
    );
};

export default FakeCheckbox;
