import styles from "./index.module.css";

type Props = {
    className?: string;
};

const LoadingSpinner = ({ className }: Props) => {
    return (
        <div className={`${styles.loadingSpinnerContainer} ${className}`}>
            <div className={styles.loadingSpinner}></div>
        </div>
    );
};

export default LoadingSpinner;
