import { useEffect, useState } from "react";

import {
    QuestionType,
    ShortTextQuestionPattern,
    LongTextQuestionPattern,
} from "types/surveys/question-pattern";
import Input from "components/Input";
import Title from "questions/components/Title";
import { QuestionProps } from "questions/types.questions";
import { usePostSurveyAnswerMutation } from "store/api/survey";
import SurveyFooter from "routes/surveys/components/SurveyFooter";
import { ShortTextAnswerPattern } from "types/surveys/answer-pattern";

const ShortTextQuestion = ({
    value,
    isChild,
    surveyKey,
    allDisabled,
    isLastQuestion,
    data: { isRequired, key, answer, text, pattern },
    onError,
    handleNext,
    handleBack,
    onValueChange,
}: QuestionProps<
    ShortTextQuestionPattern | LongTextQuestionPattern,
    ShortTextAnswerPattern | LongTextQuestionPattern
>) => {
    const [postSurveyAnswer, { isError, isLoading, isSuccess }] =
        usePostSurveyAnswerMutation();

    const [isValid, setIsValid] = useState(!isRequired);
    const [selectedValue, setSelectedValue] = useState(
        answer?.value ? answer.value.toString() : ""
    );

    const handleSubmit = () => {
        postSurveyAnswer({
            surveyKey,
            questionKey: key,
            data: JSON.stringify(selectedValue),
        });
    };

    useEffect(() => {
        if (isSuccess) handleNext();
    }, [isSuccess]);

    useEffect(() => {
        onError(isError);
    }, [isError]);

    useEffect(() => {
        setIsValid(!!selectedValue || !isRequired);
    }, [selectedValue]);

    useEffect(() => {
        if (isChild) setSelectedValue(value || "");
    }, [value]);

    return (
        <div className="flex flex-col items-center justify-center gap-12">
            {!isChild && <Title>{text}</Title>}

            <Input
                value={selectedValue}
                disabled={allDisabled}
                label={isChild ? text : undefined}
                labelClassName="text-center !text-md mb-4 !text-black"
                placeholder={isChild ? "Add your comments here" : ""}
                containerClassName="!w-[100%] max-w-[570px] max-h-[212px]"
                as={
                    pattern?.type === QuestionType.short_text
                        ? "input"
                        : "textarea"
                }
                onChange={(e: any) => {
                    setSelectedValue(e.target.value);
                    onValueChange?.(e.target.value);
                }}
            />

            {!isChild && (
                <SurveyFooter
                    isLoading={isLoading}
                    loadingText="Saving..."
                    handleBack={handleBack}
                    nextDisabled={!isValid}
                    handleNext={handleSubmit}
                    allDisabled={allDisabled}
                    nextText={isLastQuestion ? "Submit" : undefined}
                />
            )}
        </div>
    );
};

export default ShortTextQuestion;
