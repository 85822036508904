import { cn } from "lib/utils";
import { FC } from "react";
import Markdown from "markdown-to-jsx";

type WrapperProps = {
    children: React.ReactNode;
    className?: string;
};

const Wrapper = ({ children, className }: WrapperProps) => {
    return (
        <article className={cn("my-4 text-sm text-default-500", className)}>
            {children}
        </article>
    );
};

type Props = { children: string; className?: string };

const InsightDescription: FC<Props> = ({ className, children }) => {
    return (
        <Markdown
            options={{
                wrapper: (props) => (
                    <Wrapper className={className} {...props} />
                ),
                forceWrapper: true,
                overrides: {
                    ul: {
                        props: { className: "my-3 list-disc pl-[22px]" },
                    },
                    li: {
                        props: { className: "my-3" },
                    },
                    strong: {
                        props: { className: "font-bold text-foreground" },
                    },
                    em: {
                        props: { className: "not-italic" },
                    },
                    i: {
                        props: { className: "not-italic" },
                    },
                },
            }}
        >
            {children}
        </Markdown>
    );
};

export default InsightDescription;
