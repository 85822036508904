import Surveys from "./surveys";
import Parsers from "./parsers";
import Validations from "./validations";
import * as DateUtils from "./date";
import * as InsightUtils from "./insight";
import * as Status from "./status";
import * as UrlUtils from "./url";
import * as PasswordUtils from "./password";
import * as StringUtils from "./string";
import * as AvatarUtils from "./avatar";

const Utils = {
    Surveys,
    Parsers,
    Validations,
    Date: DateUtils,
    Insight: InsightUtils,
    Status,
    Url: UrlUtils,
    Password: PasswordUtils,
    String: StringUtils,
    Avatar: AvatarUtils,
};

export default Utils;
