import { useState } from "react";
import {
    InsightsTeam,
    InsightsIndividual,
} from "store/api/investorCompany/interfaces";
import TabsGroup, { TabsGroupTab } from "components/TabsGroup";

// TABS
import SoftSkillsTeamTab from "./Team";
import SoftSkillsIndividualTab from "./Individual";
import InfoTooltip from "routes/company/components/InfoTooltip";
import { TOOLTIPS } from "routes/company/tooltips";

type Tabs = "team" | "individual";

type Props = {
    team: InsightsTeam;
    individual: InsightsIndividual;
};

const EntrepreneurialPassionTab: React.FC<Props> = ({ team, individual }) => {
    const tabs: Array<TabsGroupTab<Tabs>> = [
        { label: "Team", value: "team" },
        { label: "Individual", value: "individual" },
    ];
    const [activeTab, setActiveTab] = useState<Tabs>(tabs[0].value);

    return (
        <div>
            <div className="my-8 flex flex-row justify-between">
                <div className="flex flex-row items-center gap-1 text-lg">
                    Entrepreneurial passion
                    <InfoTooltip text={TOOLTIPS.MAIN.entrepreneurialPassion} />
                </div>

                <TabsGroup<Tabs>
                    tabs={tabs}
                    activeTab={activeTab}
                    onClick={(tab) => setActiveTab(tab)}
                />
            </div>

            {activeTab === "team" && (
                <SoftSkillsTeamTab
                    teamData={team}
                    individualData={individual}
                />
            )}
            {activeTab === "individual" && (
                <SoftSkillsIndividualTab
                    teamData={team}
                    individualData={individual}
                />
            )}
        </div>
    );
};

export default EntrepreneurialPassionTab;
