import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";
import { CompanyOnboarding } from "./types";

const initialState: CompanyOnboarding.InitState = {
    profile: {
        website: "",
        companyName: "",
        image: undefined,
    },
    details: {
        country: undefined,
        foundingDate: "",
        industry: undefined,
        businessType: undefined,
    },
    description: {
        longDescription: "",
        shortDescription: "",
    },
};

export const companyOnboarding = createSlice({
    initialState,
    name: "company_onboarding",
    reducers: {
        setCompany: (
            state,
            { payload }: PayloadAction<CompanyOnboarding.Profile>
        ) => {
            state.profile = payload;
        },
        setDetails: (
            state,
            { payload }: PayloadAction<CompanyOnboarding.Details>
        ) => {
            state.details = payload;
        },
        setDescription: (
            state,
            { payload }: PayloadAction<CompanyOnboarding.Description>
        ) => {
            state.description = payload;
        },
    },
});

export const CompanyOnboardingActions = companyOnboarding.actions;
export const CompanyOnboardingSelectors = {
    all: (state: RootState) => state.companyOnboarding,
    state:
        <T extends keyof CompanyOnboarding.InitState>(key: T) =>
        (state: RootState) =>
            state.companyOnboarding[key],
};

export default companyOnboarding.reducer;
