import { FC, useMemo } from "react";
import CardBlockWrapper from "../card-block-wrapper";
import { cva } from "class-variance-authority";
import { cn } from "lib/utils";
import { Badge } from "../badge";
import {
    HumanCapitalSkills as THumanCapitalSkills,
    humanCapitalSkillsSchema,
} from "../../schemas/human-capital-skills-schema";
import { InsightsIndividualMember } from "store/api/investorCompany/interfaces";
import EmptyInsight from "routes/company/components/EmptyInsight";

const titleVariants = cva("text-sm font-semibold text-default-400");

type Props = {
    memberData: InsightsIndividualMember;
};

const HumanCapitalSkills: FC<Props> = ({ memberData }) => {
    const values = useMemo((): THumanCapitalSkills | null => {
        const data = {
            mainExpertise: memberData?.["hc-main-expertise"]?.value,
            industryExperience: memberData?.["hc-previous-industry"]?.value,
            topSkills: memberData?.["hc-top-3-skills"]?.value,
        };
        const dataParsed = humanCapitalSkillsSchema.safeParse(data);
        if (!dataParsed.success) {
            return null;
        }
        return dataParsed.data;
    }, [memberData]);

    if (!values) {
        return <EmptyInsight className="py-0" />;
    }

    const { mainExpertise, industryExperience, topSkills } = values;

    return (
        <CardBlockWrapper>
            <div className="flex flex-col gap-y-6">
                <div className="flex flex-col gap-y-2">
                    <h5 className={cn(titleVariants())}>Main expertise</h5>
                    <Badge>{mainExpertise}</Badge>
                </div>
                <div className="flex flex-col gap-y-2">
                    <h5 className={cn(titleVariants())}>Industry experience</h5>
                    <p className={"text-sm font-semibold text-foreground"}>
                        {industryExperience.join(", ")}
                    </p>
                </div>
            </div>
            <div className="flex h-full flex-col gap-y-2">
                <h5 className={cn(titleVariants())}>Top 3 skills</h5>
                <div className="flex flex-col gap-y-1">
                    {topSkills.map((value, index) => (
                        <Badge variant={"secondary"} key={index}>
                            {value}
                        </Badge>
                    ))}
                </div>
            </div>
        </CardBlockWrapper>
    );
};

export default HumanCapitalSkills;
