import {
    Pagination as NextPagination,
    PaginationItemRenderProps,
    PaginationItemType,
    PaginationProps,
} from "@nextui-org/react";
import ButtonIcon from "components/ButtonIcon";
import { cn } from "lib/utils";
import { FC } from "react";

type Props = {} & PaginationProps;

const Pagination: FC<Props> = ({ className, ...rest }) => {
    const renderItem = ({
        ref,
        key,
        value,
        page,
        total,
        isActive,
        onNext,
        onPrevious,
        setPage,
    }: PaginationItemRenderProps) => {
        if (value === PaginationItemType.NEXT) {
            return (
                <ButtonIcon
                    disabled={page > total}
                    key={key}
                    icon="ChevronRight"
                    className={cn("aspect-square")}
                    customType={"light-white"}
                    onClick={onNext}
                />
            );
        }

        if (value === PaginationItemType.PREV) {
            return (
                <ButtonIcon
                    disabled={page === 0}
                    key={key}
                    icon="ChevronLeft"
                    className={cn("aspect-square")}
                    customType={"light-white"}
                    onClick={onPrevious}
                />
            );
        }

        if (value === PaginationItemType.DOTS) {
            return (
                <ButtonIcon
                    key={key}
                    text={"..."}
                    className={cn("aspect-square")}
                    customType={"light-white"}
                    onClick={() => setPage(page)}
                />
            );
        }

        return (
            <ButtonIcon
                ref={ref}
                key={key}
                className={cn("aspect-square", isActive && "bg-background")}
                customType={"light-white"}
                text={value.toString()}
                onClick={() => setPage(value)}
            />
        );
    };

    return (
        <NextPagination
            disableCursorAnimation
            showControls
            className={cn(className)}
            classNames={{
                wrapper: "gap-3",
            }}
            radius="none"
            renderItem={renderItem}
            variant="light"
            {...rest}
        />
    );
};

export default Pagination;
