import * as yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import EnvelopePlaneImage from "../../../images/auth/envelope-plane.png";

import Input from "components/Input";
import Button from "components/Button";
import Layout from "components/OnboardingAndAuth/Layout";
import { useSendForgotPasswordMutation } from "store/api/auth";

const schema = yup.object().shape({
    email: yup.string().email("Invalid format").required("Required"),
});

const defaultValues = {
    email: "",
};

const Index = () => {
    const navigation = useNavigate();
    const [sendResetPassword, { isSuccess }] = useSendForgotPasswordMutation();

    const [email, setEmail] = useState<string>();

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues,
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema),
    });

    const onGoToSignInClick = () => navigation("/auth/sign-in");

    const onSubmit = (data: typeof defaultValues) => {
        setEmail(data.email);
        sendResetPassword(data);
    };

    return (
        <Layout
            type="auth"
            title="A window into a team’s potential"
            leftContent="VC Volt is a unique model based on decades of research on entrepreneurial teams and high performing companies."
            leftBottomSecondaryContent="The team is a company’s most important asset. Having a good analysis can contribute to bringing your company to the next level."
        >
            {isSuccess ? (
                <div className="flex flex-col items-center justify-center gap-8">
                    <div>
                        <img
                            src={EnvelopePlaneImage}
                            className="h-[160px] w-[160px]"
                        />
                    </div>

                    <div className="flex flex-col gap-4">
                        <div className="text-center text-xl text-black">
                            Check your email to reset password.
                        </div>
                        <div className="text-center text-sm text-default-400">
                            If an account with the corresponding email exists in
                            our system, a password reset link will be emailed to{" "}
                            <span>{email}</span>. If you haven't received the
                            link after 10 minutes, please contact us at
                            support@vcvolt.com for support.
                        </div>
                    </div>

                    <Button onClick={onGoToSignInClick}>Back to Sign in</Button>
                </div>
            ) : (
                <div className="flex flex-col gap-10">
                    <div>
                        <div className="cursor-pointer text-center text-3xl text-black">
                            Forgot password?
                        </div>
                        <div className="mt-2 text-center text-default-500">
                            Enter your email address to reset password.
                        </div>
                    </div>

                    <Controller
                        name="email"
                        control={control}
                        render={({
                            fieldState: { error },
                            field: { value, onChange, onBlur },
                        }) => (
                            <Input
                                onBlur={onBlur}
                                placeholder="your.email@domain.com"
                                label="Email"
                                onChange={onChange}
                                value={value}
                                errorMessage={error?.message}
                            />
                        )}
                    />

                    <Button
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isValid}
                    >
                        Reset password
                    </Button>

                    <div className="text-center text-sm text-default-400">
                        <div>
                            No need to reset?
                            <span
                                onClick={onGoToSignInClick}
                                className="cursor-pointer text-black underline"
                            >
                                {" "}
                                Sign in
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default Index;
