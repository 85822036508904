import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
    MemberOnboardingActions,
    MemberOnboardingSelectors,
} from "store/reducers/memberOnboarding";
import {
    useGetOnboardingQuery,
    useSaveOnboardingMutation,
} from "store/api/onboarding";
import API from "api";
import Utils from "utils";
import Input from "components/Input";
import FileInput, { AllowedFileTypes } from "components/FileInput";
import BottomButtons from "routes/onboarding/components/BottomButtons";
import { MemberOnboarding } from "store/reducers/memberOnboarding/types";

type Props = {
    title: string;
    handleNextClick: () => void;
};

const schema = yup.object({
    image: Utils.Validations.image,
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    linkedIn: Utils.Validations.websiteUrl,
});

const parseDefaultValues = (
    { firstName, lastName, image, linkedIn }: MemberOnboarding.ProfileNew,
    data?: Partial<MemberOnboarding.ProfileNew>
): MemberOnboarding.ProfileNew => {
    const linkedInValue = linkedIn || data?.linkedIn;
    return {
        image: (image || data?.image) ?? null,
        lastName: lastName || data?.lastName || "",
        linkedIn: linkedInValue
            ? Utils.Parsers.formatWebsiteUrlToPresent(linkedInValue)
            : "",
        firstName: firstName || data?.firstName || "",
    };
};

const PasswordStep = ({ handleNextClick, title }: Props) => {
    const dispatch = useDispatch();
    const defaultValues = useSelector(
        MemberOnboardingSelectors.state("profileNew")
    );
    const { data: savedOnboarding } = useGetOnboardingQuery();
    const [saveOnboarding, saveOnboardingPayload] = useSaveOnboardingMutation();

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema) as any,
        defaultValues: parseDefaultValues(
            defaultValues,
            savedOnboarding?.data["member.profileNew"]
        ),
    });

    const onSubmit = async (profile: MemberOnboarding.ProfileNew) => {
        profile.image =
            profile.image instanceof File
                ? await API.FileUpload.image(profile.image)
                : profile.image;
        profile.linkedIn = profile.linkedIn
            ? Utils.Parsers.formatWebsiteUrl(profile.linkedIn)
            : profile.linkedIn;

        dispatch(MemberOnboardingActions.setProfile(profile));
        saveOnboarding({ "member.profileNew": profile });
        handleNextClick();
    };

    return (
        <div>
            <div className="mb-10 text-2xl font-semibold">{title}</div>

            <div className="flex flex-col gap-5">
                <Controller
                    name="image"
                    control={control}
                    render={({
                        field: { onChange, value, onBlur },
                        fieldState: { error },
                    }) => (
                        <FileInput
                            onBlur={onBlur}
                            selectedFile={value}
                            label="Profile picture"
                            errorMessage={error?.message}
                            fileTypes={AllowedFileTypes.Image}
                            setFiles={(files) => onChange(files)}
                        />
                    )}
                />

                <Controller
                    name="firstName"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            label="First name"
                            errorMessage={error?.message}
                            placeholder="Your first name"
                        />
                    )}
                />

                <Controller
                    name="lastName"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            onBlur={onBlur}
                            label="Last name"
                            onChange={onChange}
                            placeholder="Your last name"
                            errorMessage={error?.message}
                        />
                    )}
                />

                <Controller
                    name="linkedIn"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            onBlur={onBlur}
                            label="LinkedIn"
                            onChange={onChange}
                            errorMessage={error?.message}
                            placeholder="Your LinkedIn profile url"
                        />
                    )}
                />

                <BottomButtons
                    onNext={handleSubmit(onSubmit)}
                    disableNext={!isValid || saveOnboardingPayload.isLoading}
                />
            </div>
        </div>
    );
};

export default PasswordStep;
