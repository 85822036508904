import { cn } from "lib/utils";
import React from "react";

type Props = React.InputHTMLAttributes<HTMLDivElement> & {};

const Divider = React.forwardRef<HTMLDivElement, Props>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                role="none"
                className={cn(
                    "h-[1px] w-full shrink-0 bg-default-100",
                    className
                )}
                {...props}
            />
        );
    }
);

export default Divider;
