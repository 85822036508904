import { InvitationType, UserType } from "store/api/invitation/interfaces";
import { useGetMeQuery } from "store/api/auth";
import { SuperMember } from "./SuperMember";
import { Navigate, useNavigate } from "react-router-dom";
import { Member } from "./Member";
import { Investor } from "./Investor";
import { DashboardHeader } from "routes/components/header";
import { useEffect } from "react";
import { PageLoader } from "routes/components/PageLoader";
import ErrorPage from "routes/components/error-page";

export default function Dashboard() {
    const navigate = useNavigate();
    const { data: meData, isSuccess, isFetching } = useGetMeQuery();

    useEffect(() => {
        if (isFetching) {
            return;
        }

        const isOnboarding = meData?.data?.isOnboarding;
        const type = meData?.data?.onboarding?.type;

        if (isOnboarding) {
            switch (type) {
                case InvitationType.Investor:
                    return navigate("/onboarding/investor");
                case InvitationType.Member:
                case InvitationType.Company:
                    return navigate("/onboarding/member");
                default:
                    return navigate("/auth/sign-in");
            }
        }
    }, [meData?.data?.isOnboarding, isFetching]);

    if (isFetching) {
        return <PageLoader />;
    }

    if (!isSuccess) {
        return <ErrorPage />;
    }

    if (!meData.data.user) {
        return null;
    }

    if (meData.data.auth === false) {
        return <Navigate to="/auth/sign-in" />;
    }

    const isSuper =
        meData?.data?.user?.type === UserType.Member &&
        meData.data.user.isSuper;
    const isInvestor = meData?.data?.user?.type === UserType.Investor;

    return (
        <div className="bg-default-50">
            <DashboardHeader />
            <div className="flex min-h-screen justify-center">
                {isSuper ? (
                    <SuperMember />
                ) : isInvestor ? (
                    <Investor />
                ) : (
                    <Member />
                )}
            </div>
        </div>
    );
}
