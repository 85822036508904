import { useEffect, useState } from "react";

import {
    QuestionType,
    BooleanQuestionPattern,
} from "types/surveys/question-pattern";
import Utils from "utils";
import GridSelect from "components/GridSelect";
import Title from "questions/components/Title";
import { QuestionProps } from "questions/types.questions";
import { usePostSurveyAnswerMutation } from "store/api/survey";
import SecondaryTitle from "questions/components/SecondaryTitle";
import SurveyFooter from "routes/surveys/components/SurveyFooter";
import { BooleanAnswerPattern } from "types/surveys/answer-pattern";
import QuestionRenderer from "routes/surveys/components/QuestionRenderer";
import ChildQuestionRenderer from "questions/components/ChildQuestionRenderer";

const BooleanQuestion = ({
    // value,
    isChild,
    surveyKey,
    allDisabled,
    isLastQuestion,
    data: { isRequired, key, answer, children = [], text },
    onError,
    onWarning,
    handleBack,
    handleNext,
    onValueChange,
}: QuestionProps<BooleanQuestionPattern, BooleanAnswerPattern>) => {
    const [postSurveyAnswer, { isError, isLoading, isSuccess }] =
        usePostSurveyAnswerMutation();

    const [isValid, setIsValid] = useState(!isRequired);
    const [selectedValue, setSelectedValue] = useState(answer?.value);
    const [childValues, setChildValues] = useState<Record<any, any>>(
        children.reduce((prev, curr) => {
            let value = curr.answer?.value as any;

            if (value)
                if (curr.pattern.type === "number") {
                    value =
                        Utils.Parsers.convertInputToNumberOfThousands(value);
                } else if (curr.pattern.type === "number-array") {
                    value = value.map(
                        Utils.Parsers.convertInputToNumberOfThousands
                    );
                }

            return { ...prev, [curr.key]: value ?? "" };
        }, {})
    );

    const handleSubmit = () => {
        postSurveyAnswer({
            surveyKey,
            questionKey: key,
            data: selectedValue,
        });

        Object.entries(childValues).map(([k, val]) => {
            if (!val && typeof val !== "boolean") return;

            const childQuestion = children.find((i) => i.key === k);
            const isNumberArray =
                childQuestion?.pattern?.type === QuestionType.number_array;
            const isNumber =
                childQuestion?.pattern?.type === QuestionType.number;

            let valueToSend = val;

            if (isNumber) {
                valueToSend =
                    Utils.Parsers.convertInputOfThousandsIntoNumber(
                        valueToSend
                    );
            } else if (isNumberArray) {
                valueToSend = JSON.stringify(
                    val.map(Utils.Parsers.convertInputOfThousandsIntoNumber)
                );
            } else if (typeof val === "string" || typeof val === "boolean") {
                valueToSend = JSON.stringify(val);
            }

            postSurveyAnswer({
                surveyKey,
                questionKey: k,
                data: valueToSend,
            });
        });
    };

    useEffect(() => {
        if (isSuccess) {
            const changed = answer?.value !== selectedValue;
            const childrenChanged = children.some((child) => {
                let value = child.answer?.value as any;

                if (value)
                    if (child.pattern.type === "number") {
                        value =
                            Utils.Parsers.convertInputToNumberOfThousands(
                                value
                            );
                    } else if (child.pattern.type === "number-array") {
                        value = value.map(
                            Utils.Parsers.convertInputToNumberOfThousands
                        );
                    }
                return value !== childValues[child.key];
            });
            handleNext(changed || childrenChanged);
        }
    }, [isSuccess]);

    useEffect(() => {
        onError(isError);
    }, [isError]);

    useEffect(() => {
        const areChildrenValid = Utils.Surveys.validateChildren(
            selectedValue,
            childValues,
            children
        );

        setIsValid(typeof selectedValue === "boolean" && areChildrenValid);
    }, [selectedValue, childValues, children]);

    return (
        <div
            className={`flex flex-col items-center justify-center ${!isChild && "gap-12"}`}
        >
            {!isChild && <Title>{text}</Title>}

            <div>
                {isChild && <SecondaryTitle>{text}</SecondaryTitle>}
                <GridSelect<boolean>
                    centered
                    disabled={allDisabled}
                    selected={
                        typeof selectedValue !== "undefined"
                            ? [selectedValue]
                            : []
                    }
                    onSelect={(item) => {
                        setSelectedValue(item[0]);
                        onValueChange?.(item[0]);
                    }}
                    options={[
                        { label: "Yes", value: true, icon: "Check" },
                        { label: "No", value: false, icon: "X" },
                    ]}
                />
            </div>

            <div className="w-[100%]">
                {children.map((i, index) => (
                    <ChildQuestionRenderer
                        key={index}
                        parentValue={selectedValue}
                        childConditions={i.conditions}
                        onHide={() =>
                            setChildValues((prev) => ({
                                ...prev,
                                [i.key]: undefined,
                            }))
                        }
                    >
                        <QuestionRenderer
                            isChild
                            data={i}
                            key={i.key}
                            surveyKey={surveyKey}
                            type={i.pattern.type}
                            allDisabled={allDisabled}
                            value={childValues[i.key]}
                            onError={onError}
                            onWarning={onWarning}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            onValueChange={(value) =>
                                setChildValues((prev) => ({
                                    ...prev,
                                    [i.key]: value,
                                }))
                            }
                        />
                    </ChildQuestionRenderer>
                ))}

                {!isChild && (
                    <SurveyFooter
                        isLoading={isLoading}
                        loadingText="Saving..."
                        handleBack={handleBack}
                        nextDisabled={!isValid}
                        handleNext={handleSubmit}
                        allDisabled={allDisabled}
                        nextText={isLastQuestion ? "Submit" : undefined}
                    />
                )}
            </div>
        </div>
    );
};

export default BooleanQuestion;
