import { z } from "zod";

export const CompanyDetailsSchema = z.object({
    name: z.string().default(""),
    descriptionShort: z.string().default(""),
    logo: z
        .object({
            url: z.string(),
        })
        .nullish()
        .default(null),
});

export type CompanyDetailsData = z.infer<typeof CompanyDetailsSchema>;
