import {
    InputProps,
    TextAreaProps,
    Input as NextInput,
    Textarea as NextTextarea,
    ButtonProps,
} from "@nextui-org/react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import Icon, { IconType } from "components/Icon";
import Collapsed from "components/Collapsed";
import { FieldValidation } from "types/validation.types";
import Utils from "utils";
import { cn } from "lib/utils";
import ButtonIcon from "components/ButtonIcon";

type InputAs = "input" | "textarea";

type Props = {
    separateThousands?: boolean;
    isPasswordInput?: boolean;
    validations?: Array<FieldValidation>;
    inputWrapperClassName?: string;
    as?: InputAs;
    label?: string;
    paddingRight?: number;
    errorMessage?: string;
    value?: string | number;
    labelClassName?: string;
    containerClassName?: string;
    inputClassName?: string;
    action?: {
        icon: IconType;
    } & Omit<ButtonProps, "ref">;
} & TextAreaProps &
    InputProps;

const ERROR_VANISH_DURATION = 300;
const Input = ({
    label,
    isPasswordInput,
    validations,
    as = "input",
    errorMessage: errMsg,
    labelClassName,
    containerClassName,
    disabled,
    readOnly,
    isInvalid,
    onFocus,
    onBlur,
    onChange,
    separateThousands,
    inputClassName,
    inputWrapperClassName,
    action,
    endContent,
    ...rest
}: Props) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showPassword, setShowPassword] = useState(!isPasswordInput);
    const [cursor, setCursor] = useState<number | null>(null);

    const Component = as === "textarea" ? NextTextarea : (NextInput as any);

    const componentRef = useRef<HTMLInputElement>(null);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        onFocus?.(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        onBlur?.(e);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (separateThousands) {
            const oldValue = e.target.value;
            const newValue =
                Utils.Parsers.convertInputToNumberOfThousands(oldValue);
            const selectionStart = e.target.selectionStart;

            if (selectionStart !== null) {
                setCursor(
                    Math.max(
                        selectionStart +
                            Utils.String.getCommasNumber(
                                newValue,
                                selectionStart + 1
                            ) -
                            Utils.String.getCommasNumber(
                                oldValue,
                                selectionStart + 1
                            ),
                        0
                    )
                );
            } else {
                setCursor(null);
            }

            e.target.value = newValue;
        }

        onChange?.(e);
    };

    const numberInputOnWheelPreventChange = (e: any) => {
        e.target.blur();
        e.stopPropagation();
    };

    useEffect(() => {
        if (!errMsg) {
            setTimeout(() => {
                setErrorMessage(undefined);
            }, ERROR_VANISH_DURATION);
        } else {
            setErrorMessage(errMsg);
        }
    }, [errMsg]);

    useLayoutEffect(() => {
        if (!separateThousands || cursor === null || !componentRef.current) {
            return;
        }
        componentRef.current.setSelectionRange(cursor, cursor);
    }, [separateThousands, cursor, rest.value]);

    return (
        <div className={`relative ${containerClassName}`}>
            {label && (
                <div
                    className={`mb-2 text-sm text-default-500 ${labelClassName}`}
                >
                    {label}
                </div>
            )}

            <div className="relative w-auto">
                {/* @deprecated */}
                {isPasswordInput && (
                    <div className="absolute right-[10px] top-1/2 z-40 -translate-y-1/2">
                        <Icon
                            type={showPassword ? "Eye" : "EyeOff"}
                            onClick={() => setShowPassword((prev) => !prev)}
                        />
                    </div>
                )}

                {action && (
                    <ButtonIcon
                        customType="light"
                        className={
                            "absolute right-1 top-1/2 z-40 -translate-y-1/2"
                        }
                        {...action}
                    />
                )}

                <Component
                    {...rest}
                    ref={componentRef}
                    onChange={handleChange}
                    onWheel={numberInputOnWheelPreventChange}
                    disabled={disabled}
                    readOnly={readOnly}
                    isInvalid={isInvalid}
                    type={
                        !isPasswordInput
                            ? rest.type
                            : showPassword
                              ? undefined
                              : "password"
                    }
                    onBlur={validations?.length ? handleBlur : onBlur}
                    onFocus={validations?.length ? handleFocus : onFocus}
                    classNames={{
                        label: `text-black text-sm mb-2`,
                        errorMessage: "text-danger-500 mt-2",
                        base: "bg-[transparent] !self-stretch !w-[unset]",
                        input: cn(
                            "placeholder:text-default-400",
                            `${disabled && "!text-default-400 !self-stretch"} ${inputClassName}`
                        ),
                        innerWrapper:
                            "bg-[transparent] h-auto focus:border-[#9753FC] text-sm !self-stretch !w-[100%]",
                        inputWrapper: cn(
                            `
                        p-[14px] border border-solid rounded-md border-default-200 shadow-sm h-[unset] min-h-[unset] bg-white 
                        data-[hover=true]:bg-white
                        data-[hover=true]:!border-[#9753FC]
                        data-[hover=true]:${readOnly ? "border-none" : "border-primary-500"}
                        group-data-[focus=true]:bg-white
                        group-data-[focus=true]:shadow-focus-ring
                        hover:group-data-[invalid=true]:bg-transparent
                        hover:group-data-[invalid=true]:border-[#9753FC]
                        group-data-[focus-visible=true]:ring-0
                        focus-within:${readOnly ? "border-none" : "border-[#9753FC]"}
                        ${errMsg && "border-danger-500"}
                        group-data-[invalid=true]:!bg-white
                        !self-stretch !w-[unset]
                        ${disabled && "!bg-default-50 !text-default-400 border-none !self-stretch !w-[unset]"}
                        ${readOnly && "!bg-default-50 !self-stretch !w-[unset]"}
                        ${inputWrapperClassName}
                    `,
                            readOnly &&
                                "!cursor-auto !border-none !shadow-none",
                            action && "!pr-[calc(4px+40px+8px)]"
                        ),
                    }}
                    endContent={
                        typeof endContent === "string" ? (
                            <p className="text-default-500">{endContent}</p>
                        ) : (
                            endContent
                        )
                    }
                />
            </div>

            {validations?.length && (
                <div className="mt-2 flex flex-row flex-wrap gap-2">
                    {validations?.map(({ label: lbl, type: tpe }) => (
                        <div
                            key={lbl}
                            className={`flex flex-row gap-1 text-sm ${tpe === "success" ? "bg-secondary-100" : "bg-[#F1F1F5]"} rounded-sm p-[6px] pr-[10px] ${tpe === "success" ? "text-secondary-500" : "text-default-400"}`}
                        >
                            <Icon
                                type="Check"
                                className={
                                    tpe === "success"
                                        ? "text-secondary-500"
                                        : "text-default-400"
                                }
                            />
                            {lbl}
                        </div>
                    ))}
                </div>
            )}

            {rest.maxLength && (
                <div className="mt-2 text-sm text-default-400">
                    {rest.value?.length} / {rest.maxLength}
                </div>
            )}

            <Collapsed isExpanded={!!errMsg} duration={ERROR_VANISH_DURATION}>
                <div className="mt-2 text-sm text-danger-500">
                    {errMsg || errorMessage}
                </div>
            </Collapsed>
        </div>
    );
};

export default Input;
