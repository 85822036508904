import { GuidelineStep } from "routes/dashboard/Guideline";
import { MeResponse } from "store/api/auth/interfaces";
import { UserType } from "store/api/invitation/interfaces";
import { TeamResponse } from "store/api/member/interfaces";
import { SurveysResponse } from "store/api/survey/interfaces";

export const getGuidelineSteps = (
    meData: MeResponse | undefined,
    teamData: TeamResponse | undefined,
    surveysData: SurveysResponse | undefined
) => {
    if (!teamData || !surveysData) {
        return [];
    }

    const meMember = teamData.data.members.find(
        (m) =>
            meData?.data?.user?.type === UserType.Member &&
            m.id === meData?.data?.user?.memberId
    );
    const steps: GuidelineStep[] = [];

    if (meMember) {
        meMember.surveys
            .filter((s) => !s.isSubmitted)
            .forEach((s) => {
                const survey = surveysData.data.find((i) => i.key === s.key);

                steps.push({
                    label: `${survey?.name ?? s.key}`,
                    link: `/surveys/${s.key}`,
                });
            });
    }

    return steps;
};
