import { cva, VariantProps } from "class-variance-authority";
import Icon, { IconType } from "components/Icon";
import { cn } from "lib/utils";
import React from "react";
import * as LinkPrimitive from "react-router-dom";

const linkVariants = cva(
    "group flex w-max items-center justify-center gap-x-2 underline-offset-4",
    {
        variants: {
            variant: {
                primary:
                    "text-primary-500 hover:underline aria-disabled:cursor-not-allowed aria-disabled:text-primary-300 aria-disabled:hover:no-underline",
                foreground:
                    "text-foreground underline hover:text-primary-500 aria-disabled:cursor-not-allowed aria-disabled:text-default-400",
                icon: "text-foreground hover:text-primary-500 aria-disabled:cursor-not-allowed aria-disabled:text-default-400",
            },
            size: {
                sm: "text-sm",
                md: "text-md",
            },
        },
        defaultVariants: {
            variant: "foreground",
            size: "sm",
        },
    }
);

type LinkProps = React.ComponentPropsWithoutRef<typeof LinkPrimitive.Link> &
    VariantProps<typeof linkVariants> & {
        icon?: IconType;
    };

const Link = React.forwardRef<
    React.ElementRef<typeof LinkPrimitive.Link>,
    LinkProps
>(
    (
        {
            className,
            "aria-disabled": ariaDisabled,
            to,
            variant,
            size,
            icon,
            children,
            ...props
        },
        ref
    ) => {
        return (
            <LinkPrimitive.Link
                ref={ref}
                aria-disabled={ariaDisabled}
                to={ariaDisabled === "true" ? "" : to}
                className={cn(linkVariants({ variant, size }), className)}
                {...props}
            >
                {children}
                {icon && variant === "icon" && (
                    <Icon
                        type={icon}
                        className="group-hover:text-primary-500 group-aria-disabled:text-default-400"
                    />
                )}
            </LinkPrimitive.Link>
        );
    }
);
Link.displayName = "Link";

export { Link };
