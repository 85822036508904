import * as yup from "yup";
import { FC, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanyEditProps } from "./interfaces";
import Button from "components/Button";
import Input from "components/Input";
import { Controller, useForm } from "react-hook-form";
import InputSelect from "components/InputSelect";
import Utils from "utils";
import { BusinessType, Industry } from "data/onboarding";
import { useLocationSearch } from "hooks/useLocationSearch";
import GridSelect from "components/GridSelect";
import DateInputMonthYear from "components/DateInputMonthYear";
import { useUpdateCompanyMutation } from "store/api/member";
import { InferFieldValues } from "types/utils";
import FileInput, { AllowedFileTypes } from "components/FileInput";
import API from "api";
import { useDispatch } from "react-redux";
import { GlobalActions } from "store/reducers/global";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";

const industryArray = Utils.Parsers.enumToArray(Industry);
const businessTypeArray = Utils.Parsers.enumToArray(BusinessType);

const schema = yup.object({
    logo: Utils.Validations.image,
    name: yup.string().required("Required"),
    website: Utils.Validations.websiteUrl,
    industry: Utils.Validations.searchOption,
    country: Utils.Validations.searchOption,
    foundingDate: Utils.Validations.fullDateInput,
    businessType: yup.string().required("Business type is required"),
    descriptionShort: yup.string().required("Required").max(300, "Too long"),
    descriptionLong: yup.string().required("Required").max(1_000, "Too long"),
});

const CompanyEdit: FC<CompanyEditProps> = ({ company }) => {
    const dispatch = useDispatch();
    const [updateCompany, updateCompanyData] = useUpdateCompanyMutation();
    const { data: countryData, setQuery: setCityQuery } = useLocationSearch({
        type: "country",
    });

    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm({
        resolver: yupResolver(schema) as any,
        mode: "onBlur",
        reValidateMode: "onBlur",
        values: {
            logo: company.logo ?? null,
            website: company.website
                ? Utils.Parsers.formatWebsiteUrlToPresent(company.website)
                : "",
            name: company.name,
            country: {
                label: company.country,
                value: company.country,
            },
            industry: {
                label: company.industry,
                value: company.industry,
            },
            businessType: company.businessType,
            foundingDate: Utils.Date.toISODate(company.foundingDate) ?? "", // @TODO: can founding date be empty? if yes test how date input would behave
            descriptionShort: company.descriptionShort,
            descriptionLong: company.descriptionLong,
        },
    });

    const onSubmit = async (data: InferFieldValues<typeof handleSubmit>) => {
        const logo =
            data.logo instanceof File
                ? await API.FileUpload.image(data.logo)
                : data.logo;

        updateCompany({
            name: data.name,
            website: Utils.Parsers.formatWebsiteUrl(data.website),
            businessType: data.businessType,
            descriptionLong: data.descriptionLong,
            descriptionShort: data.descriptionShort,
            foundingDate: new Date(data.foundingDate),
            country: data.country.value,
            industry: data.industry?.value,
            logo: logo?.id ?? null,
        });
    };

    useEffect(() => {
        if (updateCompanyData.isUninitialized || updateCompanyData.isLoading) {
            return;
        }

        dispatch(
            GlobalActions.showPopover({
                type: updateCompanyData.isSuccess ? "success" : "error",
                label: updateCompanyData.isSuccess
                    ? `Profile updated`
                    : "An error occurred updating company profile",
            })
        );
    }, [updateCompanyData]);

    return (
        <>
            <div className="mx-auto max-w-[638px] px-8 py-10 pb-[100px]">
                <div className="fixed left-0 right-0 top-0 flex flex-row items-center justify-between p-12">
                    <img src={Logo} className="h-[48px] w-[48px]" />

                    <Button
                        as={Link}
                        to="/dashboard"
                        customType="secondary-light"
                        icon="X"
                        iconPosition="left"
                    >
                        Close
                    </Button>
                </div>

                <div className="mb-10 flex items-center justify-between">
                    <h1 className="text-xl font-bold">Edit company profile</h1>
                </div>

                <section>
                    <h2 className="mb-6 font-semibold">Profile</h2>
                    <div className="flex flex-col gap-8 rounded-2xl bg-default-25 p-10">
                        <Controller
                            name="logo"
                            control={control}
                            render={({
                                field: { onChange, value, onBlur },
                                fieldState: { error },
                            }) => (
                                <FileInput
                                    label="Company logo"
                                    onBlur={onBlur}
                                    selectedFile={value}
                                    errorMessage={error?.message}
                                    fileTypes={AllowedFileTypes.Image}
                                    setFiles={(files) => onChange(files)}
                                    uploadLabel="Drag or upload a company logo"
                                />
                            )}
                        />

                        <Controller
                            name="name"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Input
                                    {...field}
                                    label="Company name"
                                    errorMessage={error?.message}
                                    placeholder="Your company name"
                                />
                            )}
                        />
                        <Controller
                            name="website"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Input
                                    {...field}
                                    label="Website"
                                    errorMessage={error?.message}
                                    placeholder="https://yoursite.com"
                                />
                            )}
                        />
                    </div>
                </section>

                <section className="mt-10">
                    <h2 className="mb-6 font-semibold">Details</h2>
                    <div className="flex flex-col gap-8 rounded-2xl bg-default-25 p-10">
                        <Controller
                            name="industry"
                            control={control}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <InputSelect
                                    label="Industry"
                                    selected={value as any}
                                    handleSelect={onChange}
                                    options={industryArray}
                                    errorMessage={error?.message}
                                    placeholder="Type to search; select from drop-down"
                                    getOptionValue={(i) => i?.value}
                                    renderOptionLabel={(i) => i.label}
                                />
                            )}
                        />

                        <Controller
                            name="country"
                            control={control}
                            render={({
                                fieldState: { error },
                                field: { value, onChange },
                            }) => (
                                <InputSelect
                                    isMulti={false}
                                    options={countryData}
                                    selected={value as any}
                                    handleSelect={onChange}
                                    defaultValue={value as any}
                                    errorMessage={error?.message}
                                    placeholder="Type to search countries; select from drop-down"
                                    handleInputChange={setCityQuery}
                                    getOptionValue={(option) => option?.value}
                                    renderOptionLabel={(option) => option.label}
                                    label="Country of incorporation"
                                />
                            )}
                        />

                        <Controller
                            name="businessType"
                            control={control}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <div>
                                    <GridSelect
                                        label="Business type"
                                        options={businessTypeArray}
                                        selected={value ? [value] : []}
                                        onSelect={(e) => onChange(e[0])}
                                    />
                                    {error?.message}
                                </div>
                            )}
                        />
                        <Controller
                            name="foundingDate"
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState: { error },
                            }) => (
                                <DateInputMonthYear
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errorMessage={error?.message}
                                    label="Founding date"
                                />
                            )}
                        />
                    </div>
                </section>

                <section className="mt-10">
                    <h2 className="mb-6 font-semibold">Description</h2>
                    <div className="flex flex-col gap-8 rounded-2xl bg-default-25 p-10">
                        <Controller
                            name="descriptionShort"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Input
                                    {...field}
                                    as="textarea"
                                    maxLength={300}
                                    errorMessage={error?.message}
                                    label="Explain what your company does in 1 sentence"
                                    placeholder="Describe using no more than 300 characters"
                                />
                            )}
                        />

                        <Controller
                            name="descriptionLong"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Input
                                    as="textarea"
                                    {...field}
                                    maxLength={1_000}
                                    errorMessage={error?.message}
                                    label="Elaborate what your company does in detail"
                                    placeholder="Describe using no more than 1000 characters"
                                />
                            )}
                        />
                    </div>
                </section>
            </div>

            <div className="fixed bottom-4 left-12 right-12 rounded-b-lg border-t border-solid bg-white">
                <div className="mx-auto my-4 max-w-[638px]">
                    <Button
                        disabled={!isDirty || !isValid}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Save changes
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CompanyEdit;
