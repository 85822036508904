import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Utils from "utils";
import SurveyCard from "./SurveyCard";
import { useTeamQuery } from "store/api/member";
import { useGetSurveysQuery } from "store/api/survey";

const SurveysCard: FC = () => {
    const navigate = useNavigate();

    const { data: surveysData } = useGetSurveysQuery();
    const { data: teamData } = useTeamQuery();
    const surveys = surveysData?.data.filter((s) => !s.isCompanyWide) ?? [];
    const members = teamData?.data.members ?? [];
    const total = surveys.length;
    const done = surveys.filter((survey) => survey.isSubmitted).length;

    const getSurveyTeam = (surveyKey: string) =>
        members
            .filter((i) => i.surveys.find((s) => s.key === surveyKey))
            .sort(Utils.Insight.teamMembersRoleNameCompareFn)
            .map((m) => ({
                id: m.id,
                img: m.picture,
                firstName: m.firstName,
                lastName: m.lastName,
                isSubmitted:
                    m.surveys.find((s) => s.key === surveyKey)?.isSubmitted ??
                    false,
            }))
            .sort((a, b) => Number(b.isSubmitted) - Number(a.isSubmitted));

    const finalSurveys = surveys.map((survey) => ({
        ...survey,
        team: getSurveyTeam(survey.key),
    }));

    return (
        <section className="w-full rounded-2xl bg-white p-6">
            <h3 className="mb-1 text-lg">Team surveys</h3>

            <div className="mb-6 text-sm text-default-400">
                {done}/{total} completed
            </div>

            <div className="grid grid-cols-2 gap-3 xl:grid-cols-4">
                {finalSurveys.map((survey) => (
                    <SurveyCard
                        key={survey.key}
                        survey={survey}
                        onClick={() => navigate(`/surveys/${survey.key}`)}
                    />
                ))}
            </div>
        </section>
    );
};

export default SurveysCard;
