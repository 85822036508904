import React from "react";
import {
    Card as NextCard,
    CardHeader as NextCardHeader,
    CardBody as NextCardBody,
    CardFooter as NextCardFooter,
} from "@nextui-org/card";
import { cn } from "lib/utils";

const Card = React.forwardRef<
    React.ElementRef<typeof NextCard>,
    React.ComponentPropsWithoutRef<typeof NextCard>
>(({ className, ...props }, ref) => (
    <NextCard
        ref={ref}
        className={cn(
            "rounded-xl shadow-sm transition-transform-colors-opacity",
            className
        )}
        {...props}
    />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef<
    React.ElementRef<typeof NextCardHeader>,
    React.ComponentPropsWithoutRef<typeof NextCardHeader>
>(({ className, ...props }, ref) => (
    <NextCardHeader
        ref={ref}
        className={cn("px-6 pb-4 pt-6", className)}
        {...props}
    />
));
CardHeader.displayName = "CardHeader";

const CardBody = React.forwardRef<
    React.ElementRef<typeof NextCardBody>,
    React.ComponentPropsWithoutRef<typeof NextCardBody>
>(({ className, ...props }, ref) => (
    <NextCardBody
        ref={ref}
        className={cn("px-6 pb-4 pt-0", className)}
        {...props}
    />
));
CardBody.displayName = "CardBody";

const CardFooter = React.forwardRef<
    React.ElementRef<typeof NextCardFooter>,
    React.ComponentPropsWithoutRef<typeof NextCardFooter>
>(({ className, ...props }, ref) => (
    <NextCardFooter
        ref={ref}
        className={cn("p-6 pt-0", className)}
        {...props}
    />
));
CardFooter.displayName = "CardFooter";

export { Card, CardHeader, CardBody, CardFooter };
