import { cva } from "class-variance-authority";
import { cn } from "lib/utils";
import { FC, useMemo } from "react";
import { InsightsIndividualMember } from "store/api/investorCompany/interfaces";
import {
    PersonalInformation as TPersonalInformation,
    personalInformationSchema,
} from "../../schemas/personal-information-schema";
import Divider from "components/Divider";
import { Gender } from "data/onboarding";

const wrapperVariants = cva("flex flex-col gap-y-1");
const titleVariants = cva("text-sm font-semibold text-default-400");
const descriptionVariants = cva("text-sm font-semibold text-foreground");

type Props = {
    memberData: InsightsIndividualMember;
};

const PersonalInformation: FC<Props> = ({ memberData }) => {
    const { location, nationality, yearOfBirth, gender, genderDescription } =
        useMemo((): TPersonalInformation => {
            const data = {
                location: memberData?.["member-location"]?.value,
                nationality: memberData?.["member-nationality"]?.value,
                yearOfBirth: memberData?.["member-year"]?.value,
                gender: memberData?.["member-gender"]?.value,
                genderDescription:
                    memberData?.["member-gender-description"]?.value,
            };
            const dataParsed = personalInformationSchema.safeParse(data);
            if (!dataParsed.success) {
                return {
                    location: { city: "", country: "" },
                    nationality: [],
                    yearOfBirth: 0,
                    gender: "",
                    genderDescription: null,
                };
            }
            return dataParsed.data;
        }, [memberData]);
    const showDescription: boolean =
        !!genderDescription && gender === Gender.SelfDescribe;

    return (
        <div>
            <div className="grid auto-cols-auto grid-flow-col justify-between gap-x-4">
                <div className={cn(wrapperVariants())}>
                    <h6 className={cn(titleVariants())}>Location</h6>
                    <p
                        className={cn(descriptionVariants())}
                    >{`${location.city}, ${location.country}`}</p>
                </div>
                <div className={cn(wrapperVariants())}>
                    <h6 className={cn(titleVariants())}>Nationality</h6>
                    <p className={cn(descriptionVariants())}>
                        {nationality.join(", ")}
                    </p>
                </div>
                <div className={cn(wrapperVariants())}>
                    <h6 className={cn(titleVariants())}>Year of birth</h6>
                    <p className={cn(descriptionVariants())}>{yearOfBirth}</p>
                </div>
                {!showDescription && (
                    <div className={cn(wrapperVariants())}>
                        <h6 className={cn(titleVariants())}>Gender</h6>
                        <p className={cn(descriptionVariants())}>{gender}</p>
                    </div>
                )}
            </div>
            {showDescription && (
                <>
                    <Divider className="my-6" />
                    <div className={cn(wrapperVariants())}>
                        <h6 className={cn(titleVariants())}>
                            Gender description
                        </h6>
                        <p className={cn(descriptionVariants())}>
                            {genderDescription}
                        </p>
                    </div>
                </>
            )}
        </div>
    );
};

export default PersonalInformation;
