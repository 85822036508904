import Icon from "components/Icon";
import { FC, useMemo } from "react";
import { InsightsIndividualMember } from "store/api/investorCompany/interfaces";
import {
    Linkedin as TLinkedin,
    linkedinSchema,
} from "../../schemas/linkedin-schema";

type Props = {
    memberData: InsightsIndividualMember;
};

const Linkedin: FC<Props> = ({ memberData }) => {
    const values = useMemo((): TLinkedin | null => {
        const data = {
            link: memberData?.["member-linkedin"]?.value,
        };
        const dataParsed = linkedinSchema.safeParse(data);
        if (!dataParsed.success) {
            return null;
        }
        return dataParsed.data;
    }, [memberData]);

    if (!values) {
        return null;
    }

    const { link } = values;

    return (
        <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex w-max items-center gap-2"
        >
            <Icon type="Link" size={20} />
            <span className="text-sm font-semibold text-foreground underline">
                Linkedin
            </span>
        </a>
    );
};

export default Linkedin;
