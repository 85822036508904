import global from "./global";
import memberOnboarding from "./memberOnboarding";
import companyOnboarding from "./companyOnboarding";
import investorOnboarding from "./investorOnboarding";

const reducers = {
    global,
    memberOnboarding,
    companyOnboarding,
    investorOnboarding,
};

export default reducers;
