export enum Gender {
    Female = "Female",
    Male = "Male",
    NonBinary = "Non-binary",
    SelfDescribe = "Prefer to self-describe",
    NotSpecified = "Wish not to specify",
}

export enum BusinessType {
    B2B = "B2B",
    B2C = "B2C",
    B2B2C = "B2B2C",
    DTC = "DTC",
    Other = "Other",
}

export enum MemberRole {
    CEO = "CEO",
    CTO = "CTO",
    CPOProduct = "CPO (Product)",
    CPOPeople = "CPO (People)",
    CMO = "CMO",
    CFO = "CFO",
    CCO = "CCO",
    COO = "COO",
    Other = "Other",
}

export enum Industry {
    AdTech = "Adtech",
    AdvancedManufacturing = "Advanced manufacturing",
    AgTech = "Agtech",
    ArtificialIntelligenceAndMachineLearning = "Artificial intelligence and machine learning (AI/ML)",
    AudioTech = "Audiotech",
    AugmentedReality = "Augmented reality (AR)",
    AutonomousCars = "Autonomous cars",
    B2BPayments = "B2B payments",
    Beauty = "Beauty",
    BigData = "Big Data",
    Cannabis = "Cannabis",
    CarSharing = "Carsharing",
    CleanTech = "Cleantech",
    ClimateTech = "Climate tech",
    CloudTechAndDevOps = "Cloudtech and DevOps",
    ConstructionTechnology = "Construction technology",
    CryptocurrencyAndBlockchain = "Cryptocurrency and blockchain",
    Cybersecurity = "Cybersecurity",
    Dating = "Dating",
    DigitalHealth = "Digital health",
    Ecommerce = "Ecommerce",
    EdTech = "Edtech",
    EphemeralContent = "Ephemeral content",
    ESports = "eSports",
    FashionTech = "Fashion tech",
    FemTech = "Femtech",
    FinTech = "Fintech",
    FoodTech = "Foodtech",
    FutureOfWork = "Future of Work",
    Gaming = "Gaming",
    HealthTech = "Healthtech",
    HRtech = "HRtech",
    ImpactInvesting = "Impact investing",
    Industrials = "Industrials",
    Infrastructure = "Infrastructure",
    InsurTech = "Insurtech",
    InternetOfThings = "Internet of Things (IoT)",
    LegalTech = "Legal tech",
    LifeSciences = "Life sciences",
    LOHASAndWellness = "Lifestyles of Health and Sustainability (LOHAS) and wellness",
    Manufacturing = "Manufacturing",
    Media = "Media",
    MarketingTech = "Marketing tech",
    MicroMobility = "Micro-mobility",
    Mobile = "Mobile",
    MobileCommerce = "Mobile commerce",
    MobilityTech = "Mobility tech",
    MortgageTech = "Mortgage tech",
    Music = "Music",
    Nanotechnology = "Nanotechnology",
    OilAndGas = "Oil and gas",
    PetTech = "Pet tech",
    RealEstateTech = "Real estate tech",
    RestaurantTech = "Restaurant tech",
    RideSharing = "Ridesharing",
    RoboticsAndDrones = "Robotics and drones",
    Security = "Security",
    SocialMedia = "Social media",
    SoftwareAsAService = "Software as a service (SaaS)",
    SpaceTech = "Space tech",
    SupplyChainTechnology = "Supply chain technology",
    TMT = "Technology, media and telecommunications (TMT)",
    Travel = "Travel",
    VR = "Virtual reality (VR)",
    WearablesAndQuantifiedSelf = "Wearables and quantified self",
    Web3 = "Web 3",
    ThreeDPrinting = "3D printing",
}
