import { FC } from "react";
import ProfileEditContent from "./ProfileEdit";
import { useProfileQuery } from "store/api/member";
import { useGetMeQuery } from "store/api/auth";

const ProfileEdit: FC = () => {
    const { data: meData } = useGetMeQuery();
    const { data: profileData } = useProfileQuery();

    const profile = profileData?.data;
    const me = meData?.data;

    if (!profile || !me) {
        return null;
    }

    return (
        <div className="h-[100vh] bg-default-100 p-4">
            <div
                className={`relative h-[100%] overflow-scroll rounded-lg bg-white px-[108px] md:px-[127px] lg:px-[177px] xl:px-[305px] 2xl:px-[433px] 3xl:px-[625px]`}
            >
                <ProfileEditContent profile={profile} me={me} />
            </div>
        </div>
    );
};

export default ProfileEdit;
