import { cn } from "lib/utils";
import { useAutocomplete } from "@mui/base";
import { useCallback, useEffect, useState } from "react";

import Icon from "components/Icon";
import Collapsed from "components/Collapsed";
import SearchOption from "./components/SearchOption";
import { Root, InputWrapper, Listbox } from "./components/styled";

type SingleProps<T> = {
    isMulti?: false;
    selected: T | null;
    defaultValue?: T | null;
    handleSelect: (value: T | null) => void;
};

type MultipleProps<T> = {
    isMulti: true;
    selected: Array<T>;
    defaultValue?: Array<T>;
    handleSelect: (options: Array<T>) => void;
};

type GeneralProps<T> = {
    id?: string;

    className?: string;
    listBoxClassName?: string;
    labelClassName?: string;

    options: any;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    errorMessage?: string;
    nativeAutocomplete?: string;

    onBlur?: () => void;

    handleInputChange?: (value: string) => void;
    renderOptionLabel: (option: T) => string | number;
    getOptionValue: (option: T) => string | number;
};

const ERROR_VANISH_DURATION = 300;
type Props<T> = GeneralProps<T> & (SingleProps<T> | MultipleProps<T>);

const InputSelect = <T,>({
    id,
    label,
    onBlur,
    options,
    isMulti,
    disabled,
    selected,
    className,
    defaultValue,
    handleSelect,
    getOptionValue,
    labelClassName,
    listBoxClassName,
    renderOptionLabel,
    handleInputChange,
    errorMessage: errMsg,
    nativeAutocomplete,
    placeholder = "Add your answer here",
}: Props<T>) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const {
        value,
        focused,
        expanded,
        groupedOptions,
        getTagProps,
        setAnchorEl,
        getRootProps,
        getInputProps,
        getOptionProps,
        getListboxProps,
    } = useAutocomplete({
        options,
        disabled,
        value: selected,
        multiple: isMulti,
        clearOnBlur: true,
        clearOnEscape: true,
        id: id || placeholder,
        disableCloseOnSelect: isMulti,
        defaultValue: defaultValue as Array<T>,
        onChange: (a: any, val: any) => {
            const filterDuplicates = (array: any[]) => {
                const countMap = array.reduce((acc, item) => {
                    const key = getOptionValue(item);
                    acc[key] = (acc[key] || 0) + 1;
                    return acc;
                }, {});
                return array.filter(
                    (item) => countMap[getOptionValue(item)] === 1
                );
            };

            const result = isMulti ? filterDuplicates(val) : val;
            handleSelect(result);
        },
        onInputChange: handleInputChange
            ? (a: any, val: string) => handleInputChange(val)
            : undefined,
    });

    useEffect(() => {
        if (!errMsg) {
            setTimeout(() => {
                setErrorMessage(undefined);
            }, ERROR_VANISH_DURATION);
        } else {
            setErrorMessage(errMsg);
        }
    }, [errMsg]);

    // Right now only works with single item inputs and only with SearchOption
    const handleNativeAutocomplete = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (value !== null || isMulti) {
                return;
            }
            const targetValue = e.target.value;
            const searchResult = options.find((v: unknown) => {
                const el = (typeof v === "object" ? v : {}) ?? {};
                if (!("value" in el)) {
                    return false;
                }
                return el.value === targetValue;
            });
            if (!searchResult) {
                return;
            }
            handleSelect(searchResult);
        },
        [value, isMulti, options, handleSelect]
    );

    return (
        <Root className={`relative ${className}`}>
            <div {...getRootProps()} className="w-[100%] rounded-md">
                <div
                    className={cn(
                        "mb-2 text-sm text-default-500",
                        labelClassName
                    )}
                >
                    {label}
                </div>

                <InputWrapper
                    onBlur={onBlur}
                    ref={setAnchorEl}
                    className={cn(
                        "w-[100%] !gap-2 !rounded-md border border-solid border-default-200 !px-[14px] !py-[10px] shadow-sm",
                        disabled
                            ? "!cursor-not-allowed !bg-default-50 hover:!border-transparent"
                            : "hover:!border-[#9753FC]",
                        focused && "!border-[#9753FC] !shadow-focus-ring",
                        errorMessage && !focused && "!border-[#FC3F55]"
                    )}
                >
                    {isMulti
                        ? value.map((option: T, index: number) => {
                              const { key, onDelete } = getTagProps({ index });

                              if (!option) return undefined;

                              return (
                                  <div
                                      key={key}
                                      className={
                                          "flex flex-row items-center justify-center gap-2 rounded-sm bg-default-50 px-2 py-1 text-sm text-black"
                                      }
                                  >
                                      {renderOptionLabel(option)}
                                      <Icon
                                          type="X"
                                          size={15}
                                          onClick={
                                              disabled ? undefined : onDelete
                                          }
                                          className="cursor-pointer"
                                      />
                                  </div>
                              );
                          })
                        : undefined}
                    <input
                        {...getInputProps()}
                        onBlur={(e) => {
                            onBlur?.();
                            getInputProps()?.onBlur?.(e);
                        }}
                        placeholder={placeholder}
                        className={cn(
                            "w-[100%] !min-w-[90px] cursor-pointer !bg-transparent placeholder:text-default-400",
                            disabled && "cursor-not-allowed"
                        )}
                    />
                    <div className="pointer-events-none absolute right-[14px] place-self-center self-center">
                        <Icon
                            type="ChevronDown"
                            className={`transition-transform duration-300 ${!!expanded && !!groupedOptions?.length ? "rotate-180" : ""}`}
                        />
                    </div>
                </InputWrapper>

                <Collapsed
                    isExpanded={!!errMsg}
                    duration={ERROR_VANISH_DURATION}
                >
                    <div className="mt-2 text-sm text-danger-500">
                        {errMsg || errorMessage}
                    </div>
                </Collapsed>
            </div>

            {(groupedOptions as any).length > 0 ? (
                <Listbox
                    {...getListboxProps()}
                    className={`relative left-0 right-0 flex flex-col !gap-1 !p-2 ${listBoxClassName}`}
                >
                    {(groupedOptions as any).map((option: any, index: any) => {
                        const { key, ...optionProps } = getOptionProps({
                            option,
                            index,
                        }) as any;

                        const optionValue = getOptionValue(option);

                        const isSelected = isMulti
                            ? value.some(
                                  (i: any) =>
                                      optionValue === getOptionValue(i) &&
                                      typeof optionValue !== "undefined"
                              )
                            : typeof value !== "undefined" &&
                              optionValue === getOptionValue(value);

                        return (
                            <SearchOption
                                key={optionValue}
                                isMulti={isMulti}
                                isSelected={isSelected}
                                optionProps={optionProps}
                                label={renderOptionLabel(option)}
                            />
                        );
                    })}
                </Listbox>
            ) : null}

            {nativeAutocomplete && !isMulti && (
                <input
                    name={label}
                    autoComplete={nativeAutocomplete}
                    type="text"
                    aria-hidden="true"
                    className="pointer-events-none absolute opacity-0"
                    onChange={handleNativeAutocomplete}
                />
            )}
        </Root>
    );
};

export default InputSelect;
