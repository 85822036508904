import React, { useState } from "react";

type Props = {
    title?: string;
    description: string;
    considerLarge?: number;
};

const ExpandableDescription: React.FC<Props> = ({
    title,
    description,
    considerLarge = 445,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isLarge = description.length > considerLarge;

    const toggleExpansion = () => setIsExpanded(!isExpanded);

    const label =
        isLarge && !isExpanded
            ? `${description.substring(0, considerLarge)}`
            : description;

    return (
        <div className="flex flex-col gap-2">
            {title && <div className="text-sm text-default-400">{title}</div>}

            <div className="text-sm">
                <div>{label}</div>

                {isLarge && (
                    <button
                        onClick={toggleExpansion}
                        className="mt-2 text-primary-500"
                    >
                        {isExpanded ? "Show less" : "Show more"}
                    </button>
                )}
            </div>
        </div>
    );
};

export default ExpandableDescription;
