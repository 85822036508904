import {
    MeResponse,
    SignUpRequest,
    SignInRequest,
    ResetPassword,
    SignUpResponse,
    ForgotPassword,
    ChangePasswordResponse,
    ChangePasswordRequest,
} from "./interfaces";
import { api } from "../api";

const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        signIn: builder.mutation<SignUpResponse, SignInRequest>({
            query: (body) => ({
                url: "app/auth/sign-in",
                method: "POST",
                body: JSON.stringify(body),
            }),
            invalidatesTags: () => ["Me", "Onboarding"],
        }),
        signUp: builder.mutation<SignUpResponse, SignUpRequest>({
            query: (body) => ({
                url: "app/auth/sign-up",
                method: "POST",
                body: JSON.stringify(body),
            }),
            invalidatesTags: () => ["Me", "Onboarding"],
        }),
        signOut: builder.mutation<void, void>({
            query: () => ({
                url: "app/auth/sign-out",
                method: "POST",
            }),
            invalidatesTags: () => [], // do not invalidate tags by rtk but rather do a page refresh
        }),
        getMe: builder.query<MeResponse, void>({
            query: () => ({
                url: `app/me`,
                method: "GET",
            }),
            providesTags: () => ["Me"],
        }),
        sendForgotPassword: builder.mutation<SignUpResponse, ForgotPassword>({
            query: (body) => ({
                url: "app/auth/forgot-password",
                method: "POST",
                body: JSON.stringify(body),
            }),
            invalidatesTags: () => ["Me", "Onboarding"],
        }),
        sendResetPassword: builder.mutation<SignUpResponse, ResetPassword>({
            query: (body) => ({
                url: "app/auth/reset-password",
                method: "POST",
                body: JSON.stringify(body),
            }),
            invalidatesTags: () => ["Me", "Onboarding"],
        }),
        changePassword: builder.mutation<
            ChangePasswordResponse,
            ChangePasswordRequest
        >({
            query: (body) => ({
                url: "app/shared/password/",
                method: "POST",
                body: JSON.stringify(body),
            }),
            invalidatesTags: () => ["Me", "Onboarding"],
        }),
    }),
});

export const {
    useGetMeQuery,
    useSignInMutation,
    useSignUpMutation,
    useSignOutMutation,
    useSendResetPasswordMutation,
    useSendForgotPasswordMutation,
    useChangePasswordMutation,
} = authApi;
