import { FC, ReactNode } from "react";

import InfoTooltip from "../../InfoTooltip";

type Props = { children: ReactNode; tooltip: string };

const Title: FC<Props> = ({ children, tooltip }) => {
    return (
        <div className="flex flex-row items-center gap-1">
            {children}
            <InfoTooltip text={tooltip} />
        </div>
    );
};

export default Title;
