import { FC } from "react";
import { PyramidProps } from "./interfaces";
import cn from "classnames";
import { ReactComponent as PyramidSvg } from "images/pyramid.svg";
import { ChartContainer } from "../chart";
import { Bar, BarChart, XAxis, YAxis } from "recharts";
import Utils from "utils";

const START_SIZE = 50;
const SIZE_STEP = 38;

const Pyramid: FC<PyramidProps> = ({ data, highlightedIdx, onSelectIndex }) => {
    const finalData = data.map((item) => ({
        ...item,
        fill: `url('#insight-score-${Utils.Status.getStatusType(item.value)}')`,
    }));

    return (
        <div className="flex items-center justify-center gap-x-8">
            <div className="mt-6 flex flex-col text-sm">
                {data.map((item, idx) => (
                    <button
                        type="button"
                        key={idx}
                        className={cn(
                            "flex w-[224px] justify-between rounded-sm px-2 py-1.5",
                            highlightedIdx === idx && "bg-default-50"
                        )}
                        onClick={() => onSelectIndex?.(idx)}
                    >
                        <span>{item.label}</span>
                        <span>{item.value?.toFixed(1)}</span>
                    </button>
                ))}
            </div>
            <div className="relative h-[208px] w-[248px]">
                <PyramidSvg className="absolute left-0 top-0" />
                <ChartContainer config={{}} className="h-full w-full">
                    <BarChart
                        data={finalData}
                        layout="vertical"
                        margin={{ left: 20, right: 20, top: 40, bottom: 12 }}
                        barCategoryGap={5}
                    >
                        <XAxis type="number" domain={[0, 5]} hide />
                        <YAxis type="category" hide />
                        <Bar
                            dataKey="value"
                            layout="vertical"
                            radius={4}
                            shape={(p: any) => {
                                const W = 208;
                                const wi = START_SIZE + SIZE_STEP * p.index;
                                const wn = (p.width / W) * wi;
                                const x = 20 + W / 2 - wi / 2;

                                return (
                                    <g transform={`translate(${x}, ${p.y})`}>
                                        {p.index === highlightedIdx && (
                                            <g transform="translate(-6, 6)">
                                                <path
                                                    d="M0 6.58569V1.41412C0 0.523211 1.07714 0.077042 1.70711 0.707007L4.29289 3.2928C4.68342 3.68332 4.68342 4.31648 4.29289 4.70701L1.70711 7.2928C1.07714 7.92276 0 7.47659 0 6.58569Z"
                                                    fill="#0F1117"
                                                />
                                            </g>
                                        )}
                                        <rect
                                            rx={p.radius}
                                            x={0}
                                            y={0}
                                            width={wi}
                                            height={p.height}
                                            fill="#F1F1F5"
                                        />
                                        <rect
                                            rx={p.radius}
                                            x={0}
                                            y={0}
                                            width={wn}
                                            height={p.height}
                                            fill={p.fill}
                                        />
                                    </g>
                                );
                            }}
                        />
                    </BarChart>
                </ChartContainer>
            </div>
        </div>
    );
};

export default Pyramid;
