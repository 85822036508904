type Props = {
    title: string;
    children: any;
    className?: string;
    containerClassName?: string;
};

const StatItemBg: React.FC<Props> = ({
    title,
    children,
    className,
    containerClassName,
}) => {
    return (
        <div
            className={`flex flex-col gap-2 p-5 rounded-lg bg-default-50 ${containerClassName}`}
        >
            <div className="text-sm text-default-400">{title}</div>
            <div className={className}>{children}</div>
        </div>
    );
};

export default StatItemBg;
