import { z } from "zod";

export const personalInformationSchema = z.object({
    location: z.object({
        city: z.string(),
        country: z.string(),
    }),
    nationality: z.array(z.string()),
    yearOfBirth: z.number(),
    gender: z.string(),
    genderDescription: z.string().nullish(),
});

export type PersonalInformation = z.infer<typeof personalInformationSchema>;
