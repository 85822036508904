import Button from "components/Button";
import { cn } from "lib/utils";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";

const SettingsLayout = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
    <div ref={ref} className="h-full bg-default-100 p-4" {...props}>
        <div
            className={cn(
                "relative h-full overflow-y-auto rounded-lg bg-white px-[108px] py-8 md:px-[127px] lg:px-[177px] xl:px-[305px] 2xl:px-[433px] 3xl:px-[625px]",
                className
            )}
        >
            {children}
        </div>
    </div>
));
SettingsLayout.displayName = "SettingsLayout";

const SettingsLayoutHeader = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn(
            "fixed left-0 top-0 flex w-full items-center justify-between p-12",
            className
        )}
        {...props}
    >
        <img src={Logo} className="h-[48px] w-[48px]" />
        <Button as={Link} to="/dashboard" icon="X" customType="secondary-light">
            Close
        </Button>
    </div>
));
SettingsLayoutHeader.displayName = "SettingsLayoutHeader";

const SettingsLayoutContent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn(
            "mx-auto flex max-w-[638px] flex-col gap-y-10",
            className
        )}
        {...props}
    />
));
SettingsLayoutContent.displayName = "SettingsLayoutContent";

const SettingsLayoutTitle = React.forwardRef<
    HTMLHeadingElement,
    React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
    <h1
        ref={ref}
        className={cn("text-xl font-bold text-foreground", className)}
        {...props}
    />
));
SettingsLayoutTitle.displayName = "SettingsLayoutTitle";

const SettingsLayoutBlock = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn("flex w-full flex-col gap-y-6", className)}
        {...props}
    />
));
SettingsLayoutBlock.displayName = "SettingsLayoutBlock";

const SettingsLayoutBlockTitle = React.forwardRef<
    HTMLHeadingElement,
    React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
    <h2
        ref={ref}
        className={cn("text-md font-semibold text-foreground", className)}
        {...props}
    />
));
SettingsLayoutBlockTitle.displayName = "SettingsLayoutBlockTitle";

export {
    SettingsLayout,
    SettingsLayoutHeader,
    SettingsLayoutContent,
    SettingsLayoutTitle,
    SettingsLayoutBlock,
    SettingsLayoutBlockTitle,
};
