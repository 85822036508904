/* eslint-disable @typescript-eslint/naming-convention */
import {
    InsightsTeam,
    InsightsIndividual,
} from "store/api/investorCompany/interfaces";
import Insight from "./Insight";
import LencioniPyramid from "./LencioniPyramid";
import OverallModal from "./Modals/OverallModal";
import LencioniModal from "./Modals/LencioniModal";
import { TOOLTIPS } from "routes/company/tooltips";
import { GaugeChart } from "components/ui/GaugeChart";
import ModalController from "routes/company/ModalController";
import TeamCompositionModal from "./Modals/TeamCompositionModal";
import useModalController from "routes/company/useModalController";
import StrategicConsensusModal from "./Modals/StrategicConsensusModal";
import PsychologicalSafetyModal from "./Modals/PsychologicalSafetyModal";
import useLencioniPyramidController from "./useLencioniPyramidController";

type Props = {
    teamData: InsightsTeam;
    individualData: InsightsIndividual;
};

const TeamDynamicsTeamTab: React.FC<Props> = ({
    teamData,
    individualData,
}: Props) => {
    const {
        td_overall,
        td_team_composition,
        td_strategic_consensus,
        td_psychological_safety,
        td_trust,
        td_conflict,
        td_commitment,
        td_accountability,
        td_result_orientation,
    } = teamData;
    const modalController = useModalController();
    const lencioniPyramidController = useLencioniPyramidController([
        {
            key: "td_result_orientation",
            label: td_result_orientation?.name,
            value: td_result_orientation?.value as number,
            data: td_result_orientation,
        },
        {
            key: "td_accountability",
            label: td_accountability?.name,
            value: td_accountability?.value as number,
            data: td_accountability,
        },
        {
            key: "td_commitment",
            label: td_commitment?.name,
            value: td_commitment?.value as number,
            data: td_commitment,
        },
        {
            key: "td_conflict",
            label: td_conflict?.name,
            value: td_conflict?.value as number,
            data: td_conflict,
        },
        {
            key: "td_trust",
            label: td_trust?.name,
            value: td_trust?.value as number,
            data: td_trust,
        },
    ]);
    const modals = [
        {
            title: "Overall score",
            content: (
                <OverallModal
                    teamData={teamData}
                    individualData={individualData}
                    tooltip={TOOLTIPS.teamDynamics.overall}
                />
            ),
        },
        {
            title: "Lencioni pyramid",
            content: (
                <LencioniModal
                    individualData={individualData}
                    tooltip={TOOLTIPS.teamDynamics.pyramid}
                    {...lencioniPyramidController}
                />
            ),
        },
        {
            title: "Strategic consensus",
            content: (
                <StrategicConsensusModal
                    teamData={teamData}
                    individualData={individualData}
                    tooltip={TOOLTIPS.teamDynamics.consensus}
                />
            ),
        },
        {
            title: "Psychological safety",
            content: (
                <PsychologicalSafetyModal
                    teamData={teamData}
                    individualData={individualData}
                    tooltip={TOOLTIPS.teamDynamics.safety}
                />
            ),
        },
        {
            title: "Team composition",
            content: (
                <TeamCompositionModal
                    teamData={teamData}
                    individualData={individualData}
                    tooltip={TOOLTIPS.teamDynamics.composition}
                />
            ),
        },
    ];

    return (
        <div className="md:flex md:justify-center lg:block">
            <ModalController modals={modals} {...modalController} />
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                <Insight
                    smallText
                    iconType="Users"
                    data={td_overall}
                    name="Overall score"
                    tooltip={TOOLTIPS.teamDynamics.overall}
                    onShowMore={() => modalController.open(0)}
                >
                    <GaugeChart value={(td_overall?.value as number) ?? 0} />
                </Insight>

                <div className="col-span-1 lg:col-span-2">
                    <LencioniPyramid
                        iconType="Pyramid"
                        name="Lencioni pyramid"
                        tooltip={TOOLTIPS.teamDynamics.pyramid}
                        onShowMore={() => modalController.open(1)}
                        {...lencioniPyramidController}
                    />
                </div>

                <Insight
                    name="Strategic consensus"
                    data={td_strategic_consensus}
                    iconType="Route"
                    tooltip={TOOLTIPS.teamDynamics.consensus}
                    onShowMore={() => modalController.open(2)}
                >
                    <GaugeChart
                        value={(td_strategic_consensus?.value as number) ?? 0}
                    />
                </Insight>
                <Insight
                    iconType="Smile"
                    name="Psychological safety"
                    data={td_psychological_safety}
                    tooltip={TOOLTIPS.teamDynamics.safety}
                    onShowMore={() => modalController.open(3)}
                >
                    <GaugeChart
                        value={(td_psychological_safety?.value as number) ?? 0}
                    />
                </Insight>
                <Insight
                    iconType="Component"
                    name="Team composition"
                    data={td_team_composition}
                    tooltip={TOOLTIPS.teamDynamics.composition}
                    onShowMore={() => modalController.open(4)}
                >
                    <GaugeChart
                        value={(td_team_composition?.value as number) ?? 0}
                    />
                </Insight>
            </div>
        </div>
    );
};

export default TeamDynamicsTeamTab;
