import { getNumberQuestionSchema } from "questions/Number/schemas/number-question-schema";
import { z } from "zod";

export const getNumberArrayQuestionSchema = (
    isRequired: boolean,
    hasThousandthsSeparator: boolean
) => {
    return z.array(
        getNumberQuestionSchema(isRequired, hasThousandthsSeparator)
    );
};
