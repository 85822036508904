import { FC } from "react";
import {
    InsightsIndividual,
    InsightsTeam,
} from "store/api/investorCompany/interfaces";
import { HeartChart } from "components/ui/HeartChart";
import InsightStatus from "../InsightStatus";
import IndividualScores from "routes/company/components/IndividualScores";
import Title from "routes/company/components/Modal/Title";
import InsightDescription from "routes/company/components/Modal/InsightDescription";

type Props = {
    teamData: InsightsTeam;
    individualData: InsightsIndividual;
    tooltip: string;
};

const EntrepreneurshipPassionModal: FC<Props> = ({
    tooltip,
    teamData: { ep_passion },
    individualData,
}) => {
    const epPassionValue = (ep_passion?.value as number) ?? 0;

    return (
        <div className="flex flex-col gap-4">
            <Title tooltip={tooltip}>Passion for entrepreneurship</Title>

            <div className="flex justify-center py-10">
                <HeartChart value={epPassionValue} max={5} />
            </div>

            <div>
                <InsightStatus value={epPassionValue} />

                <InsightDescription>
                    {ep_passion?.conclusion?.text ?? ""}
                </InsightDescription>
            </div>

            <IndividualScores
                individualData={individualData}
                teamMetricValue={epPassionValue}
                metric="ep_passion"
            />
        </div>
    );
};

export default EntrepreneurshipPassionModal;
