const enumToArray = (enumObj: any) => {
    return Object.keys(enumObj).map((key) => ({
        label: enumObj[key],
        value: enumObj[key],
    }));
};

const fullDateInput = (value: string) => {
    let result = "";
    const numericInput = value.replace(/[^\d]/g, "");

    for (let i = 0; i < numericInput.length && i < 8; i++) {
        if (i === 2 || i === 4) {
            result += "/";
        }
        result += numericInput.charAt(i);
    }

    return result;
};

const getReadableLocation = ({
    city,
    country,
}: {
    city: string;
    country: string;
}) => `${city}, ${country}`;

// val: DD/MM/YYYY -> YYYY-MM-DD
const joinDateInput = (val: string) => val.split("/").reverse().join("-");

const retrieveCurrencyFromAnswer = (val: string) => {
    let startIndex = val.indexOf("(") + 1;
    let endIndex = val.indexOf(")");
    return val.substring(startIndex, endIndex);
};

type TimeLeft = {
    days?: string | number;
    hours?: string | number;
    minutes?: string | number;
    seconds?: string | number;
};

const calculateTimeLeft = (deadline: Date) => {
    const difference = (new Date(deadline) as any) - (new Date() as any);
    let timeLeft: TimeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
};

const getInitials = (name: string) => {
    const nameArray = name.split(" ");
    const initials = nameArray.map((n) => n[0]).join("");
    return initials.toUpperCase();
};

const isObjectEmpty = (object: object) => Object.keys(object).length === 0;

const getMainDNSName = (url: string) => {
    try {
        // Attempt to parse the URL
        const parsedUrl = new URL(url);
        const hostname = parsedUrl.hostname;

        // Split the hostname into parts
        const parts = hostname.split(".");

        // Get the main DNS name
        const mainDNSName =
            parts.length > 1 ? parts.slice(-2).join(".") : hostname;

        return mainDNSName;
    } catch (error) {
        console.error("Invalid URL:", url);
        return url;
    }
};

const formatDateToMonthYear = (date: any, includeComma = true) => {
    const options = { year: "numeric", month: "long" } as any;
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    const [month, year] = formattedDate.split(" ");
    if (includeComma) return `${month}, ${year}`;
    else return `${month} ${year}`;
};

const abbreviateLargeNumbers = (input: number) => {
    if (typeof input !== "number") {
        return "0";
    }

    if (input >= 1_000_000_000) {
        let billions = input / 1_000_000_000;
        let formatted = billions.toFixed(1);
        if (formatted.endsWith(".0")) {
            formatted = formatted.slice(0, -2);
        }
        return formatted + "B";
    } else if (input >= 1_000_000) {
        let millions = input / 1000000;
        let formatted = millions.toFixed(1);
        if (formatted.endsWith(".0")) {
            formatted = formatted.slice(0, -2);
        }
        return formatted + "M";
    } else if (input >= 10_000) {
        let thousands = input / 1000;
        let formatted = thousands.toFixed(1);
        if (formatted.endsWith(".0")) {
            formatted = formatted.slice(0, -2);
        }
        return formatted + "K";
    } else {
        return input.toString();
    }
};

const companyDeadline = (
    deadline: string
): { time: number; type: "passed" | "days" | "hours" | "minutes" } => {
    const now = new Date();
    const deadlineDate = new Date(deadline);
    const timeDifference = deadlineDate.getTime() - now.getTime();

    if (timeDifference <= 0) {
        return { time: 0, type: "passed" };
    }

    const d = timeDifference / (1000 * 60 * 60 * 24);

    if (d >= 2) {
        return { time: Math.round(d), type: "days" };
    }

    const h = timeDifference / (1000 * 60 * 60);

    if (h >= 1.5) {
        return { time: Math.round(h), type: "hours" };
    }

    const m = timeDifference / (1000 * 60);

    if (m >= 1) {
        return { time: Math.round(m), type: "minutes" };
    }

    return { time: 0, type: "passed" };
};

const formatWebsiteUrl = (url: string) => {
    try {
        if (!url) return url;
        try {
            const parsedUrl = new URL(url);
            return parsedUrl.href;
        } catch {
            const parsedUrl = new URL("https://" + url);
            return parsedUrl.href;
        }
    } catch {
        return url;
    }
};

const formatWebsiteUrlToPresent = (url: string) => {
    try {
        if (!url) return url;
        const parsedUrl = new URL(url);
        const { host, pathname } = parsedUrl;
        return `${host}${pathname !== "/" ? pathname : ""}`;
    } catch {
        return url;
    }
};

const convertInputToNumberOfThousands = (value: string | number) => {
    if (typeof value === "number") value = value.toString();
    const numericValue = value.replace(/[^0-9.]/g, "");
    const parts = numericValue.split(".");
    const integerPart = parseInt(parts[0], 10);
    if (integerPart > 9999) {
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parts.join(".");
};

const convertInputOfThousandsIntoNumber = (value: string | number) => {
    if (typeof value === "number") value = value.toString();

    return parseFloat(value.replace(/,/g, ""));
};

const Parsers = {
    getInitials,
    enumToArray,
    fullDateInput,
    joinDateInput,
    isObjectEmpty,
    getMainDNSName,
    companyDeadline,
    formatWebsiteUrl,
    calculateTimeLeft,
    getReadableLocation,
    formatDateToMonthYear,
    abbreviateLargeNumbers,
    formatWebsiteUrlToPresent,
    retrieveCurrencyFromAnswer,
    convertInputToNumberOfThousands,
    convertInputOfThousandsIntoNumber,
};

export default Parsers;
