import { FC } from "react";
import {
    GeneratedInsightReportTeam,
    InsightsTeam,
} from "store/api/investorCompany/interfaces";
import PassionAlignment from "../PassionAlignment";
import InsightStatus from "../InsightStatus";
import Title from "routes/company/components/Modal/Title";
import InsightDescription from "routes/company/components/Modal/InsightDescription";
import OverallIndividualBar from "components/OverallIndividualBar";
import { PASSION_FILL } from "..";

type AlignmentData = {
    key: string;
    value: number;
    fill: string;
};

type Props = {
    tooltip: string;
    teamData: InsightsTeam;
    alignmentData: Array<AlignmentData>;
};

const getMetricValue = (
    data: GeneratedInsightReportTeam,
    key: string
): number | undefined => {
    return (
        ((data?.value as unknown as Record<string, number>) ?? {})?.[key] ??
        undefined
    );
};

const PassionAlignmentModal: FC<Props> = ({
    teamData: { ep_passion_alignment, ep_passion_metrics_alignment },
    alignmentData,
    tooltip,
}) => {
    const epPassionAlignmentValue =
        (ep_passion_alignment?.value as number) ?? 0;

    const values = [
        {
            key: "fnd",
            title: "Founding alignment",
            value: getMetricValue(
                ep_passion_metrics_alignment,
                "ep_founding_score"
            ),
            fill: PASSION_FILL.fnd,
        },
        {
            key: "inv",
            title: "Inventing alignment",
            value: getMetricValue(
                ep_passion_metrics_alignment,
                "ep_inventing_score"
            ),
            fill: PASSION_FILL.inv,
        },
        {
            key: "dev",
            title: "Developing alignment",
            value: getMetricValue(
                ep_passion_metrics_alignment,
                "ep_developing_score"
            ),
            fill: PASSION_FILL.dev,
        },
    ];

    return (
        <div className="flex flex-col gap-4">
            <Title tooltip={tooltip}>Passion alignment</Title>

            <div className="flex justify-center">
                <PassionAlignment
                    alignmentData={alignmentData}
                    metricValue={epPassionAlignmentValue}
                />
            </div>

            <div>
                <InsightStatus value={epPassionAlignmentValue} />

                <InsightDescription>
                    {ep_passion_alignment?.conclusion?.text ?? ""}
                </InsightDescription>
            </div>

            <div className={"flex flex-col gap-4 p-10"}>
                {values.map((v) => {
                    const { key, title, value, fill } = v;
                    return (
                        <div
                            key={key}
                            className="grid grid-cols-7 items-center"
                        >
                            <div className="col-span-3 flex items-center">
                                <div
                                    className="mr-2 size-2 rounded-[2px] bg-default-400"
                                    style={{
                                        backgroundColor: fill,
                                    }}
                                />
                                <span>{title}</span>
                            </div>

                            <OverallIndividualBar
                                metricValue={value}
                                averageValue={epPassionAlignmentValue}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PassionAlignmentModal;
