import GridSelect, { GridSelectOption } from "components/GridSelect";

type Props<T> = {
    disabled?: boolean;
    leftLabel?: string;
    rightLabel?: string;
    className?: string;
    options: Array<GridSelectOption<T>>;
    selected?: T;
    onSelect: (_: T) => void;
};

const Scale = <T,>({
    disabled,
    leftLabel,
    rightLabel,
    className,
    selected,
    options,
    onSelect,
}: Props<T>) => {
    return (
        <div className={className}>
            <GridSelect<T>
                centered
                className="gap-3"
                options={options}
                disabled={disabled}
                onSelect={(val) => onSelect(val[0])}
                selected={selected ? [selected] : []}
            />

            <div className="mt-2 flex flex-row justify-between items-between content-between">
                {leftLabel && (
                    <div className="text-sm text-default-400">{leftLabel}</div>
                )}

                <div className="flex-1" />

                {rightLabel && (
                    <div className="text-sm text-default-400">{rightLabel}</div>
                )}
            </div>
        </div>
    );
};

export default Scale;
