import { api } from "../api";
import {
    CreateInvitationRequest,
    CreateInvitationResponse,
    GetInvitation,
    InvitationQueryParams,
    ResendInvitationRequest,
    ResendInvitationResponse,
    RevokeInvitationRequest,
    RevokeInvitationResponse,
} from "./interfaces";

export const invitationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        parseInvitationToken: builder.query<
            GetInvitation,
            InvitationQueryParams
        >({
            query: ({ token }) => ({
                url: `app/auth/${token}`,
            }),
            providesTags: [],
        }),
        createInvitation: builder.mutation<
            CreateInvitationResponse,
            CreateInvitationRequest
        >({
            query: (body) => ({
                url: `app/member/team/invitations`,
                method: "POST",
                body: JSON.stringify(body),
            }),
            invalidatesTags: ["Team"],
        }),
        resendInvitation: builder.mutation<
            ResendInvitationResponse,
            ResendInvitationRequest
        >({
            query: ({ id }) => ({
                url: `app/member/team/invitations/${id}/resend`,
                method: "POST",
            }),
        }),
        revokeInvitation: builder.mutation<
            RevokeInvitationResponse,
            RevokeInvitationRequest
        >({
            query: ({ id }) => ({
                url: `app/member/team/invitations/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Team"],
        }),
    }),
});

export const {
    useParseInvitationTokenQuery,
    useCreateInvitationMutation,
    useResendInvitationMutation,
    useRevokeInvitationMutation,
} = invitationApi;
