import { useMemo } from "react";

import {
    InsightsTeam,
    InsightsMemberData,
    InsightsIndividualMember,
    GeneratedInsightReportTeam,
} from "store/api/investorCompany/interfaces";
import Utils from "utils";
import Icon from "components/Icon";
import StatLabel from "./StatLabel";
import Delimiter from "./Delimiter";
import InsightCardRow from "./InsightCardRow";
import MemberInfo from "routes/company/components/MemberInfo";
import EmptyInsight from "routes/company/components/EmptyInsight";

type Props = {
    teamData: InsightsTeam;
    individualData: InsightsIndividualMember;
};

type Rows = Array<
    | {
          title: string;
          team?: GeneratedInsightReportTeam;
          individual?: InsightsMemberData;
      }
    | "delimiter"
>;

const Insight: React.FC<Props> = ({ individualData, teamData }) => {
    const nonMemberValues = useMemo((): any => {
        const values: any = {};

        for (const key in individualData) {
            if (individualData.hasOwnProperty(key) && key.startsWith("ep_")) {
                (values as any)[key as any] = (individualData as any)[key];
            }
        }

        return values as any;
    }, [individualData]);

    const memberInfo = Utils.Insight.getMemberInfoFromInsight(individualData);

    const foundingScore = nonMemberValues.ep_founding_score?.value || 0;
    const inventingScore = nonMemberValues.ep_inventing_score?.value || 0;
    const developingScore = nonMemberValues.ep_developing_score?.value || 0;

    let mainType = "founding";
    let maxScore = foundingScore;

    if (inventingScore > maxScore) {
        mainType = "inventing";
        maxScore = inventingScore;
    }

    if (developingScore > maxScore) {
        mainType = "developing";
        maxScore = developingScore;
    }

    const rows: Rows = [
        {
            title: "Passion for founding",
            team: teamData.ep_founding_score,
            individual: nonMemberValues.ep_founding_score,
        },
        {
            title: "Passion for inventing",
            team: teamData.ep_inventing_score,
            individual: nonMemberValues.ep_inventing_score,
        },
        {
            title: "Passion for developing",
            team: teamData.ep_developing_score,
            individual: nonMemberValues.ep_developing_score,
        },
        "delimiter",
        {
            title: "Passion for entrepreneurship",
            team: teamData.ep_passion,
            individual: nonMemberValues.ep_passion,
        },
        {
            title: "Grit",
            team: teamData.ep_grit,
            individual: nonMemberValues.ep_grit,
        },
    ];

    return (
        <div className="flex h-[427px] w-[400px] flex-col gap-4 rounded-lg bg-white p-6 md:w-[unset] lg:w-[unset]">
            <MemberInfo disableTooltip {...memberInfo} />

            {Utils.Parsers.isObjectEmpty(nonMemberValues) ? (
                <EmptyInsight />
            ) : (
                <>
                    <div className="flex flex-row items-center justify-between text-sm">
                        Main type of passion
                        <StatLabel type={mainType as any} />
                    </div>

                    <Delimiter />

                    <div className="my-2 flex flex-row items-center justify-between">
                        <div className="text-sm text-default-400">
                            Types of passion
                        </div>

                        <div className="flex flex-row items-center">
                            <Icon type="User" size={16} className="mr-[21px]" />

                            <Icon
                                type="Users"
                                size={16}
                                className="text-default-400"
                            />
                        </div>
                    </div>

                    <div className="flex flex-col gap-[16px]">
                        {rows.map((item, i) =>
                            item === "delimiter" ? (
                                <Delimiter key={i} />
                            ) : (
                                item.team &&
                                item.individual && (
                                    <InsightCardRow
                                        key={i}
                                        name={item.title}
                                        team={item.team}
                                        individual={item.individual}
                                    />
                                )
                            )
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Insight;
