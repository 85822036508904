import { memo } from "react";
import { useCollapse } from "react-collapsed";

type Props = {
    duration?: number;
    className?: string;
    isExpanded: boolean;
    children: React.ReactNode;
};

const Collapsed = memo<Props>(
    ({ isExpanded, className, children, duration = 300 }: Props) => {
        const { getCollapseProps } = useCollapse({
            isExpanded,
            duration,
        });

        return (
            <div {...getCollapseProps()} className={className}>
                {children}
            </div>
        );
    }
);

export default Collapsed;
