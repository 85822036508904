import Badge from "components/Badge";
import Alert from "components/Alert";
import Button from "components/Button";
import LogoImage from "../../../images/logo.png";
import HumanCapitalImg from "images/surveys/human-capital.sm.png";
import PassionImg from "images/surveys/passion.sm.png";
import SoftImg from "images/surveys/soft.sm.png";
import TeamImg from "images/surveys/team.sm.png";
import StarImg from "images/surveys/star.png";

type Props = {
    surveyKey: string;
    title: string;
    subTitle: string;
    timeToComplete: number;
    onNext: () => void;
    isUntouched: boolean;
    onClose: () => void;
};

const getSurveyImage = (key: string) => {
    if (key === "human-capital-skills") {
        return HumanCapitalImg;
    }

    if (key === "entrepreneurial-passion") {
        return PassionImg;
    }

    if (key === "team-dynamics") {
        return TeamImg;
    }

    if (key === "soft-skills") {
        return SoftImg;
    }

    return StarImg;
};

const IntroPage = ({
    onNext,
    onClose,
    isUntouched,
    subTitle,
    title,
    surveyKey,
    timeToComplete,
}: Props) => {
    return (
        <div className="bg-gradient-custom flex h-screen flex-1 p-4">
            <div className="flex flex-1 flex-col rounded-3xl bg-white p-8">
                <div className="flex w-full flex-row justify-between">
                    <img className="h-12 w-12" src={LogoImage} alt="Logo" />
                    <Button
                        icon="X"
                        onClick={onClose}
                        customType="secondary-light"
                    >
                        Close survey
                    </Button>
                </div>

                <div className="flex max-w-[480px] flex-1 flex-col items-center justify-center self-center">
                    <img
                        alt="Logo"
                        src={getSurveyImage(surveyKey)}
                        className="mb-6 rounded-lg"
                    />

                    <div className="text-center text-5xl">{title}</div>

                    <Badge
                        icon="Timer"
                        className="my-4"
                        label={`~${timeToComplete} min to complete`}
                    />

                    <div className="text-center text-[18px]">{subTitle}</div>

                    <Alert
                        type="tip"
                        headline="Be mindful"
                        className="my-6 max-w-[480px]"
                        label={
                            <div>
                                <div>There is no right or wrong answers.</div>
                                <div>
                                    Answer honestly to get the best insights.
                                </div>
                            </div>
                        }
                    />

                    <Button
                        onClick={onNext}
                        icon="ArrowRight"
                        iconPosition="right"
                        customSize="xl"
                    >
                        {isUntouched ? "Start" : "Resume"} survey
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default IntroPage;
