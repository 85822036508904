const EnterOption = () => {
    return (
        <div
            className={`font-manrope flex items-center justify-center gap-[11px] rounded-xs bg-[#ffffff29] p-0 px-[5.671px] text-center text-xs font-semibold leading-[20px] text-white`}
        >
            Enter
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                className="text-black"
            >
                <path
                    d="M12.4958 0C12.2748 0 12.0629 0.0877975 11.9066 0.244078C11.7503 0.400358 11.6625 0.61232 11.6625 0.833333V4.16667C11.6625 4.38768 11.5747 4.59964 11.4184 4.75592C11.2622 4.9122 11.0502 5 10.8292 5H2.83751L3.92085 3.925C4.07776 3.76808 4.16592 3.55525 4.16592 3.33333C4.16592 3.11142 4.07776 2.89859 3.92085 2.74167C3.76393 2.58475 3.5511 2.49659 3.32918 2.49659C3.10726 2.49659 2.89443 2.58475 2.73751 2.74167L0.237511 5.24167C0.161644 5.32092 0.102173 5.41437 0.0625113 5.51667C-0.0208371 5.71955 -0.0208371 5.94712 0.0625113 6.15C0.102173 6.25229 0.161644 6.34575 0.237511 6.425L2.73751 8.925C2.81498 9.00311 2.90715 9.0651 3.0087 9.10741C3.11025 9.14972 3.21917 9.1715 3.32918 9.1715C3.43919 9.1715 3.54811 9.14972 3.64966 9.10741C3.75121 9.0651 3.84338 9.00311 3.92085 8.925C3.99895 8.84753 4.06095 8.75536 4.10325 8.65381C4.14556 8.55226 4.16734 8.44334 4.16734 8.33333C4.16734 8.22332 4.14556 8.1144 4.10325 8.01285C4.06095 7.9113 3.99895 7.81914 3.92085 7.74167L2.83751 6.66667H10.8292C11.4922 6.66667 12.1281 6.40327 12.5969 5.93443C13.0658 5.46559 13.3292 4.82971 13.3292 4.16667V0.833333C13.3292 0.61232 13.2414 0.400358 13.0851 0.244078C12.9288 0.0877975 12.7169 0 12.4958 0Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};

export default EnterOption;
