/* eslint-disable @typescript-eslint/naming-convention */
import Insight from "./Insight";
import InsightLargeExtra from "./InsightLargeExtra";
import { GaugeChart } from "components/ui/GaugeChart";
import {
    InsightsIndividual,
    InsightsTeam,
} from "store/api/investorCompany/interfaces";
import { StatusType } from "./InsightStatus";
import useModalController from "routes/company/useModalController";
import GritModal from "./Modals/GritModal";
import ModalController from "routes/company/ModalController";
import MainPassionTypeModal from "./Modals/MainPassionTypeModal";
import MainPassionType from "./MainPassionType";
import Utils from "utils";
import { HeartChart } from "components/ui/HeartChart";
import EntrepreneurshipPassionModal from "./Modals/EntrepreneurshipPassionModal";
import PassionAlignmentModal from "./Modals/PassionAlignmentModal";
import PassionAlignment from "./PassionAlignment";
import TypesOfPassionModal from "./Modals/TypesOfPassionModal";
import TypesOfPassion from "./TypesOfPassion";
import useTypesOfPassionController from "./useTypesOfPassionController";
import { TOOLTIPS } from "routes/company/tooltips";

type Props = {
    teamData: InsightsTeam;
    individualData: InsightsIndividual;
};

export const PASSION_FILL: Record<string, string> = {
    dev: "#416FFF",
    inv: "#F256C0",
    fnd: "#4EDA85",
};

const EntrepreneurialPassionTeamTab: React.FC<Props> = ({
    teamData,
    individualData,
}) => {
    const {
        ep_grit,
        ep_passion,
        ss_overall,
        ep_main_type,
        ep_passion_alignment,
        ep_founding_score,
        ep_inventing_score,
        ep_developing_score,
        ep_passion_answers_deviation,
    } = teamData;

    const modalController = useModalController();
    const typesOfPassionController = useTypesOfPassionController([
        {
            key: "ep_founding_score",
            label: "Passion for founding",
            fill: "#4EDA85",
            value: (ep_founding_score?.value as number) ?? 0,
            data: ep_founding_score,
        },
        {
            key: "ep_inventing_score",
            label: "Passion for inventing",
            fill: "#FCCEF1",
            value: (ep_inventing_score?.value as number) ?? 0,
            data: ep_inventing_score,
        },
        {
            key: "ep_developing_score",
            label: "Passion for developing",
            fill: "#9EBBFF",
            value: (ep_developing_score?.value as number) ?? 0,
            data: ep_developing_score,
        },
    ]);
    const developmentScore = (ep_developing_score?.value as number) ?? 0;
    const foundingScore = (ep_founding_score?.value as number) ?? 0;
    const inventingScore = (ep_inventing_score?.value as number) ?? 0;
    const mainType = Utils.Insight.getTypeOfPassion(
        developmentScore,
        foundingScore,
        inventingScore
    );
    const mainConclusion = ep_main_type?.conclusion?.text;
    const alignmentData = Object.entries(
        ep_passion_answers_deviation?.value ?? {}
    ).map(([key, value]: [string, number]) => ({
        key,
        value,
        fill: PASSION_FILL[key.split("-")[1]] ?? "#e0e0e0",
    }));

    const modals = [
        {
            title: "Passion for entrepreneurship",
            content: (
                <EntrepreneurshipPassionModal
                    teamData={teamData}
                    individualData={individualData}
                    tooltip={TOOLTIPS.entrepreneurialPassion.passion}
                />
            ),
        },
        {
            title: "Main type of passion",
            content: (
                <MainPassionTypeModal
                    passionType={mainType}
                    individualData={individualData}
                    tooltip={TOOLTIPS.entrepreneurialPassion.main}
                />
            ),
        },
        {
            title: "Types of passion",
            content: (
                <TypesOfPassionModal
                    teamData={teamData}
                    individualData={individualData}
                    tooltip={TOOLTIPS.entrepreneurialPassion.types}
                    {...typesOfPassionController}
                />
            ),
        },
        {
            title: "Passion alignment",
            content: (
                <PassionAlignmentModal
                    teamData={teamData}
                    alignmentData={alignmentData}
                    tooltip={TOOLTIPS.entrepreneurialPassion.alignment}
                />
            ),
        },
        {
            title: "Grit",
            content: (
                <GritModal
                    teamData={teamData}
                    individualData={individualData}
                    tooltip={TOOLTIPS.entrepreneurialPassion.grit}
                />
            ),
        },
    ];

    return (
        <div className="flex flex-col gap-4">
            <ModalController modals={modals} {...modalController} />

            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                <Insight
                    tooltip={TOOLTIPS.entrepreneurialPassion.passion}
                    data={ep_passion}
                    iconType="Heart"
                    name="Passion for entrepreneurship"
                    description={ep_passion?.conclusion?.text}
                    statusKey={
                        ep_passion?.conclusion?.level.toLowerCase() as StatusType
                    }
                    onShowMore={() => modalController.open(0)}
                >
                    <HeartChart
                        value={(ep_passion?.value as number) ?? 0}
                        max={5}
                    />
                </Insight>

                <Insight
                    iconType="Award"
                    data={ss_overall}
                    name="Main type of passion"
                    description={mainConclusion}
                    bottomContent={mainType.conclusion.high}
                    tooltip={TOOLTIPS.entrepreneurialPassion.main}
                    onShowMore={() => modalController.open(1)}
                >
                    <MainPassionType passionType={mainType} />
                </Insight>

                <Insight
                    data={ss_overall}
                    iconType="ChartPie"
                    name="Types of passion"
                    tooltip={TOOLTIPS.entrepreneurialPassion.types}
                    bottomContent={
                        <div className="mb-10 flex flex-col gap-y-2 text-sm">
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <div className="mr-2 size-2 rounded-[2px] bg-success-500" />
                                    <span>Passion for founding</span>
                                </div>
                                <span className="pr-8">
                                    {foundingScore?.toFixed?.(1)}
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <div className="mr-2 size-2 rounded-[2px] bg-tertiary-500" />
                                    <span>Passion for inventing</span>
                                </div>
                                <span className="pr-8">
                                    {inventingScore?.toFixed?.(1)}
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <div className="mr-2 size-2 rounded-[2px] bg-quaternary-500" />
                                    <span>Passion for developing</span>
                                </div>
                                <span className="pr-8">
                                    {developmentScore?.toFixed?.(1)}
                                </span>
                            </div>
                        </div>
                    }
                    onShowMore={() => modalController.open(2)}
                >
                    <TypesOfPassion teamData={teamData} />
                </Insight>
            </div>

            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                <div className="col-span-1 lg:col-span-2">
                    <InsightLargeExtra
                        iconType="SlidersHorizontal"
                        name="Passion alignment"
                        data={ep_passion_alignment}
                        description={ep_passion_alignment?.conclusion?.text}
                        tooltip={TOOLTIPS.entrepreneurialPassion.alignment}
                        statusKey={
                            ep_passion_alignment?.conclusion?.level.toLowerCase() as StatusType
                        }
                        onShowMore={() => modalController.open(3)}
                    >
                        <PassionAlignment
                            alignmentData={alignmentData}
                            metricValue={
                                (ep_passion_alignment?.value ?? 0) as number
                            }
                        />
                    </InsightLargeExtra>
                </div>

                <Insight
                    data={ep_grit}
                    iconType="Target"
                    name="Grit"
                    tooltip={TOOLTIPS.entrepreneurialPassion.grit}
                    description={ep_grit?.conclusion?.text}
                    statusKey={
                        ep_grit?.conclusion?.level.toLowerCase() as StatusType
                    }
                    onShowMore={() => modalController.open(4)}
                >
                    <GaugeChart value={ep_grit?.value as number} />
                </Insight>
            </div>
        </div>
    );
};

export default EntrepreneurialPassionTeamTab;
