import Icon from "components/Icon";
import { cn } from "lib/utils";
import { FC, useMemo } from "react";
import { InsightsIndividualMember } from "store/api/investorCompany/interfaces";
import {
    CoFounder as TCoFounder,
    coFounderSchema,
} from "../../schemas/co-founder-schema";

type Props = {
    memberData: InsightsIndividualMember;
};

const CoFounder: FC<Props> = ({ memberData }) => {
    const { isFounder, sharesAmount } = useMemo((): TCoFounder => {
        const data = {
            isFounder: memberData?.["member-is-founder"]?.value,
            sharesAmount: memberData?.["member-shares-amount"]?.value,
        };
        const dataParsed = coFounderSchema.safeParse(data);
        if (!dataParsed.success) {
            return {
                isFounder: false,
                sharesAmount: 0,
            };
        }
        return dataParsed.data;
    }, [memberData]);

    return (
        <div className="flex items-center justify-start gap-x-2">
            <Icon
                type={isFounder ? "CStar" : "StarOff"}
                className={cn(
                    isFounder ? "text-secondary-400" : "text-default-300"
                )}
            />
            <span className="text-sm font-semibold text-foreground">
                {isFounder ? "Co-founder" : "Not a co-founder"}{" "}
                {sharesAmount > 0 && <span>({sharesAmount}% shares)</span>}
            </span>
        </div>
    );
};

export default CoFounder;
