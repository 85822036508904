import InsightStatus, { StatusType } from "./InsightStatus";
import Icon, { IconType } from "components/Icon";
import EmptyInsight from "routes/company/components/EmptyInsight";
import InfoTooltip from "routes/company/components/InfoTooltip";
import InsightDescription from "routes/company/components/Modal/InsightDescription";
import { GeneratedInsightReportTeam } from "store/api/investorCompany/interfaces";

type Props = {
    tooltip: string;
    name: string;
    iconType: IconType;
    data: GeneratedInsightReportTeam;
    description?: string;
    statusKey?: StatusType;
    children?: React.ReactNode;
    onShowMore?: () => void;
};

const InsightLargeExtra: React.FC<Props> = ({
    iconType,
    data,
    name,
    description,
    tooltip,
    children,
    onShowMore,
}) => {
    const value = (data?.value as number) ?? 0;
    const isEmptyInsight: boolean = !data;

    /** h-[536px] */

    return (
        <div className="group relative m-auto flex h-[100%] w-[100%] max-w-[576px] flex-col gap-2 rounded-lg bg-white p-6 lg:max-w-[unset]">
            {!isEmptyInsight && (
                <button
                    type="button"
                    onClick={onShowMore}
                    className="absolute right-6 top-6 rounded p-2 opacity-0 transition duration-100 hover:bg-default-50 group-hover:opacity-100"
                >
                    <Icon type="Maximize2" size="16" />
                </button>
            )}
            <div className="flex flex-row items-center gap-2">
                <div className="rounded-sm border border-solid border-default-75 bg-default-50 p-1">
                    <Icon
                        size={20}
                        type={iconType}
                        className="text-default-400"
                    />
                </div>

                <div className="text-sm">{name}</div>

                <InfoTooltip text={tooltip} />
            </div>

            {isEmptyInsight ? (
                <EmptyInsight />
            ) : (
                <>
                    <div className="grid grid-cols-1">
                        <div className="flex items-center justify-center text-2xl">
                            {children ?? (value as number).toFixed(1)}
                        </div>

                        <div>
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-row gap-2">
                                    <InsightStatus value={value as number} />
                                </div>
                            </div>

                            {description && (
                                <InsightDescription className="my-3 line-clamp-6 h-[120px]">
                                    {description}
                                </InsightDescription>
                            )}

                            <button
                                type="button"
                                onClick={onShowMore}
                                className="flex cursor-pointer flex-row items-center text-sm transition duration-100 hover:text-primary-500"
                            >
                                Show more{" "}
                                <Icon type="ChevronRight" className="ml-2" />
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default InsightLargeExtra;
