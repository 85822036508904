import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    useGetOnboardingQuery,
    useFinishOnboardingMutation,
} from "store/api/onboarding";
import Utils from "utils";
import Banner from "../components/Banner";
import Progress from "../components/Progress";
import { useGetMeQuery } from "store/api/auth";
import { InvitationType } from "store/api/invitation/interfaces";
import { MemberFinishOnboarding } from "store/api/onboarding/interfaces";
import { MemberOnboardingSelectors } from "store/reducers/memberOnboarding";

// STEPS
import Details from "./steps/Details";
import Profile from "./steps/Profile";
import Motivation from "./steps/Motivation";
import RolesAndShares from "./steps/RolesAndShares";
import Layout from "components/OnboardingAndAuth/Layout";
import { PageLoader } from "routes/components/PageLoader";
import { useAppDispatch } from "core/hooks";
import { GlobalActions } from "store/reducers/global";

type Step = {
    title: string;
    Component: any;
};

const Member = () => {
    const dispatch = useAppDispatch();
    const { data: meData, refetch: meRefetch } = useGetMeQuery();
    const { isLoading } = useGetOnboardingQuery();
    const [finishOnboarding] = useFinishOnboardingMutation();
    const onboardingValues = useSelector(MemberOnboardingSelectors.all);
    const navigate = useNavigate();

    const [currStep, setCurrStep] = useState(0);

    const userType = meData?.data?.onboarding?.type;

    const handleNextClick = () => setCurrStep((prev) => ++prev);
    const handleBackClick = () =>
        setCurrStep((prev) => {
            if (prev >= 1) {
                return --prev;
            }

            return prev;
        });

    const STEPS: Array<Step> = [
        { title: "Profile", Component: Profile },
        { title: "Details", Component: Details },
        { title: "Roles & shares", Component: RolesAndShares },
        { title: "Co-founder info", Component: Motivation },
    ];

    useEffect(() => {
        if (
            currStep >= STEPS.length &&
            userType &&
            userType === InvitationType.Member
        ) {
            const {
                motivation: {
                    isFounder,
                    motivationForBeingFounder,
                    whyDidYouBuildThisCompany,
                },
                profileNew: { firstName, lastName, image, linkedIn },
                detailsNew: {
                    locationCountry,
                    locationCity,
                    nationality,
                    yearOfBirth,
                    gender,
                    genderDescription,
                },
                roleAndSharesNew: {
                    haveSharedValue,
                    isFounderValue,
                    roleDescription,
                    joined,
                },
            } = onboardingValues;

            finishOnboarding({
                firstName,
                lastName,
                gender,
                nationality: nationality?.map((i) => i.value),
                linkedin: linkedIn
                    ? Utils.Parsers.formatWebsiteUrl(linkedIn)
                    : undefined,
                picture: image?.id,
                year: +yearOfBirth,
                genderDescription,
                location: {
                    country: locationCountry?.value,
                    city: locationCity?.value,
                },
                role: isFounderValue?.value,
                roleDescription,
                isFounder: isFounder === "true",
                sharesAmount: +haveSharedValue,
                founderWhy: motivationForBeingFounder,
                joined: Utils.Parsers.joinDateInput(joined),
                startedCompanyWhy: whyDidYouBuildThisCompany,
            } as MemberFinishOnboarding).then((value) => {
                if ("data" in value && value.data.ok) {
                    meRefetch();
                    navigate("/dashboard");
                } else {
                    handleBackClick();
                    dispatch(
                        GlobalActions.showPopover({
                            type: "error",
                            label: "Something went wrong",
                        })
                    );
                }
            });
        } else if (
            currStep >= STEPS.length &&
            userType === InvitationType.Company
        ) {
            navigate("/onboarding/company");
        }
    }, [currStep, userType]);

    if (isLoading) {
        return <PageLoader />;
    }

    if (currStep >= STEPS.length) {
        return <PageLoader />;
    }

    const { Component, title } = STEPS[currStep];

    return (
        <Layout
            type="member"
            title="Create personal profile"
            leftContentAboveTitle={
                userType !== InvitationType.Member ? "First," : undefined
            }
            leftBottomContent={
                userType === InvitationType.Company && (
                    <Banner title="Next," subTitle="Company profile" />
                )
            }
            leftContent={
                <Progress
                    currentIndex={currStep}
                    labels={STEPS.map((i) => i.title)}
                />
            }
        >
            <Component
                title={title}
                handleNextClick={handleNextClick}
                handleBackClick={handleBackClick}
                isLastStep={currStep === STEPS.length - 1}
            />
        </Layout>
    );
};

export default Member;
