import { useState } from "react";
import {
    GeneratedInsightReportTeam,
    InsightsIndividualMember,
} from "store/api/investorCompany/interfaces";

export type LencioniPyramidData = {
    key: keyof InsightsIndividualMember;
    label: string;
    value: number;
    data: GeneratedInsightReportTeam;
};

const useLencioniPyramidController = (
    pyramidData: Array<LencioniPyramidData>
) => {
    const [currIdx, setCurrIdx] = useState<number>(0);

    const controlHandler = (action: "back" | "forward") => () => {
        setCurrIdx((prev) => {
            if (action === "back") {
                return (prev - 1 + pyramidData.length) % pyramidData.length;
            }

            return (prev + 1) % pyramidData.length;
        });
    };

    return {
        pyramidData,
        currIdx,
        setCurrIdx,
        controlHandler,
    };
};

export default useLencioniPyramidController;
