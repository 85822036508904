import { FC, useEffect, useState } from "react";
import { useTeamQuery } from "store/api/member";
import { MemberCardDetails } from "./interfaces";
import MemberCard from "./MemberCard";
import Button from "components/Button";
import { Modal, ModalContent } from "@nextui-org/react";
import { useGetMeQuery } from "store/api/auth";
import InvitationModal from "./InvitationModal";
import { useAppDispatch, useAppSelector } from "core/hooks";
import { GlobalActions, GlobalSelectors } from "store/reducers/global";
import Icon from "components/Icon";
import Utils from "utils";
import ButtonIcon from "components/ButtonIcon";
import { UserType } from "store/api/invitation/interfaces";

type Props = {
    perPage?: number;
};

const PER_PAGE = 4;
const DEFAULT_PAGE = 1;

const TeamCard: FC<Props> = ({ perPage = PER_PAGE }) => {
    const { data } = useTeamQuery();
    const { data: meData } = useGetMeQuery();
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(GlobalSelectors.isInviteTeammateOpen);
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
    const memberCards =
        data?.data.members
            .slice()
            .sort(Utils.Insight.teamMembersRoleNameCompareFn)
            .map(
                (member): MemberCardDetails => ({
                    key: `member-${member.id}`,
                    id: member.id,
                    img: member.picture,
                    name: member.firstName + " " + member.lastName,
                    subtext: member.role,
                    progress: {
                        total: member.surveys.length,
                        done: member.surveys.filter(
                            (survey) => survey.isSubmitted
                        ).length,
                    },
                })
            )
            .concat(
                data.data.invitations.map((invite) => ({
                    // @TODO: check types
                    key: `invite-${invite.id}`,
                    id: invite.id,
                    img: null,
                    name: invite.email,
                    subtext: Utils.Date.invitedSince(invite.createdAt),
                    progress: null,
                }))
            ) ?? [];
    const total = memberCards.length;
    const pages = Math.ceil(total / perPage);
    const cards = memberCards.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );
    const membersCount = data?.data.members.length ?? 0;
    const invitationsCount = data?.data.invitations.length ?? 0;
    const isSuper =
        meData?.data?.user?.type === UserType.Member &&
        meData?.data?.user?.isSuper;

    useEffect(() => {
        if (cards.length !== 0) {
            return;
        }
        setCurrentPage((page) => {
            if (page <= 1) {
                return page;
            }
            return page - 1;
        });
    }, [cards.length]);

    const handleOpenChange = (newIsOpen: boolean) =>
        dispatch(GlobalActions.toggleIsInviteTeammateOpen(newIsOpen));

    return (
        <div className="flex min-h-[430px] flex-1 flex-col rounded-2xl bg-white p-6">
            <Modal size="xl" isOpen={isOpen} onOpenChange={handleOpenChange}>
                <ModalContent className="mx-8">
                    {(onClose) => <InvitationModal onClose={onClose} />}
                </ModalContent>
            </Modal>

            <div className="mb-6 flex items-start justify-between">
                <div className="flex flex-col">
                    <h3 className="text-2xl font-semibold">My team</h3>

                    <p className="text-sm text-default-400">
                        {membersCount
                            ? `${membersCount} member(s)`
                            : "No members"}
                        {invitationsCount > 0 && (
                            <span>, {invitationsCount} pending invite(s)</span>
                        )}
                    </p>
                </div>
                {isSuper && (
                    <Button
                        customType="secondary-solid"
                        onClick={() => handleOpenChange(true)}
                    >
                        <Icon type="UserPlus" />
                        Invite teammates
                    </Button>
                )}
            </div>

            <div className="flex h-full flex-col justify-between">
                <div className="grid grid-cols-2 items-start gap-6">
                    {cards.map(({ key, ...rest }) => (
                        <MemberCard key={key} {...rest} />
                    ))}
                </div>

                {pages > 1 && (
                    <div className="mt-4 flex items-center justify-center gap-x-3">
                        <ButtonIcon
                            icon="ChevronLeft"
                            customType="light"
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                        />

                        <span className="text-sm">
                            {currentPage} of {pages}
                        </span>

                        <ButtonIcon
                            icon="ChevronRight"
                            customType="light"
                            disabled={currentPage === pages}
                            onClick={() => setCurrentPage(currentPage + 1)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default TeamCard;
