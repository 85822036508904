import Icon, { IconType } from "components/Icon";
import InsightStatus, { StatusType } from "./InsightStatus";
import InfoTooltip from "routes/company/components/InfoTooltip";
import EmptyInsight from "routes/company/components/EmptyInsight";
import { GeneratedInsightReportTeam } from "store/api/investorCompany/interfaces";
import InsightDescription from "routes/company/components/Modal/InsightDescription";

type Props = {
    tooltip: string;
    name: string;
    iconType: IconType;
    data: GeneratedInsightReportTeam;
    description?: string;
    statusKey?: StatusType;
    children?: React.ReactNode;
    bottomContent?: React.ReactNode;
    onShowMore?: () => void;
};

const Insight: React.FC<Props> = ({
    name,
    data,
    tooltip,
    iconType,
    children,
    statusKey,
    description,
    bottomContent,
    onShowMore,
}) => {
    const value = (data?.value as number) ?? 0;
    const isEmptyInsight: boolean = !data;

    return (
        <div
            className={`group relative m-auto flex h-[536px] w-[100%] max-w-[576px] flex-col gap-2 rounded-lg bg-white p-6 lg:max-w-[unset]`}
        >
            {!isEmptyInsight && (
                <button
                    type="button"
                    onClick={onShowMore}
                    className="absolute right-6 top-6 rounded p-2 opacity-0 transition duration-100 hover:bg-default-50 group-hover:opacity-100"
                >
                    <Icon type="Maximize2" size="16" />
                </button>
            )}
            <div className="flex flex-row items-center gap-2">
                <div className="rounded-sm border border-solid border-default-75 bg-default-50 p-1">
                    <Icon
                        type={iconType}
                        className="text-default-400"
                        size={20}
                    />
                </div>

                <div className="text-sm">{name}</div>

                <InfoTooltip text={tooltip} />
            </div>

            {isEmptyInsight ? (
                <EmptyInsight />
            ) : (
                <>
                    <div className="flex flex-1 items-center justify-center text-2xl">
                        {children ?? value?.toFixed(1)}
                    </div>

                    {bottomContent && (
                        <div className="line-clamp-6 h-[120px] text-sm text-default-500">
                            {bottomContent}
                        </div>
                    )}
                    <div>
                        {statusKey && (
                            <InsightStatus
                                value={value}
                                statusKey={statusKey}
                            />
                        )}
                        {description && (
                            <InsightDescription
                                className={"my-3 line-clamp-6 h-[120px]"}
                            >
                                {description}
                            </InsightDescription>
                        )}

                        <button
                            type="button"
                            onClick={onShowMore}
                            className="flex cursor-pointer flex-row items-center text-sm transition duration-100 hover:text-primary-500"
                        >
                            Show more{" "}
                            <Icon type="ChevronRight" className="ml-2" />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Insight;
