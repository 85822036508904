import { FC, useMemo } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "components/Card";
import MemberInfo from "routes/company/components/MemberInfo";
import { InsightsIndividualMember } from "store/api/investorCompany/interfaces";
import Utils from "utils";
import CoFounder from "../co-founder";
import Linkedin from "../linkedin";
import CompanyMemberInfo from "../company-member-info";
import HumanCapitalSkills from "../human-capital-skills";
import CardBlockWrapper from "../card-block-wrapper";
import KeyInsights from "../key-insights";
import Icon from "components/Icon";
import ButtonIcon from "components/ButtonIcon";

type Props = {
    memberData: InsightsIndividualMember;
    onShowMore: () => void;
};

const UserCard: FC<Props> = ({ memberData, onShowMore }) => {
    const memberInfo = useMemo(
        () => Utils.Insight.getMemberInfoFromInsight(memberData),
        [memberData]
    );

    return (
        <Card
            className={
                "group/card border-1 border-transparent hover:border-default-100"
            }
        >
            <CardHeader className={"justify-between pb-2"}>
                <MemberInfo
                    {...memberInfo}
                    disableTooltip={true}
                    classNames={{
                        avatar: "h-[48px] w-[48px]",
                        info: "gap-y-1",
                        name: "text-md",
                    }}
                    className="items-center"
                />
                <div className="flex h-full">
                    <ButtonIcon
                        customType="light"
                        icon="Maximize2"
                        onClick={onShowMore}
                        className="pointer-events-none opacity-0 group-hover/card:pointer-events-auto group-hover/card:opacity-100"
                    />
                </div>
            </CardHeader>
            <CardBody className={"gap-y-2 overflow-hidden pb-2"}>
                <CardBlockWrapper className="px-2 py-6">
                    <CoFounder memberData={memberData} />
                    <Linkedin memberData={memberData} />
                </CardBlockWrapper>
                <div className="px-2 py-3">
                    <CompanyMemberInfo memberData={memberData} />
                </div>
                <div className="flex flex-col gap-y-4 pb-3">
                    <div className="rounded-lg bg-default-50 p-5">
                        <HumanCapitalSkills memberData={memberData} />
                    </div>
                    <div className="rounded-lg bg-default-50 p-5">
                        <KeyInsights memberData={memberData} />
                    </div>
                </div>
            </CardBody>
            <CardFooter>
                <button
                    className="group flex items-center justify-center gap-x-2"
                    onClick={onShowMore}
                >
                    <span className="text-sm font-semibold text-foreground transition-colors group-hover:text-primary-500">
                        Show more
                    </span>
                    <Icon
                        type="ChevronRight"
                        className="transition-colors group-hover:text-primary-500"
                    />
                </button>
            </CardFooter>
        </Card>
    );
};

export default UserCard;
