import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";

// STEPS
import Profile from "./steps/Profile";
import Details from "./steps/Details";
import Description from "./steps/Description";

import Progress from "../components/Progress";
import { useGetMeQuery } from "store/api/auth";
import Layout from "components/OnboardingAndAuth/Layout";
import { InvitationType } from "store/api/invitation/interfaces";
import { useFinishOnboardingMutation } from "store/api/onboarding";
import { CompanyFinishOnboarding } from "store/api/onboarding/interfaces";
import { MemberOnboardingSelectors } from "store/reducers/memberOnboarding";
import { CompanyOnboardingSelectors } from "store/reducers/companyOnboarding";
import { PageLoader } from "routes/components/PageLoader";
import Utils from "utils";
import { useAppDispatch } from "core/hooks";
import { GlobalActions } from "store/reducers/global";

type Step = {
    title: string;
    Component: any;
};

const STEPS: Array<Step> = [
    { title: "Profile", Component: Profile },
    { title: "Details", Component: Details },
    { title: "Description", Component: Description },
];

const Company: FC = () => {
    const dispatch = useAppDispatch();
    const { data: meData, refetch: meRefetch } = useGetMeQuery();
    const [finishOnboarding] = useFinishOnboardingMutation();
    const memberOnboardingValues = useSelector(MemberOnboardingSelectors.all);
    const companyOnboardingValues = useSelector(CompanyOnboardingSelectors.all);
    const navigate = useNavigate();

    const [currStep, setCurrStep] = useState(0);

    const userType = meData?.data?.onboarding?.type;

    const handleNextClick = () => setCurrStep((prev) => ++prev);
    const handleBackClick = () =>
        setCurrStep((prev) => {
            if (prev > 0) {
                return --prev;
            }

            return prev;
        });

    useEffect(() => {
        if (
            currStep >= STEPS.length &&
            userType &&
            userType === InvitationType.Company
        ) {
            const {
                profile: { companyName, website, image: logo },
                description: { longDescription, shortDescription },
                details: { foundingDate, country, businessType, industry },
            } = companyOnboardingValues;

            const {
                motivation: {
                    isFounder,
                    motivationForBeingFounder,
                    whyDidYouBuildThisCompany,
                },
                profileNew: { firstName, lastName, image: picture, linkedIn },
                detailsNew: {
                    locationCountry,
                    locationCity,
                    nationality,
                    yearOfBirth,
                    gender,
                    genderDescription,
                },
                roleAndSharesNew: {
                    haveSharedValue,
                    isFounderValue,
                    roleDescription,
                    joined,
                },
            } = memberOnboardingValues;

            finishOnboarding({
                company: {
                    businessType: businessType,
                    descriptionLong: longDescription,
                    descriptionShort: shortDescription,
                    foundingDate: new Date(foundingDate),
                    country: country?.value,
                    industry: industry?.value,
                    name: companyName,
                    website: website
                        ? Utils.Parsers.formatWebsiteUrl(website)
                        : undefined,
                    logo: logo?.id,
                },
                member: {
                    firstName,
                    lastName,
                    gender,
                    nationality: nationality?.map((i) => i.value),
                    linkedin: linkedIn
                        ? Utils.Parsers.formatWebsiteUrl(linkedIn)
                        : undefined,
                    picture: picture?.id,
                    year: +yearOfBirth,
                    genderDescription,
                    location: {
                        country: locationCountry?.value,
                        city: locationCity?.value,
                    },
                    role: isFounderValue?.value,
                    roleDescription,
                    isFounder: isFounder === "true",
                    sharesAmount: +haveSharedValue,
                    founderWhy: motivationForBeingFounder,
                    joined,
                    startedCompanyWhy: whyDidYouBuildThisCompany,
                },
            } as CompanyFinishOnboarding).then((value) => {
                if ("data" in value && value.data.ok) {
                    meRefetch();
                    navigate("/dashboard");
                } else {
                    handleBackClick();
                    dispatch(
                        GlobalActions.showPopover({
                            type: "error",
                            label: "Something went wrong",
                        })
                    );
                }
            });
        }
    }, [currStep, userType]);

    if (currStep >= STEPS.length) {
        return <PageLoader />;
    }

    const { Component, title } = STEPS[currStep];

    return (
        <Layout
            type="company"
            leftContentAboveTitle="Now"
            title="Create company profile"
            leftContent={
                <Progress
                    currentIndex={currStep}
                    labels={STEPS.map((i) => i.title)}
                />
            }
        >
            <Component
                title={title}
                handleNextClick={handleNextClick}
                handleBackClick={handleBackClick}
                isLastStep={currStep === STEPS.length - 1}
            />
        </Layout>
    );
};

export default Company;
