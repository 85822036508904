import Icon from "components/Icon";
import { format } from "date-fns";

type Props = {
    value: number;
    endDate?: Date;
    label: string;
    startDate?: Date;
    unit?: string;
    hideIcon?: boolean;
    hideValue?: boolean;
};

const UpAndDownStat: React.FC<Props> = ({
    value,
    label,
    endDate,
    startDate,
    unit = "%",
    hideIcon,
    hideValue,
}) => {
    const isUp = value >= 0;

    const endDateLabel = endDate ? format(endDate, "MMM yyyy") : null;
    const startDateLabel = startDate ? format(startDate, "MMM yyyy") : null;

    return (
        <div className="flex flex-row items-center gap-1 text-sm">
            <div
                className={`flex flex-row items-center ${isUp ? "text-primary-500" : "text-danger-500"}`}
            >
                {!hideIcon && (
                    <Icon
                        size={16}
                        type={isUp ? "ArrowUp" : "ArrowDown"}
                        className={
                            isUp ? "text-primary-500" : "text-danger-500"
                        }
                    />
                )}
                {!hideValue && value}
                {unit}
            </div>

            <div>{label}</div>

            {startDate && endDate && (
                <div className="text-default-400">
                    ({startDateLabel} - {endDateLabel})
                </div>
            )}
        </div>
    );
};

export default UpAndDownStat;
