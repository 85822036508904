import Icon from "components/Icon";
import Button from "components/Button";

type Props = {
    nextText?: string;
    allDisabled?: boolean;
    isLoading?: boolean;
    className?: string;
    loadingText?: string;
    nextDisabled?: boolean;
    handleBack: () => void;
    handleNext?: () => void;
    disableSubmitOnEnter?: boolean;
};

const SurveyFooter = ({
    nextText = "Next",
    allDisabled,
    isLoading,
    className,
    loadingText,
    nextDisabled,
    disableSubmitOnEnter,
    handleBack,
    handleNext,
}: Props) => {
    return (
        <div className={`flex flex-row justify-center ${className}`}>
            <Button
                customSize="xl"
                className="mr-4"
                onClick={handleBack}
                customType="secondary-solid"
                disabled={isLoading || allDisabled}
            >
                Back
            </Button>

            <Button
                customSize="xl"
                isLoading={isLoading}
                disabled={nextDisabled || allDisabled}
                disableSubmitOnEnter={disableSubmitOnEnter}
                onClick={nextDisabled || allDisabled ? undefined : handleNext}
            >
                {isLoading ? loadingText : nextText}
                {!isLoading && (
                    <Icon type="ArrowRight" className="text-white" />
                )}
            </Button>
        </div>
    );
};

export default SurveyFooter;
