import {
    // OTHER
    LucideProps,
    // ICONS
    X,
    Eye,
    Zap,
    Code,
    Info,
    Plus,
    Home,
    User,
    Link,
    Image,
    Route,
    Heart,
    Smile,
    Users,
    Check,
    Scale,
    Frame,
    Award,
    Square,
    Trash2,
    Laptop,
    EyeOff,
    Puzzle,
    Sprout,
    Rocket,
    Trophy,
    Target,
    Hexagon,
    CircleX,
    Network,
    Package,
    Forward,
    RedoDot,
    History as LucideHistory,
    ArrowUp,
    Pyramid,
    Asterisk,
    RotateCw,
    ChartPie,
    UserPlus,
    Sparkles,
    Ellipsis,
    Building,
    LandPlot,
    ArrowDown,
    ChartLine,
    Component,
    ChevronUp,
    Crosshair,
    HandCoins,
    Lightbulb,
    CheckIcon,
    ArrowLeft,
    Handshake,
    UserRoundX,
    ListChecks,
    ArrowRight,
    TrendingUp,
    BadgeCheck,
    ListOrdered,
    DatabaseZap,
    CircleAlert,
    CircleCheck,
    SquareCheck,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ShoppingCart,
    MessageCircle,
    ArrowRightLeft,
    CircleCheckBig,
    MessagesSquare,
    ArrowDownToLine,
    CircleDollarSign,
    BriefcaseBusiness,
    SlidersHorizontal,
    MessageCircleMore,
    Maximize2,
    StarOff,
    Share,
    SquareArrowOutUpRight,
    Copy,
    Lock as LucideLock,
    TriangleAlert,
} from "lucide-react";
import Hat from "./customIcons/Hat";
import Timer from "./customIcons/Timer";
import CCheck from "./customIcons/CCheck";
import CCheckXS from "./customIcons/CCheckXS";
import CTimerFilled from "./customIcons/CTimerFilled";
import CCheckSimple from "./customIcons/CCheckSimple";
import CTimerOutlined from "./customIcons/CTimerOutlined";
import CLocationFilled from "./customIcons/CLocationFilled";
import CStar from "./customIcons/CStar";
import { cn } from "lib/utils";

export type IconType =
    | "X"
    | "Eye"
    | "Zap"
    | "Hat"
    | "Code"
    | "Info"
    | "Home"
    | "Plus"
    | "User"
    | "Link"
    | "Image"
    | "Route"
    | "CCheck"
    | "Heart"
    | "Timer"
    | "Smile"
    | "Check"
    | "Users"
    | "Scale"
    | "Frame"
    | "Award"
    | "ArrowUp"
    | "Square"
    | "Trash2"
    | "Laptop"
    | "EyeOff"
    | "Puzzle"
    | "Sprout"
    | "Rocket"
    | "Trophy"
    | "Target"
    | "Hexagon"
    | "CircleX"
    | "Network"
    | "Package"
    | "Forward"
    | "RedoDot"
    | "History"
    | "Pyramid"
    | "Asterisk"
    | "RotateCw"
    | "ChartPie"
    | "LandPlot"
    | "ChartLine"
    | "ArrowDown"
    | "CCheckXS"
    | "Sparkles"
    | "UserPlus"
    | "Ellipsis"
    | "Building"
    | "Component"
    | "ChevronUp"
    | "CheckIcon"
    | "Crosshair"
    | "HandCoins"
    | "Lightbulb"
    | "Handshake"
    | "UserRoundX"
    | "ArrowLeft"
    | "TrendingUp"
    | "ListChecks"
    | "ArrowRight"
    | "BadgeCheck"
    | "ListOrdered"
    | "DatabaseZap"
    | "CircleAlert"
    | "CircleCheck"
    | "SquareCheck"
    | "ChevronDown"
    | "ChevronLeft"
    | "CCheckSimple"
    | "ShoppingCart"
    | "CTimerFilled"
    | "ChevronRight"
    | "MessageCircle"
    | "ArrowRightLeft"
    | "CircleCheckBig"
    | "MessagesSquare"
    | "CTimerOutlined"
    | "ArrowDownToLine"
    | "CLocationFilled"
    | "CircleDollarSign"
    | "BriefcaseBusiness"
    | "SlidersHorizontal"
    | "MessageCircleMore"
    | "Maximize2"
    | "CStar"
    | "StarOff"
    | "Share"
    | "SquareArrowOutUpRight"
    | "Copy"
    | "Lock"
    | "TriangleAlert";

type Props = { type: IconType; className?: string } & LucideProps;

const ICON_TYPE_TO_ICON: { [key in IconType]: any } = {
    X,
    Eye,
    Zap,
    Hat,
    Plus,
    Code,
    Info,
    Home,
    User,
    Link,
    Timer,
    Smile,
    Check,
    Heart,
    Image,
    Route,
    Scale,
    Frame,
    Award,
    Users,
    CCheck,
    Trash2,
    Square,
    Laptop,
    EyeOff,
    Puzzle,
    Sprout,
    Rocket,
    Trophy,
    Target,
    Hexagon,
    CircleX,
    Network,
    Package,
    Forward,
    RedoDot,
    History: LucideHistory,
    Pyramid,
    Asterisk,
    RotateCw,
    ChartPie,
    ArrowUp,
    LandPlot,
    UserPlus,
    Ellipsis,
    Sparkles,
    Building,
    CCheckXS,
    Component,
    ArrowDown,
    ChartLine,
    ChevronUp,
    CheckIcon,
    Crosshair,
    HandCoins,
    Lightbulb,
    ArrowLeft,
    Handshake,
    UserRoundX,
    TrendingUp,
    ListChecks,
    ArrowRight,
    BadgeCheck,
    ListOrdered,
    DatabaseZap,
    CircleAlert,
    CircleCheck,
    SquareCheck,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ShoppingCart,
    CCheckSimple,
    CTimerFilled,
    MessageCircle,
    ArrowRightLeft,
    CircleCheckBig,
    MessagesSquare,
    CTimerOutlined,
    ArrowDownToLine,
    CLocationFilled,
    CircleDollarSign,
    BriefcaseBusiness,
    SlidersHorizontal,
    MessageCircleMore,
    Maximize2,
    CStar,
    StarOff,
    Share,
    SquareArrowOutUpRight,
    Copy,
    Lock: LucideLock,
    TriangleAlert,
};

export default function Icon({
    type,
    className,
    size = 20,
    strokeWidth = 1.5,
    ...rest
}: Props) {
    const IconComponent = ICON_TYPE_TO_ICON[type];
    return (
        <IconComponent
            className={cn("text-foreground", className)}
            size={size}
            strokeWidth={strokeWidth}
            {...rest}
        />
    );
}
