import Icon, { IconType } from "components/Icon";

type BadgeType = "primary" | "silver" | "secondary" | "danger";

type Props = {
    label: string;
    icon: IconType;
    type?: BadgeType;
    className?: string;
    iconProps?: any;
};

const TYPE_TO_BACKGROUND: Record<BadgeType, string> = {
    primary: "bg-secondary-100",
    silver: "bg-default-50",
    secondary: "bg-default-100",
    danger: "bg-danger-100",
};

const TYPE_TO_TEXT: Record<BadgeType, string> = {
    primary: "text-secondary-500",
    silver: "text-default-500",
    secondary: "text-black",
    danger: "text-danger-500",
};

const Badge = ({
    label,
    icon,
    className,
    type = "primary",
    iconProps,
}: Props) => {
    return (
        <div
            className={`flex flex-row gap-1 p-[6px] pr-[10px] text-sm ${TYPE_TO_TEXT[type]} ${TYPE_TO_BACKGROUND[type]} rounded-lg items-center ${className}`}
        >
            <Icon type={icon} {...iconProps} />
            <div>{label}</div>
        </div>
    );
};

export default Badge;
