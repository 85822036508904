import { FC } from "react";
import CompanyReport from "./components/company-report";
import { CompanyReportContextProvider } from "routes/components/CompanyReport/contexts/report-context";
import {
    useInvestorCompanyReportQuery,
    useInvestorCompanyReportsQuery,
} from "store/api/investorCompany";
import { PageLoader } from "routes/components/PageLoader";
import { useParams } from "react-router-dom";
import ErrorPage from "routes/components/error-page";

type Props = {};

const Company: FC<Props> = () => {
    const params = useParams();
    const id = params?.id as string;

    const {
        data: companyReportsData,
        isError: companyReportsIsError,
        isLoading: companyReportsIsLoading,
    } = useInvestorCompanyReportsQuery({ companyId: id }, { skip: !id });
    const reportId = companyReportsData?.data[0]?.id;
    const {
        data: companyReportData,
        isError: companyReportIsError,
        isLoading: companyReportIsLoading,
    } = useInvestorCompanyReportQuery(
        { companyId: id, reportId: reportId! },
        { skip: !id || !reportId }
    );

    if (companyReportsIsLoading || companyReportIsLoading) {
        return <PageLoader />;
    }

    if (!companyReportData || companyReportsIsError || companyReportIsError) {
        return <ErrorPage />;
    }

    return (
        <CompanyReportContextProvider
            value={{
                companyId: id,
                reportId: reportId!,
                isSharedView: false,
                ...companyReportData.data.insights,
                deadline: companyReportData.data.deadline,
                generatedAt: companyReportData.data.generatedAt,
                isOutdated: companyReportData.data.isOutdated,
                sharedView: companyReportData.data.sharedView,
                progress: companyReportData.data.progress,
            }}
        >
            <CompanyReport />
        </CompanyReportContextProvider>
    );
};

export default Company;
