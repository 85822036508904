import Button from "components/Button";
import { format } from "date-fns";
import { FC, useMemo } from "react";
import { useGetMeQuery } from "store/api/auth";
import { useCompanyQuery } from "store/api/member";
import { CompanyCardProps } from "./interfaces";
import cn from "classnames";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import Utils from "utils";
import { UserType } from "store/api/invitation/interfaces";
import CompanyPicture from "components/CompanyPicture";

const CompanyCard: FC<CompanyCardProps> = ({ className }) => {
    const { data } = useCompanyQuery();
    const { data: meData } = useGetMeQuery();

    const isSuperMember = useMemo(
        () =>
            meData?.data?.user?.type === UserType.Member &&
            meData?.data.user.isSuper,
        [meData]
    );

    // @TODO: use skeleton loader

    return (
        <div
            className={cn(
                "flex flex-1 gap-x-6 rounded-2xl bg-white p-6",
                className
            )}
        >
            <CompanyPicture
                size="medium"
                companyId={data?.data?.id}
                name={data?.data?.name}
                image={data?.data?.logo?.url}
            />
            <div className="w-full min-w-0 text-sm">
                <div
                    className={cn(
                        "flex items-start justify-between",
                        isSuperMember && "gap-x-4"
                    )}
                >
                    <h3 className="overflow-hidden overflow-ellipsis text-2xl font-semibold">
                        {data?.data.name}
                    </h3>
                    {isSuperMember && (
                        <Button
                            as={Link}
                            to="/company-edit"
                            customType="secondary-solid"
                        >
                            Edit profile
                        </Button>
                    )}
                </div>
                <div className="mt-3 flex flex-row gap-1">
                    <Icon type="CLocationFilled" />
                    <p>{data?.data?.country}</p>
                </div>
                <div className="mt-6 overflow-hidden overflow-ellipsis">
                    {/* @TODO: max lines 4 */}
                    {data?.data.descriptionShort}
                </div>
                <div className="mt-6 grid grid-cols-2 gap-6">
                    <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                        <p className="text-default-400">Website</p>
                        <a
                            href={data?.data.website}
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                            className="underline"
                        >
                            {Utils.Parsers.getMainDNSName(data?.data.website!)}
                        </a>
                    </div>
                    <div>
                        <p className="text-default-400">Founding date</p>
                        <p>
                            {data?.data
                                ? format(data.data.foundingDate, "MMMM yyyy")
                                : ""}
                        </p>
                    </div>
                    <div>
                        <p className="text-default-400">Industry</p>
                        <p>{data?.data.industry}</p>
                    </div>
                    <div>
                        <p className="text-default-400">Business type</p>
                        <p>{data?.data.businessType}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyCard;
