import { Link, useSearchParams } from "react-router-dom";
import { Modal, ModalContent, useDisclosure } from "@nextui-org/react";

import {
    useInvestorCompaniesQuery,
    useCompanyInvitationsQuery,
    useArchivedInvestorCompaniesQuery,
} from "store/api/investor";
import cn from "classnames";
import Archive from "./Archive";
import MyCompanies from "./MyCompanies";
import PendingInvites from "./PendingInvites";
import { useGetMeQuery } from "store/api/auth";
import InvitationModal from "./modals/InvitationModal";
import { UserType } from "store/api/invitation/interfaces";

const Investor = () => {
    const [searchParams] = useSearchParams();
    const { isOpen, onOpenChange, onOpen } = useDisclosure();
    const { data: meData } = useGetMeQuery();
    const { data: companiesData } = useInvestorCompaniesQuery({});
    const { data: invitationsData } = useCompanyInvitationsQuery();
    const { data: archivedCompaniesData } = useArchivedInvestorCompaniesQuery(
        {}
    );

    if (!meData) {
        return null;
    }

    const tabs = [
        {
            id: "companies",
            label: `My companies (${companiesData?.data.length ?? 0})`,
        },
        {
            id: "pending-invites",
            label: `Pending invites (${invitationsData?.data.length ?? 0})`,
        },
        {
            id: "archive",
            label: `Archive (${archivedCompaniesData?.data.length ?? 0})`,
        },
    ];
    const activeTab =
        tabs.find((tab) => tab.id === searchParams.get("tab")) ?? tabs[0];

    return (
        <div
            className={`mx-auto w-[100%] max-w-[1124px] pb-8 md:mx-8 lg:mx-[140px] xl:mx-[78px] 2xl:mx-[206px] 3xl:mx-[398px]`}
        >
            <Modal size="xl" isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => <InvitationModal onClose={onClose} />}
                </ModalContent>
            </Modal>

            <section className="py-8">
                <h1 className="text-3xl">
                    Hello,{" "}
                    {meData?.data?.user?.type === UserType.Investor
                        ? meData.data.user.data.contact.firstName
                        : ""}
                </h1>
            </section>

            <section className="mb-4 flex items-center justify-between">
                <div className="flex items-center gap-x-5">
                    {tabs.map((tab) => (
                        <Link
                            to={`?tab=${tab.id}`}
                            key={tab.id}
                            className={cn(
                                "border-b-2 pb-2 text-sm text-default-400",
                                tab.id === activeTab.id
                                    ? "border-black !text-black"
                                    : "border-transparent hover:border-default-500"
                            )}
                        >
                            {tab.label}
                        </Link>
                    ))}
                </div>
            </section>

            {activeTab.id === "companies" && (
                <MyCompanies onInviteClick={onOpen} />
            )}
            {activeTab.id === "pending-invites" && (
                <PendingInvites onInviteClick={onOpen} />
            )}
            {activeTab.id === "archive" && <Archive onInviteClick={onOpen} />}
        </div>
    );
};

export default Investor;
