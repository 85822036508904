import { FC } from "react";
import EmptyPage from "routes/components/empty-page";
import EmptyPageImg from "../../../../../../images/dashboard/empty-page.png";

type Props = {};

const EmptyShareReport: FC<Props> = () => {
    return (
        <EmptyPage
            title="Sorry, this link is no longer active"
            description="Owner of this report has deactivated this link."
            img={EmptyPageImg}
        />
    );
};

export default EmptyShareReport;
