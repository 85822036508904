import { useNavigate, useParams } from "react-router-dom";

import "./styles.css";
import Utils from "utils";

// STORE
import { useGetSurveyQuery } from "store/api/survey";

// COMPONENTS
import IntroPage from "./components/IntroPage";
import { useEffect } from "react";
import { PageLoader } from "routes/components/PageLoader";
import ErrorPage from "routes/components/error-page";

export default () => {
    const navigate = useNavigate();
    const { surveyKey = "" } = useParams();

    const {
        refetch,
        data: surveyData,
        isLoading: surveyLoading,
        isSuccess: surveySuccess,
    } = useGetSurveyQuery(surveyKey);

    useEffect(() => {
        if (surveyData?.data) {
            refetch();
        }
    }, [surveyData]);

    const handleCloseSurvey = () => navigate("/dashboard");

    if (surveyLoading) {
        return <PageLoader />;
    }

    if (!surveySuccess) {
        return <ErrorPage />;
    }

    const { name, intro, questions, answers, timeToComplete } = surveyData.data;

    return (
        <IntroPage
            surveyKey={surveyKey}
            timeToComplete={timeToComplete}
            title={name}
            subTitle={intro}
            onClose={handleCloseSurvey}
            isUntouched={!answers.length}
            onNext={() =>
                Utils.Surveys.pushSurveyNavHistory(
                    surveyKey,
                    Utils.Surveys.getQuestionsAndVisibility(questions),
                    navigate
                )
            }
        />
    );
};
