import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";
import { Global } from "./types";

const initialState: Global.InitState = {
    modal: {
        show: false,
        image: undefined,
        title: undefined,
        subTitle: undefined,
        bottomContent: undefined,
    },
    popover: {
        type: undefined,
        label: undefined,
        show: false,
    },
    isInviteTeammateOpen: false,
};

export const { actions, reducer } = createSlice({
    initialState,
    name: "global_modal",
    reducers: {
        showModal: (
            state,
            { payload }: PayloadAction<Global.ShowModalAction>
        ) => {
            state.modal = {
                ...payload,
                show: true,
            };
        },
        hideModal: (state) => {
            state.modal.show = false;
        },
        showPopover: (
            state,
            { payload }: PayloadAction<Global.ShowPopoverAction>
        ) => {
            state.popover = {
                ...payload,
                show: true,
            };
        },
        hidePopover: (state) => {
            state.popover.show = false;
        },
        toggleIsInviteTeammateOpen: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.isInviteTeammateOpen = payload;
        },
    },
});

export const GlobalActions = actions;
export const GlobalSelectors = {
    modal: (state: RootState) => state.global.modal,
    popover: (state: RootState) => state.global.popover,
    isInviteTeammateOpen: (state: RootState) =>
        state.global.isInviteTeammateOpen,
};

export default reducer;
