import { Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import LoadingSpinner from "components-legacy/LoadingSpinner";
import Icon, { IconType } from "components/Icon";
import { useState } from "react";

export type ActionPopoverListItem = {
    label: string;
    icon?: IconType;
    onClick: () => Promise<any> | any;
    type?: "default" | "danger";
    loading?: boolean;
};

type Props = {
    className?: string;
    actionButton?: React.ReactNode;
    actions: Array<ActionPopoverListItem>;
    actionButtonContent?: React.ReactNode;
    triggerScaleOnOpen?: boolean;
    onOpenChange?: (state: boolean) => void;
};

const ActionPopover = ({
    actions,
    className,
    actionButton,
    actionButtonContent,
    triggerScaleOnOpen,
    onOpenChange,
}: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const openHandler = (state: boolean) => {
        setIsOpen(state);
        onOpenChange?.(state);
    };

    return (
        <Popover
            isOpen={isOpen}
            onClose={() => openHandler(false)}
            placement="bottom-end"
            triggerScaleOnOpen={triggerScaleOnOpen}
        >
            <PopoverTrigger onClick={() => openHandler(true)}>
                {actionButton || (
                    <button
                        type="button"
                        className={`flex items-center text-sm ${className}`}
                    >
                        {actionButtonContent}
                    </button>
                )}
            </PopoverTrigger>

            <PopoverContent className="z-9999 w-[220px] p-2 text-left">
                {actions.map(({ label, icon, onClick, type, loading }) => (
                    <button
                        key={label} // Adding key for proper list rendering
                        type="button"
                        onClick={async (event) => {
                            event.stopPropagation();
                            await onClick();
                            openHandler(false);
                        }}
                        className={`z-9999 flex w-full justify-between rounded-sm p-3 text-left hover:bg-default-50 ${
                            type === "danger" ? "text-danger-500" : "text-black"
                        }`}
                    >
                        <span>{label}</span>
                        {loading && <LoadingSpinner />}
                        {icon && <Icon type={icon} />}
                    </button>
                ))}
            </PopoverContent>
        </Popover>
    );
};

export default ActionPopover;
