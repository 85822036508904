import { z } from "zod";

export const barChartDataSchema = z.array(
    z.object({
        x: z.string(),
        y: z.number(),
    })
);

export type BarChartData = z.infer<typeof barChartDataSchema>;
