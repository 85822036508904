import { cva } from "class-variance-authority";
import { cn } from "lib/utils";
import { FC, useMemo } from "react";
import { InsightsIndividualMember } from "store/api/investorCompany/interfaces";
import {
    CoFounderExplanation as TCoFounderExplanation,
    coFounderExplanationSchema,
} from "../../schemas/co-founder-explanation-schema";

const titleVariants = cva("text-sm font-semibold text-default-400");
const descriptionVariants = cva("text-sm font-semibold text-foreground");

type Props = {
    memberData: InsightsIndividualMember;
};

const CoFounderExplanation: FC<Props> = ({ memberData }) => {
    const { isFounder, founderWhy, startedCompanyWhy } =
        useMemo((): TCoFounderExplanation => {
            const data = {
                isFounder: memberData?.["member-is-founder"]?.value,
                founderWhy: memberData?.["member-founder-why"]?.value,
                startedCompanyWhy:
                    memberData?.["member-started-company-why"]?.value,
            };
            const dataParsed = coFounderExplanationSchema.safeParse(data);
            if (!dataParsed.success) {
                return {
                    isFounder: false,
                    founderWhy: "",
                    startedCompanyWhy: "",
                };
            }
            return dataParsed.data;
        }, [memberData]);

    if (!isFounder) {
        return null;
    }

    return (
        <>
            {founderWhy && (
                <div className="flex flex-col gap-y-1">
                    <h6 className={cn(titleVariants())}>
                        Motivation for being a founder
                    </h6>
                    <p className={cn(descriptionVariants())}>{founderWhy}</p>
                </div>
            )}
            {startedCompanyWhy && (
                <div className="flex flex-col gap-y-1">
                    <h6 className={cn(titleVariants())}>
                        Why did I build this company
                    </h6>
                    <p className={cn(descriptionVariants())}>
                        {startedCompanyWhy}
                    </p>
                </div>
            )}
        </>
    );
};

export default CoFounderExplanation;
