import { FC } from "react";
import { cn } from "lib/utils";

import Avatar from "components/Avatar";
import { MemberRole } from "data/onboarding";

type Props = {
    memberId: string;
    firstName: string;
    lastName: string;
    picture: string;
    role: string;
    roleDescription: string;
    className?: string;
    classNames?: { avatar?: string; info?: string; name?: string };
    avatarClassName?: string;
    disableTooltip?: boolean;
};

const MemberInfo: FC<Props> = ({
    memberId,
    role,
    roleDescription,
    picture,
    lastName,
    firstName,
    className,
    classNames,
    disableTooltip,
}: Props) => {
    return (
        <div className={cn("flex flex-row gap-3", className)}>
            <Avatar
                size="md"
                disableTooltip={disableTooltip}
                firstName={firstName}
                lastName={lastName}
                src={picture}
                className={cn("shrink-0 bg-default-50", classNames?.avatar)}
                memberId={memberId}
            />
            <div className={cn("flex flex-col truncate", classNames?.info)}>
                <p
                    className={cn(
                        "truncate text-sm text-black",
                        classNames?.name
                    )}
                >
                    {firstName} {lastName}
                </p>
                <p className="truncate text-sm text-default-400">
                    {role === MemberRole.Other ? roleDescription : role}
                </p>
            </div>
        </div>
    );
};

export default MemberInfo;
