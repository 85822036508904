import {
    InsightsCompany,
    InsightsIndividual,
} from "store/api/investorCompany/interfaces";
import Frame from "../shared/Frame";
import TeamSplit from "./components/TeamSplit";
import CompanyTeam from "./components/CompanyTeam";
import { useMemo } from "react";
import EmptyInsight from "routes/company/components/EmptyInsight";

type Props = {
    data: InsightsCompany;
    individual: InsightsIndividual;
};

const TeamCompanyCard: React.FC<Props> = ({ data, individual }) => {
    const fullTimeEmployees = data["company-employees"]?.value as number;
    const managementTeam = data["company-management-team"]?.value as number;

    const isEmptyInsight: boolean = useMemo(
        () => !isFinite(fullTimeEmployees) || !isFinite(managementTeam),
        [fullTimeEmployees, managementTeam]
    );

    return (
        <Frame
            title="Team"
            link={{ label: "Team overview", href: "?tab=team-overview" }}
        >
            <CompanyTeam individual={individual} />
            {isEmptyInsight ? (
                <EmptyInsight />
            ) : (
                <TeamSplit
                    management={{
                        title: "Management team",
                        label: managementTeam,
                    }}
                    fullTime={{
                        title: "Full-time employees",
                        label: fullTimeEmployees,
                    }}
                />
            )}
        </Frame>
    );
};
export default TeamCompanyCard;
