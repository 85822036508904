import { useState } from "react";

import Icon from "components/Icon";
import SurveyTeam from "./SurveyTeam";
import Button from "components/Button";
import { SurveyCardProps } from "./interfaces";
import HoverComponent from "components/HoverComponent";
import ProgressStatus from "components/ProgressStatus";
import SurveyBottomCompletion from "./SurveyBottomCompletion";
import HumanCapitalImg from "images/surveys/human-capital.png";
import PassionImg from "images/surveys/passion.png";
import SoftImg from "images/surveys/soft.png";
import TeamImg from "images/surveys/team.png";

const getSurveyImage = (key: string) => {
    if (key === "human-capital-skills") {
        return HumanCapitalImg;
    }

    if (key === "entrepreneurial-passion") {
        return PassionImg;
    }

    if (key === "team-dynamics") {
        return TeamImg;
    }

    if (key === "soft-skills") {
        return SoftImg;
    }
};

const SurveyCard = ({ survey, onClick }: SurveyCardProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const progress = survey.totalSteps
        ? survey.completedSteps / survey.totalSteps
        : 0;
    const isCompleted = progress === 1;
    const active = progress !== 1;
    const img = getSurveyImage(survey.key);

    return (
        <div
            onClick={active ? onClick : undefined}
            onMouseEnter={active ? () => setIsHovered(true) : undefined}
            onMouseLeave={active ? () => setIsHovered(false) : undefined}
            style={{
                backgroundImage: `url(${img})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
            className={`
                transition-all duration-350 ease-in-out
                relative flex flex-col justify-between items-start p-4 text-white rounded-xl bg-[#C8CAD4] h-[320px] ${active && "cursor-pointer"}
                ${active && "hover:bg-[#A4A5AE]"}
            `}
        >
            <div className="flex flex-col items-start">
                <ProgressStatus progress={progress} type="light" />

                <h4 className="my-1 text-xl">{survey.name}</h4>

                <div className="flex items-center text-sm">
                    <Icon type="Timer" width="16" height="16" />
                    <span className="ml-1">{survey.timeToComplete} min</span>
                </div>
            </div>

            {!isCompleted && (
                <HoverComponent
                    isHovered={isHovered}
                    secondaryContent={
                        <Button
                            onClick={onClick}
                            icon="ArrowRight"
                            iconPosition="right"
                            className="w-[100%]"
                            customType="white-light"
                            isDisabled={!isHovered || !active} // prevent tabbing through buttons
                        >
                            {progress < 1 && progress > 0
                                ? "Resume survey"
                                : "Start survey"}
                        </Button>
                    }
                >
                    <p className="mb-2">
                        <SurveyBottomCompletion survey={survey} />
                    </p>
                    <div className="z-40 flex items-center">
                        <SurveyTeam survey={survey} />
                    </div>
                </HoverComponent>
            )}
            {isCompleted && (
                <div>
                    <p className="mb-2">
                        <SurveyBottomCompletion survey={survey} />
                    </p>
                    <div className="flex items-center">
                        <SurveyTeam survey={survey} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default SurveyCard;
