import Icon from "components/Icon";

const Empty: React.FC = () => (
    <div className="flex flex-col items-center justify-center flex-1 gap-3 rounded-md bg-default-50 min-h-[120px]">
        <Icon type="UserRoundX" size={20} className="text-default-400" />
        <div className="text-sm text-center text-default-400 w-[175px]">
            No members have this experience
        </div>
    </div>
);

export default Empty;
