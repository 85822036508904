import { FC, useState } from "react";
import { useGetMeQuery, useSignOutMutation } from "store/api/auth";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
    Tooltip,
} from "@nextui-org/react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { UserType } from "store/api/invitation/interfaces";
import Header from "./header";
import Avatar from "components/Avatar";
import Button from "components/Button";

type Props = {
    classNames?: {
        base?: string;
        logo?: string;
    };
    goBack?: () => void;
    goBackText?: string;
};

const DashboardHeader: FC<Props> = ({ classNames, goBack, goBackText }) => {
    const { data: meData } = useGetMeQuery();
    const [signOut] = useSignOutMutation();
    const [isOpen, setIsOpen] = useState(false);
    const userData = meData?.data?.user;
    const isInvestor = userData?.type === UserType.Investor;
    const user = isInvestor ? userData?.data?.contact : userData?.data;

    if (!user) return null; // Ensure user is defined

    const handleLogout = async () => {
        await signOut();
        window.location.href = "/auth/sign-in";
    };

    return (
        <Header classNames={classNames}>
            {!!goBack && (
                <div className="flex max-w-[1124px] flex-1 xl:px-8">
                    <Button
                        icon="ArrowLeft"
                        customType="secondary-solid"
                        onClick={goBack}
                        className="w-min"
                    >
                        {goBackText}
                    </Button>
                </div>
            )}

            <Popover
                isOpen={isOpen}
                placement="bottom-end"
                onClose={() => setIsOpen(false)}
            >
                <PopoverTrigger>
                    <div className="cursor-pointer">
                        <Tooltip
                            showArrow
                            radius="sm"
                            color="foreground"
                            content={"Profile & Settings"}
                        >
                            <div
                                className="flex items-center text-sm"
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                <span className="mr-3">
                                    {user.firstName} {user.lastName}
                                </span>
                                <Avatar
                                    size="sm"
                                    memberId={
                                        isInvestor
                                            ? meData?.data?.userId
                                            : userData?.memberId
                                    }
                                    firstName={user.firstName}
                                    lastName={user.lastName}
                                    src={user.picture?.url}
                                    disableTooltip
                                />
                            </div>
                        </Tooltip>
                    </div>
                </PopoverTrigger>
                <PopoverContent className="w-[220px] p-2 text-left">
                    <NavLink
                        to={isInvestor ? "/investor-edit" : "/profile-edit"}
                        className={({ isActive }) =>
                            cn(
                                "block w-full rounded-sm p-3 hover:bg-default-50",
                                isActive && "bg-default-50"
                            )
                        }
                    >
                        Personal profile
                    </NavLink>
                    <NavLink
                        to={"/settings"}
                        className={({ isActive }) =>
                            cn(
                                "block w-full rounded-sm p-3 hover:bg-default-50",
                                isActive && "bg-default-50"
                            )
                        }
                    >
                        Settings
                    </NavLink>
                    <button
                        type="button"
                        onClick={handleLogout}
                        className="block w-full rounded-sm p-3 text-left hover:bg-default-50"
                    >
                        Log out
                    </button>
                </PopoverContent>
            </Popover>
        </Header>
    );
};

export default DashboardHeader;
