import { GridSelectOption } from "components/GridSelect";

export const DEADLINE_OPTIONS: Array<GridSelectOption<number>> = [
    {
        label: "2 days",
        value: 2,
    },
    {
        label: "3 days",
        value: 3,
    },
    {
        label: "1 week",
        value: 7,
    },
    {
        label: "2 weeks",
        value: 14,
    },
];
