import { Dispatch, FC } from "react";
import Title from "routes/company/components/Modal/Title";
import { InsightsIndividual } from "store/api/investorCompany/interfaces";
import LencioniPyramid from "../LencioniPyramid";
import { LencioniPyramidData } from "../useLencioniPyramidController";
import IndividualScores from "routes/company/components/IndividualScores";
import { TOOLTIPS } from "routes/company/tooltips";

type Props = {
    individualData: InsightsIndividual;
    pyramidData: Array<LencioniPyramidData>;
    currIdx: number;
    tooltip: string;
    setCurrIdx: Dispatch<React.SetStateAction<number>>;
    controlHandler: (action: "back" | "forward") => () => void;
};

const LencioniModal: FC<Props> = ({
    individualData,
    pyramidData,
    currIdx,
    tooltip,
    ...lencioniPyramidController
}) => {
    return (
        <div className="flex flex-col gap-6">
            <Title tooltip={tooltip}>Lencioni pyramid</Title>

            <div className="flex justify-center">
                <LencioniPyramid
                    tooltip={TOOLTIPS.teamDynamics.pyramid}
                    iconType="Pyramid"
                    name="Lencioni pyramid"
                    pyramidData={pyramidData}
                    currIdx={currIdx}
                    {...lencioniPyramidController}
                    showTitle={false}
                    showMore={false}
                    className="h-auto w-full gap-6 p-0"
                />
            </div>

            <IndividualScores
                individualData={individualData}
                teamMetricValue={pyramidData[currIdx].value}
                metric={pyramidData[currIdx].key}
                className="py-8"
            />
        </div>
    );
};

export default LencioniModal;
