import Utils from "utils";
import Icon from "components/Icon";

type Props = {
    website: string;
};

const CompanyAboutLink: React.FC<Props> = ({ website }) => (
    <a
        href={website}
        target="_blank"
        rel="noopener noreferrer"
        className="flex cursor-pointer flex-row items-center gap-2 text-sm underline"
    >
        <Icon type="Link" size={20} />
        {Utils.Parsers.getMainDNSName(website)}
    </a>
);

export default CompanyAboutLink;
