import { useEffect, useState } from "react";

import Title from "questions/components/Title";
import GridSelect from "components/GridSelect";
import { QuestionProps } from "questions/types.questions";
import { usePostSurveyAnswerMutation } from "store/api/survey";
import SurveyFooter from "routes/surveys/components/SurveyFooter";
import { GRID_LARGE_OPTION_TO_ICON } from "questions/components/data";
import { GridMultiSelectAnswerPattern } from "types/surveys/answer-pattern";
import { GridMultiSelectQuestionPattern } from "types/surveys/question-pattern";

const GridMultiSelectQuestion = ({
    isChild,
    surveyKey,
    allDisabled,
    isLastQuestion,
    data: { pattern, key, isRequired, answer, text },
    handleBack,
    handleNext,
    onError,
    onWarning,
}: QuestionProps<
    GridMultiSelectQuestionPattern,
    GridMultiSelectAnswerPattern
>) => {
    const [postSurveyAnswer, { isError, isLoading, isSuccess }] =
        usePostSurveyAnswerMutation();

    const [isValid, setIsValid] = useState(!isRequired);
    const [selectedValue, setSelectedValue] = useState<Array<string>>(
        answer?.value ? answer.value : []
    );

    const handleSubmit = () => {
        postSurveyAnswer({
            surveyKey,
            questionKey: key,
            data: JSON.stringify(selectedValue),
        });
    };

    useEffect(() => {
        if (isSuccess) handleNext();
    }, [isSuccess]);

    useEffect(() => {
        onError(isError);
    }, [isError]);

    useEffect(() => {
        onWarning(
            selectedValue.length > pattern.max
                ? "Please select 3 items"
                : undefined
        );

        if (
            selectedValue.length >= pattern.min &&
            selectedValue.length <= pattern.max
        ) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [selectedValue.length]);

    // make each question optionally receive callback function which will be invoked
    // with updated value of that question so that the parents knows about it

    // map the children questions of the question and pass that function
    // there wil be a useEffect or something that will check the fulfilled value for validity and toggle disabled state of the Next button

    return (
        <div className="flex flex-col items-center justify-center gap-12">
            <div>{!isChild && <Title>{text}</Title>}</div>

            <GridSelect<string>
                centered
                isMultiSelect
                disabled={allDisabled}
                selected={selectedValue}
                onSelect={setSelectedValue}
                options={pattern.options.map((label) => ({
                    label,
                    value: label,
                    icon: GRID_LARGE_OPTION_TO_ICON[label],
                }))}
            />

            <SurveyFooter
                isLoading={isLoading}
                loadingText="Saving..."
                handleBack={handleBack}
                handleNext={handleSubmit}
                allDisabled={allDisabled}
                nextDisabled={!selectedValue || !isValid}
                nextText={isLastQuestion ? "Submit" : undefined}
            />
        </div>
    );
};

export default GridMultiSelectQuestion;
