import { z } from "zod";

export const lineChartDataSchema = z.array(
    z.object({
        x: z.string(),
        y: z.number(),
    })
);

export type LineChartData = z.infer<typeof lineChartDataSchema>;
