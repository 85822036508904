type Props = {
    className?: string;
    children: string;
};

const Title = ({ children, className }: Props) => (
    <div
        className={`text-center text-3xl self-center max-w-[680px] ${className}`}
    >
        {children}
    </div>
);

export default Title;
