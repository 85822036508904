import Frame from "../shared/Frame";
import LargeStats from "./components/LargeStats";
import ChartWrapper from "../shared/ChartWrapper";
import ExpandableDescription from "../shared/ExpandableDescription";
import { InsightsCompany } from "store/api/investorCompany/interfaces";
import { ChartContainer } from "components/ui/chart";
import {
    Area,
    AreaChart,
    CartesianGrid,
    LabelList,
    XAxis,
    YAxis,
} from "recharts";
import { format } from "date-fns";
import Utils from "utils";
import { getCurrencySign } from "routes/company/utils";
import {
    TractionChartData,
    tractionChartDataSchema,
} from "./schemas/data-schema";
import { useMemo } from "react";

type Props = {
    data: InsightsCompany;
};

const TractionCompanyCard: React.FC<Props> = ({ data }) => {
    const {
        burnRate,
        runway,
        hasRecurringRevenue,
        hasMultipleRevenueStreams,
        multipleRevenueStreamsDescription,
    } = {
        burnRate: data["company-burn-rate"]?.value as number,
        runway: data["company-runway-months"]?.value as number,
        hasRecurringRevenue: data["company-mrr"]?.value as boolean,
        hasMultipleRevenueStreams: data["company-multiple-revenue-streams"]
            ?.value as boolean,
        multipleRevenueStreamsDescription: data[
            "company-multiple-revenue-streams-description"
        ]?.value as string,
    };

    const parsedTractionChartData = useMemo((): TractionChartData | null => {
        const result = tractionChartDataSchema.safeParse(
            data?.["company-mrr-amount"]?.value
        );
        if (!result.success) {
            return null;
        }
        return result.data;
    }, [data]);
    const finalMrrValue = parsedTractionChartData
        ? parsedTractionChartData.map((i) => ({
              x: new Date(i.x),
              y: i.y,
          }))
        : null;
    const mrrRecords = finalMrrValue
        ? [
              {
                  value:
                      (((finalMrrValue[1].y - finalMrrValue[0].y) /
                          finalMrrValue[0].y) *
                          100) |
                      0,
                  label: "MRR growth",
                  start: finalMrrValue[0].x,
                  end: finalMrrValue[1].x,
                  hideIcon: finalMrrValue[0].y === 0,
                  hideValue: finalMrrValue[0].y === 0,
                  unit: finalMrrValue[0].y === 0 ? "—" : "%",
              },
              {
                  value: Number(
                      (((finalMrrValue[2].y - finalMrrValue[1].y) /
                          finalMrrValue[1].y) *
                          100) |
                          0
                  ),
                  label: "Expected MRR growth",
                  start: finalMrrValue[1].x,
                  end: finalMrrValue[2].x,
                  hideIcon: finalMrrValue[1].y === 0,
                  hideValue: finalMrrValue[1].y === 0,
                  unit: finalMrrValue[1].y === 0 ? "—" : "%",
              },
          ]
        : undefined;
    const currencySign = getCurrencySign(data["company-currency"]);

    const isEmptyInsight: boolean = useMemo(
        () =>
            Object.values({
                burnRate,
                runway,
                hasRecurringRevenue,
                hasMultipleRevenueStreams,
            }).some((value) => value === undefined),
        [burnRate, runway, hasRecurringRevenue, hasMultipleRevenueStreams]
    );

    return (
        <Frame title="Traction" isEmptyInsight={isEmptyInsight}>
            <ChartWrapper
                showStats
                title="MRR"
                hasData={!!finalMrrValue && hasRecurringRevenue}
                emptyText="No recurring revenue model"
                records={mrrRecords}
                currencySign={currencySign}
            >
                {finalMrrValue && (
                    <ChartContainer config={{}} className="h-[180px] w-full">
                        <AreaChart
                            data={finalMrrValue}
                            accessibilityLayer
                            margin={{ top: 32, left: 20, right: 32 }}
                        >
                            <CartesianGrid vertical={false} stroke="#E1E1E8" />
                            <XAxis
                                dataKey="x"
                                tickLine={false}
                                tickMargin={8}
                                axisLine={false}
                                tickFormatter={(v) =>
                                    v instanceof Date
                                        ? format(v, "MMM yyyy")
                                        : "?"
                                }
                            />
                            <YAxis
                                axisLine={false}
                                tickLine={false}
                                width={30}
                                tickCount={3}
                                tickFormatter={(v: number) =>
                                    Utils.Parsers.abbreviateLargeNumbers(v)
                                }
                            />
                            <Area
                                dataKey="y"
                                type="linear"
                                fill="url(#area-gradient)"
                                stroke="#AC74FF"
                                strokeWidth="2"
                                dot={(p) => (
                                    <circle
                                        cx={p.cx}
                                        cy={p.cy}
                                        r={4}
                                        fill="#AC74FF"
                                    />
                                )}
                            >
                                <LabelList
                                    position="top"
                                    content={(p) => (
                                        <text
                                            x={p.x}
                                            y={Number(p.y) - 12}
                                            fill={p.fill}
                                            fontSize={12}
                                            textAnchor={
                                                p.index === 0
                                                    ? "start"
                                                    : "middle"
                                            }
                                            className="fill-black"
                                        >
                                            {`${currencySign}${Utils.Parsers.abbreviateLargeNumbers(p.value as number)}`}
                                        </text>
                                    )}
                                />
                            </Area>
                        </AreaChart>
                    </ChartContainer>
                )}
            </ChartWrapper>

            <LargeStats
                burnRate={burnRate}
                runway={runway}
                currencySign={currencySign}
            />
            <ExpandableDescription
                considerLarge={438}
                title="Multiple revenue streams"
                description={
                    hasMultipleRevenueStreams
                        ? multipleRevenueStreamsDescription
                        : "No"
                }
            />
        </Frame>
    );
};

export default TractionCompanyCard;
