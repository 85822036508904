import { useDisclosure } from "@nextui-org/react";
import { useState } from "react";

const useModalController = () => {
    const [current, onChangeCurrent] = useState(0);
    const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();

    const open = (idx: number) => {
        onChangeCurrent(idx);
        onOpen();
    };

    return {
        current,
        onChangeCurrent,
        isOpen,
        onOpenChange,
        onOpen,
        open,
        onClose,
    };
};

export default useModalController;
