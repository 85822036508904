import { Progress as NextProgress } from "@nextui-org/react";

type Props = {
    value: number;
    className?: string;
};

const Progress = ({ value, className }: Props) => {
    return (
        <NextProgress
            size="sm"
            value={value}
            className={`max-w-md ${className}`}
            classNames={{
                indicator: "bg-brand-gradient",
                base: "bg-[#EBEBEF]",
            }}
        />
    );
};

export default Progress;
