import EmptyInsight from "routes/company/components/EmptyInsight";
import UpAndDownStat from "./UpAndDownStats";

type GrowthRecord = {
    value: number;
    label: string;
    start?: Date;
    end?: Date;
    unit?: string;
    hideIcon?: boolean;
    hideValue?: boolean;
};

type Props = {
    showStats?: boolean;

    records?: GrowthRecord[];
    title: string;
    hasData: boolean;
    emptyText?: string;
    hideDollarSign?: boolean;
    children?: React.ReactNode;
    currencySign?: string;
};

const ChartWrapper: React.FC<Props> = ({
    title,
    hasData,
    emptyText,
    showStats,
    hideDollarSign,
    records = [],
    currencySign = "$",
    children,
}) => {
    if (hasData && !hideDollarSign) title += `, ${currencySign}`;

    // @TODO: remove this prop
    void showStats;

    return (
        <div className="flex flex-col gap-2 rounded-lg bg-default-50 p-5">
            <div>{title}</div>

            {hasData ? (
                <div className="text-center text-2xl">
                    {children ?? "N/A"}

                    {records && (
                        <div className="mt-6 flex flex-col gap-2">
                            {records.map((record, index) => (
                                <UpAndDownStat
                                    key={index}
                                    value={record.value}
                                    label={record.label}
                                    startDate={record.start}
                                    endDate={record.end}
                                    unit={record.unit}
                                    hideIcon={record.hideIcon}
                                    hideValue={record.hideValue}
                                />
                            ))}
                        </div>
                    )}
                </div>
            ) : emptyText ? (
                <div className="text-sm text-default-400">{emptyText}</div>
            ) : (
                <EmptyInsight />
            )}
        </div>
    );
};

export default ChartWrapper;
