import { ReactNode } from "react";

import LogoImage from "../../../images/logo.png";
import SideBarImageAuth from "../../../images/onboarding/side-bar-auth.png";
import SideBarImageMember from "../../../images/onboarding/side-bar-member.png";
import SideBarImageCompany from "../../../images/onboarding/side-bar-company.png";

type SideImageType = "member" | "company" | "auth";

type Props = {
    children: ReactNode;
    leftContent: ReactNode;
    leftBottomContent?: ReactNode;
    leftBottomSecondaryContent?: ReactNode;

    title?: string;
    type: SideImageType;
    leftContentAboveTitle?: string;
};

const USER_TYPE_TO_BANNER: { [key in SideImageType]: string } = {
    auth: SideBarImageAuth,
    member: SideBarImageMember,
    company: SideBarImageCompany,
};

const Layout = ({
    type,
    title,
    children,
    leftContent,
    leftBottomContent,
    leftContentAboveTitle,
    leftBottomSecondaryContent,
}: Props) => {
    return (
        <div>
            <div className="flex min-h-screen gap-4 p-4 bg-default-100">
                <div
                    className="min-w-[400px] w-[400px] rounded-2xl  p-[32px] relative overflow-hidden hidden lg:flex lg:flex-col"
                    style={{
                        backgroundImage: `url(${USER_TYPE_TO_BANNER[type]})`,
                    }}
                >
                    <div className="relative z-10 flex flex-col flex-1">
                        <img
                            alt="Logo"
                            src={LogoImage}
                            className="w-[48px] h-[48px]"
                        />

                        <div className="mt-[52px] mb-6">
                            {leftContentAboveTitle && (
                                <div className="text-sm">
                                    {leftContentAboveTitle}
                                </div>
                            )}
                            {title && (
                                <div
                                    className={`${type === "auth" && "text-white"} text-2xl`}
                                >
                                    {title}
                                </div>
                            )}
                        </div>

                        <div
                            className={`flex flex-1 flex-col gap-3 ${type === "auth" && "text-white"}`}
                        >
                            <div>{leftContent}</div>
                            <div>{leftBottomSecondaryContent}</div>
                        </div>

                        <div>{leftBottomContent}</div>
                    </div>

                    <div className="absolute bottom-0 left-0 right-0">
                        <img src={USER_TYPE_TO_BANNER[type]} />
                    </div>
                </div>

                <div className="rounded-2xl bg-[#FBFBFC] flex flex-1 flex-col gap-12 justify-center items-center">
                    <div className="w-[480px]">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
