export const createUrl = (pathname: string, params: URLSearchParams) => {
    const paramsString = params.toString();
    const queryString = `${paramsString.length ? "?" : ""}${paramsString}`;

    return `${pathname}${queryString}`;
};

export const createShareInsightsLink = (
    hostname: string,
    token: string
): string => {
    return `${hostname}/share/reports/${token}`;
};
