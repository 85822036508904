import {
    TimeInputProps,
    TimeInput as NextTimeInput,
    TimeInputValue,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { parseTime } from "@internationalized/date";

import Collapsed from "components/Collapsed";

type Props = {
    value?: string;
    label?: string;
    errorMessage?: string;
    onChange: (v?: string) => void;
} & Omit<TimeInputProps, "onChange" | "value">;

const ERROR_VANISH_DURATION = 300;
const TimeInput = ({
    onBlur,
    onChange,
    label,
    value: val,
    errorMessage: errMsg,
    ...rest
}: Props) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [value, setValue] = useState<TimeInputValue | undefined>(
        val ? parseTime(val) : undefined
    );

    const handleBlur = (e: any) => {
        if (
            value &&
            value.hour !== undefined &&
            value.minute !== undefined &&
            value.second !== undefined
        ) {
            const hour = value.hour.toString().padStart(2, "0");
            const minute = value.minute.toString().padStart(2, "0");
            const second = value.second.toString().padStart(2, "0");

            const vvv = `${hour}:${minute}:${second}`;
            onChange?.(vvv);
            setValue(parseTime(vvv));
        } else {
            onChange?.(undefined);
        }

        onBlur?.(e);
    };

    useEffect(() => {
        if (val) {
            setValue(parseTime(val));
        } else {
            setValue(undefined);
        }
    }, [val]);

    useEffect(() => {
        if (!errMsg) {
            setTimeout(() => {
                setErrorMessage(undefined);
            }, ERROR_VANISH_DURATION);
        } else {
            setErrorMessage(errMsg);
        }
    }, [errMsg]);

    return (
        <div>
            {label && (
                <div className={`mb-2 text-sm text-default-500`}>{label}</div>
            )}

            <NextTimeInput
                classNames={{
                    // texts
                    label: "text-black text-sm mb-2",
                    errorMessage: "text-danger-500 text-sm mt-2",

                    // containers
                    base: "bg-[transparent]",
                    innerWrapper:
                        "bg-[transparent] h-auto focus:border-[#9753FC]",
                    segment: `                
                    data-[editable=true]:data-[placeholder=true]:text-default-400
                    text-black
                    text-default-400
                    focus:bg-default-100
                    group-data-[invalid=true]:text-default-400
                    group-data-[invalid=true]:focus:bg-default-100
                    data-[editable=true]:data-[placeholder=false]:text-danger-500
                `,
                    input: `
                    text-black bg-white
                    p-[14px] border border-solid border-default-200 rounded-md
                    focus-within:border-[#9753FC]
                    focus-within:shadow-focus-ring
                    group-data-[invalid=true]:border-danger-500
                `,
                    inputWrapper: `
                    shadow-none p-0 m-0 h-auto bg-[transparent] rounded-md
                    focus-within:hover:bg-transparent 
                    focus-within:hover:group-data-[invalid=true]:bg-transparent
                    hover:bg-[transparent]
                    hover:group-data-[invalid=true]:bg-transparent
                    group-data-[invalid=true]:bg-transparent
                `,
                }}
                placeholderValue={value}
                isInvalid={!!errorMessage}
                className="max-w-sm"
                onChange={setValue}
                onBlur={handleBlur}
                value={value}
                hourCycle={24}
                {...rest}
            />

            <Collapsed isExpanded={!!errMsg} duration={ERROR_VANISH_DURATION}>
                <div className="mt-2 text-sm text-danger-500">
                    {errMsg || errorMessage}
                </div>
            </Collapsed>
        </div>
    );
};

export default TimeInput;

// group first:-ml-0.5 [&:not(:first-child)]:-ml-1 px-0.5 my-auto box-content tabular-nums text-start inline-block outline-none focus:shadow-sm rounded-md data-[editable=true]:text-foreground data-[editable=true]:data-[placeholder=true]:text-foreground-500 data-[invalid=true]:text-danger-300 data-[invalid=true]:data-[editable=true]:text-danger data-[invalid=true]:focus:bg-danger-400/50 dark:data-[invalid=true]:focus:bg-danger-400/20 data-[invalid=true]:data-[editable=true]:focus:text-danger data-[editable=true]:focus:text-default-foreground transition-colors motion-reduce:transition-none segment-segment-segment-segmentaaaaaaasdasdasdadasdasdasdasdasdasdasdasasdasdasd text-default-400 focus:bg-default-100 group-data-[invalid=true]:text-default-400 group-data-[invalid=true]:focus:bg-default-100 data-[editable=true]:data-[placeholder=false]:text-danger-500
// group first:-ml-0.5 [&:not(:first-child)]:-ml-1 px-0.5 my-auto box-content tabular-nums text-start inline-block outline-none focus:shadow-sm rounded-md data-[editable=true]:text-foreground data-[editable=true]:data-[placeholder=true]:text-foreground-500 data-[invalid=true]:text-danger-300 data-[invalid=true]:data-[editable=true]:text-danger data-[invalid=true]:focus:bg-danger-400/50 dark:data-[invalid=true]:focus:bg-danger-400/20 data-[invalid=true]:data-[editable=true]:focus:text-danger data-[editable=true]:focus:text-default-foreground transition-colors motion-reduce:transition-none segment-segment-segment-segmentaaaaaaasdasdasdadasdasdasdasdasdasdasdasasdasdasd text-default-400 focus:bg-default-100 group-data-[invalid=true]:text-default-400 group-data-[invalid=true]:focus:bg-default-100 data-[editable=true]:data-[placeholder=false]:text-danger-500
