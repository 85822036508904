import { useMemo } from "react";

import {
    InsightsTeam,
    InsightsCompany,
    InsightsIndividual,
} from "store/api/investorCompany/interfaces";
import SkillsMatrixCard from "./Cards/SkillsMatrix";
import TopExpertiseCard from "./Cards/TopExpertiseCard";
import IndustryExperience from "./Cards/IndustryExperience";
import SkillsDistributionCard from "./Cards/SkillsDistribution";
import NonIndustryExperienceCard from "./Cards/NonIndustryExperience";
import { TOOLTIPS } from "routes/company/tooltips";

type Props = {
    team: InsightsTeam;
    individual: InsightsIndividual;
    companyInsight: InsightsCompany;
};

const HumanCapitalSkillsTab: React.FC<Props> = ({
    team,
    individual,
    companyInsight,
}) => {
    const total = useMemo(() => Object.values(individual).length, [individual]);

    return (
        <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <TopExpertiseCard
                    individual={individual}
                    team={team}
                    tooltip={TOOLTIPS.humanCapitalSkills.topExpertise}
                />
                <SkillsDistributionCard
                    individual={individual}
                    team={team}
                    tooltip={TOOLTIPS.humanCapitalSkills.skillsDistribution}
                />
            </div>

            <SkillsMatrixCard
                individual={individual}
                tooltip={TOOLTIPS.humanCapitalSkills.skillsMatrix}
            />
            <IndustryExperience
                team={team}
                company={companyInsight}
                tooltip={TOOLTIPS.humanCapitalSkills.industryExperience}
            />

            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                <NonIndustryExperienceCard
                    total={total}
                    iconType="Rocket"
                    individual={individual}
                    title="Startup experience"
                    theKey="hc-startup-experience"
                    tooltip={TOOLTIPS.humanCapitalSkills.startupExperience}
                />
                <NonIndustryExperienceCard
                    total={total}
                    iconType="ChartLine"
                    individual={individual}
                    title="Entrepreneurial experience"
                    theKey="hc-entrepreneurial-projects"
                    tooltip={
                        TOOLTIPS.humanCapitalSkills.entrepreneurialExperience
                    }
                />
                <NonIndustryExperienceCard
                    total={total}
                    showTeamSize
                    iconType="Users"
                    individual={individual}
                    title="Management experience"
                    theKey="hc-management-position"
                    tooltip={TOOLTIPS.humanCapitalSkills.managementExperience}
                />
            </div>
        </div>
    );
};

export default HumanCapitalSkillsTab;
