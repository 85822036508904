type Props = {
    children: any;
    isHovered?: boolean;
    secondaryContent: any;
};

const HoverComponent: React.FC<Props> = ({
    children,
    isHovered,
    secondaryContent,
}) => {
    return (
        <div className="relative w-[100%]">
            <div
                className={`duration-350 transition-all ease-in-out ${isHovered ? "opacity-0" : "opacity-100"}`}
            >
                {children}
            </div>

            <div
                className={`duration-350 absolute bottom-0 left-0 right-0 top-0 flex items-end justify-end transition-all ease-in-out ${isHovered ? "opacity-100" : "opacity-0"}`}
            >
                {secondaryContent}
            </div>
        </div>
    );
};

export default HoverComponent;
