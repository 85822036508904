import Icon from "components/Icon";
import Button from "components/Button";

type Props = {
    onNext: () => void;
    onBack?: () => void;
    isLastStep?: boolean;
    disableNext?: boolean;
};

const BottomButtons = ({ onNext, onBack, disableNext, isLastStep }: Props) => {
    return (
        <div className="flex gap-4 mt-7">
            {onBack && (
                <Button
                    customSize="xl"
                    customType="secondary-solid"
                    onClick={onBack}
                >
                    Back
                </Button>
            )}
            <Button customSize="xl" disabled={disableNext} onClick={onNext}>
                {isLastStep ? "Submit" : "Next"}
                {!isLastStep && <Icon type="ArrowRight" color="white" />}
            </Button>
        </div>
    );
};

export default BottomButtons;
