import { FC } from "react";
import { useTeamQuery } from "store/api/member";
import { useGetSurveysQuery } from "store/api/survey";
import { useGetMeQuery } from "store/api/auth";
import { Guideline as GuidelineSteps } from "../Guideline";
import { getGuidelineSteps } from "utils/guideline";

const Guideline: FC = () => {
    const { data: meData } = useGetMeQuery();
    const { data: teamData, isLoading: isTeamLoading } = useTeamQuery();
    const { data: surveysData, isLoading: isSurveysLoading } =
        useGetSurveysQuery();
    const isLoading = isTeamLoading || isSurveysLoading;
    const steps = getGuidelineSteps(meData, teamData, surveysData);

    return <GuidelineSteps isLoading={isLoading} steps={steps} />;
};

export default Guideline;
