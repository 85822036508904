import { cn } from "lib/utils";
import CounterBadge from "./CounterBadge";
import Icon, { IconType } from "components/Icon";

type Type = "filled" | "outlined";

export type SkillsDistributionCardProps = {
    type?: Type;
    total?: number;
    title: string;
    number: number;
    asterisk?: boolean;
    iconType?: IconType;
};

const HCGridSelectCard: React.FC<SkillsDistributionCardProps> = ({
    title,
    total,
    number,
    asterisk,
    iconType,
    type = "filled",
}) => {
    const getScore = () => {
        if (!total) return undefined;

        const percentage = (number / total) * 100;
        if (percentage >= 50) {
            return "high";
        } else if (percentage >= 20) {
            return "medium";
        } else {
            return "low";
        }
    };

    return (
        <div
            className={cn(
                "relative flex flex-row gap-3 rounded-md p-[14px]",
                !number && "text-default-400",
                type === "filled"
                    ? "bg-default-50"
                    : "border border-solid border-default-100"
            )}
        >
            {iconType && (
                <Icon
                    size={20}
                    type={iconType}
                    className={number ? "" : "!text-default-400"}
                />
            )}

            <div className={`text-sm ${!number && "!text-default-400"}`}>
                {title}
            </div>

            {number ? (
                <CounterBadge
                    number={number}
                    score={getScore()}
                    type={type === "filled" ? "white" : "filled"}
                />
            ) : undefined}

            {asterisk && (
                <Icon
                    className="absolute right-0 top-0 -translate-y-1/3 translate-x-1/3 bg-background text-secondary-500"
                    type="Asterisk"
                />
            )}
        </div>
    );
};

export default HCGridSelectCard;
