import { FC, useMemo } from "react";
import MemberInfo from "routes/company/components/MemberInfo";
import { InsightsIndividualMember } from "store/api/investorCompany/interfaces";
import Utils from "utils";
import Linkedin from "../linkedin";
import InfoWrapperModal from "../info-wrapper-modal";
import PersonalInformation from "../personal-information";
import CoFounder from "../co-founder";
import CompanyMemberInfo from "../company-member-info";
import CoFounderExplanation from "../co-founder-explanation";
import HumanCapitalSkills from "../human-capital-skills";
import KeyInsights from "../key-insights";

type Props = {
    memberData: InsightsIndividualMember;
};

const UserCardModal: FC<Props> = ({ memberData }) => {
    const memberInfo = useMemo(
        () => Utils.Insight.getMemberInfoFromInsight(memberData),
        [memberData]
    );

    return (
        <div className="flex flex-col gap-y-6">
            <MemberInfo
                {...memberInfo}
                disableTooltip={true}
                classNames={{
                    avatar: "h-[80px] w-[80px]",
                    info: "gap-y-1",
                    name: "text-md",
                }}
                className="items-center py-3"
            />
            <InfoWrapperModal
                title="Personal information"
                className="flex flex-col gap-y-6"
            >
                <Linkedin memberData={memberData} />
                <PersonalInformation memberData={memberData} />
            </InfoWrapperModal>
            <InfoWrapperModal
                title="Role in company"
                className="flex flex-col gap-y-6"
            >
                <CoFounder memberData={memberData} />
                <CompanyMemberInfo memberData={memberData} />
                <CoFounderExplanation memberData={memberData} />
            </InfoWrapperModal>
            <InfoWrapperModal title="Human capital skills">
                <HumanCapitalSkills memberData={memberData} />
            </InfoWrapperModal>
            <InfoWrapperModal title="Key insights">
                <KeyInsights memberData={memberData} />
            </InfoWrapperModal>
        </div>
    );
};

export default UserCardModal;
