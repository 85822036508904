import Icon from "components/Icon";
import { cn } from "lib/utils";
import { FC } from "react";
import { TypeOfPassion } from "utils/insight";

type Props = {
    passionType: TypeOfPassion;
    className?: string;
};

const MainPassionType: FC<Props> = ({
    passionType: { gradient, label, icon, iconColor },
    className,
}) => {
    return (
        <div
            className={cn(
                "flex h-full animate-scale-in items-center",
                className
            )}
        >
            <div
                style={{ backgroundImage: gradient }}
                className="flex h-[160px] w-[160px] flex-col items-center justify-center rounded-full text-lg"
            >
                <Icon size={32} type={icon} className={iconColor} />
                {label}
            </div>
        </div>
    );
};

export default MainPassionType;
