import { FC, useMemo } from "react";
import CardBlockWrapper from "../card-block-wrapper";
import { cva } from "class-variance-authority";
import { cn } from "lib/utils";
import { InsightsIndividualMember } from "store/api/investorCompany/interfaces";
import {
    KeyInsights as TKeyInsights,
    keyInsightsSchema,
} from "../../schemas/key-insights-schema";
import EmptyInsight from "routes/company/components/EmptyInsight";
import Utils from "utils";
import { Badge, BadgeIcon } from "../badge";
import { IndividualScore } from "components/IndividualScore";

const titleVariants = cva("text-sm font-semibold text-default-400");

type Props = {
    memberData: InsightsIndividualMember;
};

const KeyInsights: FC<Props> = ({ memberData }) => {
    const values = useMemo((): TKeyInsights | null => {
        const data = {
            entrepreneurshipPassion: memberData?.ep_passion?.value,
            inventingScore: memberData?.ep_inventing_score?.value,
            foundingScore: memberData?.ep_founding_score?.value,
            developingScore: memberData?.ep_developing_score?.value,
            teamDynamics: memberData?.td_overall?.value,
            softSkills: memberData?.ss_overall?.value,
        };
        const dataParsed = keyInsightsSchema.safeParse(data);
        if (!dataParsed.success) {
            return null;
        }
        return dataParsed.data;
    }, [memberData]);

    if (!values) {
        return <EmptyInsight className="py-0" />;
    }

    const {
        entrepreneurshipPassion,
        inventingScore,
        foundingScore,
        developingScore,
        teamDynamics,
        softSkills,
    } = values;

    const mainType = Utils.Insight.getTypeOfPassion(
        developingScore,
        foundingScore,
        inventingScore
    );

    return (
        <CardBlockWrapper>
            <div className="flex flex-col gap-y-8">
                <div className="flex flex-col gap-y-2">
                    <h5 className={cn(titleVariants())}>
                        Passion for entrepreneurship
                    </h5>
                    <IndividualScore
                        size="sm"
                        value={entrepreneurshipPassion}
                    />
                </div>
                <div className="flex flex-col gap-y-2">
                    <h5 className={cn(titleVariants())}>Team dynamics</h5>
                    <IndividualScore size="sm" value={teamDynamics} />
                </div>
            </div>
            <div className="flex flex-col gap-y-8">
                <div className="flex flex-col gap-y-2">
                    <h5 className={cn(titleVariants())}>
                        Main type of passion
                    </h5>
                    <Badge variant={"secondary"}>
                        <BadgeIcon variant={mainType.labelType} />
                        {mainType.label}
                    </Badge>
                </div>
                <div className="flex flex-col gap-y-2">
                    <h5 className={cn(titleVariants())}>Soft skills</h5>
                    <IndividualScore size="sm" value={softSkills} />
                </div>
            </div>
        </CardBlockWrapper>
    );
};

export default KeyInsights;
