import { FC } from "react";
import { InsightsTeam } from "store/api/investorCompany/interfaces";
import Title from "routes/company/components/Modal/Title";
import FixedGrowth from "../FixedGrowth";
import { TOOLTIPS } from "routes/company/tooltips";

type Props = {
    teamData: InsightsTeam;
    tooltip: string;
};

const FixedVsGrowthModal: FC<Props> = ({ teamData, tooltip }) => {
    return (
        <div className="flex flex-col gap-4">
            <Title tooltip={tooltip}>Fixed vs Growth mindset</Title>

            <div>
                <FixedGrowth
                    tooltip={TOOLTIPS.softSkills.mindset}
                    iconType="ArrowRightLeft"
                    name="Fixed vs Growth mindset"
                    teamData={teamData}
                    showTitle={false}
                    showMore={false}
                    className="max-w-none !p-0"
                    insightClassName="py-11"
                />
            </div>
        </div>
    );
};

export default FixedVsGrowthModal;
