import { FC, useState } from "react";
import { Modal, ModalContent, Tooltip } from "@nextui-org/react";

import { MemberCardProps } from "./interfaces";
import Icon from "components/Icon";
import {
    CircularProgress,
    Popover,
    PopoverContent,
    PopoverTrigger,
    useDisclosure,
} from "@nextui-org/react";
import { useGetMeQuery } from "store/api/auth";
import {
    useDeleteMemberMutation,
    useSendSurveyReminderMutation,
    useTeamQuery,
} from "store/api/member";
import cn from "classnames";
import { useAppDispatch } from "core/hooks";
import { GlobalActions } from "store/reducers/global";
import {
    useResendInvitationMutation,
    useRevokeInvitationMutation,
} from "store/api/invitation";
import LoadingSpinner from "components-legacy/LoadingSpinner";
import DeletionModal from "./DeletionModal";
import ConfirmationModal from "components/ConfirmationModal";
import Avatar from "components/Avatar";
import { UserType } from "store/api/invitation/interfaces";

const MemberCard: FC<MemberCardProps> = ({
    id,
    img,
    name,
    subtext,
    progress,
}) => {
    const dispatch = useAppDispatch();
    const [resendInvite, { isLoading: isResending }] =
        useResendInvitationMutation();
    const [revokeInvite, { isLoading: isRevoking }] =
        useRevokeInvitationMutation();
    const [sendSurveyReminder, { isLoading: isReminderSending }] =
        useSendSurveyReminderMutation();
    const [deleteMember, { isLoading: isDeletingTeammate }] =
        useDeleteMemberMutation();
    const [showRevokeInviteModal, setShowRevokeInviteModal] =
        useState<boolean>(false);
    const { data: meData } = useGetMeQuery();
    const { data: teamData } = useTeamQuery();
    const { onOpenChange, isOpen, onClose } = useDisclosure();
    const {
        isOpen: isDeletionOpen,
        onOpen: onDeletionOpen,
        onClose: onDeletionClose,
    } = useDisclosure();
    const member = teamData?.data.members.find((m) => m.id === id);
    const invite = teamData?.data.invitations.find((i) => i.id === id);
    const userData = meData?.data?.user;
    const isSuper = userData?.type === UserType.Member && userData?.isSuper;
    const hasIncomplete = member?.surveys.some((s) => !s.isSubmitted);
    const showActions =
        isSuper &&
        (invite || (member && hasIncomplete)) &&
        userData?.memberId !== id;
    const showUserIcon = !progress;
    const hasAnyProgress = !!progress?.done;

    const handleDeleteTeammate = async () => {
        try {
            const res = await deleteMember({ memberId: id });

            if (!("data" in res)) {
                const message = "Something went wrong, please refresh the page"; // || (res as any)?.error?.data?.error;
                throw new Error(message);
            }

            dispatch(
                GlobalActions.showPopover({
                    type: "dark",
                    label: `${name} deleted from the team`,
                })
            );
            onClose();
        } catch (err: unknown) {
            const message =
                err instanceof Error
                    ? err.message
                    : "Something went wrong, please refresh the page";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    };

    const handleResend = async () => {
        try {
            const res = await resendInvite({ id });

            if (!("data" in res)) {
                throw new Error(
                    "Something went wrong, please refresh the page"
                );
            }

            dispatch(
                GlobalActions.showPopover({
                    type: "success",
                    label: "Invite resent",
                })
            );
            onClose();
        } catch (err: unknown) {
            const message =
                err instanceof Error
                    ? err.message
                    : "Something went wrong, please refresh the page";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    };

    const handleRevoke = async () => {
        try {
            const res = await revokeInvite({ id });

            if (!("data" in res)) {
                const message = "Something went wrong, please refresh the page"; // (res as any)?.error?.data?.error;
                throw new Error(message);
            }

            setShowRevokeInviteModal(false);
            dispatch(
                GlobalActions.showPopover({
                    type: "dark",
                    label: `Invite for ${name} revoked`,
                })
            );
            onClose();
        } catch (err: unknown) {
            const message =
                err instanceof Error
                    ? err.message
                    : "Something went wrong, please refresh the page";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    };

    const handleSendReminder = async () => {
        try {
            const res = await sendSurveyReminder({ id });

            if (!("data" in res)) {
                throw new Error(
                    "Something went wrong, please refresh the page"
                );
            }

            dispatch(
                GlobalActions.showPopover({
                    type: "success",
                    label: "Reminder sent",
                })
            );
            onClose();
        } catch (err: unknown) {
            const message =
                err instanceof Error
                    ? err.message
                    : "Something went wrong, please refresh the page";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    };

    let tooltipContent = "No surveys completed";
    if (progress)
        if (progress.done === progress.total) {
            tooltipContent = "All surveys completed";
        } else if (progress.done) {
            tooltipContent = `${progress.done}/${progress.total} surveys completed`;
        }

    return (
        <>
            <Modal size="xl" hideCloseButton isOpen={isDeletionOpen}>
                <ModalContent className="mx-8">
                    {() => (
                        <DeletionModal
                            memberId={id}
                            name={name}
                            picture={img?.url}
                            onClose={onDeletionClose}
                            onSubmit={handleDeleteTeammate}
                        />
                    )}
                </ModalContent>
            </Modal>

            <div className="group flex flex-col rounded-lg p-3 text-sm hover:bg-default-50">
                <div className="flex items-start justify-between">
                    <div className="relative flex justify-start">
                        <Avatar
                            size="md"
                            memberId={showUserIcon ? undefined : id}
                            name={name}
                            src={img?.url}
                            fallbackType={showUserIcon ? "icon" : "initials"}
                            disableTooltip
                        />

                        {progress && (
                            <Tooltip
                                showArrow
                                radius="sm"
                                color="foreground"
                                content={tooltipContent}
                            >
                                <div className="absolute left-8 rounded-full bg-white">
                                    <div className="relative flex h-10 w-10 items-center justify-center rounded-full bg-white p-1">
                                        <CircularProgress
                                            className={`!h-[35px] !w-[35px] rounded-full ${progress.done === progress.total ? "bg-primary-100" : "bg-white"}`}
                                            value={
                                                progress.done === progress.total
                                                    ? 0
                                                    : 100 *
                                                      (progress.done /
                                                          progress.total)
                                            }
                                            showValueLabel={
                                                progress.done !== progress.total
                                            }
                                            classNames={{
                                                track: "stroke-primary-100 ",
                                                indicator:
                                                    "stroke-primary-500 ",
                                            }}
                                            valueLabel={`${progress.done}/${progress.total}`}
                                        />
                                        {progress.done === progress.total && (
                                            <div className="absolute self-center">
                                                <Icon
                                                    type="CCheck"
                                                    fill="#9153FF"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    {showActions && (
                        <Popover
                            isOpen={isOpen}
                            onOpenChange={onOpenChange}
                            placement="bottom-end"
                        >
                            <PopoverTrigger
                                className={cn(
                                    "transition duration-100",
                                    !isOpen &&
                                        "invisible opacity-0 group-hover:visible group-hover:opacity-100"
                                )}
                            >
                                <button
                                    type="button"
                                    className="rounded-sm bg-default-100 p-3"
                                >
                                    <Icon type="Ellipsis" />
                                </button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[220px] p-2 text-left">
                                {invite && (
                                    <>
                                        <button
                                            type="button"
                                            disabled={isResending}
                                            onClick={handleResend}
                                            className="flex w-full items-center justify-between rounded-sm p-3 text-left hover:bg-default-50"
                                        >
                                            <span>Resend invite</span>
                                            {isResending && <LoadingSpinner />}
                                        </button>
                                        <button
                                            type="button"
                                            disabled={isRevoking}
                                            onClick={() =>
                                                setShowRevokeInviteModal(true)
                                            }
                                            className="flex w-full items-center justify-between rounded-sm p-3 text-left text-danger-500 hover:bg-default-50"
                                        >
                                            <span>Revoke invite</span>
                                            {isRevoking && <LoadingSpinner />}
                                        </button>
                                    </>
                                )}
                                {member && (
                                    <>
                                        <button
                                            type="button"
                                            disabled={isReminderSending}
                                            onClick={handleSendReminder}
                                            className="flex w-full items-center justify-between rounded-sm p-3 text-left hover:bg-default-50"
                                        >
                                            <span>Send survey reminder</span>
                                            {isReminderSending && (
                                                <LoadingSpinner />
                                            )}
                                        </button>
                                        <Tooltip
                                            showArrow
                                            radius="sm"
                                            color="foreground"
                                            isDisabled={!hasAnyProgress}
                                            placement="top-start"
                                            content={
                                                hasAnyProgress ? (
                                                    <div className="max-w-[80px]">
                                                        Teammate cannot be
                                                        deleted
                                                    </div>
                                                ) : undefined
                                            }
                                        >
                                            <button
                                                type="button"
                                                onClick={onDeletionOpen}
                                                disabled={
                                                    isDeletingTeammate ||
                                                    hasAnyProgress
                                                }
                                                className={`flex w-full items-center justify-between rounded-sm p-3 text-left ${!hasAnyProgress ? "text-danger-500" : "text-danger-300"} hover:bg-default-50`}
                                            >
                                                <span>Delete teammate</span>
                                                {isDeletingTeammate && (
                                                    <LoadingSpinner />
                                                )}
                                            </button>
                                        </Tooltip>
                                    </>
                                )}
                            </PopoverContent>
                        </Popover>
                    )}
                </div>
                <div className="mt-1 overflow-hidden text-ellipsis whitespace-nowrap">
                    {name}
                </div>
                <div className="overflow-hidden text-ellipsis whitespace-nowrap text-default-400">
                    {subtext}
                </div>
            </div>

            <ConfirmationModal
                content={
                    <div className="text-sm text-default-500">
                        Are you sure you want to revoke{" "}
                        <span className="text-black">{name || "teammate"}</span>{" "}
                        invite?
                    </div>
                }
                title="Revoke invite?"
                show={showRevokeInviteModal}
                onSubmit={handleRevoke}
                buttonTexts={{ submit: "Yes, revoke" }}
                onClose={() => setShowRevokeInviteModal(false)}
            />
        </>
    );
};

export default MemberCard;
