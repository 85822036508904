import Chip from "../shared/Chip";
import Frame from "../shared/Frame";
import StatItem from "../shared/StatItem";
import ChartWrapper from "../shared/ChartWrapper";
import { InsightsCompany } from "store/api/investorCompany/interfaces";
import { BarChart } from "components/ui/BarChart";
import { LineChart } from "components/ui/LineChart";
import { ChartContainer } from "components/ui/chart";
import { BarChart as RBarChart, Bar, LabelList, YAxis, XAxis } from "recharts";
import { format } from "date-fns";
import { getCurrencySign } from "routes/company/utils";
import { useMemo } from "react";
import {
    NpsScoreChartData,
    npsScoreChartDataSchema,
} from "./schemas/data-schema";

type Props = {
    data: InsightsCompany;
};

const MarketAndCustomers: React.FC<Props> = ({ data }) => {
    const { position, npsTrack } = {
        position: data["company-market-position"]?.value,
        npsTrack: data["company-nps-track"]?.value as boolean,
    };
    const marketSize = data["company-market-size"]?.value as unknown as
        | { x: string; y: number }[]
        | null;
    const customers = data["company-customers"]?.value as unknown as
        | { x: string; y: number }[]
        | null;

    const parsedNpsScore = useMemo((): NpsScoreChartData | null => {
        const result = npsScoreChartDataSchema.safeParse(
            data?.["company-nps-score"]?.value
        );
        if (!result.success) {
            return null;
        }
        return result.data;
    }, [data]);

    const finalNpsScore = parsedNpsScore
        ? parsedNpsScore.map((i) => ({
              x: new Date(i.x),
              y: i.y,
          }))
        : null;
    const currencySign = getCurrencySign(data["company-currency"]);

    const isEmptyInsight: boolean = useMemo(
        () =>
            Object.values({
                position,
                npsTrack,
                marketSize,
                customers,
            }).some((value) => value === undefined),
        [position, npsTrack, marketSize, customers]
    );

    return (
        <Frame title="Market and Customers" isEmptyInsight={isEmptyInsight}>
            <StatItem title="Company market position">
                <Chip>{position}</Chip>
            </StatItem>

            <ChartWrapper
                showStats
                hasData={!!marketSize}
                title="Addressable market size"
                currencySign={currencySign}
                records={[
                    {
                        value: data["company-market-growth-in1y"]
                            ?.value as number,
                        label: "Expected market growth in 1 year",
                    },
                    {
                        value: data["company-market-growth-in5y"]
                            ?.value as number,
                        label: "Expected market growth in 5 years",
                    },
                ]}
            >
                {marketSize && (
                    <BarChart data={marketSize} currencySign={currencySign} />
                )}
            </ChartWrapper>

            <ChartWrapper
                hasData={!!customers}
                hideDollarSign
                title="Customers"
            >
                {customers && (
                    <LineChart data={customers} className="h-[250px] w-full" />
                )}
            </ChartWrapper>

            <ChartWrapper
                hideDollarSign
                title="NPS score"
                hasData={!!finalNpsScore && npsTrack}
                emptyText="Not tracking NPS score"
            >
                {finalNpsScore && (
                    <ChartContainer config={{}} className="h-[100px] w-full">
                        <RBarChart
                            accessibilityLayer
                            data={finalNpsScore}
                            layout="vertical"
                            margin={{
                                right: 40,
                            }}
                        >
                            <YAxis
                                dataKey="x"
                                type="category"
                                tickLine={false}
                                tickMargin={10}
                                axisLine={false}
                                hide
                            />
                            <XAxis dataKey="y" type="number" hide />
                            <Bar
                                dataKey="y"
                                layout="vertical"
                                fill="#AC74FF"
                                radius={4}
                                minPointSize={79}
                            >
                                <LabelList
                                    dataKey="x"
                                    position="insideLeft"
                                    offset={8}
                                    fontSize={14}
                                    className="fill-white"
                                    formatter={(v: Date) =>
                                        format(v, "MMM yyyy")
                                    }
                                />
                                <LabelList
                                    dataKey="y"
                                    position="right"
                                    className="fill-black"
                                    offset={8}
                                    fontSize={14}
                                />
                            </Bar>
                        </RBarChart>
                    </ChartContainer>
                )}
            </ChartWrapper>
        </Frame>
    );
};

export default MarketAndCustomers;
