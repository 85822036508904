import { useCallback, useEffect, useMemo } from "react";
import {
    FieldErrors,
    FieldValues,
    Path,
    PathValue,
    UseFormSetValue,
    UseFormTrigger,
    UseFormWatch,
} from "react-hook-form";
import Utils from "utils";

const useDeadlineValidation = <T extends FieldValues>(
    errors: FieldErrors<T>,
    setValue: UseFormSetValue<T>,
    watch: UseFormWatch<T>,
    trigger: UseFormTrigger<T>
) => {
    useEffect(() => {
        const subscription = watch((_value, { name }) => {
            if (name === "date" || name === "time") {
                trigger(["deadline"] as Array<Path<T>>);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger]);

    const showDeadlineError: boolean = useMemo(() => {
        if (errors.date !== undefined || errors.time !== undefined) {
            return false;
        }
        if (errors.deadline === undefined) {
            return false;
        }
        return true;
    }, [errors.date, errors.time, errors.deadline]);

    const handleDeadlineOption = useCallback(
        (
            option: Array<number>,
            setOption: React.Dispatch<React.SetStateAction<number[]>>
        ) => {
            setOption(option);
            const days = option[0];
            const now = new Date();
            now.setDate(now.getDate() + days);
            const isoDate = now.toISOString();
            setValue(
                "date" as Path<T>,
                Utils.Date.formatDateToISO(isoDate) as PathValue<T, Path<T>>,
                {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                }
            );
            setValue("time" as Path<T>, "22:00:00" as PathValue<T, Path<T>>, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            });
        },
        []
    );

    return { showDeadlineError, handleDeadlineOption };
};

export default useDeadlineValidation;
