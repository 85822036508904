import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "components/Card";
import CheckBox from "components-legacy/CheckBox";
import Icon from "components/Icon";
import Input from "components/Input";
import Button from "components/Button";
import Collapsed from "components/Collapsed";
import { SharedView } from "store/api/shared-view/interfaces";
import { useUpdateSharedViewMutation } from "store/api/shared-view";
import { useDebounce } from "use-debounce";
import { useDispatch } from "react-redux";
import { GlobalActions } from "store/reducers/global";
import { writeToClipboard } from "utils/clipboard";
import Utils from "utils";

type Props = {
    sharedView: SharedView | undefined;
    onDeactivateShareLink: () => void;
    companyId: string;
    reportId: string;
};

const ShareInsightsModalForm: FC<Props> = ({
    sharedView,
    onDeactivateShareLink,
    companyId,
    reportId,
}) => {
    const dispatch = useDispatch();
    const [updateSharedView] = useUpdateSharedViewMutation();
    const [showCopyLinkConfirmation, setShowCopyLinkConfirmation] =
        useState<boolean>(false);
    const [showCopyPasswordConfirmation, setShowCopyPasswordConfirmationn] =
        useState<boolean>(false);
    const [setPasswordToggle, setSetPasswordToggle] = useState<boolean>(
        !!sharedView?.password?.length
    );
    const [password, setPassword] = useState<string>(
        sharedView?.password ?? ""
    );
    const [debouncedPassword] = useDebounce(password, 800);
    const shareLink = useMemo<string>(
        () =>
            Utils.Url.createShareInsightsLink(
                window.location.origin,
                sharedView?.token ?? ""
            ),
        [sharedView]
    );
    const hasSharedView = useMemo<boolean>(
        () => sharedView !== undefined,
        [sharedView]
    );

    useEffect(() => {
        if (!hasSharedView) {
            return;
        }
        const updateSharedViewHandler = async () => {
            try {
                const newPassword =
                    debouncedPassword.length > 0 ? debouncedPassword : null;
                const response = await updateSharedView({
                    companyId,
                    reportId,
                    password: newPassword,
                });

                if (!("data" in response)) {
                    throw new Error("Something went wrong");
                }
            } catch (e) {
                const message =
                    e instanceof Error ? e.message : "Something went wrong";

                dispatch(
                    GlobalActions.showPopover({
                        type: "error",
                        label: message,
                    })
                );
            }
        };
        updateSharedViewHandler();
    }, [
        hasSharedView,
        debouncedPassword,
        companyId,
        reportId,
        updateSharedView,
        dispatch,
    ]);

    const setPasswordToggleHandler = useCallback((checked: boolean) => {
        setSetPasswordToggle(checked);
        if (!checked) {
            setPassword("");
        }
    }, []);

    const copyLinkHandler = useCallback(() => {
        setShowCopyLinkConfirmation(true);
        setTimeout(() => {
            setShowCopyLinkConfirmation(false);
        }, 1500);
        writeToClipboard(shareLink);
    }, [shareLink]);

    const copyPasswordHandler = useCallback(() => {
        setShowCopyPasswordConfirmationn(true);
        setTimeout(() => {
            setShowCopyPasswordConfirmationn(false);
        }, 1500);
        writeToClipboard(password);
    }, [password]);

    const passwordHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
        },
        []
    );

    return (
        <Card>
            <CardHeader className={"gap-x-2 px-5 pb-6 pt-5"}>
                <Icon type="Link" />
                <p>Link to share insights</p>
            </CardHeader>
            <CardBody className={"gap-y-3 px-5 pb-5"}>
                <Input
                    inputWrapperClassName="!pr-14"
                    value={Utils.Parsers.formatWebsiteUrlToPresent(shareLink)}
                    readOnly={true}
                    action={{
                        as: "a",
                        href: shareLink,
                        target: "_blank",
                        rel: "noopener noreferrer",
                        icon: "SquareArrowOutUpRight",
                    }}
                />
                <CheckBox
                    className="!my-2 w-max"
                    defaultValue={setPasswordToggle}
                    onToggle={setPasswordToggleHandler}
                >
                    Set password
                </CheckBox>
                <Collapsed isExpanded={setPasswordToggle}>
                    <Input
                        value={password}
                        onChange={passwordHandler}
                        placeholder="Enter password"
                        action={{
                            icon: showCopyPasswordConfirmation
                                ? "Check"
                                : "Copy",
                            onClick: copyPasswordHandler,
                            disabled: !password,
                        }}
                    />
                </Collapsed>
            </CardBody>
            <CardFooter className={"justify-end gap-x-3 px-5 pb-5 pt-1"}>
                <Button
                    customType="secondary-solid"
                    icon="X"
                    onClick={onDeactivateShareLink}
                >
                    Deactivate link
                </Button>
                <Button
                    icon={showCopyLinkConfirmation ? "Check" : "Copy"}
                    onClick={copyLinkHandler}
                >
                    {showCopyLinkConfirmation ? "Copied" : "Copy link"}
                </Button>
            </CardFooter>
        </Card>
    );
};

export default ShareInsightsModalForm;
