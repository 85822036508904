import { useEffect, useState } from "react";

import Scale from "components/Scale";
import Title from "questions/components/Title";
import { GridSelectOption } from "components/GridSelect";
import { QuestionProps } from "questions/types.questions";
import { usePostSurveyAnswerMutation } from "store/api/survey";
import { ScaleAnswerPattern } from "types/surveys/answer-pattern";
import SurveyFooter from "routes/surveys/components/SurveyFooter";
import { ScaleQuestionPattern } from "types/surveys/question-pattern";

const SCALE_OPTIONS: Array<GridSelectOption<number>> = Array.from({
    length: 5,
}).map((_, value) => ({ value: value + 1, label: JSON.stringify(value + 1) }));

const ScaleQuestion = ({
    isChild,
    surveyKey,
    allDisabled,
    isLastQuestion,
    data: { answer, key, pattern, text },
    handleBack,
    handleNext,
    onError,
}: QuestionProps<ScaleQuestionPattern, ScaleAnswerPattern>) => {
    const [postSurveyAnswer, { isError, isLoading, isSuccess }] =
        usePostSurveyAnswerMutation();

    const [selectedValue, setSelectedValue] = useState<
        string | number | undefined
    >(answer?.value);

    const { labelMax, labelMin } = pattern;

    const handleSubmit = () => {
        postSurveyAnswer({
            surveyKey,
            data: selectedValue,
            questionKey: key,
        });
    };

    useEffect(() => {
        if (isSuccess) handleNext();
    }, [isSuccess]);

    useEffect(() => {
        onError(isError);
    }, [isError]);

    return (
        <div className="flex justify-center items-center flex-col gap-12">
            {!isChild && <Title>{text}</Title>}

            <Scale
                leftLabel={labelMin}
                rightLabel={labelMax}
                options={SCALE_OPTIONS}
                selected={selectedValue}
                onSelect={setSelectedValue}
                disabled={isLoading || allDisabled}
            />

            <SurveyFooter
                isLoading={isLoading}
                loadingText="Saving..."
                handleBack={handleBack}
                allDisabled={allDisabled}
                handleNext={handleSubmit}
                nextDisabled={!selectedValue}
                nextText={isLastQuestion ? "Submit" : undefined}
            />
        </div>
    );
};

export default ScaleQuestion;
