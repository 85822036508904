import { useState } from "react";

type Props = {
    className?: string;
    children?: any;
    defaultValue?: boolean;
    onToggle?: (checked: boolean) => void;
};

const CheckBox = ({ className, children, defaultValue, onToggle }: Props) => {
    const [isChecked, setIsChecked] = useState(defaultValue ?? false);

    const handleToggle = () => {
        const newCheckedStatus = !isChecked;
        setIsChecked(newCheckedStatus);
        if (onToggle) {
            onToggle(newCheckedStatus);
        }
    };

    return (
        <div
            className={`mb-4 flex cursor-pointer items-center text-pale ${className}`}
        >
            <input
                id="default-checkbox"
                type="checkbox"
                checked={isChecked}
                onChange={handleToggle}
                className="h-4 w-4 cursor-pointer rounded border-gray-300 bg-accent focus:bg-accent focus:ring-2"
            />
            <label
                htmlFor="default-checkbox"
                className="ms-2 text-sm font-semibold text-gray-900 dark:text-gray-300"
            >
                {children}
            </label>
        </div>
    );
};

export default CheckBox;
