import { useDispatch } from "react-redux";
import { useMemo, useState } from "react";
import { Modal, ModalContent, useDisclosure } from "@nextui-org/react";

import {
    useResendCompanyInvitationMutation,
    useRevokeCompanyInvitationMutation,
} from "store/api/investor";
import Utils from "utils";
import Icon from "components/Icon";
import { GlobalActions } from "store/reducers/global";
import ConfirmationModal from "components/ConfirmationModal";
import { CompanyInvitation } from "store/api/investor/interfaces";
import ActionPopover, { ActionPopoverListItem } from "components/ActionPopover";
import CompanyDeadlineBadge from "components/CompanyDeadlineBadge";
import DeadlineModal from "../modals/DeadlineModal";

type Props = { data: CompanyInvitation };

const PendingInviteCard = ({
    data: { email, deadline, id, companyName, createdAt },
}: Props) => {
    const {
        isOpen: setDeadlineIsOpen,
        onOpen: setDeadlineOnOpen,
        onOpenChange: setDeadlineOnOpenChange,
    } = useDisclosure();

    const dispatch = useDispatch();

    const [resendInvitation] = useResendCompanyInvitationMutation();
    const [revokeInvitation] = useRevokeCompanyInvitationMutation();

    const [showRevokeInviteModal, setShowRevokeInviteModal] = useState(false);

    const deadlineMeta = useMemo(
        () => Utils.Parsers.companyDeadline(deadline as any),
        [deadline]
    );

    const handleResendInvite = async () => {
        try {
            const res = await resendInvitation({ id });

            if (!("data" in res)) {
                const message = "Something went wrong, please refresh the page";
                throw new Error(message);
            }

            dispatch(
                GlobalActions.showPopover({
                    label: `${companyName} invite resent`,
                    type: "success",
                })
            );
        } catch (err: unknown) {
            const message =
                err instanceof Error
                    ? err.message
                    : "Something went wrong, please refresh the page";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    };

    const actions: Array<ActionPopoverListItem> = [
        {
            label: deadline ? "Edit survey deadline" : "Set survey deadline",
            onClick: setDeadlineOnOpen,
        },
        {
            label: "Resend invite",
            onClick: handleResendInvite,
        },
        {
            type: "danger",
            label: "Revoke invite",
            onClick: () => setShowRevokeInviteModal(true),
        },
    ];

    const handleRevokeInvite = async () => {
        try {
            const res = await revokeInvitation({ id });

            if (!("data" in res)) {
                const message = "Something went wrong, please refresh the page";
                throw new Error(message);
            }

            setShowRevokeInviteModal(false);
            dispatch(
                GlobalActions.showPopover({
                    label: `${companyName} invite revoked`,
                    type: "dark",
                })
            );
        } catch (err: unknown) {
            const message =
                err instanceof Error
                    ? err.message
                    : "Something went wrong, please refresh the page";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    };

    const invitedMeta = Utils.Date.invitedSince(createdAt);

    return (
        <>
            <Modal
                size="xl"
                isOpen={setDeadlineIsOpen}
                onOpenChange={setDeadlineOnOpenChange}
            >
                <ModalContent>
                    {(onClose) => (
                        <DeadlineModal
                            entityId={id}
                            type="invitation"
                            onClose={onClose}
                            companyName={companyName}
                            deadline={deadline}
                        />
                    )}
                </ModalContent>
            </Modal>

            <div
                className={`flex h-[184px] w-[364px] flex-col justify-between rounded-lg border border-default-75 bg-white p-[24px] shadow-sm md:!w-[unset]`}
            >
                <div>
                    <div className="flex flex-row items-center justify-between">
                        <div className="text-lg text-black">
                            {companyName || "Company"}
                        </div>

                        <ActionPopover
                            actions={actions}
                            actionButtonContent={
                                <Icon
                                    type="Ellipsis"
                                    className="cursor-pointer"
                                />
                            }
                        />
                    </div>

                    <div className="mt-[10px] text-sm">{email}</div>
                </div>

                <div className="flex flex-row items-center justify-between">
                    {invitedMeta !== "now" && (
                        <div className="text-sm text-default-400">
                            {invitedMeta}
                        </div>
                    )}

                    {deadlineMeta.type !== "passed" && (
                        <CompanyDeadlineBadge
                            date={deadline}
                            time={deadlineMeta.time}
                            state={deadlineMeta.type}
                        />
                    )}
                </div>
            </div>

            <ConfirmationModal
                content={
                    <div className="text-sm text-default-500">
                        Are you sure you want to revoke{" "}
                        <span className="text-black">
                            {companyName || "Company"}
                        </span>{" "}
                        invite?
                    </div>
                }
                title="Revoke invite?"
                show={showRevokeInviteModal}
                onSubmit={handleRevokeInvite}
                buttonTexts={{ submit: "Yes, revoke" }}
                onClose={() => setShowRevokeInviteModal(false)}
            />
        </>
    );
};

export default PendingInviteCard;
