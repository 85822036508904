import {
    OnboardingRequest,
    OnboardingResponse,
    MemberFinishOnboarding,
    CompanyFinishOnboarding,
    InvestorFinishOnboarding,
} from "./interfaces";
import { api } from "../api";

export const onboardingApi = api.injectEndpoints({
    endpoints: (builder) => ({
        saveOnboarding: builder.mutation<OnboardingResponse, OnboardingRequest>(
            {
                query: (body) => ({
                    url: "app/shared/onboarding",
                    method: "PATCH",
                    body: JSON.stringify(body),
                }),
            }
        ),
        getOnboarding: builder.query<OnboardingResponse, void>({
            query: () => ({
                url: `app/shared/onboarding`,
                method: "GET",
            }),
            providesTags: () => ["Onboarding"],
        }),
        finishOnboarding: builder.mutation<
            OnboardingResponse,
            | InvestorFinishOnboarding
            | MemberFinishOnboarding
            | CompanyFinishOnboarding
        >({
            query: (body) => ({
                url: "app/shared/onboarding/finish",
                method: "POST",
                body: JSON.stringify(body),
            }),
            invalidatesTags: ["Me", "Surveys"],
        }),
    }),
});

export const {
    useGetOnboardingQuery,
    useSaveOnboardingMutation,
    useFinishOnboardingMutation,
} = onboardingApi;
