import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import Avatar from "components/Avatar";
import { AvatarProps, VariantProps } from "@nextui-org/react";
import { cn } from "lib/utils";
import React from "react";

const avatarGroupVariants = cva("flex h-auto items-center", {
    variants: {
        variant: {
            singleline: "w-max justify-center",
            multiline: "w-auto flex-wrap justify-start",
        },
    },
    defaultVariants: {
        variant: "singleline",
    },
});

const avatarVariants = cva(
    "transition-transform data-[focus-visible=true]:translate-x-2 data-[hover=true]:translate-x-2 rtl:data-[focus-visible=true]:-translate-x-2 rtl:data-[hover=true]:-translate-x-2",
    {
        variants: {
            variant: {
                default: "ms-0",
                shifted: "my-[2px] -ms-[5px]",
                "shifted-x": "-ms-[5px]",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
);

type Props = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof avatarGroupVariants> & {
        multiline?: boolean;
        size?: AvatarProps["size"];
        max?: number;
    };

const AvatarGroup = React.forwardRef<HTMLDivElement, Props>(
    ({ className, variant, size, max = 5, children, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(avatarGroupVariants({ variant }), className)}
                role="group"
                {...props}
            >
                {React.Children.map(children, (child, index) => {
                    if (index >= max) {
                        return null;
                    }
                    if (React.isValidElement(child)) {
                        return (
                            <Slot
                                {...{ size: size }}
                                className={cn(
                                    avatarVariants({
                                        variant:
                                            variant === "multiline"
                                                ? "shifted"
                                                : index === 0
                                                  ? "default"
                                                  : "shifted-x",
                                    })
                                )}
                                style={{ zIndex: max - index }}
                            >
                                {child}
                            </Slot>
                        );
                    }
                    return child;
                })}
                {React.Children.count(children) > max && (
                    <Avatar
                        size={size}
                        name={`+${React.Children.count(children) - max}`}
                        fallbackType="name"
                        className={cn(
                            avatarVariants({
                                variant:
                                    variant === "multiline"
                                        ? "shifted"
                                        : "shifted-x",
                            })
                        )}
                        disableTooltip
                    />
                )}
            </div>
        );
    }
);

AvatarGroup.displayName = "AvatarGroup";

export default AvatarGroup;
