type ScoreType = "high" | "medium" | "low";

type Props = {
    number: number;
    score?: ScoreType;
    type?: "white" | "filled";
};

const TYPE_TO_BACKGROUND: Record<ScoreType, string> = {
    high: "!bg-secondary-400",
    medium: "!bg-secondary-300",
    low: "!bg-secondary-200",
};

const CounterBadge: React.FC<Props> = ({ number, type = "white", score }) => {
    return (
        <div
            className={`text-xs h-5 px-[8.5px] rounded-[6px] flex items-center ${type === "white" ? "bg-white" : "bg-default-100 border border-solid border-default-200"} ${TYPE_TO_BACKGROUND[score!]}`}
        >
            {number}
        </div>
    );
};

export default CounterBadge;
