import * as yup from "yup";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "components/Link";
import Alert from "components/Alert";
import Input from "components/Input";
import Button from "components/Button";
import Collapsed from "components/Collapsed";
import Layout from "components/OnboardingAndAuth/Layout";
import { InvitationType } from "store/api/invitation/interfaces";
import { useGetMeQuery, useSignInMutation } from "store/api/auth";

const schema = yup.object().shape({
    password: yup.string().required("Required"),
    email: yup.string().email("Invalid format").required("Required"),
});

const defaultValues = {
    email: "",
    password: "",
};

const Index = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const getMePayload = useGetMeQuery();
    const [signIn, authData] = useSignInMutation();

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues,
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema) as any,
    });

    const onSubmit = (data: typeof defaultValues) => {
        signIn(data);
    };

    useEffect(() => {
        if (!getMePayload.isSuccess) return;

        const { data } = getMePayload.data;

        const user = data.user;
        const isAuth = data.auth;
        const onboarding = data.onboarding;

        if (isAuth) {
            if (data.isOnboarding) {
                switch (onboarding?.type) {
                    case InvitationType.Investor:
                        return navigate("/onboarding/investor");
                    case InvitationType.Company:
                    case InvitationType.Member:
                        return navigate("/onboarding/member");
                }
            } else if (user) {
                const params = new URLSearchParams(location.search);
                const redirectURL = params.get("redirect") || "/dashboard";
                return navigate(redirectURL);
            }
        }
    }, [getMePayload]);

    return (
        <Layout
            type="auth"
            title="A window into a team’s potential"
            leftContent="VC Volt is a unique model based on decades of research on entrepreneurial teams and high performing companies."
            leftBottomSecondaryContent="The team is a company’s most important asset. Having a good analysis can contribute to bringing your company to the next level."
        >
            <div className="flex flex-col gap-10">
                <div>
                    <div className="text-center text-3xl text-black">
                        Welcome to VC Volt
                    </div>
                    <div className="mt-2 text-center text-default-500">
                        Use your credentials to access the platform.
                    </div>
                </div>

                <Collapsed isExpanded={authData.isError}>
                    <Alert
                        type="error"
                        label="Please enter a valid email and password. Passwords are case-sensitive."
                    />
                </Collapsed>

                <div className="flex flex-col gap-6">
                    <Controller
                        name="email"
                        control={control}
                        render={({
                            fieldState: { error },
                            field: { value, onChange, onBlur },
                        }) => (
                            <Input
                                value={value}
                                label="Email"
                                onBlur={onBlur}
                                onChange={onChange}
                                errorMessage={error?.message}
                                placeholder="your.email@domain.com"
                            />
                        )}
                    />

                    <Controller
                        name="password"
                        control={control}
                        render={({
                            fieldState: { error },
                            field: { value, onChange, onBlur },
                        }) => (
                            <Input
                                value={value}
                                onBlur={onBlur}
                                isPasswordInput
                                type="password"
                                label="Password"
                                onChange={onChange}
                                errorMessage={error?.message}
                                placeholder="Enter your password"
                            />
                        )}
                    />

                    <Link to={"/auth/reset-password"}>Forgot password</Link>
                </div>

                <Button onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                    Sign In
                </Button>

                <div className="text-center text-sm text-default-400">
                    <div>
                        Don't have an account? The platform is invite-only.
                    </div>
                    <div>
                        Contact{" "}
                        <Link
                            to={"mailto:hello@vcvolt.com"}
                            reloadDocument={true}
                            className="inline"
                        >
                            hello@vcvolt.com
                        </Link>{" "}
                        to learn more.
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Index;
