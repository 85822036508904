import { FC } from "react";

type ItemProps = {
    title: string;
    label: string | number;
};

type WrapperProps = {
    children?: React.ReactNode;
};

type Props = {
    management: ItemProps;
    fullTime: ItemProps;
};

const Item: React.FC<ItemProps> = ({ label, title }) => (
    <div className="flex flex-1 flex-col gap-1 text-sm">
        <div className="text-default-400">{title}</div>
        <div>{label}</div>
    </div>
);

const Wrapper: FC<WrapperProps> = ({ children }) => {
    return (
        <div className="flex flex-row gap-6 rounded-lg bg-default-50 px-6 py-5">
            {children}
        </div>
    );
};

const TeamSplit: React.FC<Props> = ({ fullTime, management }) => {
    return (
        <Wrapper>
            <Item {...fullTime} />
            <Item {...management} />
        </Wrapper>
    );
};

export default TeamSplit;
