import Chip from "../shared/Chip";
import Frame from "../shared/Frame";
import StatItemBg from "./components/StatItemBg";
import ExpandableDescription from "../shared/ExpandableDescription";
import { InsightsCompany } from "store/api/investorCompany/interfaces";
import FakeCheckbox from "../../../HumanCapitalSkills/components/FakeCheckbox";
import { useMemo } from "react";

type Props = {
    data: InsightsCompany;
};

const PrioritiesAndAdvantages: React.FC<Props> = ({ data }) => {
    const {
        scalable,
        aiMlUsed,
        currentPriority,
        hasNetworkEffect,
        hasTechAdvantage,
        aiMlUsedDescription,
        currentPriorityOther,
        scalableInternational,
        techAdvantageDescription,
        networkEffectDescription,
    } = {
        hasNetworkEffect: data["company-network-effect"]?.value as boolean,
        networkEffectDescription: data["company-network-effect-description"]
            ?.value as string,

        hasTechAdvantage: data["company-tech-advantage"]?.value as boolean,
        techAdvantageDescription: data["company-tech-advantage-description"]
            ?.value as string,

        aiMlUsed: data["company-ai-use"]?.value as boolean,
        aiMlUsedDescription: data["company-ai-use-description"]
            ?.value as string,

        currentPriority: data["company-current-priority"]?.value,
        currentPriorityOther: data["company-current-priority-other"]?.value,

        scalable: data["company-scalable-company"]?.value as boolean,
        scalableInternational: data["company-international-scalability"]
            ?.value as boolean,
    };

    const isEmptyInsight: boolean = useMemo(
        () =>
            Object.values({
                hasNetworkEffect,
                hasTechAdvantage,
                aiMlUsed,
                currentPriority,
                scalable,
            }).some((value) => value === undefined),
        [
            currentPriority,
            scalable,
            hasTechAdvantage,
            aiMlUsed,
            hasNetworkEffect,
        ]
    );

    return (
        <Frame
            title="Priorities and Advantages"
            isEmptyInsight={isEmptyInsight}
        >
            <StatItemBg title="Biggest priority">
                <div className="flex items-start">
                    <Chip allowExpand considerLarge={445} type="primary">
                        {currentPriority === "Other"
                            ? currentPriorityOther
                            : currentPriority}
                    </Chip>
                </div>
            </StatItemBg>

            <StatItemBg
                title="Company scale plans"
                className="flex flex-col gap-2"
            >
                <FakeCheckbox
                    checked={scalable}
                    checkedLabel="We are scalable company"
                    uncheckedLabel="We are not a scalable company"
                />
                {scalable && (
                    <FakeCheckbox
                        checked={!scalableInternational}
                        uncheckedLabel="To scale internationally we need teams in each or most countries"
                        checkedLabel="To scale internationally we do not need teams in each country"
                    />
                )}
            </StatItemBg>

            <ExpandableDescription
                title="Technology enabled competitive advantage"
                description={hasTechAdvantage ? techAdvantageDescription : "No"}
            />

            <ExpandableDescription
                title="Use of AI/ML"
                description={aiMlUsed ? aiMlUsedDescription : "No"}
            />

            <ExpandableDescription
                title="Network effect"
                description={hasNetworkEffect ? networkEffectDescription : "No"}
            />
        </Frame>
    );
};

export default PrioritiesAndAdvantages;
