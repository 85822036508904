import { useCallback, useMemo } from "react";
import { Modal, ModalContent, Tooltip, useDisclosure } from "@nextui-org/react";

import Utils from "utils";
import Button from "components/Button";
import { useAppDispatch } from "core/hooks";
import Icon, { IconType } from "components/Icon";
import StatusCompany from "components/StatusCompany";
import { GlobalActions } from "store/reducers/global";
import CompanyDeadlineBadge from "components/CompanyDeadlineBadge";
import ProgressStatusCompany from "components/ProgressStatusCompany";
import DeadlineModal from "routes/dashboard/Investor/modals/DeadlineModal";
import ActionPopover, { ActionPopoverListItem } from "components/ActionPopover";
import { useInvestorCompanyGenerateReportMutation } from "store/api/investorCompany";
import ShareInsightsModal from "../share-insights-modal";
import DeactivateShareInsightsModal from "../deactivate-share-insights-modal";
import { useCompanyReportContext } from "routes/components/CompanyReport/contexts/report-context";
import {
    CompanyDetailsData,
    CompanyDetailsSchema,
} from "../../schemas/company-details-schema";
import CompanyHeaderCompanyDetails from "../company-details";

type Props = {
    className?: string;
};

const CompanyHeader: React.FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch();
    const [generateReport, { isLoading: isGenerating }] =
        useInvestorCompanyGenerateReportMutation();
    const {
        isOpen: setDeadlineIsOpen,
        onOpen: setDeadlineOnOpen,
        onOpenChange: setDeadlineOnOpenChange,
    } = useDisclosure();
    const {
        isOpen: shareInsightsIsOpen,
        onOpen: shareInsightsOnOpen,
        onOpenChange: shareInsightsOnOpenChange,
        onClose: shareInsightsOnClose,
    } = useDisclosure();
    const {
        isOpen: deactivateShareInsightsIsOpen,
        onOpen: deactivateShareInsightsOnOpen,
        onOpenChange: deactivateShareInsightsOnOpenChange,
        onClose: deactivateShareInsightsOnClose,
    } = useDisclosure();
    const {
        companyId,
        reportId,
        company,
        deadline,
        generatedAt,
        sharedView,
        progress,
    } = useCompanyReportContext();

    if (companyId === undefined || reportId === undefined) {
        throw new Error("Something went wrong");
    }

    const { name, descriptionShort, logo } = useMemo((): CompanyDetailsData => {
        const data = {
            name: company?.["company-name"]?.value,
            descriptionShort: company?.["company-description-short"]?.value,
            logo: company?.["company-logo"]?.value,
        };
        const dataParsed = CompanyDetailsSchema.safeParse(data);
        if (!dataParsed.success) {
            return {
                name: "",
                descriptionShort: "",
                logo: null,
            };
        }
        return dataParsed.data;
    }, [company]);

    const handleGenerateReport = useCallback(
        async (regenerate = false) => {
            try {
                const res = await generateReport({
                    companyId,
                    reportId,
                });

                if (!("data" in res)) {
                    throw new Error("Failed to generate report");
                }

                dispatch(
                    GlobalActions.showPopover({
                        type: "success",
                        label: regenerate
                            ? "Report regenerated"
                            : "Report generated",
                    })
                );
            } catch (err: unknown) {
                const message =
                    err instanceof Error
                        ? err.message
                        : "Failed to generate report";

                dispatch(
                    GlobalActions.showPopover({
                        type: "error",
                        label: message,
                    })
                );
            }
        },
        [companyId, reportId, dispatch, generateReport]
    );

    const hasNoDataForReport = !progress;
    const isOnboardingNotStarted = progress === 0;
    const isOnboardingCompleted = progress === 1;
    const isOnboardingInProgress =
        !isOnboardingNotStarted && !isOnboardingCompleted;
    const isReportGenerated = !!generatedAt;

    const actions = useMemo(() => {
        const act: Array<ActionPopoverListItem> = [];

        if (isOnboardingNotStarted)
            act.push({
                label: "Set survey deadline",
                onClick: setDeadlineOnOpen,
            });
        else if (isOnboardingCompleted && hasNoDataForReport)
            act.push({
                label: "Regenerate report",
                onClick: () => handleGenerateReport(true),
            });
        else if (isOnboardingInProgress)
            act.push(
                {
                    label: isReportGenerated
                        ? "Regenerate report"
                        : "Generate report",
                    onClick: () => handleGenerateReport(isReportGenerated),
                    loading: isGenerating,
                },
                {
                    label: "Edit survey deadline",
                    onClick: setDeadlineOnOpen,
                }
            );
        else {
            act.push({
                label: isReportGenerated
                    ? "Regenerate report"
                    : "Generate report",
                onClick: () => handleGenerateReport(isReportGenerated),
            });
        }

        // @TODO revert back sometime
        // act.push({
        //     type: "danger",
        //     label: "Archive company",
        //     onClick: () => console.log("Archive company"),
        // });

        return act;
    }, [
        isGenerating,
        isReportGenerated,
        hasNoDataForReport,
        isOnboardingCompleted,
        isOnboardingInProgress,
        isOnboardingNotStarted,
        handleGenerateReport,
        setDeadlineOnOpen,
    ]);

    const deadlineMeta = useMemo(
        () => (deadline ? Utils.Parsers.companyDeadline(deadline) : null),
        [deadline]
    );

    const [func, icon, label] = useMemo((): [() => void, IconType, string] => {
        if (isReportGenerated) {
            return [shareInsightsOnOpen, "Share", "Share insights"];
        }

        return [handleGenerateReport, "Zap", "Generate Report"];
    }, [isReportGenerated, shareInsightsOnOpen, handleGenerateReport]);

    return (
        <>
            <Modal
                size="xl"
                isOpen={setDeadlineIsOpen}
                onOpenChange={setDeadlineOnOpenChange}
            >
                <ModalContent>
                    {(onClose) => (
                        <DeadlineModal
                            type="company"
                            entityId={companyId}
                            companyName={name}
                            onClose={onClose}
                            deadline={deadline}
                        />
                    )}
                </ModalContent>
            </Modal>

            <ShareInsightsModal
                isOpen={shareInsightsIsOpen}
                onOpenChange={shareInsightsOnOpenChange}
                onClose={shareInsightsOnClose}
                deactivateOnOpen={deactivateShareInsightsOnOpen}
                sharedView={sharedView}
                companyId={companyId}
                reportId={reportId}
            />

            <DeactivateShareInsightsModal
                isOpen={deactivateShareInsightsIsOpen}
                onOpenChange={deactivateShareInsightsOnOpenChange}
                onClose={deactivateShareInsightsOnClose}
                shareOnOpen={shareInsightsOnOpen}
                companyId={companyId}
                reportId={reportId}
            />

            <CompanyHeaderCompanyDetails
                image={logo?.url}
                companyName={name}
                companyDescriptionShort={descriptionShort}
                className={className}
                secondaryContent={
                    <div className="flex flex-row items-center gap-2">
                        {!isOnboardingCompleted && (
                            <ProgressStatusCompany
                                value={Number(
                                    Math.floor((progress ?? 0) * 100).toFixed(0)
                                )}
                            />
                        )}
                        {isReportGenerated && (
                            <StatusCompany
                                iconType="CCheckSimple"
                                label="Report generated"
                            />
                        )}
                        {isOnboardingInProgress &&
                            deadlineMeta &&
                            deadlineMeta.type !== "passed" && (
                                <CompanyDeadlineBadge
                                    date={deadline}
                                    time={deadlineMeta.time}
                                    state={deadlineMeta.type}
                                />
                            )}
                    </div>
                }
            >
                <div>
                    <div className="flex flex-row gap-3">
                        <Tooltip
                            content={
                                !isReportGenerated && "No data for the report"
                            }
                        >
                            <Button
                                icon={icon}
                                onClick={func}
                                isDisabled={isOnboardingNotStarted}
                            >
                                {label}
                            </Button>
                        </Tooltip>

                        <ActionPopover
                            actions={actions}
                            className="rounded-sm bg-white p-[10px]"
                            actionButtonContent={
                                <Icon type="Ellipsis" size={20} />
                            }
                        />
                    </div>
                </div>
            </CompanyHeaderCompanyDetails>
        </>
    );
};

export default CompanyHeader;
