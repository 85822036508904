import React, { useMemo } from "react";

import Card from "./components/Card";
import Empty from "./components/Empty";
import { IconType } from "components/Icon";
import FakeCheckbox from "../../components/FakeCheckbox";
import ContainerWrapper from "../../components/ContainerWrapper";
import { InsightsIndividual } from "store/api/investorCompany/interfaces";
import Utils from "utils";

type Props = {
    total: number;
    title: string;
    tooltip?: string;
    iconType: IconType;
    showTeamSize?: boolean;
    individual: InsightsIndividual;
    theKey: keyof InsightsIndividual[""];
};

const NonIndustryExperienceCard: React.FC<Props> = ({
    title,
    tooltip,
    total,
    theKey,
    iconType,
    individual,
    showTeamSize,
}) => {
    const members = useMemo(
        () =>
            Object.entries(individual)
                .filter(([, i]) => (i[theKey]?.value as boolean) === true)
                .sort(Utils.Insight.membersRoleNameCompareFn)
                .map(([, individualData]) => ({
                    ...Utils.Insight.getMemberInfoFromInsight(individualData),
                    time: individualData[
                        `${theKey}-years` as keyof typeof individualData
                    ]?.value as number,
                    teamSize: showTeamSize
                        ? (individualData["hc-management-team-size"]
                              ?.value as number)
                        : undefined,
                })),
        [individual, showTeamSize, theKey]
    );

    return (
        <ContainerWrapper
            name={title}
            iconType={iconType}
            tooltip={tooltip}
            className="my-0 h-auto"
        >
            <div className="flex flex-row items-center justify-center py-[39px]">
                <FakeCheckbox
                    type="rose"
                    disabledWhenUnchecked
                    checked={!!members.length}
                    uncheckedLabel="No members"
                    checkedLabel={`${members.length}/${total} members`}
                />
            </div>

            {members.length ? (
                members.map((props, index) => <Card key={index} {...props} />)
            ) : (
                <Empty />
            )}
        </ContainerWrapper>
    );
};

export default NonIndustryExperienceCard;
