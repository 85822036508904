import { cva } from "class-variance-authority";
import { cn } from "lib/utils";
import { FC, ReactNode } from "react";

const titleVariants = cva("text-sm font-semibold text-default-400");

type Props = {
    title: string;
    className?: string;
    children?: ReactNode;
};

const InfoWrapperModal: FC<Props> = ({ title, className, children }) => {
    return (
        <div className="flex flex-col gap-y-2">
            <h5 className={cn(titleVariants())}>{title}</h5>
            <div className={cn("rounded-lg bg-default-50 p-5", className)}>
                {children}
            </div>
        </div>
    );
};

export default InfoWrapperModal;
