import { cva, VariantProps } from "class-variance-authority";
import Icon, { IconType } from "components/Icon";
import { cn } from "lib/utils";
import { LucideProps } from "lucide-react";
import React, { FC } from "react";

const badgeVariants = cva(
    "flex w-min items-center text-nowrap rounded-sm px-[6px] py-1 text-sm font-semibold text-foreground",
    {
        variants: {
            variant: {
                default: "bg-secondary-200",
                secondary:
                    "border-1 border-default-200 bg-background shadow-sm",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
);

type BadgeProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof badgeVariants> & {};

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
    ({ className, variant, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(badgeVariants({ variant }), className)}
                {...props}
            />
        );
    }
);

const badgeIconVariants = cva("", {
    variants: {
        variant: {
            default: "",
            founding: "text-success-500",
            inventing: "text-tertiary-500",
            developing: "text-quaternary-500",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});

type BadgeIconProps = LucideProps &
    VariantProps<typeof badgeIconVariants> & { type?: IconType };

const BadgeIcon: FC<BadgeIconProps> = ({
    className,
    variant,
    size,
    type,
    ...props
}) => {
    let icon: IconType | undefined = type;

    switch (variant) {
        case "developing":
            icon = "TrendingUp";
            break;
        case "inventing":
            icon = "Lightbulb";
            break;
        case "founding":
            icon = "Sprout";
            break;
        default:
            break;
    }

    if (!icon) {
        return null;
    }

    return (
        <Icon
            className={cn(
                badgeIconVariants({ variant }),
                "mr-2 h-4 w-4",
                className
            )}
            size={16}
            type={icon}
            {...props}
        />
    );
};
export { Badge, BadgeIcon };
