import { FC, useEffect } from "react";
import { useGetMeQuery } from "store/api/auth";
import Gleap from "gleap";
import { UserType } from "store/api/invitation/interfaces";
import { useCompanyQuery } from "store/api/member";
import { skipToken } from "@reduxjs/toolkit/query";

const IS_GLEAP_ACTIVE = ["prod", "stage", "dev"].includes(
    process.env.REACT_APP_MODE ?? ""
);

const GleapAuth: FC = () => {
    const { data: meData } = useGetMeQuery();
    const { data: companyData } = useCompanyQuery(
        meData?.data?.user?.type === UserType.Member ? undefined : skipToken
    );

    useEffect(() => {
        const data = meData?.data;

        if (
            !data ||
            !IS_GLEAP_ACTIVE ||
            (data.user?.type === UserType.Member && !companyData)
        ) {
            return;
        }

        if (Gleap.isUserIdentified()) {
            if (data.auth === false) {
                Gleap.clearIdentity();
            }
        } else if (data.auth) {
            const userData = data.user;
            const isInvestor = userData?.type === UserType.Investor;
            const user = isInvestor ? userData?.data?.contact : userData?.data;

            Gleap.identify(data.userId, {
                email: data.email,
                name: user ? `${user.firstName} ${user.lastName}` : "",
            });
        }
    }, [meData, companyData]);

    return null;
};

export default GleapAuth;
