import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
    InvestorOnboardingActions,
    InvestorOnboardingSelectors,
} from "store/reducers/investorOnboarding";
import {
    useGetOnboardingQuery,
    useSaveOnboardingMutation,
} from "store/api/onboarding";
import API from "api";
import Utils from "utils";
import Input from "components/Input";
import FileInput, { AllowedFileTypes } from "components/FileInput";
import BottomButtons from "routes/onboarding/components/BottomButtons";
import { InvestorOnboarding } from "store/reducers/investorOnboarding/types";

type Props = {
    title: string;
    handleNextClick: () => void;
};

const schema = yup.object({
    image: Utils.Validations.image,
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
});

const parseDefaultValues = (
    { firstName, lastName, image }: InvestorOnboarding.Profile,
    data?: Partial<InvestorOnboarding.Profile>
): InvestorOnboarding.Profile => ({
    image: (image || data?.image) ?? null,
    lastName: lastName || data?.lastName || "",
    firstName: firstName || data?.firstName || "",
});

const ProfileStep = ({ title, handleNextClick }: Props) => {
    const dispatch = useDispatch();
    const defaultValues = useSelector(
        InvestorOnboardingSelectors.state("profile")
    );
    const { data: savedOnboarding } = useGetOnboardingQuery();
    const [saveOnboarding, saveOnboardingPayload] = useSaveOnboardingMutation();

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues: parseDefaultValues(
            defaultValues,
            savedOnboarding?.data["investor.profile"]
        ),
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema) as any,
    });

    const onSubmit = async (profile: InvestorOnboarding.Profile) => {
        profile.image =
            profile.image instanceof File
                ? await API.FileUpload.image(profile.image)
                : profile.image;

        dispatch(InvestorOnboardingActions.setProfile(profile));
        saveOnboarding({ "investor.profile": profile });
        handleNextClick();
    };

    return (
        <div>
            <div className="mb-10 text-2xl font-semibold">{title}</div>

            <div className="flex flex-col gap-5">
                <Controller
                    name="image"
                    control={control}
                    render={({
                        field: { onChange, value, onBlur },
                        fieldState: { error },
                    }) => (
                        <FileInput
                            onBlur={onBlur}
                            selectedFile={value}
                            label="Profile picture"
                            errorMessage={error?.message}
                            fileTypes={AllowedFileTypes.Image}
                            setFiles={(files) => onChange(files)}
                        />
                    )}
                />

                <Controller
                    name="firstName"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            onBlur={onBlur}
                            label="First name"
                            onChange={onChange}
                            errorMessage={error?.message}
                            placeholder="Your first name"
                        />
                    )}
                />

                <Controller
                    name="lastName"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            onBlur={onBlur}
                            label="Last name"
                            onChange={onChange}
                            errorMessage={error?.message}
                            placeholder="Your last name"
                        />
                    )}
                />

                <BottomButtons
                    onNext={handleSubmit(onSubmit)}
                    disableNext={!isValid || saveOnboardingPayload.isLoading}
                />
            </div>
        </div>
    );
};

export default ProfileStep;
