import * as yup from "yup";
import isURL from "validator/lib/isURL";
import { z } from "zod";

const searchOption = yup
    .object()
    .shape({
        label: yup.string().required("Required"),
        value: yup.string().required("Required"),
    })
    .nullable()
    .required("Required");

const searchOptionArray = yup
    .array()
    .of(
        yup.object({
            label: yup.string().required("Required"),
            value: yup.string().required("Required"),
        })
    )
    .required("Required")
    .min(1, "At least one item is required");

const fullDateInput = yup
    .string()
    .required("Required")
    .matches(
        /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
        "Invalid date format."
    );

const yearInput = yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be a valid year (e.g. 1999)")
    .test("valid-year", "Must be a valid year (e.g. 1999)", (value) => {
        if (!value) return true;
        const year = parseInt(value, 10);
        return !isNaN(year) && year >= 1900 && year <= new Date().getFullYear();
    });

const image = yup
    .mixed()
    .nullable()
    .test("fileOrURL", "Maximum size: 5MB", (value) => {
        if (value instanceof File) {
            const isFileSizeValid = value.size <= 5242880;
            const isFileTypeValid = [
                "image/jpeg",
                "image/png",
                "image/heic",
                "image/heif",
                "image/webp",
            ].includes(value.type);
            return isFileSizeValid && isFileTypeValid;
        } else if (typeof value === "object") {
            if (value === null) {
                return true;
            }
            return /^(https?:\/\/).*/.test((value as any)?.url);
        }
        return !value;
    });

const websiteUrl = yup
    .string()
    .nullable()
    .test(
        "is-url-valid",
        'Must be a valid URL (e.g. "https://domain.com")',
        (value) => {
            if (!value || value.trim() === "") return true;
            return isURL(value);
        }
    );

const email = z.string().email("Please enter a valid email");

const sharePercentage = yup
    .number()
    .typeError("Must be a number")
    .required("Required")
    .min(0, "Must be a number between 0 and 100")
    .max(100, "Must be a number between 0 and 100");

const Validations = {
    email,
    image,
    yearInput,
    websiteUrl,
    searchOption,
    fullDateInput,
    searchOptionArray,
    sharePercentage,
};

export default Validations;
