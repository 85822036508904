import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Utils from "utils";
import Input from "components/Input";
import Button from "components/Button";
import DateInput from "components/DateInput";
import TimeInput from "components/TimeInput";
import CheckBox from "components-legacy/CheckBox";
import { InvitationModalProps } from "../interfaces";
import { GlobalActions } from "store/reducers/global";
import { useInviteCompanyMutation } from "store/api/investor";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import GridSelect from "components/GridSelect";
import { DEADLINE_OPTIONS } from "../constants/deadline";
import useDeadlineValidation from "../hooks/useDeadlineValidation";
import Collapsed from "components/Collapsed";

const schema = z
    .object({
        name: z.string(),
        email: z.string(),
        isDeadline: z.boolean(),
        date: z.string(),
        time: z.string(),
    })
    .transform(({ isDeadline, date, time, ...args }) => ({
        ...args,
        isDeadline,
        ...(isDeadline && {
            date,
            time,
            deadline: `${date}T${time}${Utils.Date.getUserTimezoneOffset()}`,
        }),
    }))
    .pipe(
        z.object({
            name: z.string().min(1, "Required"),
            email: z.string().email(),
            isDeadline: z.boolean(),
            date: z.string().date().optional(),
            time: z.string().time().optional(),
            deadline: z.string().datetime({ offset: true }).optional(),
        })
    )
    .superRefine(({ isDeadline, deadline }, ctx) => {
        try {
            if (!isDeadline) {
                return z.NEVER;
            }
            if (!deadline) {
                throw new Error("Invalid date");
            }

            if (new Date(deadline) < new Date()) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Date can't be in the past",
                    path: ["deadline"],
                });
            }
        } catch (e) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid date",
                path: ["deadline"],
            });
        }
    });

const defaultValues = {
    name: "",
    email: "",
    isDeadline: false,
    date: "",
    time: "",
    deadline: "",
};

const InvitationModal: FC<InvitationModalProps> = ({ onClose }) => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState<Array<number>>([]);
    const [inviteCompany, { isLoading }] = useInviteCompanyMutation();

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isDirty },
        watch,
        trigger,
        setValue,
    } = useForm({
        defaultValues,
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: zodResolver(schema),
    });
    const { showDeadlineError, handleDeadlineOption } = useDeadlineValidation(
        errors,
        setValue,
        watch,
        trigger
    );

    const [wIsDeadline] = watch(["isDeadline"]);

    const onSubmit = handleSubmit(async (data) => {
        try {
            const { email, name, isDeadline, deadline } = data;

            const res = await inviteCompany({
                email,
                companyName: name,
                deadline: isDeadline ? deadline : undefined,
            });

            if (!("data" in res)) {
                throw new Error("Something went wrong");
            }

            dispatch(
                GlobalActions.showPopover({
                    type: "success",
                    label: `${name} invite sent`,
                })
            );

            onClose();
        } catch (err: unknown) {
            const message =
                err instanceof Error ? err.message : "Something went wrong";

            dispatch(
                GlobalActions.showPopover({
                    type: "error",
                    label: message,
                })
            );
        }
    });

    return (
        <div className="w-[592px] max-w-full rounded-2xl bg-white p-10">
            <h2 className="mb-3 text-xl">Invite company to VC Volt</h2>

            <p className="mb-8 text-sm text-default-500">
                Founder will receive invite and permissions to invite other
                members of the team to complete surveys
            </p>

            <form onSubmit={onSubmit}>
                <div className="flex flex-col gap-6">
                    <Controller
                        name="name"
                        control={control}
                        render={({
                            field: { onChange, value, onBlur },
                            fieldState: { error },
                        }) => (
                            <Input
                                errorMessage={error?.message}
                                placeholder="Enter company name"
                                onBlur={onBlur}
                                label="Company name"
                                value={value}
                                onChange={({ target }) =>
                                    onChange(target.value)
                                }
                            />
                        )}
                    />

                    <Controller
                        name="email"
                        control={control}
                        render={({
                            field: { onChange, value, onBlur },
                            fieldState: { error },
                        }) => (
                            <Input
                                errorMessage={error?.message}
                                placeholder="Enter founder email"
                                label="Founder email"
                                onBlur={onBlur}
                                value={value}
                                onChange={({ target }) =>
                                    onChange(target.value)
                                }
                            />
                        )}
                    />

                    <Controller
                        name="isDeadline"
                        control={control}
                        render={({ field: { onChange } }) => (
                            <CheckBox onToggle={onChange}>
                                Set deadline for the survey
                            </CheckBox>
                        )}
                    />
                </div>

                {wIsDeadline && (
                    <>
                        <div className="flex rounded-lg bg-default-75 p-4 text-sm">
                            <span className="mr-3 text-default-400">Note</span>
                            <span className="font-semibold">
                                It takes ~40 mins for one team member to
                                complete all surveys.
                            </span>
                        </div>

                        <div className="mt-4 flex gap-x-2 text-sm">
                            <GridSelect
                                optionClassName="py-[6px] px-3"
                                selected={selected}
                                isMultiSelect={false}
                                onSelect={(value) =>
                                    handleDeadlineOption(value, setSelected)
                                }
                                options={DEADLINE_OPTIONS}
                            />
                        </div>

                        <div className="mt-4 grid grid-cols-2 gap-x-4">
                            <Controller
                                name="date"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { value, onChange, onBlur },
                                }) => (
                                    <DateInput
                                        value={value}
                                        errorMessage={error?.message}
                                        label="Deadline date"
                                        onChange={(v) => onChange(v ?? "")}
                                        onBlur={onBlur}
                                    />
                                )}
                            />
                            <Controller
                                name="time"
                                control={control}
                                render={({
                                    fieldState: { error },
                                    field: { value, onChange, onBlur },
                                }) => (
                                    <TimeInput
                                        value={value}
                                        errorMessage={error?.message}
                                        label="Deadline time"
                                        onChange={(v) => onChange(v ?? "")}
                                        onBlur={onBlur}
                                    />
                                )}
                            />
                        </div>
                        <Collapsed isExpanded={showDeadlineError}>
                            <div className="mt-2 text-sm text-danger-500">
                                {errors?.deadline?.message}
                            </div>
                        </Collapsed>
                    </>
                )}

                <div className="mt-8 flex justify-end gap-x-3">
                    <Button
                        onClick={onClose}
                        customType="secondary-solid"
                        customSize="xl"
                    >
                        Cancel
                    </Button>

                    <Button
                        type="submit"
                        disabled={!isValid || !isDirty}
                        isLoading={isLoading}
                        customSize="xl"
                    >
                        Send invite
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default InvitationModal;
