export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.33301 1.04199C7.33301 0.489707 7.78072 0.0419922 8.33301 0.0419922H11.6663C12.2186 0.0419922 12.6663 0.489707 12.6663 1.04199C12.6663 1.59428 12.2186 2.04199 11.6663 2.04199H8.33301C7.78072 2.04199 7.33301 1.59428 7.33301 1.04199Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2071 7.45989C13.5976 7.85041 13.5976 8.48357 13.2071 8.8741L10.7071 11.3741C10.3166 11.7646 9.68342 11.7646 9.29289 11.3741C8.90237 10.9836 8.90237 10.3504 9.29289 9.95989L11.7929 7.45989C12.1834 7.06936 12.8166 7.06936 13.2071 7.45989Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99967 5C6.87006 5 4.33301 7.53705 4.33301 10.6667C4.33301 13.7963 6.87006 16.3333 9.99967 16.3333C13.1293 16.3333 15.6663 13.7963 15.6663 10.6667C15.6663 7.53705 13.1293 5 9.99967 5ZM2.33301 10.6667C2.33301 6.43248 5.76549 3 9.99967 3C14.2339 3 17.6663 6.43248 17.6663 10.6667C17.6663 14.9008 14.2339 18.3333 9.99967 18.3333C5.76549 18.3333 2.33301 14.9008 2.33301 10.6667Z"
            fill="currentColor"
        />
    </svg>
);
