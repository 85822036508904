import React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "lib/utils";
import Utils from "utils";

const individualScoreVariants = cva(
    "flex items-center justify-center rounded-sm text-sm text-black",
    {
        variants: {
            variant: {
                default: "",
                low: "bg-secondary-100",
                medium: "bg-secondary-200",
                high: "bg-secondary-300",
            },
            size: {
                sm: "h-[24px] w-[34px]",
                md: "h-[32px] w-[44px]",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "md",
        },
    }
);

type ScoreWrapperProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof individualScoreVariants>;

const IndividualScoreWrapper = React.forwardRef<
    HTMLDivElement,
    ScoreWrapperProps
>(({ className, variant, size, ...props }, ref) => {
    return (
        <div
            ref={ref}
            className={cn(
                individualScoreVariants({ variant, size }),
                className
            )}
            {...props}
        />
    );
});
IndividualScoreWrapper.displayName = "IndividualScoreWrapper";

type ScoreProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof individualScoreVariants> & {
        value: number;
    };

const IndividualScore = React.forwardRef<HTMLDivElement, ScoreProps>(
    ({ value, variant, ...props }, ref) => {
        value = Number(value.toFixed(1));
        return (
            <IndividualScoreWrapper
                ref={ref}
                variant={variant ? variant : Utils.Status.getStatusType(value)}
                {...props}
            >
                {value}
            </IndividualScoreWrapper>
        );
    }
);
IndividualScore.displayName = "IndividualScore";

export { IndividualScoreWrapper, IndividualScore };
