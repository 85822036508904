import { SurveyTeamProps } from "./interfaces";
import { FC } from "react";
import AvatarGroup from "components/AvatarGroup";
import Avatar from "components/Avatar";

const MAX_SURVEY_TEAM = 6;

const SurveyTeam: FC<SurveyTeamProps> = ({ survey }) => {
    return (
        <AvatarGroup size="sm" max={MAX_SURVEY_TEAM}>
            {survey.team.map(
                ({ id, img, firstName, lastName, isSubmitted }, i) => (
                    <Avatar
                        key={i}
                        memberId={id}
                        firstName={firstName}
                        lastName={lastName}
                        src={img?.url}
                        isDisabled={!isSubmitted}
                        disableTooltip
                    />
                )
            )}
        </AvatarGroup>
    );
};

export default SurveyTeam;
