import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useGetMeQuery } from "store/api/auth";
import { PageLoader } from "./components/PageLoader";

const AuthGuard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data: meData, isLoading } = useGetMeQuery();

    useEffect(() => {
        if (meData && !meData.data?.auth) {
            const redirectURL = encodeURIComponent(location.pathname);

            navigate(`/auth/sign-in?redirect=${redirectURL}`);
        }
    }, [meData]);

    if (isLoading) {
        return <PageLoader />;
    }

    return <Outlet />;
};

export default AuthGuard;
