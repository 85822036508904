import Button from "components/Button";
import { IconType } from "components/Icon";
import { cn } from "lib/utils";

export type GridSelectOption<T> = {
    label: string;
    icon?: IconType;
    value: T;
};

type Props<T> = {
    label?: string;
    disabled?: boolean;
    centered?: boolean;
    className?: string;
    selected: Array<T>;
    isMultiSelect?: boolean;
    optionClassName?: string;
    onSelect: (_: Array<T>) => void;
    options: Array<GridSelectOption<T>>;
};

const GridSelect = <T,>({
    label,
    options,
    disabled,
    centered,
    onSelect,
    selected,
    className,
    isMultiSelect,
    optionClassName,
}: Props<T>) => {
    const handleSelect = (value: T) => {
        if (!isMultiSelect) {
            onSelect([value]);
            return;
        }

        const exists = selected.includes(value);

        const valueUpd = exists
            ? selected.filter((item) => item !== value)
            : [...selected, value];
        onSelect(valueUpd);
    };

    return (
        <div>
            {label && (
                <div className="mb-2 text-sm text-default-500">{label}</div>
            )}

            <div
                className={`flex flex-row flex-wrap gap-3 ${centered && "justify-center"} ${className}`}
            >
                {options.map(({ label: lbl, icon, value }) => (
                    <Button
                        icon={icon}
                        customSize="xl"
                        disabled={disabled}
                        key={value as string}
                        customType="secondary-light"
                        onClick={() => handleSelect(value)}
                        className={cn(
                            "gap-3 border border-solid border-default-200 shadow-sm",
                            icon ? "!min-w-[120px]" : "!min-w-[72px]",
                            selected.includes(value) &&
                                "border-[#9753FC] bg-[#F8F5FF]",
                            selected.includes(value)
                                ? `hover:bg-[${isMultiSelect ? "#EEE5FF" : "#9753FC"}]`
                                : "hover:border hover:border-solid hover:border-[#AC74FF] hover:bg-white",
                            optionClassName
                        )}
                    >
                        {lbl}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default GridSelect;
