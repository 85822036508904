import { z } from "zod";

export const npsScoreChartDataSchema = z.array(
    z.object({
        x: z.string(),
        y: z.number(),
    })
);

export type NpsScoreChartData = z.infer<typeof npsScoreChartDataSchema>;
