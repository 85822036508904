import { useMemo } from "react";

import {
    InsightsTeam,
    InsightsMemberData,
    InsightsIndividualMember,
    GeneratedReportSoftSkillMemberKeys,
    GeneratedInsightReportTeam,
} from "store/api/investorCompany/interfaces";
import Utils from "utils";
import Icon from "components/Icon";
import Delimiter from "./Delimiter";
import InsightCardRow from "./InsightCardRow";
import MemberInfo from "routes/company/components/MemberInfo";
import OverallIndividualBar from "components/OverallIndividualBar";
import EmptyInsight from "routes/company/components/EmptyInsight";

type Props = {
    teamData: InsightsTeam;
    individualData: InsightsIndividualMember;
};

type Rows = Array<
    | {
          title: string;
          team?: GeneratedInsightReportTeam;
          individual?: InsightsMemberData;
      }
    | "delimiter"
>;

const Insight: React.FC<Props> = ({ individualData, teamData }) => {
    const nonMemberValues = useMemo((): Record<
        GeneratedReportSoftSkillMemberKeys,
        InsightsMemberData
    > => {
        const values: Partial<
            Record<GeneratedReportSoftSkillMemberKeys, InsightsMemberData>
        > = {};

        for (const key in individualData) {
            if (individualData.hasOwnProperty(key) && key.startsWith("ss_")) {
                (values as any)[key as any] = (individualData as any)[key];
            }
        }

        return values as any;
    }, [individualData]);

    const memberInfo = Utils.Insight.getMemberInfoFromInsight(individualData);

    const rows: Rows = [
        {
            title: "Task clarity",
            team: teamData.ss_task_clarity,
            individual: nonMemberValues.ss_task_clarity,
        },
        {
            title: "Stress resistance",
            team: teamData.ss_stress_resistance,
            individual: nonMemberValues.ss_stress_resistance,
        },
        {
            title: "Resilience",
            team: teamData.ss_resilience,
            individual: nonMemberValues.ss_resilience,
        },
        {
            title: "Risk propensity",
            team: teamData.ss_risk_propensity,
            individual: nonMemberValues.ss_risk_propensity,
        },
        {
            title: "Self-reflection",
            team: teamData.ss_self_reflection,
            individual: nonMemberValues.ss_self_reflection,
        },
        {
            title: "Motivation",
            team: teamData.ss_motivation,
            individual: nonMemberValues.ss_motivation,
        },
        "delimiter",
        {
            title: "Growth mindset",
            team: teamData.ss_growth_mindset,
            individual: nonMemberValues.ss_growth_mindset,
        },
        {
            title: "Fixed mindset",
            team: teamData.ss_fixed_mindset,
            individual: nonMemberValues.ss_fixed_mindset,
        },
    ];

    return (
        <div className="flex h-[524px] w-[400px] flex-col gap-4 rounded-lg bg-white p-6 md:w-[unset] lg:w-[unset]">
            <MemberInfo disableTooltip {...memberInfo} />

            {Utils.Parsers.isObjectEmpty(nonMemberValues) ? (
                <EmptyInsight />
            ) : (
                <>
                    <OverallIndividualBar
                        metricValue={
                            (individualData?.ss_overall?.value as number) ?? 0
                        }
                        averageValue={
                            (teamData?.ss_overall?.value as number) ?? 0
                        }
                    />

                    <div className="my-2 flex flex-row items-center justify-between">
                        <div className="text-sm text-default-400">
                            Soft skills matrix
                        </div>

                        <div className="flex flex-row items-center">
                            <Icon type="User" size={16} className="mr-[21px]" />

                            <Icon
                                type="Users"
                                size={16}
                                className="text-default-400"
                            />
                        </div>
                    </div>

                    <div className="flex flex-col gap-[16px]">
                        {rows.map((item, i) =>
                            item === "delimiter" ? (
                                <Delimiter key={i} />
                            ) : (
                                item.team &&
                                item.individual && (
                                    <InsightCardRow
                                        key={i}
                                        name={item.title}
                                        team={item.team}
                                        individual={item.individual}
                                    />
                                )
                            )
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Insight;
