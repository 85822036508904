import { FC, useMemo } from "react";
import { PaginationResponse } from "store/api/shared.types";

type Props = {
    pagination?: PaginationResponse;
};

const PaginationDescription: FC<Props> = ({ pagination }) => {
    const [start, end] = useMemo((): [number, number] => {
        if (!pagination) {
            return [0, 0];
        }
        const { currentPage, perPage, nextPage, totalRecords } = pagination;
        const startRecords = Math.min(currentPage * perPage + 1, totalRecords);
        let endRecords = currentPage * perPage + perPage;
        if (nextPage === null) {
            endRecords = totalRecords;
        }
        return [startRecords, endRecords];
    }, [pagination]);

    return (
        <span className="text-sm font-semibold text-foreground">
            {`Showing ${start}-${end} of ${pagination?.totalRecords ?? 0}`}
        </span>
    );
};

export default PaginationDescription;
