import ButtonIcon from "components/ButtonIcon";
import InsightStatus from "./InsightStatus";
import Icon, { IconType } from "components/Icon";
import { Dispatch } from "react";
import { MatrixControllerData } from "./useMatrixController";
import { SpiderChart } from "components/ui/SpiderChart";
import { cn } from "lib/utils";
import InsightDescription from "routes/company/components/Modal/InsightDescription";
import EmptyInsight from "routes/company/components/EmptyInsight";
import InfoTooltip from "routes/company/components/InfoTooltip";

type Props = {
    name: string;
    iconType: IconType;
    matrixData: Array<MatrixControllerData>;
    currIdx: number;
    setCurrIdx: Dispatch<React.SetStateAction<number>>;
    controlHandler: (shift: -1 | 1) => void;
    showTitle?: boolean;
    showMore?: boolean;
    tooltip: string;
    onShowMore?: () => void;
    className?: string;
    wrapperClassName?: string;
    statusWrapperClassName?: string;
};

const Matrix: React.FC<Props> = ({
    tooltip,
    iconType,
    matrixData,
    currIdx,
    setCurrIdx,
    controlHandler,
    name,
    showTitle = true,
    showMore = true,
    onShowMore,
    className,
    wrapperClassName,
    statusWrapperClassName,
}) => {
    const currData = matrixData[currIdx]?.data;
    const isEmptyInsight: boolean = !currData;

    /** h-[536px] */

    return (
        <div
            className={cn(
                "group relative m-auto flex w-[100%] max-w-[576px] flex-col gap-2 rounded-lg bg-white p-6 lg:max-w-[unset]",
                className
            )}
        >
            {showMore && !isEmptyInsight && (
                <button
                    type="button"
                    onClick={onShowMore}
                    className="absolute right-6 top-6 rounded p-2 opacity-0 transition duration-100 hover:bg-default-50 group-hover:opacity-100"
                >
                    <Icon type="Maximize2" size="16" />
                </button>
            )}
            {showTitle && (
                <div className="flex flex-row items-center gap-2">
                    <div className="rounded-sm border border-solid border-default-75 bg-default-50 p-1">
                        <Icon
                            size={20}
                            type={iconType}
                            className="text-default-400"
                        />
                    </div>

                    <div className="text-sm">{name}</div>

                    <InfoTooltip text={tooltip} />
                </div>
            )}

            {isEmptyInsight ? (
                <EmptyInsight />
            ) : (
                <>
                    <div
                        className={cn(
                            "grid grid-cols-1",
                            showMore && "lg:grid-cols-2",
                            wrapperClassName
                        )}
                    >
                        <div className="flex items-center justify-center text-2xl">
                            <SpiderChart
                                data={matrixData.map((v) => v.data) as any}
                                className="h-[350px] w-[520px]"
                                highlightedIdx={currIdx}
                                onClick={setCurrIdx}
                                fill="url('#soft-skills-matrix')"
                            />
                        </div>

                        <div className={cn("ml-4", statusWrapperClassName)}>
                            <div className="flex flex-row items-center justify-between">
                                <div className="flex flex-row gap-2">
                                    <span>{currData?.name ?? "?"}</span>
                                    <InsightStatus
                                        value={(currData?.value as number) ?? 0}
                                    />
                                </div>
                                <div className="flex items-center">
                                    <ButtonIcon
                                        icon="ChevronLeft"
                                        customType="light"
                                        className="cursor-pointer"
                                        onClick={() => controlHandler(-1)}
                                    />
                                    <ButtonIcon
                                        icon="ChevronRight"
                                        customType="light"
                                        className="cursor-pointer"
                                        onClick={() => controlHandler(1)}
                                    />
                                </div>
                            </div>

                            <InsightDescription
                                className={cn(
                                    showMore && "my-3 line-clamp-6 h-[120px]"
                                )}
                            >
                                {currData?.conclusion?.text ?? ""}
                            </InsightDescription>

                            {showMore && (
                                <button
                                    type="button"
                                    onClick={onShowMore}
                                    className="flex cursor-pointer flex-row items-center text-sm transition duration-100 hover:text-primary-500"
                                >
                                    Show more{" "}
                                    <Icon
                                        type="ChevronRight"
                                        className="ml-2"
                                    />
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Matrix;
