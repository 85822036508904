import { useEffect, useState } from "react";

import Title from "questions/components/Title";
import InputSelect from "components/InputSelect";
import { QuestionProps } from "questions/types.questions";
import { usePostSurveyAnswerMutation } from "store/api/survey";
import SurveyFooter from "routes/surveys/components/SurveyFooter";
import { DropDownMultiSelectAnswerPattern } from "types/surveys/answer-pattern";
import { DropDownMultiSelectQuestionPattern } from "types/surveys/question-pattern";

const DropDownMultiSelectQuestion = ({
    isChild,
    surveyKey,
    allDisabled,
    isLastQuestion,
    data: { key, isRequired, answer, pattern, text },
    onError,
    handleBack,
    handleNext,
}: QuestionProps<
    DropDownMultiSelectQuestionPattern,
    DropDownMultiSelectAnswerPattern
>) => {
    const [postSurveyAnswer, { isError, isLoading, isSuccess }] =
        usePostSurveyAnswerMutation();

    const [isValid, setIsValid] = useState(!isRequired);
    const [selectedValue, setSelectedValue] = useState<Array<string>>(
        answer?.value ? answer.value : []
    );

    const handleSubmit = () => {
        postSurveyAnswer({
            surveyKey,
            questionKey: key,
            data: JSON.stringify(selectedValue),
        });
    };

    useEffect(() => {
        if (isSuccess) handleNext();
    }, [isSuccess]);

    useEffect(() => {
        onError(isError);
    }, [isError]);

    useEffect(() => {
        setIsValid(!!selectedValue.length);
    }, [selectedValue]);

    const placeholder =
        key === "hc-previous-industry"
            ? "Type to search, select one or multiple"
            : "Select an option";

    return (
        <div className="flex flex-col items-center justify-center gap-12">
            {!isChild && <Title>{text}</Title>}

            <InputSelect<string>
                isMulti={true}
                disabled={allDisabled}
                selected={selectedValue}
                placeholder={placeholder}
                options={pattern.options}
                defaultValue={selectedValue}
                handleSelect={setSelectedValue}
                getOptionValue={(option) => option}
                className="!w-[100%] !max-w-[600px]"
                renderOptionLabel={(option) => option}
            />

            <SurveyFooter
                disableSubmitOnEnter
                isLoading={isLoading}
                loadingText="Saving..."
                handleBack={handleBack}
                handleNext={handleSubmit}
                allDisabled={allDisabled}
                nextDisabled={!selectedValue || !isValid}
                nextText={isLastQuestion ? "Submit" : undefined}
            />
        </div>
    );
};

export default DropDownMultiSelectQuestion;
