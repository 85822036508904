import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { InvitationType } from "store/api/invitation/interfaces";
import { useGetMeQuery, useSignOutMutation } from "store/api/auth";
import { useParseInvitationTokenQuery } from "store/api/invitation";
import { skipToken } from "@reduxjs/toolkit/query";

const OnboardingIndex = () => {
    const navigate = useNavigate();
    const { token = "" } = useParams();
    const [signOut] = useSignOutMutation();
    const { data: meData, ...mePayload } = useGetMeQuery();
    const { data: tokenData, ...tokenPayload } = useParseInvitationTokenQuery(
        token
            ? {
                  token,
              }
            : skipToken
    );

    useEffect(() => {
        if (tokenPayload.isLoading) return;

        // sign out from current account and start the onboarding process from scratch if new invitation token is valid
        if (token && tokenPayload.isSuccess) {
            signOut();
        } else {
            if (meData?.data?.isOnboarding) {
                const userType =
                    tokenData?.data?.type || meData.data?.onboarding?.type;

                switch (userType) {
                    case InvitationType.Investor:
                        return navigate("/onboarding/investor");
                    case InvitationType.Member:
                    case InvitationType.Company:
                        return navigate("/onboarding/member");
                    default:
                        return navigate("/auth/sign-in");
                }
            } else if (!mePayload.isLoading && !mePayload.isFetching) {
                navigate("/auth/sign-in");
            }
        }
    }, [tokenPayload.isSuccess, mePayload.isSuccess]);

    return <Outlet />;
};

export default OnboardingIndex;
