import {
    InsightsCompany,
    InsightsIndividual,
} from "store/api/investorCompany/interfaces";

// CARDS
import TeamCompanyCard from "./Cards/Team";
import AboutCompanyCard from "./Cards/About";
import FundingCompanyCard from "./Cards/Funding";
import TractionCompanyCard from "./Cards/Traction";
import MarketAndCustomers from "./Cards/MarketAndCustomers";
import PrioritiesAndAdvantages from "./Cards/PrioritiesAndAdvantages";

type Props = {
    individual: InsightsIndividual;
    companyInsight: InsightsCompany;
};

const AboutTab: React.FC<Props> = ({ individual, companyInsight }) => {
    return (
        <>
            <div className="flex-row hidden gap-4 lg:flex">
                <div className="flex flex-col flex-1 gap-4">
                    <AboutCompanyCard data={companyInsight} />
                    <FundingCompanyCard data={companyInsight} />
                    <MarketAndCustomers data={companyInsight} />
                </div>

                <div className="flex flex-col flex-1 gap-4">
                    <TeamCompanyCard
                        individual={individual}
                        data={companyInsight}
                    />
                    <TractionCompanyCard data={companyInsight} />
                    <PrioritiesAndAdvantages data={companyInsight} />
                </div>
            </div>

            <div className="grid gap-4 lg:hidden">
                <AboutCompanyCard data={companyInsight} />
                <TeamCompanyCard
                    individual={individual}
                    data={companyInsight}
                />
                <FundingCompanyCard data={companyInsight} />
                <TractionCompanyCard data={companyInsight} />
                <MarketAndCustomers data={companyInsight} />
                <PrioritiesAndAdvantages data={companyInsight} />
            </div>
        </>
    );
};

export default AboutTab;
