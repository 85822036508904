import Icon from "components/Icon";

type Props = {
    title: string;
    subTitle: string;
};

const Banner = ({ title, subTitle }: Props) => (
    <div
        className="flex w-[100%] flex-row gap-4 py-[10px] px-5 rounded-lg"
        style={{
            border: "1.229px solid var(--content1-opacity-32, rgba(255, 255, 255, 0.32)",
            background:
                "var(--background-opacity-24, rgba(255, 255, 255, 0.24))",
        }}
    >
        <div className="flex flex-1 flex-col">
            <div className="text-white text-sm">{title}</div>
            <div className="text-white text-lg">{subTitle}</div>
        </div>

        <div>
            <Icon type="Forward" color="white" />
        </div>
    </div>
);

export default Banner;
