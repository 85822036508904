import Utils from "utils";
import Frame from "../shared/Frame";
import StatItem from "../shared/StatItem";
import Raising from "./components/Raising";
import NotRaising from "./components/NotRaising";
import { InsightsCompany } from "store/api/investorCompany/interfaces";
import { useMemo } from "react";

type Props = {
    data: InsightsCompany;
};

const FundingCompanyCard: React.FC<Props> = ({ data }) => {
    const {
        roundValue,
        fundingType,
        roundLastValue,
        fundingTypeOther,
        isRaisingCapital,
        shareholderNames,
        dateDataCollected,
        otherShareholders,
        preValuationAmount,
        roundLastValueOther,
        raisingCapitalAmount,
        hasPreValuationAmount,
        roundValueDescription,
        preLastValuationAmount,
        hasLastPreValuationAmount,
    } = useMemo(
        () => ({
            fundingType: data["company-funding-type"]?.value as string,
            fundingTypeOther: data["company-funding-type-other"]
                ?.value as string,
            dateDataCollected: data["company-raising-capital"]
                ?.dataCollectedAt as Date,
            isRaisingCapital: data["company-raising-capital"]?.value as boolean,
            raisingCapitalAmount: data["company-raising-capital-amount"]
                ?.value as number,
            hasPreValuationAmount: data["company-pre-valuation"]
                ?.value as string,
            hasLastPreValuationAmount: data["company-last-pre-valuation"]
                ?.value as string,
            preValuationAmount: data["company-pre-valuation-amount"]
                ?.value as number,
            preLastValuationAmount: data["company-last-pre-valuation-amount"]
                ?.value as number,
            roundValue: data["company-funding-round"]?.value as string,
            roundValueDescription: data["company-funding-round-description"]
                ?.value as string,
            roundLastValue: data["company-last-funding-round"]?.value as string,
            roundLastValueOther: data["company-last-funding-round-description"]
                ?.value as string,
            otherShareholders: data["company-other-shareholders"]
                ?.value as boolean,
            shareholderNames: data["company-other-shareholders-names"]
                ?.value as string,
        }),
        [data]
    );

    const isEmptyInsight: boolean = useMemo(
        () =>
            Object.values({
                isRaisingCapital,
                otherShareholders,
            }).some((value) => value === undefined),
        [isRaisingCapital, otherShareholders]
    );

    return (
        <Frame title="Funding" isEmptyInsight={isEmptyInsight}>
            <Raising
                date={dateDataCollected}
                isRaisingCapital={isRaisingCapital}
                roundLabel={
                    roundValue === "Other" ? roundValueDescription : roundValue
                }
                raisingLabel={
                    "$" +
                    Utils.Parsers.abbreviateLargeNumbers(raisingCapitalAmount)
                }
                preMoneyValuation={
                    hasPreValuationAmount === "Yes"
                        ? "$" +
                          Utils.Parsers.abbreviateLargeNumbers(
                              preValuationAmount
                          )
                        : hasPreValuationAmount
                }
            />

            {isRaisingCapital ? (
                <StatItem
                    title="Type of funding"
                    label={
                        fundingType === "Other" ? fundingTypeOther : fundingType
                    }
                />
            ) : (
                <NotRaising
                    roundLabel={
                        roundLastValue === "Other"
                            ? roundLastValueOther
                            : roundLastValue
                    }
                    preMoneyValuation={
                        hasLastPreValuationAmount === "Yes"
                            ? "$" +
                              Utils.Parsers.abbreviateLargeNumbers(
                                  preLastValuationAmount
                              )
                            : hasLastPreValuationAmount
                    }
                />
            )}

            <StatItem
                label={otherShareholders ? shareholderNames : "No"}
                title="Company other shareholders except the founders"
            />
        </Frame>
    );
};

export default FundingCompanyCard;
