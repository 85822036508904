export enum QuestionType {
    scale = "scale",
    number = "number",
    boolean = "boolean",
    long_text = "long-text",
    short_text = "short-text",
    grid_select = "grid-select",
    number_array = "number-array",
    grid_multi_select = "grid-multi-select",
    drop_down_multi_select = "drop-down-multi-select",
    location_search_multi_select = "location-search-multi-select",
}

type UnitType = "text" | "currency";

export type ScaleQuestionPattern = {
    type: QuestionType.scale;
    labelMax: string;
    labelMin: string;
};

export type GridSelectQuestionPattern = {
    withIcons?: boolean;
    options: Array<string>;
    type: QuestionType.grid_select;
};

export type GridMultiSelectQuestionPattern = {
    max: number;
    min: number;
    options: Array<string>;
    type: QuestionType.grid_multi_select;
};

export type DropDownMultiSelectQuestionPattern = {
    type: QuestionType.drop_down_multi_select;
    options: Array<string>;
};

export type BooleanQuestionPattern = {
    type: QuestionType.boolean;
};

export type NumberQuestionPattern = {
    type: QuestionType.number;
    unit?: {
        type: UnitType;
        value?: string;
        dependsOn?: string;
    };
};

export type LocationSearchMultiSelectQuestionPattern = {
    type: QuestionType.location_search_multi_select;
};

export type ShortTextQuestionPattern = {
    type: QuestionType.short_text;
};

export type LongTextQuestionPattern = {
    type: QuestionType.short_text;
};

export type NumberArrayQuestionPattern = {
    type: QuestionType.number_array;
    unit: {
        value?: string;
        type: UnitType;
        dependsOn: string;
    };
    labels: Array<string>;
};

export type AllQuestionPatterns =
    | ScaleQuestionPattern
    | NumberQuestionPattern
    | BooleanQuestionPattern
    | LongTextQuestionPattern
    | ShortTextQuestionPattern
    | GridSelectQuestionPattern
    | NumberArrayQuestionPattern
    | GridMultiSelectQuestionPattern
    | DropDownMultiSelectQuestionPattern
    | LocationSearchMultiSelectQuestionPattern;
