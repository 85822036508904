import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
    InvestorOnboardingActions,
    InvestorOnboardingSelectors,
} from "store/reducers/investorOnboarding";
import {
    useGetOnboardingQuery,
    useSaveOnboardingMutation,
} from "store/api/onboarding";
import Input from "components/Input";
import BottomButtons from "routes/onboarding/components/BottomButtons";
import { InvestorOnboarding } from "store/reducers/investorOnboarding/types";
import Utils from "utils";
import InputSelect from "components/InputSelect";
import { InputSearchResults } from "types/validation.types";
import { useLocationSearch } from "hooks/useLocationSearch";

type Props = {
    title: string;
    handleNextClick: () => void;
    handleBackClick: () => void;
};
const schema = yup.object({
    state: yup.string(),
    street: yup.string().required("Required"),
    zipCode: yup.string().required("Required"),
    city: yup.string().required("Required"),
    country: Utils.Validations.searchOption,
});

const parseDefaultValues = (
    { state, street, zipCode, city, country }: InvestorOnboarding.Billing,
    data?: Partial<InvestorOnboarding.Billing>
): InvestorOnboarding.Billing => ({
    state: state || data?.state || "",
    street: street || data?.street || "",
    zipCode: zipCode || data?.zipCode || "",
    city: city || data?.city || "",
    country: country || data?.country,
});

const BillingStep = ({ title, handleBackClick, handleNextClick }: Props) => {
    const dispatch = useDispatch();
    const defaultValues = useSelector(
        InvestorOnboardingSelectors.state("billing")
    );
    const [saveOnboarding, saveOnboardingPayload] = useSaveOnboardingMutation();
    const { data: savedOnboarding } = useGetOnboardingQuery();
    const { data: countryData, setQuery: setNationalityQuery } =
        useLocationSearch({ type: "country" });

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues: parseDefaultValues(
            defaultValues,
            savedOnboarding?.data["investor.billing"]
        ),
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema) as any,
    });

    const onSubmit = (billing: InvestorOnboarding.Billing) => {
        dispatch(InvestorOnboardingActions.setBilling(billing));
        saveOnboarding({ "investor.billing": billing });
        handleNextClick();
    };

    return (
        <div>
            <div className="mb-10 text-2xl font-semibold">{title}</div>

            <div className="flex flex-col gap-5">
                <Controller
                    name="street"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            label="Street"
                            onBlur={onBlur}
                            onChange={onChange}
                            errorMessage={error?.message}
                            placeholder="Street name & number"
                            autoComplete="billing street-address"
                        />
                    )}
                />

                <Controller
                    name="city"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            label="City"
                            onBlur={onBlur}
                            onChange={onChange}
                            errorMessage={error?.message}
                            placeholder="City name"
                            autoComplete="billing address-level2"
                        />
                    )}
                />

                <Controller
                    name="zipCode"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            label="Postcode"
                            onBlur={onBlur}
                            onChange={onChange}
                            errorMessage={error?.message}
                            placeholder="Postcode or zip code"
                            autoComplete="billing postal-code"
                        />
                    )}
                />

                <Controller
                    name="state"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, onBlur, value, ref },
                    }) => (
                        <Input
                            ref={ref}
                            value={value}
                            label="State"
                            onBlur={onBlur}
                            onChange={onChange}
                            errorMessage={error?.message}
                            placeholder="State or province"
                            autoComplete="billing address-level1"
                        />
                    )}
                />

                <Controller
                    name="country"
                    control={control}
                    render={({
                        fieldState: { error },
                        field: { onChange, value },
                    }) => (
                        <InputSelect<InputSearchResults[0]>
                            isMulti={false}
                            options={countryData}
                            selected={value ?? null}
                            handleSelect={onChange}
                            defaultValue={value}
                            errorMessage={error?.message}
                            label="Country"
                            handleInputChange={setNationalityQuery}
                            getOptionValue={(option) => option?.value}
                            renderOptionLabel={(option) => option.label}
                            placeholder="Type country name to search and select"
                            nativeAutocomplete="billing country"
                        />
                    )}
                />

                <BottomButtons
                    isLastStep
                    onBack={handleBackClick}
                    onNext={handleSubmit(onSubmit)}
                    disableNext={!isValid || saveOnboardingPayload.isLoading}
                />
            </div>
        </div>
    );
};

export default BillingStep;
