import InsightStatus, { StatusType } from "./InsightStatus";
import Icon, { IconType } from "components/Icon";
import { Pyramid } from "components/ui/Pyramid";
import { cn } from "lib/utils";
import { Dispatch } from "react";
import { LencioniPyramidData } from "./useLencioniPyramidController";
import InsightDescription from "routes/company/components/Modal/InsightDescription";
import ButtonIcon from "components/ButtonIcon";
import EmptyInsight from "routes/company/components/EmptyInsight";
import InfoTooltip from "routes/company/components/InfoTooltip";

type Props = {
    name: string;
    tooltip: string;
    iconType: IconType;
    pyramidData: Array<LencioniPyramidData>;
    currIdx: number;
    setCurrIdx: Dispatch<React.SetStateAction<number>>;
    controlHandler: (action: "back" | "forward") => () => void;
    showTitle?: boolean;
    showMore?: boolean;
    onShowMore?: () => void;
    className?: string;
};

const LencioniPyramid: React.FC<Props> = ({
    name,
    iconType,
    pyramidData,
    currIdx,
    tooltip,
    setCurrIdx,
    controlHandler,
    showTitle = true,
    showMore = true,
    onShowMore,
    className,
}) => {
    const currData = pyramidData[currIdx];
    const statusKey =
        currData.data?.conclusion?.level.toLowerCase() as StatusType;
    const currName = currData.data?.name ?? "Lencioni pyramid";
    const currDescription = currData?.data?.conclusion?.text ?? "";
    const isEmptyInsight: boolean = !currData.data;

    return (
        <div
            className={cn(
                "group relative flex h-[536px] flex-col gap-2 rounded-lg bg-white p-6",
                className
            )}
        >
            {showMore && !isEmptyInsight && (
                <button
                    type="button"
                    onClick={onShowMore}
                    className="absolute right-6 top-6 rounded p-2 opacity-0 transition duration-100 hover:bg-default-50 group-hover:opacity-100"
                >
                    <Icon type="Maximize2" size="16" />
                </button>
            )}
            {showTitle && (
                <div className="flex flex-row items-center gap-2">
                    <div className="rounded-sm border border-solid border-default-75 bg-default-50 p-1">
                        <Icon
                            size={20}
                            type={iconType}
                            className="text-default-400"
                        />
                    </div>

                    <div className="text-sm">{name}</div>

                    <InfoTooltip text={tooltip} />
                </div>
            )}

            {isEmptyInsight ? (
                <EmptyInsight />
            ) : (
                <>
                    <div className="flex flex-1 items-center justify-center text-2xl">
                        <Pyramid
                            data={pyramidData}
                            highlightedIdx={currIdx}
                            onSelectIndex={setCurrIdx}
                        />
                    </div>

                    <div>
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-row items-center gap-2">
                                <span>{currName}</span>
                                <InsightStatus
                                    value={0}
                                    statusKey={statusKey}
                                />
                            </div>

                            <div className="flex flex-row items-center gap-x-1">
                                <ButtonIcon
                                    icon="ChevronLeft"
                                    customType="light"
                                    className="cursor-pointer"
                                    onClick={controlHandler("back")}
                                />
                                <ButtonIcon
                                    icon="ChevronRight"
                                    customType="light"
                                    className="cursor-pointer"
                                    onClick={controlHandler("forward")}
                                />
                            </div>
                        </div>

                        <InsightDescription
                            className={cn(
                                showMore && "my-3 line-clamp-6 h-[120px]"
                            )}
                        >
                            {currDescription}
                        </InsightDescription>

                        {showMore && (
                            <button
                                type="button"
                                onClick={onShowMore}
                                className="flex cursor-pointer flex-row items-center text-sm transition duration-100 hover:text-primary-500"
                            >
                                Show more{" "}
                                <Icon type="ChevronRight" className="ml-2" />
                            </button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default LencioniPyramid;
