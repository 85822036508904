import { cn } from "lib/utils";
import { FC, ReactNode } from "react";

type Props = {
    className?: string;
    children?: ReactNode;
};

const CardBlockWrapper: FC<Props> = ({ className, children }) => {
    return (
        <div className={cn("grid grid-cols-2 gap-x-6", className)}>
            {children}
        </div>
    );
};

export default CardBlockWrapper;
