import Button from "components/Button";
import EmptyImg from "images/dashboard/investor/no-insights.png";

type Props = {
    allowGenerateReport: boolean;
    handleGenerate: () => void;
};

const Empty: React.FC<Props> = ({ handleGenerate, allowGenerateReport }) => (
    <div className="mt-12 flex flex-1 flex-col items-center justify-center gap-6 rounded-lg bg-white py-[120px]">
        <div className="flex h-[180px] w-[320px] items-center justify-center">
            <img src={EmptyImg} alt="No insights" />
        </div>

        <div>
            <div className="text-center text-black">Report not generated</div>
            <div className="mt-2 w-[484px] text-center text-sm text-default-400">
                Generate a report based on the currently submitted answers.
                We'll notify you if new answers will be submitted after report
                generation
            </div>
        </div>

        {allowGenerateReport && (
            <Button
                icon="Zap"
                customSize="md"
                onClick={handleGenerate}
                customType="secondary-solid"
            >
                Generate report
            </Button>
        )}
    </div>
);

export default Empty;
