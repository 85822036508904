import { FC, useMemo } from "react";
import Alert from "components/Alert";
import { useCompanyReportContext } from "routes/components/CompanyReport/contexts/report-context";

type Props = {};

const ReportInfo: FC<Props> = () => {
    const { generatedAt, sharedBy } = useCompanyReportContext();

    const generatedAtFormated = useMemo((): string => {
        if (!generatedAt) {
            return "";
        }
        const formater = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
        });
        return formater.format(new Date(generatedAt));
    }, [generatedAt]);

    return (
        <Alert
            className="max-w-xs"
            type="info"
            label={
                <p className="text-default-500">
                    This report was generated by VC Volt{" "}
                    {sharedBy?.companyName && (
                        <span>
                            for{" "}
                            <span className="text-foreground">
                                {sharedBy?.companyName}
                            </span>
                        </span>
                    )}{" "}
                    {generatedAt && (
                        <span>
                            on{" "}
                            <span className="text-foreground">
                                {generatedAtFormated}
                            </span>
                        </span>
                    )}
                    .
                </p>
            }
        />
    );
};

export default ReportInfo;
