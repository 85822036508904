import { api } from "../api";
import { SearchLocationDTO, SearchLocationQuery } from "./interfaces";

const locationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        searchLocations: builder.query<SearchLocationDTO, SearchLocationQuery>({
            query: ({ type, filterCountry, ...rest }) => ({
                params: {
                    ...rest,
                    ...(filterCountry && { "filter[country]": filterCountry }),
                },
                method: "GET",
                url: `/app/shared/${type === "city" ? "locations" : "countries"}`,
            }),
        }),
    }),
});

export const { useSearchLocationsQuery } = locationApi;
