import CompanyPicture from "components/CompanyPicture";
import { cn } from "lib/utils";
import { FC, ReactNode } from "react";
import { useCompanyReportContext } from "routes/components/CompanyReport/contexts/report-context";

type Props = {
    image: string | null | undefined;
    companyName: string;
    companyDescriptionShort: string;
    className?: string;
    children?: ReactNode;
    secondaryContent?: ReactNode;
};

const CompanyHeaderCompanyDetails: FC<Props> = ({
    image,
    companyName,
    companyDescriptionShort,
    className,
    children,
    secondaryContent,
}) => {
    const { companyId } = useCompanyReportContext();

    return (
        <div className={cn("flex flex-col gap-6 lg:flex-row", className)}>
            <CompanyPicture
                size="large"
                companyId={companyId}
                name={companyName}
                image={image}
            />

            <div
                className={cn(
                    "flex min-w-0 flex-1 flex-col items-start gap-2 overflow-hidden",
                    secondaryContent && "justify-between"
                )}
            >
                <h3 className="max-w-full overflow-hidden overflow-ellipsis text-lg text-foreground">
                    {companyName}
                </h3>
                <p className="max-w-full overflow-hidden overflow-ellipsis text-sm text-default-500">
                    {companyDescriptionShort}
                </p>

                {secondaryContent}
            </div>

            {children}
        </div>
    );
};

export default CompanyHeaderCompanyDetails;
