export const AVATAR_COLORS: Array<{ from: string; to: string }> = [
    {
        from: "#E3E5E1",
        to: "#FAFAF9",
    },
    {
        from: "#DCC9F6",
        to: "#F1E6FF",
    },
    {
        from: "#E9C8D3",
        to: "#FFEFF6",
    },
    {
        from: "#E3C1B5",
        to: "#FBEAE3",
    },
    {
        from: "#C0ECEC",
        to: "#E5FDFD",
    },
    {
        from: "#C5D9EA",
        to: "#E2EFF9",
    },
    {
        from: "#E1EBAE",
        to: "#F6FBD5",
    },
    {
        from: "#CBD2BA",
        to: "#EAEEDE",
    },
    {
        from: "#D8DADC",
        to: "#F4F5F6",
    },
    {
        from: "#EFCCE6",
        to: "#FFF5FF",
    },
    {
        from: "#EEE1BA",
        to: "#FFFCE2",
    },
    {
        from: "#D5D5F6",
        to: "#F0F1FF",
    },
    {
        from: "#E6CEEF",
        to: "#FFF1FF",
    },
    {
        from: "#D2E5D9",
        to: "#F0FAF4",
    },
    {
        from: "#D6CCE4",
        to: "#F4EDFC",
    },
    {
        from: "#B3DCD5",
        to: "#E5F2F0",
    },
];
