import { cn } from "lib/utils";
import { FC } from "react";
import {
    InsightsIndividual,
    InsightsIndividualMember,
} from "store/api/investorCompany/interfaces";
import MemberInfo from "../MemberInfo";
import OverallIndividualBar from "components/OverallIndividualBar";
import Utils from "utils";

type Props = {
    individualData: InsightsIndividual;
    teamMetricValue: number;
    metric: keyof InsightsIndividualMember;
    className?: string;
};

const IndividualScores: FC<Props> = ({
    individualData,
    teamMetricValue,
    metric,
    className,
}: Props) => {
    return (
        <div className={cn("flex flex-col gap-4 p-10", className)}>
            {Object.entries(individualData)
                .sort(Utils.Insight.membersRoleNameCompareFn)
                .map(([key, value]) => {
                    const memberInfo =
                        Utils.Insight.getMemberInfoFromInsight(value);
                    const metricData = value[metric]?.value;
                    const metricValue =
                        typeof metricData === "number" ? metricData : undefined;

                    return (
                        <div
                            key={key}
                            className="grid grid-cols-7 items-center"
                        >
                            <MemberInfo
                                className="col-span-3"
                                {...memberInfo}
                            />

                            <OverallIndividualBar
                                metricValue={metricValue}
                                averageValue={teamMetricValue}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

export default IndividualScores;
