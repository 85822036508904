import { FC } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";

import router from "./routes";
import { store } from "./store";
import GleapAuth from "GleapAuth";
import GlobalModal from "containers/GlobalModal";
import GlobalPopover from "containers/GlobalPopover";
import { useBreakpoint } from "hooks/useBreakpoint";
import MobileEmptyState from "MobileEmptyState";

const App: FC = () => {
    const isMobile = useBreakpoint("sm");

    return (
        <Provider store={store}>
            <NextUIProvider locale="sv-SE" className="h-full">
                <GlobalModal />
                <GlobalPopover />
                <GleapAuth />
                {/* temporary solution to show mobile empty state */}
                {isMobile ? (
                    <MobileEmptyState />
                ) : (
                    <RouterProvider router={router} />
                )}
            </NextUIProvider>
        </Provider>
    );
};

export default App;
