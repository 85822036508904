import { Modal, ModalContent } from "@nextui-org/react";

import Button from "components/Button";

type Props = {
    show: boolean;
    title: string;
    content: React.ReactNode;

    buttonTexts?: {
        cancel?: string;
        submit?: string;
    };

    onClose: () => void;
    onSubmit: () => void;
};

const ConfirmationModal = ({
    onClose,
    show,
    onSubmit,
    content,
    title,
    buttonTexts,
}: Props) => {
    return (
        <Modal isOpen={show} onClose={onClose} size="4xl">
            <ModalContent className="!w-[600px] p-10">
                {(handleCLose) => (
                    <>
                        <div className="mb-4 text-xl">{title}</div>

                        <div>{content}</div>

                        <div className="mt-8 flex justify-end gap-3">
                            <Button
                                onClick={handleCLose}
                                customType="secondary-solid"
                            >
                                {buttonTexts?.cancel || "Cancel"}
                            </Button>
                            <Button onClick={onSubmit} customType="danger">
                                {buttonTexts?.submit || "Submit"}
                            </Button>
                        </div>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default ConfirmationModal;
