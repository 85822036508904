import { useState } from "react";
import {
    GeneratedInsightReportTeam,
    InsightsIndividualMember,
} from "store/api/investorCompany/interfaces";

export type TypesOfPassionControllerData = {
    key: keyof InsightsIndividualMember;
    label: string;
    fill: string;
    value: number;
    data: GeneratedInsightReportTeam;
};

const useTypesOfPassionController = (
    typesOfPassionData: Array<TypesOfPassionControllerData>
) => {
    const [currIdx, setCurrIdx] = useState<number>(0);

    const controlHandler = (shift: -1 | 1) => {
        let nextIdx = currIdx + shift;

        if (nextIdx < 0) {
            nextIdx = typesOfPassionData.length - 1;
        } else if (nextIdx >= typesOfPassionData.length) {
            nextIdx = 0;
        }

        setCurrIdx(nextIdx);
    };

    return {
        typesOfPassionData,
        currIdx,
        setCurrIdx,
        controlHandler,
    };
};

export default useTypesOfPassionController;
