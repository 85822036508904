import {
    CompaniesResponse,
    SetDeadlineRequest,
    EditDeadlineRequest,
    InviteCompanyRequest,
    InviteCompanyResponse,
    ArchiveCompanyResponse,
    CompanyInvitationsResponse,
    ArchiveAndUnarchiveCompanyRequest,
} from "./interfaces";
import { api } from "../api";
import Utils from "utils";

const investorApi = api.injectEndpoints({
    endpoints: (builder) => ({
        archivedInvestorCompanies: builder.query<
            CompaniesResponse,
            {
                page?: number;
                search?: string;
                orderName?: "asc" | "desc";
                orderActivity?: "asc" | "desc";
            }
        >({
            query: ({ page, search, orderName, orderActivity }) =>
                Utils.Url.createUrl(
                    "/app/investor/companies/archived",
                    new URLSearchParams({
                        ...(page && { page: page.toString() }),
                        ...(search && { search: search }),
                        ...(orderName && { "order[name]": orderName }),
                        ...(orderActivity && {
                            "order[activity]": orderActivity,
                        }),
                    })
                ),
            providesTags: ["ArchivedCompanies"],
        }),
        archiveCompany: builder.mutation<
            ArchiveCompanyResponse,
            ArchiveAndUnarchiveCompanyRequest
        >({
            query: ({ id }) => ({
                url: `/app/investor/companies/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["ArchivedCompanies", "CompanyInvitation"],
        }),
        unarchiveCompany: builder.mutation<
            ArchiveCompanyResponse,
            ArchiveAndUnarchiveCompanyRequest
        >({
            query: ({ id }) => ({
                url: `/app/investor/companies/archived/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["ArchivedCompanies", "CompanyInvitation"],
        }),
        investorCompanies: builder.query<
            CompaniesResponse,
            {
                page?: number;
                search?: string;
                orderName?: "asc" | "desc";
                orderActivity?: "asc" | "desc";
            }
        >({
            query: ({ page, search, orderName, orderActivity }) =>
                Utils.Url.createUrl(
                    "/app/investor/companies",
                    new URLSearchParams({
                        ...(page && { page: page.toString() }),
                        ...(search && { search: search }),
                        ...(orderName && { "order[name]": orderName }),
                        ...(orderActivity && {
                            "order[activity]": orderActivity,
                        }),
                    })
                ),
            providesTags: ["CompanyInvitation"],
        }),
        companyInvitations: builder.query<CompanyInvitationsResponse, void>({
            query: () => "/app/investor/company-invitations",
            providesTags: ["CompanyInvitation"],
        }),
        inviteCompany: builder.mutation<
            InviteCompanyResponse,
            InviteCompanyRequest
        >({
            query: (body) => ({
                url: "/app/investor/company-invitations",
                method: "POST",
                body: JSON.stringify(body),
            }),
            invalidatesTags: ["CompanyInvitation"],
        }),
        setDeadline: builder.mutation<
            InviteCompanyResponse,
            SetDeadlineRequest
        >({
            query: ({ companyId, ...rest }) => ({
                url: `/app/investor/companies/${companyId}`,
                method: "PATCH",
                body: JSON.stringify(rest),
            }),
            invalidatesTags: ["CompanyInvitation", "InvestorCompany"],
        }),
        editInvitationDeadline: builder.mutation<
            InviteCompanyResponse,
            EditDeadlineRequest
        >({
            query: ({ invitationId, ...rest }) => ({
                url: `/app/investor/company-invitations/${invitationId}`,
                method: "PATCH",
                body: JSON.stringify(rest),
            }),
            invalidatesTags: ["CompanyInvitation"],
        }),
        resendCompanyInvitation: builder.mutation<any, { id: string }>({
            query: ({ id }) => ({
                url: `app/investor/company-invitations/${id}/resend`,
                method: "POST",
            }),
            invalidatesTags: ["CompanyInvitation"],
        }),
        revokeCompanyInvitation: builder.mutation<any, { id: string }>({
            query: ({ id }) => ({
                url: `app/investor/company-invitations/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["CompanyInvitation"],
        }),
    }),
});

export const {
    useArchivedInvestorCompaniesQuery,
    useArchiveCompanyMutation,
    useUnarchiveCompanyMutation,

    useSetDeadlineMutation,
    useInvestorCompaniesQuery,
    useCompanyInvitationsQuery,
    useInviteCompanyMutation,
    useEditInvitationDeadlineMutation,
    useResendCompanyInvitationMutation,
    useRevokeCompanyInvitationMutation,
} = investorApi;
