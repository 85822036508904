import Avatar from "components/Avatar";
import Button from "components/Button";
import ButtonIcon from "components/ButtonIcon";

type Props = {
    memberId: string;
    name: string;
    picture: string | undefined;
    onClose: () => void;
    onSubmit: () => void;
};

const DeletionModal: React.FC<Props> = ({
    memberId,
    name,
    picture,
    onClose,
    onSubmit,
}) => {
    return (
        <div className="flex flex-col gap-8 p-10">
            <div className="flex flex-col gap-4">
                <ButtonIcon
                    icon="X"
                    onClick={onClose}
                    customType="light"
                    className="absolute right-4 top-4"
                />

                <span className="text-xl">Delete teammate?</span>

                <div className="flex flex-row items-center gap-3 text-sm">
                    <Avatar
                        size="sm"
                        memberId={memberId}
                        name={name}
                        src={picture}
                        disableTooltip
                    />
                    {name}
                </div>

                <span className="text-sm text-default-500">
                    This teammate will loose access to company surveys. All the
                    answers will be deleted from the report. This action is
                    permanent and cannot be undone.
                </span>
            </div>

            <div className="flex justify-end gap-3">
                <Button customType="secondary-solid" onClick={onClose}>
                    Cancel
                </Button>
                <Button customType="danger" onClick={onSubmit}>
                    Yes, delete
                </Button>
            </div>
        </div>
    );
};

export default DeletionModal;
