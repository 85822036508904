import { useEffect } from "react";
import { Button as NextUIButton, ButtonProps } from "@nextui-org/react";
import { cva } from "class-variance-authority";
import Icon, { IconType } from "components/Icon";
import EnterOption from "./options/Enter.option";
import { cn } from "lib/utils";

type ButtonOption = "enter";
type ButtonSize = "md" | "xl";
type IconPosition = "left" | "right";
type ButtonType =
    | "primary-solid"
    | "secondary-solid"
    | "secondary-light"
    | "white-light"
    | "danger";

type Props = ButtonProps & {
    to?: string;
    icon?: IconType;
    option?: ButtonOption;
    customType?: ButtonType;
    customSize?: ButtonSize;
    iconPosition?: IconPosition;
    iconClassName?: string;
    disableSubmitOnEnter?: boolean;
};

export const buttonVariants = cva(
    "inline-flex flex-shrink-0 items-center justify-center gap-2",
    {
        variants: {
            variant: {
                "primary-solid": cn(
                    "bg-brand-gradient text-white",
                    "hover:bg-brand-gradient-hover",
                    "focus:bg-brand-gradient-hover",
                    "focus:shadow-focus-ring",
                    "active:bg-brand-gradient-hover",
                    "disabled:bg-brand-gradient-disabled"
                ),
                "secondary-light": cn(
                    "text-black",
                    "hover:bg-default-50",
                    "focus:bg-default-50",
                    "focus:shadow-focus-ring",
                    "active:bg-default-100",
                    "disabled:text-default-400"
                ),
                "secondary-solid": cn(
                    "bg-default-50 text-black",
                    "hover:bg-default-100",
                    "focus:bg-default-100",
                    "focus:shadow-focus-ring",
                    "active:bg-default-200",
                    "disabled:bg-default-50",
                    "disabled:text-default-400"
                ),
                "white-light": cn(
                    "bg-[#ffffff26] text-white",
                    "hover:bg-[#ffffff3d]",
                    "focus:bg-[#ffffff3d]",
                    "focus:shadow-focus-ring",
                    "disabled:bg-[#ffffff26]",
                    "disabled:text-[#ffffff99]"
                ),
                danger: cn(
                    "bg-danger-500 text-white",
                    "hover:bg-danger-600",
                    "focus:bg-danger-600",
                    "focus:shadow-focus-ring",
                    "disabled:bg-danger-300",
                    "disabled:text-white"
                ),
            },
            size: {
                md: "h-btn-md rounded-sm",
                xl: "h-btn-xl rounded-md",
            },
        },
        defaultVariants: {
            variant: "primary-solid",
            size: "md",
        },
    }
);

const ICON_STYLE_TO_TYPE: Record<ButtonType, string> = {
    "primary-solid": "text-white",
    "secondary-light": "text-black",
    "secondary-solid": "text-black",
    "white-light": "text-white",
    danger: "text-white",
};

const Button = ({
    icon,
    option,
    children,
    onClick,
    className: clsNm,
    disableSubmitOnEnter = false,
    customSize = "md",
    iconPosition = "left",
    iconClassName,
    customType = "primary-solid",
    ...rest
}: Props) => {
    useEffect(() => {
        if (option !== "enter") return;

        const handleKeyDown = (event: KeyboardEvent) => {
            if (
                !disableSubmitOnEnter &&
                event.key === "Enter" &&
                !rest.disabled
            ) {
                onClick?.(event as any);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [option, rest.disabled]);

    const disabled = rest.disabled || rest.isDisabled;

    return (
        <NextUIButton
            {...rest}
            disabled={disabled}
            isDisabled={disabled}
            className={cn(
                buttonVariants({ variant: customType, size: customSize }),
                clsNm
            )}
            onPress={onClick as any}
        >
            {icon && iconPosition === "left" ? (
                <Icon
                    className={cn(
                        ICON_STYLE_TO_TYPE[customType],
                        iconClassName
                    )}
                    type={icon}
                />
            ) : undefined}

            {children}

            {icon && iconPosition === "right" ? (
                <Icon
                    className={cn(
                        ICON_STYLE_TO_TYPE[customType],
                        iconClassName
                    )}
                    type={icon}
                />
            ) : undefined}
            {option === "enter" ? <EnterOption /> : undefined}
        </NextUIButton>
    );
};

export default Button;
