import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";

// STEPS
import BillingStep from "./steps/Billing";
import DetailsStep from "./steps/Details";
import ProfileStep from "./steps/Profile";

import {
    useGetOnboardingQuery,
    useFinishOnboardingMutation,
} from "store/api/onboarding";
import Progress from "../components/Progress";
import Layout from "components/OnboardingAndAuth/Layout";
import { PageLoader } from "routes/components/PageLoader";
import { InvestorOnboardingSelectors } from "store/reducers/investorOnboarding";
import { useGetMeQuery } from "store/api/auth";
import { useAppDispatch } from "core/hooks";
import { GlobalActions } from "store/reducers/global";
import { InvestorFinishOnboarding } from "store/api/onboarding/interfaces";

type Step = {
    title: string;
    Component: any;
};

const Investor: FC = () => {
    const dispatch = useAppDispatch();
    const { refetch: meRefetch } = useGetMeQuery();
    const { isLoading } = useGetOnboardingQuery();
    const [finishOnboarding] = useFinishOnboardingMutation();
    const onboardingValues = useSelector(InvestorOnboardingSelectors.all);

    const [currStep, setCurrStep] = useState(0);
    const navigate = useNavigate();

    const handleNextClick = () => setCurrStep((prev) => ++prev);
    const handleBackClick = () =>
        setCurrStep((prev) => {
            if (prev > 0) {
                return --prev;
            }

            return prev;
        });

    const STEPS: Array<Step> = [
        { title: "Personal details", Component: ProfileStep },
        { title: "Company details", Component: DetailsStep },
        { title: "Company address", Component: BillingStep },
    ];

    useEffect(() => {
        if (currStep >= STEPS.length) {
            const {
                profile: { firstName, lastName, image },
                details: { companyName, vat, contactRole },
                billing: { street, zipCode, city, country, state },
            } = onboardingValues;
            finishOnboarding({
                vat: vat,
                companyName,
                contact: {
                    lastName,
                    firstName,
                    role: contactRole,
                    picture: image?.id!,
                },
                address: {
                    state,
                    street,
                    city,
                    country: country?.value,
                    postcode: zipCode,
                },
            } as InvestorFinishOnboarding).then((value) => {
                if ("data" in value && value.data.ok) {
                    meRefetch();
                    navigate("/investor");
                } else {
                    handleBackClick();
                    dispatch(
                        GlobalActions.showPopover({
                            type: "error",
                            label: "Something went wrong",
                        })
                    );
                }
            });
        }
    }, [currStep]);

    if (isLoading) {
        return <PageLoader />;
    }

    if (currStep >= STEPS.length) {
        return <PageLoader />;
    }

    const { Component, title } = STEPS[currStep];

    return (
        <Layout
            type="member"
            title="Enter account details"
            leftContent={
                <Progress
                    currentIndex={currStep}
                    labels={STEPS.map((i) => i.title)}
                />
            }
        >
            <Component
                title={title}
                handleNextClick={handleNextClick}
                handleBackClick={handleBackClick}
                isLastStep={currStep === STEPS.length - 1}
            />
        </Layout>
        // <OnboardingTemplate
        //     rightSection={{
        //         image,
        //         title,
        //         subTitle,
        //         content: (
        //             <VerticalProgress
        //                 data={STEPS_PROGRESS}
        //                 currentStep={currStep}
        //             />
        //         ),
        //     }}
        // >
        //     <Component
        //         handleBackClick={handleBackClick}
        //         handleNextClick={handleNextClick}
        //     />
        // </OnboardingTemplate>
    );
};

export default Investor;
