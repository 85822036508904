import { Button as NextUIButton, ButtonProps } from "@nextui-org/react";

import Icon, { IconType } from "components/Icon";
import { cn } from "lib/utils";
import React from "react";

type ButtonType = "solid" | "light" | "light-white";

type Props = ButtonProps & {
    icon?: IconType;
    text?: string;
    customType?: ButtonType;
};

const STYLE_TO_TYPE: Record<ButtonType, string> = {
    solid: `
        min-w-0 text-black w-auto !important inline-flex items-center gap-2.5 p-2.5 rounded-sm bg-[var(--default-50,#f6f6f8)]
        hover:bg-[var(--default-100,#ededf2)]
        focus:bg-[var(--default-100,#ededf2)]
        focus:shadow-[0px_0px_0px_2px_var(--primary-300,#bd97ff)]
        active:bg-[var(--default-200,#e1e1e8)]
        disabled:bg-[var(--default-50,#f6f6f8)]`,
    light: cn(
        "min-w-0 text-black w-auto !important inline-flex items-center gap-2.5 p-2.5 rounded-sm",
        "hover:bg-default-50",
        "focus:bg-default-50",
        "focus:shadow-focus-ring",
        "active:bg-default-100",
        "disabled:text-default-400 disabled:cursor-not-allowed"
    ),
    "light-white": cn(
        "group inline-flex w-auto min-w-0 items-center gap-2.5 rounded-sm bg-transparent p-2.5 text-foreground",
        "hover:bg-background",
        "focus:bg-background focus:shadow-focus-ring",
        "active:bg-background active:shadow-sm",
        "disabled:pointer-events-none disabled:text-default-400"
    ),
};

const ICON_STYLE_TO_TYPE: Record<ButtonType, string> = {
    solid: cn(""),
    light: cn("group-disabled:text-default-400"),
    "light-white": cn("group-disabled:text-default-400"),
};

const ButtonIcon = React.forwardRef<HTMLButtonElement, Props>(
    ({ icon, text, customType = "solid", className, ...rest }, ref) => {
        const styles = [STYLE_TO_TYPE[customType]].filter((i) => i).join(" ");

        return (
            <NextUIButton ref={ref} className={cn(styles, className)} {...rest}>
                {icon ? (
                    <Icon
                        type={icon}
                        className={ICON_STYLE_TO_TYPE[customType]}
                    />
                ) : (
                    text
                )}
            </NextUIButton>
        );
    }
);

export default ButtonIcon;
