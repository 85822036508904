import Utils from "utils";

type Props = {
    burnRate: number;
    runway: number;
    currencySign?: string;
};

type ItemProps = {
    title: string;
    label: string;
};

const Item: React.FC<ItemProps> = ({ title, label }) => {
    return (
        <div className="flex flex-1 flex-col items-center justify-center rounded-lg bg-default-50 py-12">
            <div className="text-center text-lg">{title}</div>
            <div className="text-center text-sm text-default-400">{label}</div>
        </div>
    );
};

const LargeStats: React.FC<Props> = ({
    burnRate,
    runway,
    currencySign = "$",
}) => {
    const burnRateTitle = `${currencySign}${Utils.Parsers.abbreviateLargeNumbers(burnRate)}/month`;
    const runwayTitle = `${runway} months`;

    return (
        <div className="flex flex-row gap-6">
            <Item label="Monthly burn rate" title={burnRateTitle} />
            <Item label="Runway" title={runwayTitle} />
        </div>
    );
};

export default LargeStats;
