type Props = {
    title: string;
    label?: string;
    children?: React.ReactNode;
};

const StatItem: React.FC<Props> = ({ label, title, children }) => {
    if (label === undefined || label === null) return null;
    if (typeof label === "string" && !label) return undefined;
    else if (Number.isNaN(label)) return undefined;

    return (
        <div className="flex flex-col gap-2 text-sm">
            <div className="text-default-400">{title}</div>
            {label && (
                <div>
                    {label.split("\n").map((line, index) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </div>
            )}
            {children}
        </div>
    );
};

export default StatItem;
