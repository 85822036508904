import { RadialChart } from "components/ui/RadialChart";
import { cn } from "lib/utils";
import { FC } from "react";
import { InsightsTeam } from "store/api/investorCompany/interfaces";

type Props = {
    teamData: InsightsTeam;
    className?: string;
};

const TypesOfPassion: FC<Props> = ({
    teamData: { ep_developing_score, ep_founding_score, ep_inventing_score },
    className,
}) => {
    const developmentScore = (ep_developing_score?.value as number) ?? 0;
    const foundingScore = (ep_founding_score?.value as number) ?? 0;
    const inventingScore = (ep_inventing_score?.value as number) ?? 0;
    return (
        <RadialChart
            className={cn("h-[240px] w-full", className)}
            data={[
                {
                    fill: "#9EBBFF",
                    value: developmentScore,
                    label: "Development",
                },
                {
                    fill: "#FCCEF1",
                    value: inventingScore,
                    label: "Inventing",
                },
                {
                    fill: "#4EDA85",
                    value: foundingScore,
                    label: "Founding",
                },
            ]}
        />
    );
};

export default TypesOfPassion;
