import { FC, useEffect, useMemo, useState } from "react";

import Empty from "./Empty";
import Input from "components/Input";
import Button from "components/Button";
import CompanyCard from "./cards/CompanyCard";
import { useInvestorCompaniesQuery } from "store/api/investor";
import EmptyCompanies from "../../../images/dashboard/investor/EmptyCompanies.png";
import Pagination from "components/Pagination";
import useSearchParamsPagination from "hooks/useSearchParamsPagination";
import PaginationDescription from "components/PaginationDescription";
import LoadingSpinner from "components-legacy/LoadingSpinner";
import SortMenu from "./components/SortMenu";
import useCompaniesSort from "./hooks/useCompaniesSort";
import { useDebounce } from "use-debounce";

type Props = {
    onInviteClick: () => void;
};

const MyCompanies: FC<Props> = ({ onInviteClick }) => {
    const [query, setQuery] = useState<string>("");
    const [debouncedQuery] = useDebounce(query, 300);
    const { page, setPage } = useSearchParamsPagination();
    const { sort, actions } = useCompaniesSort(
        [
            {
                label: "Latest activity",
            },
            {
                label: "Alphabet",
            },
        ],
        (_sort, searchParams) => setPage(1, searchParams)
    );
    const { data, isLoading, isFetching } = useInvestorCompaniesQuery({
        page: page - 1,
        search: debouncedQuery,
        orderActivity: sort === 0 ? "desc" : undefined,
        orderName: sort === 1 ? "desc" : undefined,
    });
    const companies = useMemo(() => data?.data ?? [], [data]);

    useEffect(() => {
        if (page <= 1 || !debouncedQuery) {
            return;
        }
        setPage(1);
    }, [page, debouncedQuery, setPage]);

    useEffect(() => {
        const totalPages = data?.pagination?.totalPages;
        if (!totalPages || totalPages >= page) {
            return;
        }
        setPage(totalPages);
    }, [page, data?.pagination?.totalPages, setPage]);

    const hasNoCompanies =
        !isFetching && data?.data.length === 0 && page <= 1 && !debouncedQuery;
    const noCompaniesFound = !isFetching && data?.data.length === 0;

    if (hasNoCompanies) {
        return (
            <Empty
                image={EmptyCompanies}
                title="No companies yet"
                buttonLabel="Invite company"
                onInviteClick={onInviteClick}
                subText="Companies you invited will appear here after founder onboarding"
            />
        );
    }

    return (
        <div>
            <div className="my-6 flex flex-row items-center justify-between">
                <div className="text-xl text-black">My companies</div>

                <Button onClick={onInviteClick} icon="UserPlus">
                    Invite company
                </Button>
            </div>

            <div className="mb-6 flex justify-between">
                <Input
                    value={query}
                    onChange={({ target }) => setQuery(target.value)}
                    placeholder="Search by name"
                    containerClassName="w-[364px]"
                />

                <SortMenu sort={sort} actions={actions} />
            </div>
            {hasNoCompanies && (
                <div className="py-8 text-center text-xl text-default-500">
                    You have no companies
                </div>
            )}
            {noCompaniesFound && (
                <div className="py-8 text-center text-xl text-default-500">
                    No companies found
                </div>
            )}

            {isLoading && (
                <div className="flex h-[200px] items-center justify-center">
                    <LoadingSpinner className="scale-[4]" />
                </div>
            )}

            <div className="flex items-center justify-center md:block">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                    {companies.map((company) => (
                        <CompanyCard
                            query={debouncedQuery}
                            key={company.id}
                            company={company}
                        />
                    ))}
                </div>
            </div>

            <div className="mt-12 flex items-center justify-between">
                <PaginationDescription pagination={data?.pagination} />
                <Pagination
                    onChange={(newPage) => setPage(newPage)}
                    total={data?.pagination?.totalPages ?? 0}
                    page={page}
                />
            </div>
        </div>
    );
};

export default MyCompanies;
